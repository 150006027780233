import './Login.css';

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

import NaverLogin from './NaverLogin';
import KakaoLogin from './KakaoLogin';
// import GoogleLogin from './GoogleLogin';
import LoginGoogle from './GoogleLogin';

function Login(props) {
  // const [showPw, setShowPw] = useState(false);
  // const handleShowPw = (e) => {
  //   setPassword(e.target.value);
  // };
  // const [showPw, setShowPw] = useState(false);
  // const [pw, setPw] = useState('');

  // const toggleShowPw = () => {
  //   setShowPw(!showPw);
  // };

  // const handlePw = (e) => {
  //   setPw(e.target.value);
  // };
  const APIToken = localStorage.getItem("login-token");
  const loginUserId = localStorage.getItem('user-id');
  const loginUserLevel = localStorage.getItem('user-level');
  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
  const [showChoosePopup, setShowChoosePopup] = useState(false);

  const [userInfoState, setUserInfoState] = useState('');

  // 로그인
  const [userEmail, setUserEmail] = useState();
  const [userPw, setUserPw] = useState();

  const [showPw, setShowPw] = useState(false);
  const [pw, setPw] = useState('');

  const toggleShowPw = () => {
    setShowPw(!showPw);
  };

  const handlePw = (e) => {
    setPw(e.target.value);
  };

  const handleUserEmail = (event) => {
    event.preventDefault();
    setUserEmail(event.target.value);
  }

  const handleUserPw = (event) => {
    event.preventDefault();
    setUserPw(event.target.value);
  }

  // 로그인 유효성 검사
  // var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
  var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]).*$/
  // var regexEmail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  var regexEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
  function validation () {
    if (userEmail === undefined && userPw === undefined) {
      // alert('필수 정보를 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("필수 정보를 입력해주세요.");
      return false;
    }

    if (!userEmail) {
      // alert('이메일 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("이메일 입력해주세요.");
      return false;
    }

    if (!userPw) {
      // alert('비밀번호를 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("비밀번호를 입력해주세요.");
      return false;
    }
    
    if(!regexEmail.test(userEmail)) {
      // alert('이메일 형식을 확인하세요.');
      setShowPopup(true);
      setPopupMsg("이메일 형식을 확인하세요.");
      return false;
    }

    if(!regexPw.test(userPw)) {
      // alert('비밀번호는 문자/숫자/특수문자 모두 포함하여야 합니다.');
      setShowPopup(true);
      setPopupMsg("비밀번호는 문자/숫자/특수문자 모두 포함하여야 합니다.");
      return false;
    }

    return true;
  }

  const navigate = useNavigate();

  const loginPost = () => {
    let isOK = validation();
    if(isOK){
      axios({
        method:'post',
        url:`/api/login`,
        data: { 
          email: userEmail, 
          password: userPw, 
        },
      })
      .then((result)=>{
        if (result.data.success) {
          localStorage.setItem('login-token', result.data.token);
          localStorage.setItem('user-name', result.data.data.userNm);
          localStorage.setItem('user-level', result.data.data.levelCd);
          localStorage.setItem('user-id', result.data.data.userId);
          localStorage.setItem('user-ty', result.data.data.userTy);
          localStorage.setItem('user-email', result.data.data.email);
          localStorage.setItem('user-photoUrl', result.data.data.photoUrl);
          localStorage.setItem('user-coTy', result.data.data.coTy);

          if(props.autoLoginCheckbox == true){
            localStorage.setItem('auto-login', 'true');
            localStorage.setItem('login-time', new Date().getTime() + 86400000000);
          }
          else if(props.autoLoginCheckbox == false){
            localStorage.setItem('auto-login', 'false');
            localStorage.setItem('login-time', new Date().getTime() + 86400000);
          }
          props.setIsLoggedIn(true);
        } else {
          setShowPopup(true);
          // console.log(result.data.message)
          if (result.data.message) {
            setPopupMsg(result.data.message);
          } else if (result.data.msg) {
            setPopupMsg(result.data.msg);
          }
          // setPopupMsg(result.data.message);
          // setPopupMsg(result.data.msg);
        }
      })
      .catch((error)=>{
          console.log(error)  
      })
      
      // }
      // else if(!ID)
      // {
      //     setPopupMsg("ID를 입력하세요.");
      //     setShowAddPopup(true);
      // }
      // else if(!PW)
      // {
      //     setPopupMsg("비밀번호를 입력하세요.");
      //     setShowAddPopup(true);
      // }
      // else
      // {
      //     setPopupMsg("로그인 실패");
      //     setShowAddPopup(true);
      // }
    }
  }


  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
        loginPost()
    }
  };

  const handleOnKeyPressLogin = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      setShowPopup(false);
    }
  };
  const handleOnClick = () => {
    setShowPopup(false);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      // e.preventDefault();
      // e.stopPropagation(); 
      handleOnClick();
    }
  };

  const closeButtonRef = useRef(null);
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [showPopup]);

  // useEffect(() => {
  //   if (props.isLoggedIn && props.userProfile) {
  //     if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
  //       // setShowChoosePopup(true);
  //       // setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //       setBool(true);
  //     } else {
  //       navigate("/mydropia");
  //     }
  //   }
  // }, [props.isLoggedIn, props.userProfile])

  // const [bool, setBool] = useState(false);
  // useEffect (() => {
  //   if (bool) {
  //     // setShowChoosePopup(true);
  //     // setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //   }
  // }, [bool])
  useEffect(() => {
    if (props.isLoggedIn && props.userProfile) {
      if (props.userProfile.id_type_cd === 1) {
        console.log(props.userProfile.prj_ty_cd_arr);
        if (props.userProfile.intro === null || props.userProfile.prj_ty_cd_arr === null || props.userProfile.prj_ty_cd_arr.length === 0) {
          // setShowChoosePopup(true);
          // setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
          setBool(true);
        } else {
          navigate("/mydropia");
        }
      }
    }
  }, [props.isLoggedIn, props.userProfile])

  const [bool, setBool] = useState(false);
  useEffect (() => {
    if (bool && props.userProfile.id_type_cd === 1) {
      setShowChoosePopup(true);
      setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
    }
  }, [bool])
  
  useEffect(() => {
    // console.log(loginUserId);
    // console.log(props.isLoggedIn);
    // console.log(props.userProfile);
    if (props.isLoggedIn && props.userProfile) {
      // 관리자일때
      if (loginUserLevel === '100') {
        navigate("/manager");
      }
      if (props.userProfile.id_type_cd === 2 || props.userProfile.id_type_cd === 3 || props.userProfile.id_type_cd === 4) {
        if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
          if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
            setShowChoosePopup(true);
            setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
          }
          navigate("/mydropia");
          // setShowChoosePopup(true);
          // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
        } else if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
          setUserInfoState('required');
          setShowPopup(true);
          setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
        } else  {
          navigate("/mydropia");
        }
  
      }
      // if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
      //   if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
      //     setShowChoosePopup(true);
      //     setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      //   }
      //   // setShowChoosePopup(true);
      //   // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      // } else if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
      //   setUserInfoState('required');
      //   setShowPopup(true);
      //   setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      // } else  {
      //   navigate("/mydropia");
      // }


      // if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
      //   setUserInfoState('required');
      //   setShowPopup(true);
      //   setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      // } else if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
      //   if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
      //     setShowChoosePopup(true);
      //     setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      //   }
      //   // setShowChoosePopup(true);
      //   // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
      // } else {
      //   navigate("/mydropia");
      // }
    }
  }, [props.isLoggedIn, props.userProfile])

  // useEffect(() => {
  //   // if (props.isLoggedIn && props.userProfile) {
  //   //   if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
  //   //     // setShowChoosePopup(true);
  //   //     // setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //   //     setBool(true);
  //   //   } else {
  //   //     navigate("/mydropia");
  //   //   }
  //   // }
  //   if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
  //     setUserInfoState('required');
  //     setShowPopup(true);
  //     setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //   } else if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
  //     if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
  //       setShowChoosePopup(true);
  //       setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     }
  //     // setShowChoosePopup(true);
  //     // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //   } else {
  //     navigate("/mydropia");
  //   }
  // }, [props.isLoggedIn, props.userProfile])


  const brCount = (popupMsg && popupMsg.match(/<br>/g) || []).length;
  // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
  const fontSizeValue = brCount === 0 ? '18px' : '16px';

  // 네이버 (NAVER)
	// const NAVER_CLIENT_ID = "74ot6yROEbINPB6pg7E4" // 발급 받은 Client ID 입력 
	// const NAVER_CALLBACK_URL = "http://localhost:3600/auth/naver" // 작성했던 Callback URL 입력
	// const NAVER_CALLBACK_URL = "http://localhost:4000/auth/naver" // 작성했던 Callback URL 입력

  // 구글 (Google)
  // const GOOGLE_CLIENT_ID = "596412713335-b0g23ll80n4ps3mgo0j1decb5tgo46c7.apps.googleusercontent.com";
  // const GOOGLE_REDIRECT_URI = "http://localhost";

  return (
    <React.Fragment>
      <div className='flex-center contents-col Login-container'>
        <label className='login-text'>로그인</label>
        <input 
          className='login email' 
          type="text" 
          placeholder='이메일'
          value={userEmail || ''}
          onChange={handleUserEmail}
          onKeyDown={handleOnKeyPress}
        />
        <div className="input-container">
          <input 
            className='login pw'
            type={showPw  ? "text" : "password"}
            // value={pw} 
            // onChange={handlePw}
            value={userPw || ''} 
            onChange={handleUserPw}
            onKeyDown={handleOnKeyPress}
            placeholder='비밀번호'
          ></input>
          <i className={showPw ? "fa-solid fa-eye showPwIcon" : "fa-solid fa-eye-slash showPwIcon"} onClick={toggleShowPw}></i>
        </div>
        <div className='contents-row sb'>
          <div className='contents-row'>
            <input 
              className='stay-login'
              type='checkbox'
              checked={props.autoLoginCheckbox}
              onChange={props.handleAutoLoginCheckbox}
            />
            <label onClick={props.handleAutoLoginCheckbox}>로그인 상태 유지</label>
          </div>
          <label className='find-pw'>비밀번호 찾기</label>
        </div>
        <div className='gc-btn login-btn' onClick={loginPost}>로그인</div>
        <div className='hr-solid'/>
        <label>SNS 계정으로 로그인</label>
        {/* <div className='flex-center sns-login kakao'>
          <div className='symbol kakao-symbol'></div>
          <span className='sns-text'>카카오톡 로그인</span>
        </div>
        <div className='flex-center sns-login naver'>
          <div className='symbol naver-symbol'></div>
          <span className='sns-text'>네이버 로그인</span>
        </div>
        <div className='flex-center sns-login google'>
          <div className='symbol google-symbol'></div>
          <span className='sns-text'>Google 로그인</span>
        </div> */}
        <NaverLogin 
          // clientId={NAVER_CLIENT_ID} 
          // callbackUrl={NAVER_CALLBACK_URL}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          // setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        />
        <KakaoLogin 
          // clientId={NAVER_CLIENT_ID} 
          // callbackUrl={NAVER_CALLBACK_URL}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          // setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        />
        {/* <GoogleLogin 
          clientId={GOOGLE_CLIENT_ID} 
          callbackUrl={GOOGLE_REDIRECT_URI}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          // setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        /> */}
        <LoginGoogle 
          // clientId={GOOGLE_CLIENT_ID} 
          // callbackUrl={GOOGLE_REDIRECT_URI}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          // setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        />
        <p>아직&nbsp;<span className='dropia'>Dropia</span>회원이 아니신가요?<span >&emsp;<Link to={'/signup'} className='signup'>회원가입</Link></span></p>
      </div>

      {/* <Popup
        show={showPopup}
        header=""
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p>{popupMsg}</p>
          <div 
            ref={closeButtonRef}
            className="contents-row gc-btn flex-center close_btn" 
            onClick={handleOnClick} 
            onKeyDown={handleOnKeyDown} 
            tabIndex="0" 
            // role="button" 
          >
            닫기
          </div>
        </div>
      </Popup> */}
      <Popup
        show={showPopup}
        // onCancel={() => setShowAddPopup(false)}
        header=""
        // icon={<i className="code x icon"  
        //         onClick={() => {
        //             // setShowAddPopup(false);
        //             setShowPopup(false);
        //         }} 
        //     /> }
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p
            style={{
              fontSize: fontSizeValue,
              // padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          >
            {/* {popupMsg} */}
          </p>
          <div 
            className="contents-row gc-btn flex-center close_btn" 
            onClick={() => {
              setShowPopup(false);
              // console.log(props.isLoggedIn)
              if (props.isLoggedIn && userInfoState === 'required') {
                navigate("/profile");
              }
              // setUserInfoState('')
            }}  
          >{props.isLoggedIn ? '확인' : '닫기'}</div>
        </div>
      </Popup>

      <Popup
        show={showChoosePopup}
        header=""
      >
        <div className='contents-col full-width flex-center popup-info'>
          {/* <p>{popupMsg}</p> */}
          <p
            style={{
                fontSize: fontSizeValue,
                // padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <div className='contents-row flex-center'>
            <div 
              className="contents-row gc-btn flex-center close_btn no" 
              onClick={() => {
                setShowChoosePopup(false);
                navigate("/mydropia");
              }}  
            >
              아니요
            </div>
            <div 
              className="contents-row gc-btn flex-center gc-btn close_btn yes" 
              onClick={() => {
                setShowChoosePopup(false);
                navigate("/profile");
              }}  
            >
              네
            </div>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default Login;
