import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Backdrop from './Backdrop';
import './Modal.css';

const ModalOverlay = props => {

    const [header, setHeader] = useState(true);
  
    useEffect(()=>{
      setHeader(props.header);
    }
    ,[])

    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, []);
    
    const content = (
      <div className={`modal ${props.className}`} style={props.style}>
        {
        header?
        <header className={`modal__header ${props.headerClass}`}>
          <span className='modal_header'>{props.header}</span>
          <span className='modal_icon'style={props.style} >{props.icon}</span>
        </header>
        : <span className='modal_icon'style={props.style} >{props.icon}</span>
        }
        <form
          onSubmit={
            props.onSubmit ? props.onSubmit : event => event.preventDefault()
          }
        >
          <div className={`modal__content ${props.contentClass}`}>
            {props.children}
          </div>
          {/* <footer className={`modal__footer ${props.footerClass}`}>
            {props.footer}
          </footer> */}
        </form>
      </div>
    );
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
  };
  
  const Modal = props => {
    return (
      <React.Fragment>
        {props.show && <Backdrop onClick={props.onCancel} />}
        <CSSTransition
          in={props.show}
          mountOnEnter
          unmountOnExit
          timeout={200}
          classNames="modal"
        >
          <ModalOverlay {...props} />
        </CSSTransition>
      </React.Fragment>
    );
  };
  
  export default Modal;