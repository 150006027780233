import './Map.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate , useParams } from 'react-router-dom';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { Dropdown, Icon, Pagination } from 'semantic-ui-react';

import ProductReg from '../../routes/Product/ProductReg';
import ProductDetail from '../../routes/Product/ProductDetail';

import PreviewImage_Noimage from './no-image.png'

// AXIOS
import axios from "axios";

import turf from "turf"


function Map(props) {

  const APIToken = localStorage.getItem("login-token");
  const userID = localStorage.getItem('user-id');
  const userType = localStorage.getItem('user-ty');

  // 사이드바 ON/OFF
  const [sidebarHidden, setSidebarHidden] = useState(false);
  // 요약정보 ON/OFF
  const [substract, setSubstract] = useState(false);
  // 선택 산출물
  const [selectedProduct, setSelectedProduct] = useState(0);
  // 미리보기 이미지
  const [previewImage, setPreviewImage] = useState();
  // 산출물 등록 ON/OFF
  const [productRegModal, setProductRegModal] = useState(false);
  // 산출물 상세보기 ON/OFF
  const [productDetailModal, setProductDetailModal] = useState(false);
  // 일반지도&영상지도
  const [onmapSat, setOnmapSat] = useState(true);
  // 산출물 목록
  const [productList, setProductList] = useState();
  // 산출물 개수
  const [productCount, setProductCount] = useState([]);
  // 검색어
  const [searchKeyword, setSearchKeyword] = useState('');
  // 하단 알림
  const [bottomMsg, setBottomMsg] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        // getProductList();
    }
};

  // const [activePage, setActivePage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);

  // const handlePagination = (event, data) => {
  //   setActivePage(data.activePage);
  // };
  
  // const pageSize = 10;
  
  const onmapHandler = (e) => {setOnmapSat(e);}

  const navigate = useNavigate();

  const mapContainer = useRef(null);
  const mapRef = useRef(null);

  const MAPLIB_KEY = 'KpJ2HZtu5M21qcys8AVL';
	// const VWORLD_URL = 'https://api.vworld.kr/req/wmts/1.0.0';
	const VWORLD_URL = 'https://api.vworld.kr/req/wmts/1.1.1';
	const VWORLD_KEY = '18A51781-1604-33A6-8E99-EFEE908F7323';
	// const VWORLD_KEY = 'DEF9B04D-5D98-3A49-8D93-9479EF8F018C';

	const vworldSat = VWORLD_URL + '/' + VWORLD_KEY + '/Satellite/{z}/{y}/{x}.jpeg'
  const vworldBase = VWORLD_URL + '/' + VWORLD_KEY + '/Base/{z}/{y}/{x}.png'

  // Layer ID
	let V_WORLD_MAP_ID = "vworld_map_satellite";

  // Layer Source
	let V_WORLD_MAP_SRC = {
    type: "raster",
    minzoom: 6,
    maxzoom: 19,
    tiles: [vworldSat],
    tileSize: 256,
  };

  // Layer
	let V_WORLD_MAP_LAYER = {
    id: V_WORLD_MAP_ID,
    type: 'raster',
		source: V_WORLD_MAP_SRC,
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'raster-opacity': 1.0,
    },
  };

  let V_WORLD_MAP_BASE_ID = "vworld_map_base";

	let V_WORLD_MAP_BASE_SRC = {
    type: "raster",
    minzoom: 6,
    maxzoom: 19,
    tiles: [vworldBase],
    tileSize: 256,
  };

	let V_WORLD_MAP_BASE_LAYER = {
    id: V_WORLD_MAP_BASE_ID,
    type: 'raster',
		source: V_WORLD_MAP_BASE_SRC,
    layout: {
      'visibility': 'none'
    },
    paint: {
      'raster-opacity': 1.0,
    },
  };

  useEffect(() => {
    if (mapRef.current) return; // stops map from intializing more than once
  
    mapRef.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/basic/style.json?key=${MAPLIB_KEY}`,
      //style: vworldBase,
      center: [127.095125, 37.211971],
      zoom: 14,
    });

    mapRef.current.on('load', ()=>{

      mapRef.current.addLayer(V_WORLD_MAP_LAYER);
      mapRef.current.addLayer(V_WORLD_MAP_BASE_LAYER);

      mapRef.current.setMaxBounds([[124.0, 33.0], [132.0, 39.0]]);

      mapRef.current.addControl(new maplibregl.NavigationControl(), 'bottom-left');

    })
  }, []);

  useEffect(()=>{
    if(mapRef.current && mapRef.current.getLayer(V_WORLD_MAP_ID)){
      if(onmapSat){
        mapRef.current.setLayoutProperty(V_WORLD_MAP_ID, 'visibility', 'visible');
        mapRef.current.setLayoutProperty(V_WORLD_MAP_BASE_ID, 'visibility', 'none');
      }
      else if(!onmapSat){
        mapRef.current.setLayoutProperty(V_WORLD_MAP_ID, 'visibility', 'none');
        mapRef.current.setLayoutProperty(V_WORLD_MAP_BASE_ID, 'visibility', 'visible');
      }
    }
  },[onmapSat])

  useEffect(()=>{
    if(sidebarHidden == true)
      setSubstract(false);
    else if(sidebarHidden == false)
      setSubstract(true);
  },[sidebarHidden])

  // useEffect(() => {
  //   getProductList();
  // }, []);

  // 산출물 리스트 페이지네이션
  const pageSize = 8;
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listTotalNm, setListTotalNm] = useState();

  const handlePagination = (event, data) => {
    setActivePage(data.activePage);
  };

  // 산출물 리스트 불러오기
  function getProductList () {
    axios({
      method:'get',
      url:`/api/output/list`
      +`?page=${activePage}`
      +`&perPage=${pageSize}`
      +`&keyword=${searchKeyword}`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      console.log(result);
      if(result.data.success == true){
        let product_list = result.data.data;
        setProductList(product_list);
        setListTotalNm(result.data.total);
        setTotalPages(Math.ceil(result.data.total / pageSize));
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  useEffect(() => {
    getProductList();
  }, [activePage]);

  // 등록 산출물 개수 산출
  useEffect(()=>{
    let array = [0, 0, 0, 0, 0, 0];
    if(productList){
      for(let i=0 ; i<productList.length ; i++){
        if(productList[i].output_ty_cd == 1){array[0]++}
        else if(productList[i].output_ty_cd == 2){array[1]++}
        else if(productList[i].output_ty_cd == 3){array[2]++}
        else if(productList[i].output_ty_cd == 101){array[3]++}
        else if(productList[i].output_ty_cd == 102){array[4]++}
        else if(productList[i].output_ty_cd == 103){array[5]++}
      }
    }
    setProductCount(array);
  },[productList])

  useEffect(()=>{
    if(selectedProduct.output_preview){
      setPreviewImage({
        backgroundImage: `url(${selectedProduct.output_preview})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '200px',
        height: '180px',
      })
    }
    else{
      setPreviewImage({
        backgroundImage: `url(${PreviewImage_Noimage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '200px',
        height: '180px',
      })
    }
  },[selectedProduct])

  // Zoomlevel
  // const [zoomLevel, setZoomLevel] = useState(120);

  // useEffect(()=>{
  //   if(mapRef.current != null){
  //     mapRef.current.on('zoom', ()=>{
  //       const map = mapRef.current.getMap();
  //       const zoomlevel = map.getZoom();
  //       setZoomLevel(zoomlevel * 10);
  //     })

  //     // mapRef.current.on('click', ITRST_LAYER_ID, function (e) {
  //     //   var feature = e.features[0];
  //     //   var featureProperties = feature.properties;
  //     //   console.log(featureProperties);
  //     // });
  //   }
    
  // },[mapRef.current])

  // const zoomlevelHandler = (e) => {
  //   const map = mapRef.current.getMap();
  //   map.setZoom(e.target.value / 10);
  // }

  return (
    <div className='product-container'>
		  <div className={`map-wrap contents-row ${sidebarHidden ? 'wide' : ''}`}>
      	<div ref={mapContainer} className="map">
        </div>
      </div>

      <div className="mapcontrol-container contents-col">
        <div className="map-select contents-col flex-center">
          <div className={`aerial-image btn ${onmapSat? 'active' : ''}`} onClick={()=>{
            onmapHandler(true);
          }}>항공</div>
          <div className={`normal-image btn ${onmapSat? '' : 'active'}`} onClick={()=>{
            onmapHandler(false);
          }}>일반</div>
        </div>
      </div>

      <div className={`sidebar-container ${sidebarHidden ? 'hidden' : ''}`}>
        <div className='product-management'>
          <div className='header-text'>산출물 관리</div>
          <div className='product-summary-box primary contents-col'>
            <div className='sub-text'>일반 미디어</div>
            <div className='contents-row'>
              <div className='group contents-row'>
                <div className='content-text-typeA'>이미지</div>
                <div className='content-text-typeB'>{productCount? productCount[0] : 0}</div>
                <div className='content-text-typeC'>점</div>
              </div>
              <div className='flex-spacer'></div>
              <div className='group contents-row'>
                <div className='content-text-typeA'>비디오</div>
                <div className='content-text-typeB'>{productCount? productCount[1] : 0}</div>
                <div className='content-text-typeC'>점</div>
              </div>
            </div>
            <div className='group contents-row'>
              <div className='content-text-typeA'>기타</div>
              <div className='content-text-typeB'>{productCount? productCount[2] : 0}</div>
              <div className='content-text-typeC'>점</div>
            </div>
          </div>
          <div className='product-summary-box secondary contents-col'>
            <div className='sub-text'>공간정보 데이터</div>
            <div className='contents-row'>
              <div className='group contents-row'>
                <div className='content-text-typeA'>정사영상</div>
                <div className='content-text-typeB'>{productCount? productCount[3] : 0}</div>
                <div className='content-text-typeC'>점</div>
              </div>
              <div className='flex-spacer'></div>
              <div className='group contents-row'>
                <div className='content-text-typeA'>DEM</div>
                <div className='content-text-typeB'>{productCount? productCount[4] : 0}</div>
                <div className='content-text-typeC'>점</div>
              </div>
            </div>
            <div className='group contents-row'>
              <div className='content-text-typeA'>3D 모델</div>
              <div className='content-text-typeB'>{productCount? productCount[5] : 0}</div>
              <div className='content-text-typeC'>점</div>
            </div>
          </div>
          <div 
            className='product-reg-button flex-center'
            onClick={()=>{
              if(props.isLoggedIn)
                setProductRegModal(true);
              else
                navigate('/login')
              }}
          >등록하기
          </div>
        </div>
        <div className='product-list'>
          <div className='contents-row'>
            <div className='header-text'>산출물 목록 조회</div>
            <div className='flex-spacer'></div>
            <div className='content-text-typeC'>전체</div>
            <div className='content-text-typeC input'>{listTotalNm}</div>
            <div className='content-text-typeC'>건</div>
          </div>
          <div className='search contents-row'>
            <Dropdown
              className='dropdown'
              placeholder='전체'
              fluid
              selection
              icon='angle down'
              options={[
                {text: '전체', value: true},
                // {text: '이름', value: false},
              ]}
            >
            </Dropdown>
            <input 
              className='search-keyword' 
              type='text'
              value={searchKeyword}
              onChange={(e)=>{setSearchKeyword(e.target.value)}}
              onKeyDown={handleKeyPress}
            ></input>
            <div className="search-icon" onClick={getProductList}></div>
          </div>
          <div className='contents-col'>
            {
              productList && productList.map((product, i) => {
              return (
                // i >= (activePage - 1) * pageSize &&
                // i < activePage * pageSize && (
                  <div key={i} className='contents-row product-item'>
                    <div className={`product-item-mark ${product.output_ty_cd == 1 || product.output_ty_cd == 2 || product.output_ty_cd == 3? 'green':'blue'} flex-center`}>
                      {
                      product.output_ty_cd == 1? '이미지' :  
                      product.output_ty_cd == 2? '비디오' :
                      product.output_ty_cd == 3? '기타' :
                      product.output_ty_cd == 101? '정사영상' :
                      product.output_ty_cd == 102? 'DEM' :
                      product.output_ty_cd == 103? '3D' :
                      null
                      }
                    </div>
                    <div 
                      className='product-item-name align-center'
                      onClick={()=>{
                        setSubstract(true);
                        setSelectedProduct(product);

                        if(product.output_geojson == null){
                          mapRef.current.jumpTo({  
                            center: [127.095125, 37.211971],
                            zoom: 13});
                          setBottomMsg(true);
                        }
                        else{
                          setBottomMsg(false);
                          let geojsondata = JSON.parse(product.output_geojson);
                          let center = turf.center(geojsondata);
                          mapRef.current.jumpTo({  
                            center: [center.geometry.coordinates[0], center.geometry.coordinates[1]],
                            zoom: 15});
                        }

                        if (product.output_ty_cd == 101){

                          let ORTHO_IMAGE_ID = `layer_${product.output_id}`
                          let ORTHO_IMAGE_SRC = {
                            type: "raster",
                            tiles: 
                            ["http://geocapture.co.kr:8080/geoserver"
                            + "/gwc/service/wmts?SERVICE=WMTS&REQUEST=GetTile&LAYER="
                            + `${product.output_layer_nm}`
                            + "&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&format=image%2Fpng&TileCol={x}&TileRow={y}"],
                            bounds:[
                              product.output_latlon_bbox[0], 
                              product.output_latlon_bbox[3],
                              product.output_latlon_bbox[2],
                              product.output_latlon_bbox[1]
                            ]
                          }
                        
                          let ORTHO_IMAGE_LAYER = {
                            id: ORTHO_IMAGE_ID,
                            type: 'raster',
                            source: ORTHO_IMAGE_SRC,
                            layout: {
                              'visibility': 'visible'
                            },
                            paint: {
                              'raster-opacity': 1.0,
                            },
                          }
                          if(!mapRef.current.getLayer(ORTHO_IMAGE_ID)){
                            mapRef.current.addLayer(ORTHO_IMAGE_LAYER);
                          }
                          mapRef.current.moveLayer(ORTHO_IMAGE_ID);
                        }
                      }}
                    >{product.output_title}</div>
                  </div>
                // )
              )
              })
            }
            <div className="pagination-container">
              <Pagination
                className="pagination"
                // activePage={1}
                // totalPages={1}
                activePage={activePage}
                totalPages={totalPages}
                ellipsisItem={{ key: 'ellipsisItem-1', content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                onPageChange={handlePagination}
              />
            </div>
          </div>

        </div>
        <div className={`sidebar-hide-button ${sidebarHidden ? 'hidden' : ''}`} onClick={()=>{setSidebarHidden(!sidebarHidden)}}></div>
      </div>

        {
        substract && selectedProduct?
        <div className={`substract-container ${substract ? 'active' : ''}`}>
          <div className='product-item-name'>{selectedProduct.output_title}</div>
          <div className='product-item-address'>{selectedProduct.output_addr}</div>
          {
            selectedProduct.output_preview?
            <div className='preview-image' style={previewImage}></div>
            :
            <div className='preview-image' style={previewImage}></div>
          }
          <div className='flex-center'>
            <div 
              className='product-item-detail flex-center' 
              onClick={()=>{
                if(props.isLoggedIn)
                  setProductDetailModal(true)
                else
                  navigate('/login')
              }}
            >상세보기</div>
          </div>
        </div>
        :
        null
        }

      {
        productRegModal?
        <ProductReg
          productRegModal={productRegModal}  
          setProductRegModal={setProductRegModal}

          getProductList={getProductList}
        ></ProductReg>
        :
        null
      }

      {
        productDetailModal?
        <ProductDetail
          productDetailModal={productDetailModal}  
          setProductDetailModal={setProductDetailModal}
          substract={substract}
          setSubstract={setSubstract}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}

          getProductList={getProductList}
        ></ProductDetail>
        :
        null
      }

      {
        bottomMsg?
        <div className='bottom-message flex-center contents-col'>
          <div>※ 등록된 위치정보가 없습니다.</div>
          <div>(GeoJson is Null)</div>
        </div>
        :
        null
      }
    </div>
  );
}

export default Map;