import './Profile.css';
import './Myprofile.css';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Pagination, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// AXIOS
import axios from "axios";

function Myprofile({ profileData, portfolioList, setProfileState, reviewList, reviewRankList }) {
    
    const APIToken = localStorage.getItem("login-token");
    const loginUserId = localStorage.getItem('user-id');
    const loginUserType = localStorage.getItem('user-ty');

    const barCharts = useRef();
    const navigate = useNavigate();

    const [projectList, setProjectList] = useState();

    useEffect (() => {
        let urlstring = `/api/project/list?`;

        if(loginUserType == 1){ // 파트너
            urlstring += `partnerId=${loginUserId}`;
        }
        else if(loginUserType == 2){ // 클라이언트
            urlstring += `userId=${loginUserId}`;
        }

        axios({
            method:'get',
            url: urlstring,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            if(result.data.success === true) {
                let project_list = result.data.data;
                setProjectList(project_list);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }, [])

    const columnCalculateBackgroundColor = (value) => {
        if (isNaN(value) || value === 0) {
            return '#D9D9D9';
        } else {
            const ratio = value / reviewList.total;
            return `linear-gradient(to top, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
        }
    };

    const rowCalculateBackgroundColor = (rowValue) => {
        const value = parseFloat(rowValue);
        if (isNaN(value) || value === 0) {
            return '#D9D9D9';
        } else {
            const ratio = value / 5;
            return `linear-gradient(to right, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
        }
    };

    // Dropia 프로젝트 n년 계산
    const [ageInYears, setAgeInYears] = useState('');
    useEffect(() => {
        // 프로필 가입일을 Date 객체로 변환
        const joinDateObj = new Date(profileData.hist_join_dt);
        // 현재 날짜를 가져오기
        const currentDate = new Date();
        // 년수 계산
        setAgeInYears(currentDate.getFullYear() - joinDateObj.getFullYear());
    }, [])

    // console.log(profileData.projectTypeMapping);

    // 분야 (1:일반 레포츠 / 2:미디어 촬영 /  3:시설점검 / 4:공간정보 / 5:농업, 방제 / 6.기타 - 직접입력)
    const fieldLabels = {
        1: '일반 레포츠',
        2: '미디어 촬영',
        3: '시설점검',
        4: '공간정보',
        5: '농업,방제',
        6: '기타',
    };

    // console.log(profileData);
    // console.log(profileData.user_ty);

    // 내가 받은 리뷰 요약 별점
    const lineStyle = {
        position: 'relative',
        width: '101.25px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    };

    // 회사연혁 pagination
    const itemsPerPage = 2;
    const [activePage, setActivePage] = useState(1);

    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    console.log(profileData.co_history_arr)
    const totalPages = Math.ceil(profileData.co_history_arr && profileData.co_history_arr.length / itemsPerPage);
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentPageItems = profileData.co_history_arr != null && profileData.co_history_arr.slice(startIndex, startIndex + itemsPerPage);
    console.log(currentPageItems);

    // 데이터가 모두 비어있는지 확인하는 함수
    const allItemsEmpty = currentPageItems && currentPageItems.every(item => !item.date && !item.content);

    console.log(profileData.prj_ty_custom)

    return (
        <React.Fragment>
            <div className='contents-col full-width'>
                <div className='contents-row full-width my-profile-box'>
                    <div className='contents-row flex-center progress-box user-info spacer'>
                        {!profileData.photoUrl ? (
                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                            <div className='user-logo-img none '></div>
                        ) : (
                            <img
                                className="user-logo-img"
                                src={profileData.photoUrl}
                                alt="Uploaded Image"
                                // style={{ maxWidth: '100%', maxHeight: '70px' }}
                            />
                        )}
                         <div className='contents-col user-info-box'>
                            <p className='user-nm'>{profileData.user_nm}</p>
                            <div className='contents-row my-profile-tag-box'>
                                {profileData.prj_ty_cd_arr && profileData.prj_ty_cd_arr.length !== 0 ? 
                                // profileData.prj_ty_cd_arr.map((code, index) => (
                                //     // console.log(fieldLabels[code])
                                //     <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>{fieldLabels[code]}</div>
                                // ))
                                // profileData.prj_ty_cd_arr.map((code, index) => (
                                //     fieldLabels[code] !== "기타" && (
                                //         <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                                //             {fieldLabels[code]}
                                //         </div>
                                //     )
                                // ))

                                profileData.prj_ty_cd_arr.map((code, index) => (
                                    fieldLabels[code] === "기타" ? (
                                        <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                                            {profileData.prj_ty_custom}
                                        </div>
                                    ) : (
                                        <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                                            {fieldLabels[code]}
                                        </div>
                                    )
                                ))
                                :
                                <p className='result-info'>선택한 분야 정보가 없습니다.</p>}
                            </div>
                        </div> 
                    </div>
                    <div className='contents-row spacer'>
                        <div className='contents-col flex-center privacy-box'>
                            {console.log(profileData.co_years)}
                            <div className='contents-row privacy-row'>
                                <div className='gc-btn flex-center info-btn privacy years'>경력 {profileData.co_years === null ? '0' : profileData.co_years}년</div>
                                {/* <div className='gc-btn flex-center info-btn privacy'>{profileData.co_ty === 1 ? '개인' : profileData.co_ty === 2 ? '개인사업자' : '법인사업자'}</div> */}
                                {profileData && profileData.co_ty &&
                                    <div className='gc-btn tag-flex flex-center info-btn privacy'>
                                        {profileData && profileData.co_ty === 1 ? '개인' : profileData && profileData.co_ty === 2 ? '개인사업자' : profileData && profileData.co_ty === 3 ? '법인사업자' : null}
                                    </div>
                                }
                            </div>
                            <label>{profileData.sigungu_nm_arr[0] === '전체' && profileData.sigungu_nm_arr[1] === '전체' ? '전체' : profileData.sigungu_nm_arr.join(' ')}</label>
                        </div>
                        <div className='contents-col flex-center progress-box'>
                            <p className='sub-title'>Dropia 활동</p>
                            <div className='contents-row flex-center full-width'>
                                
                                <p className='flex-center'>{ageInYears}년</p>
                                <p className='flex-center prj-result'>{projectList && projectList.filter(project => project.prj_stts_cd === 8).length}건</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='profile-intro'>{profileData.intro === 'null' ? '' : profileData.intro}</div>
                {/* <div className='profile-intro'>Lorem ipsum dolor sit amet consectetur. Eu amet gravida odio amet faucibus diam velit augue. Nisi nibh accumsan fermentum feugiat montes posuere integer elit neque. Non tellus enim ac massa. Tempus arcu ornare massa vehicula ut odio.</div> */}
            </div>
            <div className='hr-solid'/>
            {reviewList && (
            <div className='contents-row full-width'>
                <div className='contents-col rating-container'>
                    <div className='contents-row flex-center portfolio-menubar rating-box'>
                        <div className='main-text'>내가 받은 리뷰 요약</div>
                        <div className='contents-row flex-center'>
                            <div className='contents-row '>
                                <div className='contents-row star-rating-box' style={lineStyle}>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                                <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewList.data.length === 0 ? 0 : reviewList.avgAvr / 5) * 101.25) + 'px' }}>
                                {/* <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewList.avgScore5 / 5) * 101.25) + 'px' }}> */}
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                            </div>
                            <p>{reviewList.data.length === 0 ? '0' : reviewList.avgAvr} &nbsp;<span className='rating'>/5</span></p>
                            <p className='peoplee-num'>{reviewList.data.length === 0 ? '0' : reviewList.total}명 평가</p>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>전문성</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore1), position: 'relative' }}></div>
                            <label>{reviewList.avgScore1}</label>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>적극성</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore2), position: 'relative' }}></div>
                            <label>{reviewList.avgScore2}</label>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>만족도</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore3), position: 'relative' }}></div>
                            <label>{reviewList.avgScore3}</label>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>일정준수</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore4), position: 'relative' }}></div>
                            <label>{reviewList.avgScore4}</label>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>의사소통</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore5), position: 'relative' }}></div>
                            <label>{reviewList.avgScore5}</label>
                        </div>
                        <div className='contents-row full-width lex-center rating-factors'>
                            {/* <label>하자보수</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(defectRepair_Cnt), position: 'relative' }}></div>
                            <label>{defectRepair_Cnt.toFixed(2)}</label> */}
                        </div>
                    </div>
                </div>
                {/* <div className='contents-row full-width flex-center spacer'>
                    
                </div> */}
                <div className='contents-row full-width flex-center rating-container overall-rating-box'>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_a}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_a), position: 'relative' }}></div>
                        <span>최고</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_b}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_b), position: 'relative' }}></div>
                        <span>좋음</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_c}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_c), position: 'relative' }}></div>
                        <span>보통</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_d}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_d), position: 'relative' }}></div>
                        <span>별로</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_e}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_e), position: 'relative' }}></div>
                        <span>최악</span>
                    </div>
                </div>
            </div>
            )}
            {/* <div className='main-text'>평점</div>
            <div className='contents-row full-width'>
                <div className='contents-col spacer'>
                    <div className='contents-row full-width flex-center rating-box my-profile'>
                        <div className='contents-row star-rating-box'>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                        </div>
                        <p>3.56 &nbsp;<span className='rating'>/5</span></p>
                        <p>22명 평가</p>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>전문성</label>
                            <div className='full-width bar-chart'></div>
                            
                            <div className='full-width bar-chart' ref={barCharts} style={{ background: '#D9D9D9', position: 'relative', overflow: 'hidden' }}>
                                {renderColoredDivs()}
                            </div>
                            <div className='full-width bar-chart' style={{ background: calculateBackgroundColor(), position: 'relative' }}>
                                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'none' }}>
                                    {inputValue && inputValue !== '0' && <div style={{ width: `${parseFloat(inputValue) * 20}%`, height: '100%', background: '#FBB01F' }}></div>}
                                </div>
                            </div>
                            <input type='text' value={inputValue} onChange={handleChange} />
                            
                            <div className='full-width bar-chart' style={{ background: calculateBackgroundColor(), position: 'relative' }}>
                                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'none' }}>
                                    {inputValue && inputValue !== '0' && <div style={{ width: `${parseFloat(inputValue) * 20}%`, height: '100%', background: '#FBB01F' }}></div>}
                                </div>
                            </div>
                            <input
                                ref={inputRef}
                                type='number'
                                // step='0.01'
                                value={inputValue}
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                                // min="0"
                                // max="5"
                            />
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>적극성</label>
                            <div className='full-width bar-chart'></div>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>만족도</label>
                            <div className='full-width bar-chart'></div>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>일정준수</label>
                            <div className='full-width bar-chart'></div>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>의사소통</label>
                            <div className='full-width bar-chart'></div>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>하자보수</label>
                            <div className='full-width bar-chart'></div>
                        </div>
                    </div>
                </div>
                <div className='contents-row full-width flex-center spacer'>
                    
                </div>
            </div> */}
            <div className='hr-solid'/>
            {profileData.user_ty === 2 ? 
            <>
            <div className='full-width'>
                <div className='main-text'>주요 협력업체</div>
                <div className='contents-row act-info'>
                        {profileData.contractors_arr && profileData.contractors_arr !== null ? 
                        profileData.contractors_arr.map((contractor, index) => (
                            <div className='contents-row flex-center border info-input company-nm' key={index}>{contractor}</div>
                        ))
                        :
                            <div className='contents-row full-width flex-center'>
                                <p className='result-info'>주요 협력업체 정보가 없습니다.</p>
                            </div>
                        }
                    </div>
                </div>
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='main-text'>회사 연혁</div>
                {/* {profileData.co_history_arr && profileData.co_history_arr.map((item, index) => (
                    <div key={index} className='contents-row act-info history'>
                        <label>{item.date}</label>
                        <label>{item.content}</label>
                    </div>
                ))} */}
                {/* {console.log(currentPageItems)} */}
                {allItemsEmpty ? (
                    <div className='contents-row full-width flex-center'>
                        <p className='result-info'>회사 연혁 정보가 없습니다.</p>
                    </div>
                ) : (
                    currentPageItems && currentPageItems.map((item, index) => (
                    <div key={index} className='contents-row act-info history'>
                        <label>{item.date}</label>
                        <label>{item.content}</label>
                    </div>
                    ))
                )}
                {currentPageItems.length > 0 && (
                // {currentPageItems.date === '' || currentPageItems.content === '' && !allItemsEmpty && (
                    <div className="flex-center">
                        <div className="flex-center">
                        <Pagination
                            className="pagination"
                            activePage={activePage}
                            totalPages={totalPages}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            onPageChange={handlePageChange} // 페이지 변경 이벤트 처리
                        />
                        </div>
                    </div>
                )}
            </div>
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='main-text'>Dropia 진행 프로젝트</div>
                {projectList && projectList.length !== 0? 
                projectList.map((project, index) => {
                    if(project.prj_stts_cd == 5){
                        return(
                        <div className='contents-row flex-center project-progress req' key={index}>
                            <div className='contents-col flex-center spacer progress-box req'>
                                <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                {/* <p>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.prj_start_de.substring(2)} ~ {project.prj_end_de.substring(2)}</p> */}
                                <p>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일</p>
                            </div>
                            <div className='contents-row spacer progress-box req'>
                                <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                                <div className='contents-col req-state'>
                                {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{project.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                <div className='flex-center'>
                                    <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${project.prj_qc_yn? '':'none'}`}/>
                                    <div className={`prj-qc-yn ${project.prj_qc_yn? '':'none'}`}>{project.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                </div>
                                <p className='contents-row flex-center project-user-info'>
                                    {!project.prj_owner_user_photo ? (
                                            <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                    ) : (
                                        <img
                                            className="user-logo-img small"
                                            src={project.prj_owner_user_photo}
                                            // src={props.userProfile.photoUrl}
                                            alt="Uploaded Image"
                                            style={{ maxWidth: '100%', maxHeight: '22px' }}
                                        />
                                    )}
                                    {project.prj_owner_user_nm}
                                </p>
                                    {/* <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p> */}
                                </div>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                                {/* <div className='gc-btn info-btn flex-center view-detail'>상세보기</div> */}
                            </div>
                        </div>)
                    }
                })
                : 
                <div className='contents-row full-width flex-center'>
                    <p className='result-info'>진행된 프로젝트가 없습니다.</p>
                </div>
                }
            </div>
            
            {/* <div className="flex-center">
                <Pagination
                    className="pagination"
                    activePage={1}
                    totalPages={3}  
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                    // onPageChange={handlePageChange}
                >                        
                </Pagination>
            </div> */}
            </>
            : profileData.user_ty === 1 ?
            <> 
            <div className='main-text'>포트폴리오</div>
            <div className='contents-row full-width flex-center portfolio-list'>
            {console.log(portfolioList)}
            
            {portfolioList && portfolioList.length !== 0 ? 
            portfolioList.map((portfolio, index) => (
                <React.Fragment key={index}>
                <div 
                    className='contents-col flex-center rrn-img-box portfolio-box'
                    key={index}
                    onClick={()=>{
                        setProfileState('portfolio-view');
                    }}
                >
                    {/* <div className='rrn-img portfolio-img'></div> */}
                    {/* <img 
                        src={portfolio.prj_outpur_urls && portfolio.prj_outpur_urls[portfolio.prj_output_main_idx]}
                        alt={portfolio.prj_outpur_urls && portfolio.prj_outpur_urls[portfolio.prj_output_main_idx]}
                        className='rrn-img portfolio-img-img'
                        style={{
                            maxWidth: '100%',
                            maxHeight: '130px',
                            objectFit: 'contain'
                        }}
                    /> */}
                    {/* {console.log(portfolio.prj_outpur_urls)} */}
                    {/* {console.log(portfolio.prj_outpur_urls[0])} */}
                    {/* {portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx] && portfolio.prj_output_urls[portfolio.prj_output_main_idx] ?
                        (
                            <img 
                                src={portfolio.prj_outpur_urls && portfolio.prj_outpur_urls[portfolio.prj_output_main_idx]}
                                alt={portfolio.prj_outpur_urls && portfolio.prj_outpur_urls[portfolio.prj_output_main_idx]}
                                className='rrn-img portfolio-img-img'
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '130px',
                                    objectFit: 'contain'
                                }}
                            />
                        ) : (
                            <div 
                                className='contents-row flex-center'
                                style={{maxWidth: '100%', height: '200px', objectFit: 'contain'}}
                            >
                                썸네일 이미지 없음
                            </div>
                        )
                    } */}
                    {portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx] && portfolio.prj_output_urls[portfolio.prj_output_main_idx] ? 
                        (
                          <img 
                            src={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                            alt={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                            className='rrn-img portfolio-img-img'
                            style={{
                              maxWidth: '100%',
                              // maxHeight: '130px',
                              objectFit: 'contain'
                            }}
                          />
                          ) : (
                          <div 
                            className='contents-row flex-center'
                            style={{maxWidth: '100%', height: '200px', objectFit: 'contain'}}
                          >
                            썸네일 이미지 없음
                          </div>
                        )
                    }
                    <p>{portfolio.prtfolio_nm}</p>
                    <span>{portfolio.prj_ty_nm}</span>
                    {/* <span>{portfolio.prj_ty_custom}</span> */}
                    <span>{portfolio.prj_start_de} ~ {portfolio.prj_end_de}</span>
                </div>
                {/* <Pagination
                    className="pagination"
                    activePage={1}
                    totalPages={3}  
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                    // onPageChange={handlePageChange}
                >                        
                </Pagination> */}
                </React.Fragment>
                ))
            : 
                <div className='contents-row full-width flex-center'>
                    <p className='result-info'>등록된 포트폴리오가 없습니다.</p>
                </div>
            } 

                {/* <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div>
                <div className='contents-col flex-center rrn-img-box portfolio-box'>
                    <div className='rrn-img portfolio-img'></div>
                    <p>2023년 국유재산(문화재) 드론영상촬영</p>
                    <span>항공사진</span>
                    <span>22.10.13 ~ 22.11.25</span>
                </div> */}
            </div>
            
            </>
            : null}
        </React.Fragment>
    );
}

export default Myprofile;