import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../../shared/components/uiElements/Popup';

import axios from "axios";

import snsLoginConfig from '../../config/snsLoginConfig';

const { naver } = window;

const NaverLogin = ({ clientId, callbackUrl, isLoggedIn, userProfile, setIsLoggedIn, setUserInfoState }) => {
    const navigate = useNavigate();
    const naverButtonRef = useRef(null);

    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
    const [showChoosePopup, setShowChoosePopup] = useState(false);

    // 네이버 로그인
    const naverLogin = new naver.LoginWithNaverId({
        // clientId: clientId,
        // callbackUrl: callbackUrl,
        clientId: snsLoginConfig.NAVER_CLIENT_ID,
        callbackUrl: snsLoginConfig.NAVER_CALLBACK_URL,
        isPopup: true,
        // isPopup: true,
        loginButton: {
        color: "green",
        type: 3,
        height: 50,
        },
    });

    const naverLoginRef = useRef(null);
    
    useEffect(() => {
        if (window.naver) {
            naverLoginRef.current = new window.naver.LoginWithNaverId({
                // clientId: clientId,
                // callbackUrl: callbackUrl,
                clientId: snsLoginConfig.NAVER_CLIENT_ID,
                callbackUrl: snsLoginConfig.NAVER_CALLBACK_URL,
                isPopup: true,
                loginButton: {
                    color: 'green',
                    type: 3,
                    height: 50,
                },
            });
            naverLoginRef.current.init();
        }
    }, [snsLoginConfig.NAVER_CLIENT_ID, snsLoginConfig.callbackUrl]);

    // NaverCallback 후
    const [user, setUser] = useState(null);
    const [naverId, setNaverId] = useState('');
    const [naverEmail, setNaverEmail] = useState('');

    // 로그인 성공 여부
    useEffect(() => {
        // Listen for messages from the popup window
        const handleMessage = (event) => {
            if (event.data.type === 'NAVER_LOGIN_SUCCESS') {
                const { user } = event.data;
                // Handle the user data received from NaverCallback
                // console.log('Received user data from NaverCallback:', user);
                setNaverId(user.id);
                setNaverEmail(user.email);
                // Perform actions with user data as needed
                // Example: setUserInfoState(user);

                // Example: Trigger login process based on received data
                // Example: You may want to update states or perform API calls here
                // Example: For simplicity, let's assume you set user info state
                // setUserInfoState(user);

                // Example: Redirect or perform actions after successful login
                // navigate('/dashboard'); // Redirect to dashboard after login
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // 사용자 이메일 체크
    const [naverExist, setNaverExist] = useState();
    
    const emailCheckNaver = () => {
        axios({
            method:'get',
            url:`/api/account/check?email=${naverEmail}`,
            // headers: {
            //   'Authorization': `Bearer {${APIToken}}`
            // },
        })
        .then((result)=>{
            // console.log(result.data);
            if(result.data.success == true) {
                // setNaverExist(result.data.data.exist);
                const emailCheckData = result.data.data;
                setNaverExist(emailCheckData.exist);
                if (emailCheckData.exist) {
                    if (emailCheckData.id_type_cd === 2) {
                    setShowPopup(true);
                    setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다 (구글)");
                    // setIsLoggedIn(false);
                    } else if (emailCheckData.id_type_cd === 4) {
                    setShowPopup(true);
                    setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다 (카카오)");
                    // setIsLoggedIn(false);
                    }
                }
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    useEffect(() => {
        // if (naverLoginOk) {
            if (naverEmail) {
                emailCheckNaver();
            }
        // }
    }, [naverEmail])

    const [idTyCd, setIdTyCd] = useState('');

    useEffect(() => {
        // if (snsState === 'naver' && naverId && naverEmail) {
        //     setIdTyCd(3);
        //     setSnsId(naverId);
        //     setSnsEmail(naverEmail);
        // }
        setIdTyCd(3);
        if (naverExist === false) {
            signupNaver();
        }
        if (naverExist === true) {
            loginNaver();
        }
    }, [naverExist, naverId, naverEmail])

    const signupNaver = () => {
        axios({
            method:'post',
            url:`/api/sns-signup`,
            data: { 
                idTyCd: idTyCd, 
                snsId: naverId, 
                email: naverEmail
            },
        })
        .then((result)=>{
            // console.log(result.data);
            // navigate("/login");
            if (result.data.success) {
                emailCheckNaver();
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
            }
        })
        .catch((error)=>{
            console.log(error)  
        })
    }

    const loginNaver = () => {
        axios({
            method:'post',
            url:`/api/sns-login`,
            data: { 
            idTyCd: idTyCd, 
            snsId: naverId, 
            email: naverEmail
            },
        })
        .then((result)=>{
            // console.log(result.data);
            if (result.data.success) {
                localStorage.setItem('login-token', result.data.token);
                localStorage.setItem('user-name', result.data.data.userNm);
                localStorage.setItem('user-level', result.data.data.levelCd);
                localStorage.setItem('user-id', result.data.data.userId);
                localStorage.setItem('user-ty', result.data.data.userTy);
                localStorage.setItem('user-email', result.data.data.email);
                localStorage.setItem('user-photoUrl', result.data.data.photoUrl);
                localStorage.setItem('user-coTy', result.data.data.coTy);
                setIsLoggedIn(true);
            } else {
                setShowPopup(true);
                setPopupMsg('가입정보가 일치하지 않습니다');
            }
            // window.location.reload();
        })
        .catch((error)=>{
            console.log(error)  
        })
    }
    
    useEffect(() => {
        if (naverButtonRef.current) {
            naverLogin.init();
            // averLogin.logout();
            // getUser();
        }
    }, [naverButtonRef.current]);
    
    // const naverRef = useRef();
    const handleClick = () => {
        if (naverButtonRef.current) {
            naverButtonRef.current.children[0].click();
        }
    };

    const brCount = (popupMsg.match(/<br>/g) || []).length;
    // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
    const fontSizeValue = brCount === 0 ? '18px' : '16px';

    return (
        <>
        <div ref={naverButtonRef} id="naverIdLogin"></div>
        <div className='flex-center sns-login naver' onClick={handleClick}>
          <div className='symbol naver-symbol'></div>
          <span className='sns-text'>네이버 로그인</span>
        </div>

        <Popup
            show={showPopup}
            header=""
        >
            <div className='contents-col full-width flex-center popup-info'>
                {/* <p>{popupMsg}</p> */}
                <p
                    style={{
                        fontSize: fontSizeValue,
                        // padding: paddingValue,
                    }}
                    dangerouslySetInnerHTML={{ __html: popupMsg }}
                ></p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => setShowPopup(false)}  
                >닫기</div>
            </div>
        </Popup>
        </>
    )
};

export default NaverLogin;
