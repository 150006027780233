
import './Mydropia.css';

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Pagination, Icon } from 'semantic-ui-react';

// AXIOS
import axios from "axios";

function Mydropia(props) {
    // console.log(props);
    const APIToken = localStorage.getItem("login-token");
    
    const loginUserId = localStorage.getItem('user-id');
    const loginUserTy = localStorage.getItem('user-ty');
    // const loginUserTyState = localStorage.getItem('user-ty-state');
    const navigate = useNavigate();

    // 진행중인 프로젝트
    const [prjSttsCd, setPrjSttsCd] = useState('');
    const progressSteps = [
        { id: 2, label: '지원' },
        { id: 3, label: '미팅' },
        { id: 4, label: '계약' },
        { id: 5, label: '진행' },
        { id: 7, label: '완료' }
    ];

    // 클라이언트 진행중인 프로젝트
    const [clientProjectList2, setClientProjectList2] = useState([]);
    const [clientProjectList3, setClientProjectList3] = useState([]);
    const [clientProjectList4, setClientProjectList4] = useState([]);
    const [clientProjectList5, setClientProjectList5] = useState([]);
    const [clientProjectList7, setClientProjectList7] = useState([]);
    const [clientProjectList100, setClientProjectList100] = useState([]);
  
    const fetchClientData = (prjSttsCd, setProjectList) => {
      if (prjSttsCd) {
        axios({
            method:'get',
            url:`/api/project/list?userId=${loginUserId}&prjSttsCd=${prjSttsCd}`,
            // url:`/api/project/list?userId=${loginUserId}&prjSttsCd=${prjSttsCd}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            // console.log(result.data.data);
            if(result.data.success === true) {
                let project_list = result.data;
                setProjectList(project_list.data);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }
    };

    // 파트너 진행중인 프로젝트
    const [partnerProjectList2, setPartnerProjectList2] = useState([]);
    const [partnerProjectList3, setPartnerProjectList3] = useState([]);
    const [partnerProjectList4, setPartnerProjectList4] = useState([]);
    const [partnerProjectList5, setPartnerProjectList5] = useState([]);
    const [partnerProjectList7, setPartnerProjectList7] = useState([]);
    const [partnerProjectList100, setPartnerProjectList100] = useState([]);
    
    const fetchPartnerData = (prjSttsCd, setProjectList) => {
      if (prjSttsCd) {
        axios({
            method:'get',
            // url:`/api/project/list?partnerId=1&prjSttsCd=$1`,
            url:`/api/project/list?partnerId=${loginUserId}&prjSttsCd=${prjSttsCd}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            console.log(result);
            if(result.data.success === true) {
                let project_list = result.data.data;
                setProjectList(project_list);
            }
        })
        .catch((error)=>{
            console.log(error)
        })
      }
    };

    // 클라이언트
    useEffect (() => {
        fetchClientData(2, setClientProjectList2);
    }, []);
    useEffect (() => {
        fetchClientData(3, setClientProjectList3);
    }, []);
    useEffect (() => {
        fetchClientData(4, setClientProjectList4);
    }, []);
    useEffect (() => {
        fetchClientData(5, setClientProjectList5);
    }, []);
    useEffect (() => {
        fetchClientData(7, setClientProjectList7);
    }, []);
    useEffect (() => {
        fetchClientData(100, setClientProjectList100);
    }, []);

    // 파트너
    useEffect (() => {
        fetchPartnerData(2, setPartnerProjectList2);
    }, []);
    useEffect (() => {
        fetchPartnerData(3, setPartnerProjectList3);
    }, []);
    useEffect (() => {
        fetchPartnerData(4, setPartnerProjectList4);
    }, []);
    useEffect (() => {
        fetchPartnerData(5, setPartnerProjectList5);
    }, []);
    useEffect (() => {
        fetchPartnerData(7, setPartnerProjectList7);
    }, []);
    useEffect (() => {
        fetchPartnerData(100, setPartnerProjectList100);
    }, []);
    
    // useEffect (() => {
    //   fetchData(2, setClientProjectList2);
    // }, []);
    // useEffect (() => {
    //   fetchData(3, setClientProjectList3);
    // }, []);
    // useEffect (() => {
    //   fetchData(4, setClientProjectList4);
    // }, []);
    // useEffect (() => {
    //     fetchData(5, setClientProjectList5);
    // }, []);
    // useEffect (() => {
    //     fetchData(8, setClientProjectList8);
    // }, []);
    // useEffect (() => {
    //   fetchData(100, setClientProjectList100);
    // }, []);

   
    const [projectList, setProjectList] = useState([]);
    const [partnerProjectList, setPartnerProjectList] = useState([]);
    const [clientProjectList, setClientProjectList] = useState([]);

    const handleProgressClick = (statusNumber) => {
        let selectedProjectList = [];
        if (loginUserTy === '1') {
            switch (statusNumber) {
                case 2:
                    selectedProjectList = partnerProjectList2;
                    break;
                case 3:
                    selectedProjectList = partnerProjectList3;
                    break;
                case 4:
                    selectedProjectList = partnerProjectList4;
                    break;
                case 5:
                    selectedProjectList = partnerProjectList5;
                    break;
                case 7:
                    selectedProjectList = partnerProjectList7;
                    break;
                default:
                    selectedProjectList = [];
            }
            setPartnerProjectList(selectedProjectList);
            setPrjSttsCd(statusNumber);
        }

        if (loginUserTy === '2') {
            switch (statusNumber) {
                case 2:
                    selectedProjectList = clientProjectList2;
                    break;
                case 3:
                    selectedProjectList = clientProjectList3;
                    break;
                case 4:
                    selectedProjectList = clientProjectList4;
                    break;
                case 5:
                    selectedProjectList = clientProjectList5;
                    break;
                case 7:
                    selectedProjectList = clientProjectList7;
                    break;
                default:
                    selectedProjectList = [];
            }
            setClientProjectList(selectedProjectList);
            setPrjSttsCd(statusNumber);
        }
    };

    useEffect (() => {
        if (loginUserTy === '1') {
            setProjectList(partnerProjectList);
        } else if (loginUserTy === '2') {
            setProjectList(clientProjectList);
        }
    }, [loginUserTy, partnerProjectList, clientProjectList]) 

    // const [prjSttsCd, setPrjSttsCd] = useState('');
    // 프로젝트 상태 코드에 따른 배열 데이터 매핑
    const getProjectListLength = (stepId, props) => {

        if (loginUserTy === '1') {
            switch (stepId) {
                case 2:
                    return partnerProjectList2.length;
                case 3:
                    return partnerProjectList3.length;
                    break;
                case 4:
                    return partnerProjectList4.length;
                case 5:
                    return partnerProjectList5.length;
                case 7:
                    return partnerProjectList7.length;
                default:
                    return 0
            }
        }

        if (loginUserTy === '2') {
            switch (stepId) {
                case 2:
                    return clientProjectList2.length;
                    break;
                case 3:
                    return clientProjectList3.length;
                    break;
                case 4:
                    return clientProjectList4.length;
                    break;
                case 5:
                    return clientProjectList5.length;
                    break;
                case 7:
                    return clientProjectList7.length;
                    break;
                default:
                    return 0
            }
        }
    };

    // 누적 계약 프로젝트 (n건) / 누적 계약금 n만원
    // 내 프로젝트 (파트너 partnerId)
    const [myPartnerProjectList, setMyPartnerProjectList] = useState(null);
    const [cumContractProjectTotal, setCumContractProjectTotal] = useState('');
    const [cumContractProjectTotalCost, setCumContractProjectTotalCost] = useState('');
    useEffect (() => {
        axios({
            method:'get',
            url:`/api/project/list?partnerId=${loginUserId}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            console.log(result)
            if(result.data.success === true) {
                // console.log(result.data);
                setMyPartnerProjectList(result.data.data);
                setCumContractProjectTotal(result.data.total)
                setCumContractProjectTotalCost(result.data.totalCost);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }, [])

    // 프로젝트 완료율 (상태로 해서,, 완료율 = 완료 / 완료 + 실패)
    const [completionRate, setCompletionRate] = useState();

    useEffect(() => {
        let completion = 0;

        if(loginUserTy == 1){
            completion = ((partnerProjectList7.length / partnerProjectList7.length + partnerProjectList100.length) || 0) * 100
        }
        else if(loginUserTy == 2){
            completion = ((clientProjectList7.length / clientProjectList7.length + clientProjectList100.length) || 0) * 100
        }
        setCompletionRate(completion);
    }, [])

    // 내 프로젝트 클라이언트 Pagination
    const [activeClientPage, setActiveClientPage] = useState(1);
    const [totalClientPages, setTotalClientPages] = useState(0);
    const handleClientPageChange = (e, { activePage }) => {
        setActiveClientPage(activePage);
    };
    const clientPageSize = 5;
    
    // 내 프로젝트 (클라이언트 userId)
    const [myClientProjectList, setMyClientProjectList] = useState(null);
    const [cumMyClientContractProjectTotal, setMyClientCumContractProjectTotal] = useState('');
    const [cumMyClientContractProjectTotalCost, setMyClientCumContractProjectTotalCost] = useState('');

    useEffect (() => {
        axios({
            method:'get',
            url:`/api/project/list?userId=${loginUserId}&prjSttsCd=-1&page=${activeClientPage}&perPage=${clientPageSize}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            console.log(result)
            if(result.data.success === true) {
                const clientData = result.data;
                setMyClientProjectList(clientData.data);
                setMyClientCumContractProjectTotal(clientData.total);
                setMyClientCumContractProjectTotalCost(clientData.totalCost);
                setTotalClientPages(Math.ceil(clientData.total / clientPageSize));
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }, [activeClientPage])

    // 프로젝트 진행상태
    const statusTextMap = {
        1: '생성',
        2: '지원',
        3: '미팅',
        4: '계약',
        5: '진행',
        6: '검수',
        7: '완료',
        // 7: '지급',
        // 8: '완료',
        100: '실패'
    };

    return (
    <div className='Dropia-container'>
        <div className='contents-row dropia-reg'>
            <div className='contents-col dropia-reg-main'>
                <div className='contents-row full-width'>
                    <div className='main-title'>My <span className='dropia'>Dropia</span></div>
                    {
                    props.userProfile.user_ty === 1 ?
                        <div className='gc-btn gc-btn-green flex-center  partner none-hover'>파트너</div>
                    : props.userProfile.user_ty === 2 ?
                        <div className='gc-btn flex-center client none-hover'>클라이언트</div>
                    :  props.userProfile.user_ty === 3 ?
                        <div className='gc-btn flex-center manager-tag none-hover'>매니저</div>
                    : null
                    }
                    {/* {props.userProfile.user_ty === 1 ?
                        <div className='gc-btn gc-btn-green flex-center info-btn partner none-hover'>파트너</div>
                    : props.userProfile.user_ty === 2 ?
                        <div className='gc-btn flex-center info-btn client none-hover'>클라이언트</div>
                    : null} */}
                </div>
                <div className='hr-solid'/>
                <div className='full-width'>
                    <div className='main-text'>진행중인 프로젝트</div>
                    <div className='contents-row flex-center project-progress'>
                    {progressSteps && progressSteps.map((step, index) => {
                        const projectListLength = getProjectListLength(step.id, props);
                        // console.log(projectListLength)
                        return (
                            <React.Fragment key={step.id}>
                                <div className={`flex-center contents-col progress-circle ${prjSttsCd === step.id ? 'click' : ''}`} onClick={() => handleProgressClick(step.id)}>
                                {/* <div className='flex-center contents-col progress-circle' onClick={() => handleProgressClick(step.id)}> */}
                                    <span>{step.label}</span>
                                    <span>{projectListLength}</span>
                                </div>
                                {index !== progressSteps.length - 1 && <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>}
                            </React.Fragment>
                        );
                    })}
                    </div>
                    {/* <div className='contents-row flex-center project-progress'>
                        {progressSteps.map((step, index) => (
                            <React.Fragment key={step.id}>
                                <div className='flex-center contents-col progress-circle' onClick={() => handleProgressClick(step.id)}>
                                    <span>{step.label}</span>
                                    <span></span>
                                </div>
                                {index !== progressSteps.length - 1 && <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>}
                            </React.Fragment>
                        ))}
                    </div> */}
                    {/* <div className='contents-row flex-center project-progress'>
                        <div className='flex-center contents-col progress-circle'>
                            <span>지원</span>
                        </div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>
                        <div className='flex-center contents-col progress-circle'>
                            <span>미팅</span>
                        </div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>
                        <div className='flex-center contents-col progress-circle'>
                            <span>계약</span>
                        </div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>
                        <div className='flex-center contents-col progress-circle'>
                            <span>진행</span>
                        </div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>
                        <div className='flex-center contents-col progress-circle'>
                            <span>완료</span>
                        </div>
                    </div> */}
                    <div className='contents-row flex-center project-progress'>
                        <div className='contents-col flex-center spacer progress-box'>
                            <p>누적 계약 프로젝트</p>
                            {/* <p className='prj-result'>{cumContractProjectTotal} 건</p> */}
                            {/* <p className='prj-result'>{loginUserTy === 1 ? cumContractProjectTotal : loginUserTy === 2 ? cumMyClientContractProjectTotal : null} 건</p> */}
                            <p className='prj-result'>{loginUserTy === '1' ? cumContractProjectTotal : loginUserTy === '2' ? cumMyClientContractProjectTotal : null} 건</p>
                            {/* <p className='prj-result'>{(loginUserTy === '1' ? cumContractProjectTotal : loginUserTy === '2' ? cumMyClientContractProjectTotal : null) ?? 0} 건</p> */}
                        </div>
                        <div className='contents-col flex-center spacer progress-box'>
                            <p>프로젝트 완료율</p>
                            <p className='prj-result'>{completionRate} %</p>
                            {/* <p className='prj-result'>100 %</p> */}
                        </div>
                        <div className='contents-col flex-center spacer progress-box'>
                            <p>누적 계약금</p>
                            <p className='prj-result'>{loginUserTy === '1' ? cumContractProjectTotalCost : loginUserTy === '2' ? cumMyClientContractProjectTotalCost : null} 만원</p>
                            {/* <p className='prj-result'>{(loginUserTy === '1' ? cumContractProjectTotalCost : loginUserTy === '2' ? cumMyClientContractProjectTotalCost : null) ?? 0} 만원</p> */}
                            {/* <p className='prj-result'>{loginUserTy === 1 ? cumContractProjectTotalCost : loginUserTy === 2 ? cumMyClientContractProjectTotalCost : null} 만원</p> */}
                        </div>
                    </div>
                </div>
                {prjSttsCd &&  (
                <>
                <div className='hr-solid'/>
                <div className='full-width'>
                    {/* <div className='main-text'>완료 프로젝트</div> */}
                    <div className='main-text'>
                        {progressSteps.map(step => {
                            if (step.id === parseInt(prjSttsCd)) {
                                return `${step.label} 프로젝트`;
                            }
                        })}
                    </div>
                    {projectList && projectList.length !== 0 ? 
                    projectList.map((project, i) => (
                        <div className='contents-row full-width flex-center project-progress req' key={i}>
                            <div className='contents-col flex-center spacer progress-box req'>
                                <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                {/* {console.log(partnerPrjItem)} */}
                                <p className='contents-row flex-center'>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[project.prj_stts_cd]}</span></p>
                            </div>
                            <div className='contents-row spacer progress-box req'>
                                <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                                <div className='contents-col req-state'>
                                    {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{partnerPrjItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                    <div className='flex-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${project.prj_qc_yn? '':'none'}`}/>
                                        <div className={`prj-qc-yn ${project.prj_qc_yn? '':'none'}`}>{project.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                    </div>
                                    <div className='contents-row flex-center project-user-info'>
                                        {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                                        {!project.prj_owner_user_photo ? (
                                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                            <div className='user-logo-img small none'></div>
                                        ) : (
                                            <img
                                                className="user-logo-img small"
                                                src={project.prj_owner_user_photo}
                                                // src={props.userProfile.photoUrl}
                                                alt="Uploaded Image"
                                                style={{ maxWidth: '100%', maxHeight: '22px' }}
                                            />
                                        )}
                                        {project.prj_owner_user_nm}
                                    </div>
                                </div>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                            </div>
                        </div>
                        // <div className='contents-row flex-center project-progress req' key={i}>
                        //     <div className='contents-col flex-center spacer progress-box req apply'>
                        //         <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                        //         {/* {console.log(statusTextMap[project.prj_stts_cd])} */}
                        //         <p className='apply'>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[project.prj_stts_cd]}</span></p>
                        //     </div>
                        //     <div className='contents-row spacer progress-box req'>
                        //         <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                        //         <div className='contents-col req-state'>
                        //             <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{project.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                        //             <p className='contents-row flex-center'>
                        //                 {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                        //                 <div className='user-logo-img small none'></div>
                        //                 {project.prj_owner_user_nm}
                        //             </p>
                        //         </div>
                        //         <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                        //     </div>
                        // </div>
                    ))
                    : 
                        <div className='contents-row full-width flex-center'>
                            <p className='result-info'>
                                {progressSteps.map(step => {
                                    if (step.id === parseInt(prjSttsCd)) {
                                        return `${step.label} 프로젝트가 없습니다.`;
                                    }
                                })}
                            </p>
                            {/* <p className='result-info'>회사 연혁 없습니다.</p> */}
                        </div>
                    }
                    {/* {projectList.map((project, i) => (
                        <div className='contents-row flex-center project-progress req' key={i}>
                            <div className='contents-col flex-center spacer progress-box req apply'>
                                <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                <p className='apply'>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[project.prj_stts_cd]}</span></p>
                            </div>
                            <div className='contents-row spacer progress-box req'>
                                <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                                <div className='contents-col req-state'>
                                    <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{project.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                                    <p className='contents-row flex-center'>
                                        <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>
                                        e****e
                                    </p>
                                </div>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                            </div>
                        </div>
                    ))} */}
                </div>
                </>
                )}

                {projectList.length === 0 && prjSttsCd === '' ? (
                <>
                <div className='hr-solid'/>
                <div className='main-text'>내 프로젝트</div>
                {loginUserTy === '1' ? 
                    myPartnerProjectList && myPartnerProjectList.length !== 0 ? 
                    myPartnerProjectList.map((partnerPrjItem, index) => {
                        // console.log(myPartnerProjectList);
                        return (
                        <div className='contents-row full-width flex-center project-progress req' key={index}>
                            <div className='contents-col flex-center spacer progress-box req'>
                                <p>{partnerPrjItem.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                {/* {console.log(partnerPrjItem)} */}
                                <p className='contents-row flex-center'>예상금액 {partnerPrjItem.exptd_cost_tt} 만원 | 예상일정 {partnerPrjItem.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[partnerPrjItem.prj_stts_cd]}</span></p>
                            </div>
                            <div className='contents-row spacer progress-box req'>
                                <div className='gc-btn info-btn video'>{partnerPrjItem.prj_ty_nm}</div>
                                <div className='contents-col req-state'>
                                    {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{partnerPrjItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                    <div className='flex-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${partnerPrjItem.prj_qc_yn? '':'none'}`}/>
                                        <div className={`prj-qc-yn ${partnerPrjItem.prj_qc_yn? '':'none'}`}>{partnerPrjItem.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                    </div>
                                    <div className='contents-row flex-center project-user-info'>
                                        {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                                        {!partnerPrjItem.prj_owner_user_photo ? (
                                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                            <div className='user-logo-img small none'></div>
                                        ) : (
                                            <img
                                                className="user-logo-img small"
                                                src={partnerPrjItem.prj_owner_user_photo}
                                                // src={props.userProfile.photoUrl}
                                                alt="Uploaded Image"
                                                style={{ maxWidth: '100%', maxHeight: '22px' }}
                                            />
                                        )}
                                        {partnerPrjItem.prj_owner_user_nm}
                                    </div>
                                </div>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${partnerPrjItem.prj_id}`)}}>상세보기</div>
                            </div>
                        </div>
                        );
                    })
                    :
                    <div className='contents-row full-width flex-center'>
                        <p className='result-info'>내 프로젝트 정보가 없습니다.</p>
                    </div>
                : loginUserTy === '2' ?  
                    myClientProjectList && myClientProjectList.length !== 0 ? 
                    <>
                    {myClientProjectList.map((clientPrjItem, index) => {
                        // {console.log(myClientProjectList)}
                        return (
                        <div className='contents-row full-width flex-center project-progress req' key={index}>
                            {/* {console.log(clientPrjItem)} */}
                            <div className='contents-col flex-center spacer progress-box req'>
                                <p>{clientPrjItem.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                <p>예상금액 {clientPrjItem.exptd_cost_tt} 만원 | 예상일정 {clientPrjItem.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[clientPrjItem.prj_stts_cd]}</span></p>
                            </div>
                            <div className='contents-row spacer progress-box req'>
                                <div className='gc-btn info-btn video'>{clientPrjItem.prj_ty_nm}</div>
                                <div className='contents-col req-state'>
                                    {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{clientPrjItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                    <div className='flex-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${clientPrjItem.prj_qc_yn? '':'none'}`}/>
                                        <div className={`prj-qc-yn ${clientPrjItem.prj_qc_yn? '':'none'}`}>{clientPrjItem.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                    </div>
                                    
                                    <div className='contents-row flex-center project-user-info'>
                                        {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                                        {!clientPrjItem.prj_owner_user_photo ? (
                                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                            <div className='user-logo-img small none'></div>
                                        ) : (
                                            <img
                                                className="user-logo-img small"
                                                src={clientPrjItem.prj_owner_user_photo}
                                                alt="Uploaded Image"
                                                style={{ maxWidth: '100%', maxHeight: '22px' }}
                                            />
                                        )}
                                        {clientPrjItem.prj_owner_user_nm}
                                    </div>
                                </div>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${clientPrjItem.prj_id}`)}}>상세보기</div>
                            </div>
                        </div>
                        );
                    })}
                    <div className="flex-center">
                        <Pagination
                            className="pagination"
                            activePage={activeClientPage}
                            totalPages={totalClientPages}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                            onPageChange={handleClientPageChange}
                        >                        
                        </Pagination>
                    </div>
                    </>
                    :
                    <div className='contents-row full-width flex-center'>
                        <p className='result-info'>내 프로젝트 정보가 없습니다.</p>
                    </div>
                : null}
                {/* <div className='full-width'>
                    <div className='main-text'>견적 제안 요청 프로젝트</div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req apply'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p className='apply'>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                            <div className='contents-row flex-center apply-info'>
                                <div className='gc-btn gc-btn-line apply-btn'>지원</div>
                                <p>지원 파트너</p>
                                <p>3개 업체</p>
                            </div>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                </div>
                <div className='hr-solid'/>
                <div className='full-width'>
                    <div className='main-text'>관심 프로젝트</div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-solid fa-heart"/></p>
                            <p>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-solid fa-heart"/></p>
                            <p>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                </div> */}
                </>
                ) : null}
                {/* <div className='hr-solid'/> */}
                {/* <div className='full-width'>
                    <div className='main-text'>진행중 프로젝트</div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req apply'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p className='apply'>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                            <div className='contents-row flex-center apply-info'>
                                <div className='gc-btn gc-btn-line apply-btn'>지원</div>
                                <p>지원 파트너</p>
                                <p>3개 업체</p>
                            </div>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                    <div className='contents-row flex-center project-progress req'>
                        <div className='contents-col flex-center spacer progress-box req apply'>
                            <p>2023년 국유재산(문화재) 드론영상촬영<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p className='apply'>예상금액 5,500 만원 | 예상일정 ~3.20</p>
                            <div className='contents-row flex-center apply-info'>
                                <div className='gc-btn gc-btn-line apply-btn'>지원</div>
                                <p>지원 파트너</p>
                                <p>3개 업체</p>
                            </div>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>VIDEO</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>검수 포함</p>
                                <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail'>상세보기</div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className='contents-col dropia-reg-sub flex-center dropia'>
                <div className='contents-row flex-center progress-box req user-info'>
                    {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                    {!props.userProfile.photoUrl ? (
                        // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                        <div className='user-logo-img middle none'></div>
                    ) : (
                        <img
                            className="user-logo-img"
                            src={props.userProfile.photoUrl}
                            // src={props.userProfile && props.userProfile.photoUrl}
                            alt="Uploaded Image"
                            // style={{ maxWidth: '100%', maxHeight: '70px' }}
                        />
                    )}
                    {/* {!loginUserPhotoUrl ? (
                            <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                    ) : (
                        <img
                            className="user-logo-img"
                            src={loginUserPhotoUrl}
                            alt="Uploaded Image"
                            style={{ maxWidth: '100%', maxHeight: '70px' }}
                        />
                    )} */}
                    {/* <i className="fa-solid fa-user-large user-logo"></i> */}
                    <div className='contents-col'>
                        {/* <p className='user-nm'>{loginUserName}</p>
                        <p className='user-email'>{loginUserEmail}</p> */}
                        <p className='user-nm'>{props.userProfile.user_nm}</p>
                        <p className='user-email'>{props.userProfile.email}</p>
                        {/* <p className='user-nm'>{props.userProfile && props.userProfile.user_nm}</p>
                        <p className='user-email'>{props.userProfile && props.userProfile.email}</p> */}
                    </div>
                    {/* {loginUserTy === '1'? 
                        <div className='gc-btn gc-btn-green flex-center info-btn partner none-hover'>파트너</div>
                    :   
                        <div className='gc-btn flex-center info-btn client none-hover'>클라이언트</div>
                    } */}
                    {/* <div className='gc-btn gc-btn-green flex-center info-btn partner'>파트너</div> */}
                    {loginUserTy === '1' ?
                        <div className='gc-btn gc-btn-green flex-center info-btn login-user-ty-state partner none-hover'>파트너</div>
                    : loginUserTy === '2' ?
                        <div className='gc-btn flex-center info-btn login-user-ty-state client none-hover'>클라이언트</div>
                    : null}
                    {/* {loginUserTyState === '1'? 
                        <div className='gc-btn gc-btn-green flex-center info-btn login-user-ty-state partner none-hover'>파트너</div>
                    :   
                        <div className='gc-btn flex-center info-btn login-user-ty-state client none-hover'>클라이언트</div>
                    } */}
                </div>
                <div className='hr-solid'/>
                {progressSteps.map((step, index) => {
                    const projectListLength = getProjectListLength(step.id, props);
                    return (
                        <React.Fragment key={step.id}>
                            <div className='contents-col project-status-box'>
                                <div className='contents-row project-status'>
                                    <p>{step.label} 프로젝트</p>
                                    <p>{projectListLength} 건</p>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
                {/* <div className='contents-col project-status-box'>
                    <div className='contents-row project-status'>
                        <p>지원 프로젝트</p>
                        <p>13건</p>
                    </div>
                    <div className='contents-row project-status'>
                        <p>계약 프로젝트</p>
                        <p>9건</p>
                    </div>
                    <div className='contents-row project-status'>
                        <p>완료 프로젝트</p>
                        <p>8건</p>
                    </div>
                    <div className='contents-row project-status'>
                        <p>누적 계약금</p>
                        <p>14,000 만원</p>
                    </div>
                </div> */}

                <div className='contents-col flex-center dropia-btn-box'>
                    {/* 파트너일때 */}
                    <Link to={'/profile'} className='gc-btn gc-btn-green dropia-btn'>프로필 업데이트</Link>
                    {/* {props.userProfile.user_ty === 3 && loginUserTyState === '1' ? 
                        <div className='gc-btn gc-btn-line dropia-btn client' onClick={props.handleChangeUserTy}>클라이언트로 전환</div> 
                    : props.userProfile.user_ty === 3 && loginUserTyState === '2' ? 
                        <div className='gc-btn gc-btn-green gc-btn-green-line dropia-btn partner' onClick={props.handleChangeUserTy}>파트너로 전환</div>
                    : null} */}
                    {/* 클라이언트일때 */}
                    {/* <Link to={'/profile'} className='gc-btn gc-btn dropia-btn'>프로필 업데이트</Link>
                    <div className='gc-btn gc-btn-line dropia-btn partner'>파트너로 전환</div> */}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Mydropia;