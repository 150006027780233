import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../../shared/components/uiElements/Popup';

import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import axios from "axios";

import snsLoginConfig from '../../config/snsLoginConfig';

// import { gapi } from 'gapi-script'

const LoginGoogle = ({onSocial, clientId, callbackUrl, isLoggedIn, userProfile, setIsLoggedIn, setUserInfoState }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

  const [loginResult, setLoginResult] = useState(null); // loginResult 상태 정의

  const login = useGoogleLogin({
    onSuccess: res => setLoginResult(res), // 로그인 성공 시 loginResult 상태 업데이트
    onFailure: err => console.log(err),   // 로그인 실패 시 에러 처리
    flow: 'auth_code',                    // 인증 흐름 설정 ('auth_code' 또는 'implicit')
  });

  // 사용자 정보 상태
  const [googleId, setGoogleId] = useState('');
  const [googleEmail, setGoogleEmail] = useState('');
  const [googleExist, setGoogleExist] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (loginResult && loginResult.code) {
        try {
          const response = await axios.post('https://oauth2.googleapis.com/token', {
            code: loginResult.code,
            client_id: snsLoginConfig.GOOGLE_CLIENT_ID,
            client_secret: snsLoginConfig.GOOGLE_SECRET,
            redirect_uri: snsLoginConfig.GOOGLE_REDIRECT_URI,
            grant_type: 'authorization_code'
          });

          const { access_token } = response.data;

          localStorage.setItem('google-token', access_token);

          const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
            headers: {
              Authorization: `Bearer ${access_token}`
            }
          });
        
          const userInfo = userInfoResponse.data;
          setGoogleId(userInfo.id);
          setGoogleEmail(userInfo.email);
          // setIsLoggedIn(true);

          // onSocial 함수가 props로 전달된 경우 실행
          if (onSocial) {
            onSocial(userInfo);
          }
        } catch (error) {
          console.error('Failed to fetch user information:', error);
        
          if (error.response) {
            console.error('Server responded with:', error.response.status, error.response.data);
          } else if (error.request) {
            console.error('No response received:', error.request);
          } else {
            console.error('Error setting up request:', error.message);
          }
        }
      }
    };

    fetchData();
  }, [loginResult, snsLoginConfig.GOOGLE_CLIENT_ID, snsLoginConfig.GOOGLE_SECRET, snsLoginConfig.GOOGLE_REDIRECT_URI]); // 의존성 배열에 loginResult 및 관련된 변수들을 포함

  useEffect(() => {
    if (googleEmail) {
      emailCheckGoogle();
    }
  }, [googleEmail]);

  const emailCheckGoogle = () => {
    axios.get(`/api/account/check?email=${googleEmail}`)
    .then((result) => {
        if (result.data.success) {
          const emailCheckData = result.data.data;
          setGoogleExist(emailCheckData.exist);
          if (emailCheckData.exist) {
            if (emailCheckData.id_type_cd === 3) {
              setShowPopup(true);
              setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다<br>(네이버)");
              // setIsLoggedIn(false);
            } else if (emailCheckData.id_type_cd === 4) {
              setShowPopup(true);
              setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다<br>(카카오)");
              // setIsLoggedIn(false);
            }
          }
        }
    })
    .catch((error) => {
        console.log(error);
    });
  }

  const [idTyCd, setIdTyCd] = useState('');

  useEffect(() => {
    const idTyCd = 2; // 아이디 타입 코드 설정
    setIdTyCd(2)
    if (googleExist === false) {
      signupGoogle();
    } else if (googleExist === true) {
      loginGoogle();
    }
  }, [googleExist, googleId, googleEmail]);

  const signupGoogle = () => {
    axios.post('/api/sns-signup', {
      idTyCd: idTyCd,
      snsId: googleId,
      email: googleEmail
    })
    .then((result) => {
      // console.log(result);
      if (result.data.success) {
        // navigate("/login");
        emailCheckGoogle();
      } else {
        setShowPopup(true);
        setPopupMsg(result.data.msg);
      }
    })
    .catch((error) => {
        console.log(error);
    });
  }

  const loginGoogle = () => {
    axios.post('/api/sns-login', {
      idTyCd: idTyCd,
      snsId: googleId,
      email: googleEmail
    })
    .then((result) => {
      // console.log(result.data);
      if (result.data.success) {
        localStorage.setItem('login-token', result.data.token);
        localStorage.setItem('user-name', result.data.data.userNm);
        localStorage.setItem('user-level', result.data.data.levelCd);
        localStorage.setItem('user-id', result.data.data.userId);
        localStorage.setItem('user-ty', result.data.data.userTy);
        localStorage.setItem('user-email', result.data.data.email);
        localStorage.setItem('user-photoUrl', result.data.data.photoUrl);
        localStorage.setItem('user-coTy', result.data.data.coTy);
        setIsLoggedIn(true);
        // window.location.reload();
      } else {
        setShowPopup(true);
        setPopupMsg('가입정보가 일치하지 않습니다');
      }
      // window.location.reload();
    })
    .catch((error) => {
        console.log(error);
    });
  }

  const brCount = (popupMsg.match(/<br>/g) || []).length;
  // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
  const fontSizeValue = brCount === 0 ? '18px' : '16px';

  return (
      <>
      <div className='flex-center sns-login google' onClick={() => login()}>
        <div className='symbol google-symbol'></div>
        <span className='sns-text'>Google 로그인</span>
      </div>

      <Popup
        show={showPopup}
        header=""
      >
        <div className='contents-col full-width flex-center popup-info'>
          {/* <p>{popupMsg}</p> */}
          <p
              style={{
                  fontSize: fontSizeValue,
                  // padding: paddingValue,
              }}
              dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => setShowPopup(false)}  
          >닫기</div>
        </div>
      </Popup>
      </>
  )
};

export default LoginGoogle;
