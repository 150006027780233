// 네이버 (NAVER)
const NAVER_CLIENT_ID = "74ot6yROEbINPB6pg7E4" 
const NAVER_CALLBACK_URL = "http://test.geocapture.co.kr:3006/auth/naver" // test서버 Callback URL (2)
// const NAVER_CALLBACK_URL = "http://test.geocapture.co.kr/auth/naver" // test서버 Callback URL
// const NAVER_CALLBACK_URL = "http://localhost:4000/auth/naver" // localhost Callback URL

// 카카오 (Kakao)
const KAKAO_JAVASCRIPT_KEY = "41cb61296997dd4ca0024361171ad4b8";
// const KAKAO_REST_API_KEY = "a5d018191ff2044e02fa3f46d6735e7a";
const KAKAO_REDIRECT_URI = "http://test.geocapture.co.kr:3006"; // test서버 Callback URL (2)
// const KAKAO_REDIRECT_URI = "http://test.geocapture.co.kr/auth/kakao"; // test서버 Callback URL
// const KAKAO_REDIRECT_URI = "http://localhost:4000"; // localhost Redirect URI

// 구글 (Google)
const GOOGLE_CLIENT_ID = "596412713335-avi2skn8i088qd8a9a02rhl42s3jtsve.apps.googleusercontent.com";
const GOOGLE_SECRET = "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";
const GOOGLE_REDIRECT_URI = "http://test.geocapture.co.kr:3006"; // test서버 Callback URL (2)
// const GOOGLE_REDIRECT_URI = "http://test.geocapture.co.kr"; // test서버 Callback URL
// const GOOGLE_REDIRECT_URI = "http://localhost:4000"; // localhost Redirect URI

const snsLoginConfig = {
    // 네이버 (Naver)
    NAVER_CLIENT_ID:NAVER_CLIENT_ID, 
    NAVER_CALLBACK_URL:NAVER_CALLBACK_URL,
    // 카카오 (Kakao)
    KAKAO_JAVASCRIPT_KEY:KAKAO_JAVASCRIPT_KEY,
    // KAKAO_REST_API_KEY:KAKAO_REST_API_KEY,
    KAKAO_REDIRECT_URI:KAKAO_REDIRECT_URI,
    // 구글 (Google)
    GOOGLE_CLIENT_ID:GOOGLE_CLIENT_ID,
    GOOGLE_SECRET:GOOGLE_SECRET,
    GOOGLE_REDIRECT_URI:GOOGLE_REDIRECT_URI
};

module.exports = snsLoginConfig;