import './Home.css'

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import Popup from '../../shared/components/uiElements/Popup';

function Home(props) {

  const userType = localStorage.getItem('user-ty');
  
  const navigate = useNavigate();

  const [showAlarmPopup, setShowAlarmPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');

  // Dropia 메인화면
  return (
    <div className='Home-container'>
      <div className='Main-contents-top'>
        <div className='textA'>모든 종류의 드론 촬영</div>
        <div className='text-group'>
            <div className='logo'></div>
            <div className='textB'>에서 빠르고 안전하게</div>
        </div>
        <div className='button-container'>
          <div 
            className='button'
            onClick={()=>{
              if(props.isLoggedIn)
                if(userType == 1){
                  setPopupMsg('프로젝트 등록은\n클라이언트 사용자만 가능합니다.')
                  setShowAlarmPopup(true);
                }
                else{
                  navigate('/projectreg')
                }
              else
                {navigate('/login')}
            }}
          >프로젝트 등록하기</div>
        </div>
        <div className='image'></div>
      </div>
      
      <div className="Help-Text">대금, 계약 걱정없이 안심하고 사용하세요.</div>
      
      <div className='Main-contents-bottom'>
        <div className='group-left'>
          <div className='text-title'>대금보호 시스템</div> 
          <div className='text-group contents-col'>
            <div className='text-exp'>결과물 확인 후, 직접 최종대금 승인을 하는</div>
            <div className='text-exp'>방식으로 대금을 안전하게 보호합니다.</div>
          </div>
          <div className='text-detail'>자세히 알아보기 ▷</div>
          <div className='image'></div>
        </div>
        <div className='group-right'>
          <div className='contents-row'>
            <div className='text-logo'>Dropia</div>
            <div className='text-title'>표준 계약서</div>
          </div>
          <div className='text-group contents-col'>
            <div className='text-exp'>법무법인의 검토를 받고 믿을 수 있는</div>
            <div className='text-exp'>표준계약서로 공정한 계약이 가능합니다.</div>
          </div>
          <div className='text-detail'>자세히 알아보기 ▷</div>
          <div className='image'></div>
        </div>  
      </div>
      {
        showAlarmPopup?
        <Popup
          show={showAlarmPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowAlarmPopup(false);
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }
    </div>
  )
}

export default Home;