import './ManagerProfile.css';

import React, { useState, useEffect, useRef } from 'react';

import Popup from '../../shared/components/uiElements/Popup';
import ChangePwModal from '../User/Changepw';

// AXIOS
import axios from "axios";

function ManagerProfile(props) {

    const APIToken = localStorage.getItem("login-token");

    useEffect(() => {
        if (props.profileData) {
            // 이메일
            setUserEmail(props.profileData.email);
            // 이름
            setUserNm(props.profileData.user_nm);
            // 전화번호
            setUserTelNum(props.profileData.phone_no);
            // 계정 유형
            setUserType(props.profileData.user_ty);
            // 이미지 변경
            setImageUrl(props.profileData.photoUrl);
            //
        }
    }, [props.profileData])

    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

    // onChage 클릭 변경
    const [onChangeClick, setOnChangeClick] = useState(false);

    const [userEmail, setUserEmail] = useState();
    const [userNm, setUserNm] = useState();
    const [userTelNum, setUserTelNum] = useState();

    const handleUserEmail = (event) => {
        event.preventDefault();
        setUserEmail(event.target.value);
        setOnChangeClick(true);
    }
    const handleUserNm = (event) => {
        event.preventDefault();
        setUserNm(event.target.value);
        setOnChangeClick(true);
    }
    const handleUserTelNum = (event) => {
        event.preventDefault();
        setUserTelNum(event.target.value);
        setOnChangeClick(true);
    }

    // 이미지 변경
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
            event.target.value = ''; // 같은 파일 올리면 인지 못함 > 초기화
        };
        reader.readAsDataURL(file);
        setOnChangeClick(true);
    };

    const handleImageChange = () => {
        fileInputRef.current.click();
    };

    // 비밀번호 변경 
    const [showChangePwPopup, setShowChangePwPopup] = useState(false);

    // 계정 유형 (1: 파트너, 2: 클라이언트, 3: 둘다)
    // const [isCheckedPartner, setIsCheckedPartner] = useState(false);
    // const [isCheckedClient, setIsCheckedClient] = useState(false);
    // const [isCheckedManager, setIsCheckedManager] = useState(false);
    const [userType, setUserType] = useState(0);

    // const handlePartnerChange = (e) => {
    //     if (props.profileData.user_ty === null) {
    //         if (e.target.checked) {
    //             setUserType(1);
    //         } else {
    //             setUserType(null);
    //         }
    //     }
    // };

    // const handleClientChange = (e) => {
    //     if (props.profileData.user_ty === null) {
    //         if (e.target.checked) {
    //             setUserType(2);
    //         } else {
    //             setUserType(null);
    //         }
    //     }
    // };

    // const handleManagerChange = (e) => {
    //     if (props.profileData.user_ty === null) {
    //         if (e.target.checked) {
    //             setUserType(3);
    //         } else {
    //             setUserType(null);
    //         }
    //     }
    // };


    // 유효성검사
    const validateFormData = () => {
        if (!userEmail) {
            return {
                isValid: false,
                message: "이메일을 입력해주세요."
            };
        }
        if (!userNm) {
            return {
                isValid: false,
                message: "이름을 입력해주세요."
            };
        }
        if (!userTelNum) {
            return {
                isValid: false,
                message: "전화번호를 입력해주세요."
            };
        }
        if (!userType) {
            return {
                isValid: false,
                message: "계정을 선택해주세요."
            };
        }
        return { isValid: true };
    };


    // 프로필 업데이트
    const profileManagerUpd = () => {
        const { isValid, message } = validateFormData();
        
        if (!isValid) {
            setShowPopup(true);
            setPopupMsg(message);
            return;
        }

        // console.log(Intro);
        const baslicFormData = new FormData();
        baslicFormData.append("userId", props.profileData.user_id);
        baslicFormData.append("userNm", userNm);
        baslicFormData.append("email", userEmail);
        baslicFormData.append("phoneNo", userTelNum);
        baslicFormData.append("userTy", userType);
        axios({
            method:'post',
            url:`/api/account/update/basic`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: baslicFormData,
        })
        . then((result)=>{
            console.log(result);
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (기본정보) 업데이트 되었습니다.");
                props.setUserProfile(result.data.data);
                // setOnChangeClick(false);
                // props.setLoginprops.profileData(result.data.data);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (기본정보)에 실패하였습니다.");
            }
        })
        .catch((error) => {

        })
    }
    

    return (
        <React.Fragment>
            {/* <div className='main-text'>인증 관리</div> */}
            <div className='main-text'>사용자 관리</div>
            <div className='contents-row flex-center full-width user-mgt-box'>
                <div className='contents-col user-mgt spacer'>
                    <div className='contents-row act-info'>
                        <label>이메일<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='이메일을 입력해 주세요'
                            onChange={handleUserEmail}
                            value={userEmail || ''}
                        />
                    </div>
                    <div className='contents-row act-info'>
                        <label>이름 (업체명)<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='이름을 입력해 주세요'
                            onChange={handleUserNm}
                            value={userNm || ''}
                        />
                    </div>
                    <div className='contents-row act-info'>
                        <label>전화번호<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='전화번호를 입력해 주세요'
                            onChange={handleUserTelNum}
                            value={userTelNum || ''}
                        />
                    </div>
                    <div className='contents-row'>
                        <label>계정 유형<span className='required'>*</span></label>
                        <div className='contents-row flex-center spacer account-type'>
                            <div className='contents-row field'>
                                <input
                                    id="partnerCheckbox"
                                    type='checkbox'
                                    checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 1 : userType === 1}
                                    // disabled={props.profileData.user_ty !== null}
                                    // onChange={handlePartnerChange}
                                />
                                <label htmlFor="partnerCheckbox">파트너</label>
                            </div>
                            <div className='contents-row field'>
                                <input
                                    id="clientCheckbox"
                                    type='checkbox'
                                    checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 2 : userType === 2}
                                    // disabled={props.profileData.user_ty !== null}
                                    // onChange={handleClientChange}
                                />
                                <label htmlFor="clientCheckbox">클라이언트</label>
                            </div>
                            <div className='contents-row field'>
                                <input
                                    id="clientCheckbox"
                                    type='checkbox'
                                    checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 3 : userType === 3}
                                    // disabled={props.profileData.user_ty !== null}
                                    // onChange={handleManagerChange}
                                />
                                <label htmlFor="clientCheckbox">매니저</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contents-row flex-center mod-user-info'>
                    <div className='gc-btn gc-btn-line flex-center act-btn pw-change' onClick={() => {setShowChangePwPopup(true)}}>비밀번호 변경</div>
                    <div className='contents-col flex-center'>
                        {!imageUrl ? (
                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                            <div className='user-logo-img none'></div>
                        ) : (
                            <img
                                className="user-logo-img circle"
                                src={imageUrl}
                                alt="Uploaded Image"
                            />
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <div className='gc-btn gc-btn-green flex-center act-btn' onClick={handleImageChange}>이미지 변경</div>
                    </div>
                </div>
            </div>
            <div className='full-width flex-center manager-bottom'>
                <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileManagerUpd : null}>저장하기</div>
                {/* <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileBasicUpd : null}>저장하기</div> */}
                {/* <button className={`  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileBasicUpd : null}>저장하기</button> */}
                <span className='required-info'>* 필수항목</span>
            </div>
            {/* <Popup
                show={showPopup}
                header=""
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p>{popupMsg}</p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                    }}  
                >닫기</div>
                </div>
            </Popup> */}
            <Popup
                show={showPopup}
                // onCancel={() => setShowAddPopup(false)}
                header=""
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             // setShowAddPopup(false);
                //             setShowPopup(false);
                //         }} 
                //     /> }
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p
                    // style={{
                    //   fontSize: fontSizeValue,
                    //   padding: paddingValue,
                    // }}
                    // dangerouslySetInnerHTML={{ __html: popupMsg }}
                >
                    {popupMsg}
                </p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                        setOnChangeClick(false);
                    }}  
                >닫기</div>
                </div>
            </Popup>
            <ChangePwModal 
                showChangePwPopup={showChangePwPopup} 
                setShowChagePwPopup={setShowChangePwPopup}
            ></ChangePwModal>
        </React.Fragment>
    );
}

export default ManagerProfile;