import './Profile.css';
import Document from './Document';
import Portfolio from './Portfolio';
import Review from './Review';
import Myprofile from './Myprofile';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Pagination, Icon } from 'semantic-ui-react';
import BasicInformation from './BasicInformation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popup from '../../shared/components/uiElements/Popup';

import { googleLogout } from '@react-oauth/google';

// AXIOS
import axios from "axios";
// TEST

function Profile(props) {
    
  const APIToken = localStorage.getItem("login-token");
  const loginUserId = localStorage.getItem('user-id');
  const loginUserType = localStorage.getItem('user-ty');
  const loginUserTy = localStorage.getItem('user-ty');
  
  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

  // 서브메뉴
  const [profileState, setProfileState] = useState('basic-information');
  // 포트폴리오 목록 조회
  const [portfolioList, setPortfolioList] = useState();
  // 포트폴리오 상세보기
  const [selectPortfolio, setSelectPortfolio] = useState();
  // 내가 받은 리뷰 (toUserId = 로그인 유저) 
  const [reviewList, seReviewList] = useState(null);
  //
  const [reviewRankList, setReviewRankList] = useState({});
  //
  const [projectList, setProjectList] = useState();
    
  useEffect (() => {

    let urlstring = `/api/project/list?`;

    if(loginUserType == 1){ // 파트너
        urlstring += `partnerId=${loginUserId}`;
    }
    else if(loginUserType == 2){ // 클라이언트
        urlstring += `userId=${loginUserId}`;
    }

    axios({
        method:'get',
        url: urlstring,
        headers: {
            'Authorization': `Bearer {${APIToken}}`
        },
    })
    .then((result)=>{
        if(result.data.success === true) {
            let project_list = result.data.data;
            setProjectList(project_list);
        }
    })
    .catch((error)=>{
        console.log(error);
    })
}, [])

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePageChange = (e, { activePage }) => {
      setActivePage(activePage);
  };
  
  const portfoPageSize = 6;

  // main-title
  let titleText;
  switch (profileState) {
    case 'basic-information': titleText = '기본 정보'; break;
    case 'document-mgt': titleText = '문서 관리'; break;
    case 'portfolio-mgt': titleText = '포트폴리오 관리'; break;
    case 'portfolio-add': titleText = '포트폴리오 등록'; break;
    case 'portfolio-view': titleText = '포트폴리오 보기'; break;
    case 'review-mgt': titleText = '리뷰 관리'; break;
    case 'my-profile': titleText = '프로필 보기'; break;
    default: titleText = '내 프로필보기  / 로그아웃'; break;
  }

  // 로그아웃
  const navigate = useNavigate();
  const handleLogout = () => {
    if (props.userProfile.id_type_cd == 3) {
      localStorage.removeItem("com.naver.nid.access_token");
      // window.location.reload();
      // naverLogin.logout();
    } else if (props.userProfile.id_type_cd == 4) {
      if (window.Kakao && window.Kakao.Auth.getAccessToken()) {
        window.Kakao.Auth.logout(() => {
          // console.log(window.Kakao.Auth.getAccessToken());
      })}
    } else if (props.userProfile.id_type_cd == 2) {
      // handleGoogleLogout();
      googleLogout();
    }
    props.logout();
    navigate('/login');
  }

  const handleProgressStateClick = (value) => {
    navigate("/mydropia");
    props.setProgressState(value);
  };

  useEffect (() => {
    axios({
      method:'get',
      url:`/api/prtfolio/list?userId=${loginUserId}&page=${activePage}&perPage=${portfoPageSize}`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success){
          const portfolioList = result.data;
          setPortfolioList(portfolioList.data);
          setTotalPages(Math.ceil(portfolioList.total / portfoPageSize));
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  } , [profileState, activePage])

  useEffect (() => {
    axios({
      method:'get',
      url:`/api/project/review/list?toUserId=${loginUserId}`,
      headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success === true) {
          seReviewList(result.data);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }, [])

  useEffect(() => {
    if (reviewList !== null) {
      if (reviewList.rank) {
        setReviewRankList(reviewList.rank);
      }
    }
  }, [reviewList])

  // 뒤로가기 (히스토리 상태 관리)
  // 데이터 변경 여부 상태 변수
  const [isDataChanged, setIsDataChanged] = useState(false);

  const handleStateChange = (newState) => {
    // console.log(isDataChanged)
    // setIsDataChanged(false); 
    // if (isDataChanged) {
    //   if (window.confirm('저장하지 않았는데 이동하시겠습니까?')) {
    //     // setIsDataChanged(false); // 데이터 변경 여부 초기화
    //     setProfileState(newState);
    //   }
    // } else {
    //   setProfileState(newState);
    // }

    setProfileState(newState);
    // setProfileState(state);
    window.history.pushState({ profileState: newState }, '', '/profile');
    // window.history.pushState({ profileState: state }, '', '/profile');
  };

  // 뒤로가기 (뒤로가기 버튼 감지 및 상태 업데이트)
  useEffect(() => {
    const handlePopState = (event) => {
    if (event.state && event.state.profileState) {
        setProfileState(event.state.profileState);
    }
    };
    window.addEventListener('popstate', handlePopState);
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
    window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // 인증신청
  const applySendMsg = () => {
      
    axios({
        method:'post',
        url:`/api/msg/send`,
        headers: {
            'Authorization': `Bearer {${APIToken}}`
        },
        data: {
            msgTyCd : 10,
            toUserId : -1,
            msgTitle : '업자 인증 요청입니다',
            msgContents : '인증 부탁드립니다',
            // prjId : 
        }, 
    })
    . then((result) => {
        // console.log(result.data);
        if (result.data.success) {
            setShowPopup(true);
            setPopupMsg('인증신청 되었습니다');
            // setPopupMsg("포트폴리오가 삭제되었습니다.");
            // setIsSuccessOk(true);
        } else {
            // setShowPopup(true);
            // setPopupMsg(result.data.msg);
            // setPopupMsg("포트폴리오를 삭제하지 못하였습니다.");
        }
    })
    . catch((error) => {
        
    })
  }


  return (
    <React.Fragment>
      <div className='Dropia-container'>
        <div className='contents-row dropia-reg'>
          <div className='contents-col dropia-reg-main'>
            <div className='contents-row full-width main-title-container'>
              <div className='contents-row full-width flex-center main-title-box'>
                <div className='main-title'>{titleText}</div>
                {
                  props.userProfile.user_ty === 1 ?
                    <div className='gc-btn gc-btn-green flex-center partner none-hover'>파트너</div>
                  : props.userProfile.user_ty === 2 ?
                    <div className='gc-btn flex-center client none-hover'>클라이언트</div>
                  :  props.userProfile.user_ty === 3 ?
                    <div className='gc-btn flex-center manager-tag none-hover'>매니저</div>
                  : null
                }
              </div>
              {/* {console.log(props.userProfile.certified_com_yn)} */}
              {profileState === 'document-mgt' && props.userProfile.certified_com_yn === 'false' ? <div className='gc-btn flex-center act-btn info-btn change' onClick={applySendMsg} >인증신청</div> : null}
              {/* {profileState === 'document-mgt' && !props.userProfil.certified_com_yn ? <div className='gc-btn flex-center act-btn info-btn change' onClick={applySendMsg} >인증신청</div> : null} */}
            </div>
            
            <div className='hr-solid'/>

            {
              profileState === 'basic-information' ? 
              <BasicInformation 
                profileData = {props.userProfile} 
                setUserProfile={props.setUserProfile} 
                imageUrl={props.imageUrl} 
                setImageUrl={props.setImageUrl} 
                selectedFile={props.selectedFile} 
                setSelectedFile={props.setSelectedFile}

                setIsDataChanged={setIsDataChanged}
              ></BasicInformation>
              : 
              profileState === 'document-mgt' ? 
              <Document 
                profileData = {props.userProfile} 
                setUserProfile={props.setUserProfile} 
                imageUrl={props.imageUrl} 
                setImageUrl={props.setImageUrl} 
                selectedFile={props.selectedFile} 
                setSelectedFile={props.setSelectedFile}

                setIsDataChanged={setIsDataChanged}
              ></Document>
              : 
              profileState === 'portfolio-mgt' ? 
              <React.Fragment>
                <div className='full-width'>
                  <div className='main-text'>Dropia에서 진행한 프로젝트</div>
                  <div className='contents-row flex-center project-progress'>
                    <div className='contents-col flex-center spacer progress-box'>
                      <p>진행중인 프로젝트</p>
                      <div className='contents-row prj-result'>
                        <p className='flex-center'>{projectList && projectList.filter(project => project.prj_stts_cd === 5).length} 건</p>
                        <div className='gc-btn flex-center act-btn info-btn' onClick={() => {handleProgressStateClick(5)}}>바로가기</div>
                      </div>
                    </div>
                    <div className='contents-col flex-center spacer progress-box'>
                      <p>진행완료 프로젝트</p>
                      <div className='contents-row prj-result'>
                        <p className='flex-center'>{projectList && projectList.filter(project => project.prj_stts_cd === 8).length} 건</p>
                        <div className='gc-btn flex-center act-btn info-btn' onClick={() => {handleProgressStateClick(8)}}>바로가기</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='hr-solid'/>
                <div className='contents-row full-width flex-center portfolio-menubar'>
                  <div className='main-text'>외부 포트폴리오</div>
                  <div className='gc-btn gc-btn-green info-btn flex-center act-btn' onClick={()=>{setProfileState('portfolio-add')}}>
                    <FontAwesomeIcon icon="fa-solid fa-plus"/>포트폴리오 추가
                  </div>
                </div>
                <div className='contents-row full-width flex-center portfolio-list'>
                  {portfolioList && portfolioList.length !== 0 ? 
                  portfolioList.map((portfolio, index) => (
                    <div 
                      className='contents-col flex-center rrn-img-box portfolio-box'
                      key={index}
                      onClick={()=>{
                        setSelectPortfolio(portfolio);
                        setProfileState('portfolio-view');
                      }}
                    >
                      {portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx] && portfolio.prj_output_urls[portfolio.prj_output_main_idx] ? 
                        (
                          <img 
                            src={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                            alt={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                            className='rrn-img portfolio-img-img'
                            style={{
                              maxWidth: '100%',
                              // maxHeight: '130px',
                              objectFit: 'contain'
                            }}
                          />
                          ) : (
                          <div 
                            className='contents-row flex-center'
                            style={{maxWidth: '100%', height: '200px', objectFit: 'contain'}}
                          >
                            썸네일 이미지 없음
                          </div>
                        )
                      }
                      <p>{portfolio.prtfolio_nm}</p>
                      <span>{portfolio.prj_ty_nm}</span>
                      {/* <span>{portfolio.prj_ty_custom}</span> */}
                      <span>{portfolio.prj_start_de} ~ {portfolio.prj_end_de}</span>
                    </div>
                  ))
                  : 
                    <div className='contents-row full-width flex-center'>
                      <p className='result-info'>등록된 포트폴리오가 없습니다.</p>
                    </div>
                  } 
                </div>
                {portfolioList.length !== 0 ?
                <div className="flex-center">
                  <Pagination
                    className="pagination"
                    activePage={activePage}
                    totalPages={totalPages}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                    onPageChange={handlePageChange}
                  >                        
                  </Pagination>
                </div>
                : null}
              </React.Fragment>
              : 
              profileState === 'portfolio-add' ? 
              <Portfolio 
                profileState={profileState} 
                setProfileState={(state) => setProfileState(state)}
              ></Portfolio>
              : 
              profileState === 'portfolio-view' ? 
              <Portfolio 
                profileState={profileState} 
                selectPortfolio={selectPortfolio} 
                setProfileState={(state) => setProfileState(state)}
              ></Portfolio>
              : 
              profileState === 'review-mgt' ? 
              <Review 
                portfolioList={portfolioList} 
                reviewList={reviewList} 
                reviewRankList={reviewRankList}
              ></Review>
              : 
              profileState === 'my-profile' ? 
              <Myprofile 
                profileData = {props.userProfile} 
                portfolioList={portfolioList} 
                setProfileState={(state) => setProfileState(state)} 
                reviewRankList={reviewRankList} 
                reviewList={reviewList}
              ></Myprofile>
              : 
              <Review></Review>
            }
          </div>

          <div className='contents-col dropia-reg-sub flex-center profile'>
            <div className='contents-row flex-center progress-box req user-info'>
              {!props.userProfile.photoUrl ? (
                // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                <div className='user-logo-img middle none'></div>
                ) : (
                <img
                    className="user-logo-img"
                    src={props.userProfile.photoUrl}
                    alt="Uploaded Image"
                />
              )}
              <div className='contents-col user-info-box'>
                <p className='user-nm'>{props.userProfile.user_nm}</p>
                <p className='user-email'>{props.userProfile.email}</p>
              </div>
              {/* {loginUserTy === '1' ? 
                <div className='gc-btn gc-btn-green flex-center info-btn login-user-ty-state partner none-hover'>파트너</div>
              : loginUserTy === '2' ? 
                <div className='gc-btn flex-center info-btn login-user-ty-state client none-hover'>클라이언트</div>
              : null} */}
              {
                props.userProfile.user_ty === 1 ?
                  <div className='gc-btn gc-btn-green flex-center info-btn login-user-ty-state partner none-hover'>파트너</div>
                : props.userProfile.user_ty === 2 ?
                  <div className='gc-btn flex-center info-btn login-user-ty-state client none-hover'>클라이언트</div>
                :  props.userProfile.user_ty === 3 ?
                  <div className='gc-btn flex-center login-user-ty-state manager-tag none-hover'>매니저</div>
                : null
              }
            </div>
            <div className='contents-col project-status-box'>
              <div className={`contents-row project-status profile ${profileState === 'basic-information' ? 'active' : ''}`} onClick={() => handleStateChange('basic-information')}>
                <p>기본정보</p>
              </div>
              <div className={`contents-row project-status profile ${profileState === 'document-mgt' ? 'active' : ''}`} onClick={() => handleStateChange('document-mgt')}>
                <p>문서관리</p>
              </div>
              {props.userProfile.user_ty === 1 ?
                <div className={`contents-row project-status profile ${profileState === 'portfolio-mgt' || profileState === 'portfolio-add' || profileState === 'portfolio-view'? 'active' : ''}`} onClick={() => handleStateChange('portfolio-mgt')}>
                  <p>포트폴리오 관리</p>
                </div>
              : null}
              <div className={`contents-row project-status profile ${profileState === 'review-mgt' ? 'active' : ''}`} onClick={() => handleStateChange('review-mgt')}>
                <p>리뷰관리</p>
              </div>
              <div className='hr-solid'/>
              <div className={`contents-row project-status profile ${profileState === 'my-profile' ? 'active' : ''}`} onClick={() => handleStateChange('my-profile')}>
                <p>내 프로필 보기</p>
              </div>
              <div className='contents-row project-status profile' onClick={handleLogout}>
                <p>로그아웃</p>
              </div>
            </div>
            <div className='contents-col flex-center dropia-btn-box'></div>
          </div>
        </div>
      </div>
      <Popup
          show={showPopup}
      >
        <div className='contents-col full-width flex-center popup-info'>
        <p>{popupMsg}</p>
          <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
              setShowPopup(false);
              }}  
          >닫기</div>
        </div>
      </Popup>
    </React.Fragment>
  );
}

export default Profile;