import './Profile.css';
import './Portfolio.css';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'semantic-ui-react';

import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

function Portfolio({ profileState, selectPortfolio, setProfileState }) {
    const APIToken = localStorage.getItem("login-token");
    
    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
    const [popupState, setPopupState] = useState();                     // 팝업 상태
     const [showQuestionPopup, setShowQuestionPopup] = useState(false);  // 질문팝업 boolen
    // const [portfolioState, setPortfolioState] = useState('portfolio-view');
    // main-title
    // let titleText;
    // switch (portfolioState) {
    // case 'portfolio-detail':
    //     titleText = '기본 정보';
    //     break;
    // case 'document-mod':
    //     titleText = '문서 관리';
    //     break;
    // default:
    //     titleText = '내 프로필보기  / 로그아웃';
    //     break;
    // }
    
    // 분야 (1:일반 레포츠 / 2:미디어 촬영 /  3:시설점검 / 4:공간정보 / 5:농업, 방제 / 6.기타 (직접입력)
    const [selectedFields, setSelectedFields] = useState();
    const [selectedField, setSelectedField] = useState();

    // const handleCheckboxChange = (value) => {
    //     // if (selectedField === value) {
    //     //     setSelectedField(null);
    //     // } else {
    //     //     setSelectedField(value);
    //     // }
    //     if (profileState === 'portfolio-add' ||  modifyMode) {
    //         setSelectedField(prevSelectedField => prevSelectedField === value ? null : value);
    //     }
    //     // setSelectedField(value === selectedField ? null : value);
    // };
    const handleCheckboxChange = (value) => {
        if (profileState === 'portfolio-add' || modifyMode) {
            setSelectedField(prevSelectedField => prevSelectedField === value ? null : value);
        }
    };

    const fields = [
        { label: '일반 레포츠', value: 1 },
        { label: '미디어촬영', value: 2 },
        { label: '시설점검', value: 3 },
        { label: '공간정보', value: 4 },
        { label: '농업,방제', value: 5 },
        { label: '기타', value: 6 }
    ];


    // 분야 - 기타 (직접입력)
    const [prjTyCustom, setPrjTyCustom] = useState('');

    const handlePrjTyCustom = (event) => {
        event.preventDefault();
        setPrjTyCustom(event.target.value);
    }

    // 포트폴리오 정보
    const [portfolioNm , setPortfolioNm] = useState();
    const [portfolioIntro, setPortfolioIntro] = useState();
    
    const handlePortfolioNm = (event) => {
        event.preventDefault();
        setPortfolioNm(event.target.value);
    }
    const handlePortfolioIntro = (event) => {
        event.preventDefault();
        setPortfolioIntro(event.target.value);
    }

    // 포트폴리오 정보 (참여율)
    const participationRateOption = [
        {key: 0,text: '0%', value: 0},
        {key: 1,text: '10%', value: 10},
        {key: 2, text: '20%', value: 20},
        {key: 3, text: '30%', value: 30},
        {key: 4, text: '40%', value: 40},
        {key: 5, text: '50%', value: 50},
        {key: 6, text: '60%', value: 60},
        {key: 7, text: '70%', value: 70},
        {key: 8, text: '80%', value: 80},
        {key: 9, text: '90%', value: 90},
        {key: 10, text: '100%', value: 100},
    ]
    const [participationRate, setParticipationRate] = useState(null);
    const handleParticipationRate = (e, {value}) => {
        e.preventDefault();
        setParticipationRate(value);
    }

    // 포트폴리오 정보 (프로젝트 기간)
    const [portfolioStartDate, setPortfolioStartDate] = useState();
    const [portfolioEndDate, setPortfolioEndDate] = useState();
    const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);

    const handlePortfolioStartDate = (e) => {
        e.preventDefault();
        setPortfolioStartDate(e.target.value);
        setIsEndDateEnabled(true);
    };
    const handlePortfolioEndDate = (e) => {
        e.preventDefault();
        setPortfolioEndDate(e.target.value);
    };
    // const handleDateChange = (index, newValue) => {
    //     const updatedHistoryData = [...historyData];
    //     updatedHistoryData[index].date = newValue;
    //     setHistoryData(updatedHistoryData);
    // };

    // 고객사 정보
    const [isPublic, setIsPublic] = useState(false);
    const [customerNm, setCustomerNm] = useState();
    const [customerRole, setCustomerRole] = useState();

    const handleIsPublic = (event) => {
        setIsPublic(event.target.checked);
    };
    const handleCustomerNm = (e) => {
        e.preventDefault();
        setCustomerNm(e.target.value);
    };
    const handleCustomerRole = (e) => {
        e.preventDefault();
        setCustomerRole(e.target.value);
    };
    
    // 산출물 정보
    const [prjOutputFile, setPrjOutputFile] = useState();
    const [prjOutputUrl, setPrjOutputUrl] = useState();
    const [prjOutputNm, setPrjOutputNm] = useState();

    // 산출물 정보
    const [selectedPrjOutputFiles, setSelectedPrjOutputFiles] = useState([]);
    // const [selectedCertiFiles, setSelectedCertiFiles] = useState([]);
    const [selectedPrjOutputFileUrls, setSelectedPrjOutputFileUrls] = useState([]);
    // const [selectedCertiFileUrls, setSelectedCertiFileUrls] = useState([]);
    const [selectedCertiFileNames, setSelectedCertiFileNames] = useState([]);
    
    // const certiFileRef = useRef();
    const prjOutputFileRef = useRef();
    
    const handleCertiFileClick = () => {
        prjOutputFileRef.current.click();
    };

    const [newSelectCertiFileNames, setNewSelectCertiFileNames] = useState([]);
    // const handleCertiFileUpload = (e) => {
    //     const files = Array.from(e.target.files);
    //     const fileNames = files.map(file => file.name);
    //     // console.log(fileNames)
    //     setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
    //     setNewSelectCertiFileNames(fileNames);
    //     setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
        
    //     files.forEach(file => {
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             setSelectedCertiFileUrls((prevFiles) => [...prevFiles, event.target.result]);
    //         };
    //         reader.readAsDataURL(file);
    //     });
    //     e.target.value = '';
    // };

    const [existingCertiUrls, setExistingCertiUrls] = useState([]);
    const [existingCertiDescriptions, setExistingCertiDescriptions] = useState([]);
    const [newCertiUrls, setNewCertiUrls] = useState([]);
    const [newCertiDescriptions, setNewCertiDescriptions] = useState([]);
    let files;
    const handlePrjOutputFileUpload = (e) => {
        files = Array.from(e.target.files);
        // setSelectedPrjOutputFiles((prevFiles) => console.log(prevFiles));
        setSelectedPrjOutputFiles(prevFiles => [...prevFiles, ...files]);
        // setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
        
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (event) => {
                // setSelectedPrjOutputFileUrls((prevFiles) => console.log(prevFiles));
                // setSelectedPrjOutputFileUrls((prevFiles) => [...prevFiles, event.target.result]);
                setNewCertiUrls((prevUrls) => [...prevUrls, event.target.result]);
                setNewCertiDescriptions(prevDescriptions => [...prevDescriptions, '']);
            };
            reader.readAsDataURL(file);
        });
        e.target.value = '';
    };

    // const [deletedCertiIndexes, setDeletedCertiIndexes] = useState([]);

    // const handleDeleteCertiFile = (index) => {
    //     setDeletedCertiIndexes(prevIndexes => [...prevIndexes, index.toString()]);
    
    //     setSelectedCertiFiles((prevFiles) => {
    //         const newFiles = [...prevFiles];
    //         newFiles.splice(index, 1);
    //         return newFiles;
    //     });
    //     setSelectedCertiFileNames((prevFileNames) => {
    //         const newFileNames = [...prevFileNames];
    //         newFileNames.splice(index, 1);
    //         return newFileNames;
    //     });
    // };
    
    // const handleDeleteCertiFile = (index) => {
    //     setDeletedCertiIndexes(prevIndexes => [...prevIndexes, index.toString()]);
    
    //     setSelectedPrjOutputFiles((prevFiles) => {
    //         const newFiles = [...prevFiles];
    //         newFiles.splice(index, 1);
    //         return newFiles;
    //     });
    // };

    
    // 산출물 이미지 이름
    const [prjOutputDescriptions, setPrjOutputDescriptions] = useState([]);
    
    useEffect(() => {
        if (selectedPrjOutputFileUrls) {
            setPrjOutputDescriptions(Array(selectedPrjOutputFileUrls.length).fill(''));
        }
    }, [])
    
    const handleDescriptionChange = (index, description, isExisting) => {
        if (isExisting) {
            const updatedDescriptions = [...existingCertiDescriptions];
            updatedDescriptions[index] = description;
            setExistingCertiDescriptions(updatedDescriptions);
        } else {
            const updatedDescriptions = [...newCertiDescriptions];
            updatedDescriptions[index] = description;
            setNewCertiDescriptions(updatedDescriptions);
        }
    };

    // 산출물 이미지 썸네일 선택 index
    // const [clickedIndex, setClickedIndex] = useState(''); 
    // const handleClick = (index) => {
    //     setClickedIndex(index); // 클릭된 인덱스 저장
    // };
    

    // const handleCheck = (index) => {
    //     // 이미 선택된 이미지라면 선택 해제
    //     if (selectedIndex === index.toString()) {
    //         setSelectedIndex('');
    //     } else {
    //         // 새로운 이미지를 선택하거나 이미 선택된 이미지를 변경
    //         setSelectedIndex(index.toString());
    //     }
    // };

    // 산출물 이미지 삭제
    const [selectedDelIndex, setSelectedDelIndex] = useState([]);
    // const handleDelete = (index, isExisting) => {
    //     // 해당 인덱스의 이미지와 설명 삭제
    //     if (isExisting) {
    //         // Handle existing data deletion
    //         const updatedUrls = [...existingCertiUrls];
    //         const updatedDescriptions = [...existingCertiDescriptions];
    //         updatedUrls.splice(index, 1);
    //         updatedDescriptions.splice(index, 1);
    
    //         setExistingCertiUrls(updatedUrls);
    //         setExistingCertiDescriptions(updatedDescriptions);
    //     } else {
    //         // Handle new data deletion
    //         const updatedUrls = [...newCertiUrls];
    //         const updatedDescriptions = [...newCertiDescriptions];
    //         updatedUrls.splice(index, 1);
    //         updatedDescriptions.splice(index, 1);
    
    //         setNewCertiUrls(updatedUrls);
    //         setNewCertiDescriptions(updatedDescriptions);
    //     }
        
    //     // 선택된 이미지의 인덱스를 배열에 추가
    //     // setDeletedCertiIndexes((prevIndexes) => [...prevIndexes, index]);
    //     setSelectedDelIndex((prevIndexes) =>[...prevIndexes, index]);
    //     // if (selectedPrjOutputFileUrls.length === 1 && selectedIndex === '') {
    //     //     setSelectedDelIndex(index.toString());
    //     // } else if (selectedPrjOutputFileUrls.length > 1) {
    //     //     setSelectedDelIndex(selectedIndex === index.toString() ? '' : index.toString());
    //     // }


    //     // const newUrls = selectedPrjOutputFileUrls.filter((_, i) => i !== index);
    //     // const newDescriptions = prjOutputDescriptions.filter((_, i) => i !== index);
    //     // setSelectedPrjOutputFileUrls(newUrls);
    //     // setPrjOutputDescriptions(newDescriptions);

    //     const prjArr = selectedPrjOutputFiles.filter((el, idx) => 
    //         idx !== index-prjOutputDescriptions.length
    //     );
    //     setSelectedPrjOutputFiles([...prjArr]);
    // };
    const handleDelete = (index, isExisting) => {
        if (isExisting) {
            // 기존 데이터에서 파일 삭제 처리
            const updatedUrls = [...existingCertiUrls];
            const updatedDescriptions = [...existingCertiDescriptions];
            updatedUrls.splice(index, 1);
            updatedDescriptions.splice(index, 1);
    
            setExistingCertiUrls(updatedUrls);
            setExistingCertiDescriptions(updatedDescriptions);
    
            // 삭제된 인덱스를 저장
            setSelectedDelIndex(prevIndexes => [...prevIndexes, index]);
        } else {
            // 새로운 데이터에서 파일 삭제 처리
            const updatedUrls = [...newCertiUrls];
            const updatedDescriptions = [...newCertiDescriptions];
            updatedUrls.splice(index, 1);
            updatedDescriptions.splice(index, 1);
    
            setNewCertiUrls(updatedUrls);
            setNewCertiDescriptions(updatedDescriptions);
    
            // 선택된 파일 목록에서 삭제된 파일 제거
            setSelectedPrjOutputFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        }
    };
    

    // const handleDelete = (index, isExisting) => {
    //     if (isExisting) {
    //         // Handle existing data deletion
    //         const updatedUrls = [...existingCertiUrls];
    //         const updatedDescriptions = [...existingCertiDescriptions];
    //         updatedUrls.splice(index, 1);
    //         updatedDescriptions.splice(index, 1);
    
    //         setExistingCertiUrls(updatedUrls);
    //         setExistingCertiDescriptions(updatedDescriptions);
    //     } else {
    //         // Handle new data deletion
    //         const updatedUrls = [...newCertiUrls];
    //         const updatedDescriptions = [...newCertiDescriptions];
    //         updatedUrls.splice(index, 1);
    //         updatedDescriptions.splice(index, 1);
    
    //         setNewCertiUrls(updatedUrls);
    //         setNewCertiDescriptions(updatedDescriptions);
    //     }
    
    //     // Save deleted index
    //     setSelectedDelIndex((prevIndexes) => [...prevIndexes, index]);
    
    //     setOnChangeClick(true);
    // };

    // const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [selectedExistingIndex, setSelectedExistingIndex] = useState('');
    const [selectedNewIndex, setSelectedNewIndex] = useState('');
    // const handleCheck = (index) => {
    //     // if (selectedPrjOutputFileUrls.length === 1 && selectedIndex === '') {
    //     //     setSelectedIndex(index.toString());
    //     // } else if (selectedPrjOutputFileUrls.length > 1) {
    //     //     setSelectedIndex(selectedIndex === index.toString() ? '' : index.toString());
    //     // }
    //     if (selectedPrjOutputFileUrls.length === 1) {
    //         setSelectedIndex(index.toString());
    //     } else if (selectedPrjOutputFileUrls.length > 1) {
    //         setSelectedIndex(selectedIndex === index.toString() ? '' : index.toString());
    //     }
    //     // if (selectedPrjOutputFileUrls.length > 1) {
    //     //     setSelectedIndex(selectedIndex === index.toString() ? '' : index.toString());
    //     // }
    // };
    // const handleCheck = (index) => {
    //     // If the clicked index is already selected, uncheck it (set selectedIndex to null)
    //     // Otherwise, set selectedIndex to the clicked index
    //     setSelectedIndex(prevIndex => prevIndex === index ? null : index);
    // };
    // const handleCheck = (index) => {
    //     // Toggle selectedIndex between the clicked index and null (to uncheck it)
    //     setSelectedIndex(prevIndex => prevIndex === index.toString() ? null : index.toString());
    // };
    // const handleCheck = (index) => {
    //     // Toggle the selectedIndex if the clicked index matches the current selectedIndex
    //     if (selectedIndex === index.toString()) {
    //         setSelectedIndex('');  // Deselect if already selected
    //     } else {
    //         setSelectedIndex(index.toString());  // Select the new index
    //     }
    // };
    // const handleCheck = (index, isExisting) => {
    //     if (isExisting) {
    //         setSelectedExistingIndex(prevIndex => prevIndex === index.toString() ? '' : index.toString());
    //     } else {
    //         setSelectedNewIndex(prevIndex => prevIndex === index.toString() ? '' : index.toString());
    //     }
    // };

    useEffect(() => {
        // Automatically select the first image from newCertiUrls if no item is selected
        if (newCertiUrls.length > 0 && selectedNewIndex === '') {
            setSelectedNewIndex('0');  // Select the first image (index 0)
        }
    }, [newCertiUrls]);
    
    const handleCheck = (index, isExisting) => {
        if (isExisting) {
            // If an item from existingCertiUrls is selected, clear the selection from newCertiUrls
            setSelectedExistingIndex(prevIndex => prevIndex === index.toString() ? '' : index.toString());
            if (selectedNewIndex !== '') {
                setSelectedNewIndex(''); // Deselect any selected item in newCertiUrls
            }
        } else {
            // If an item from newCertiUrls is selected, clear the selection from existingCertiUrls
            setSelectedNewIndex(prevIndex => prevIndex === index.toString() ? '' : index.toString());
            if (selectedExistingIndex !== '') {
                setSelectedExistingIndex(''); // Deselect any selected item in existingCertiUrls
            }
        }
    };
    
    
    // 산출물 URL
    const [outputUrl , setOutputUrl] = useState('');
    const handleOutputUrl = (event) => {
        event.preventDefault();
        setOutputUrl(event.target.value);
    }

    // 수정 모드
    const [modifyMode, setModifyMode] = useState(false);
    
    // 프로젝트 정보
    // console.log(selectPortfolio);
    
    useEffect(() => {
        if (selectPortfolio && !modifyMode) {
            // 프로젝트 제목
            setPortfolioNm(selectPortfolio.prtfolio_nm);
            // 프로젝트 분야
            setSelectedField(selectPortfolio.prj_ty_cd);
            // 프로젝트 분야 (기타)
            setPrjTyCustom(selectPortfolio.prj_ty_custom);
            // 프로젝트 참여율
            setParticipationRate(selectPortfolio.prj_part_rate);
            // 프로젝트 소개글
            setPortfolioIntro(selectPortfolio.prj_summ);
            // setPortfolioIntro(selectPortfolio.prj_summ);
            // 프로젝트 기간 (start)
            setPortfolioStartDate(selectPortfolio.prj_start_de);
            // 프로젝트 기간 (end)
            setPortfolioEndDate(selectPortfolio.prj_end_de);
            // 고객사명
            setCustomerNm(selectPortfolio.prj_client_nm);
            // 고객사 역할
            setCustomerRole(selectPortfolio.prj_client_role);
            // 비공개
            setIsPublic(selectPortfolio.prj_client_privt);
            // 산출물 URL prj_output_url
            setOutputUrl(selectPortfolio.prj_output_url);
            // 산출물 이미지
            if (selectPortfolio.prj_output_urls === null || selectPortfolio.prj_output_urls === undefined) {
                setSelectedPrjOutputFiles([]); 
                setPrjOutputDescriptions([]);
            } else {
                setSelectedPrjOutputFileUrls(selectPortfolio.prj_output_urls);
                setPrjOutputDescriptions(selectPortfolio.prj_output_cn_arr);
            }
            setExistingCertiUrls(selectPortfolio.prj_output_urls || []);
            setExistingCertiDescriptions(selectPortfolio.prj_output_cn_arr || []);
            // 산출물 메인 인덱스
            // console.log(selectPortfolio.prj_output_main_idx)
            if (selectPortfolio.prj_output_main_idx === null || selectPortfolio.prj_output_main_idx === undefined) {
                setSelectedIndex('')
            } else {
                setSelectedIndex(selectPortfolio.prj_output_main_idx.toString());
                setSelectedExistingIndex(selectPortfolio.prj_output_main_idx.toString());
                // setSelectedIndex(selectPortfolio.prj_output_main_idx.toString())
            }
            // setSelectedIndex(selectPortfolio.prj_output_main_idx.toString())
        }
    }, [selectPortfolio, modifyMode])

    // 성공여부
    const [isSuccessOk, setIsSuccessOk] = useState(false);

    // 유효성검사
    const validateFormData = () => {
        if (!portfolioNm) {
            return {
                isValid: false,
                message: "제목을 입력해주세요."
            };
        }
        if (!selectedField) {
            return {
                isValid: false,
                message: "프로젝트 분야를 선택해주세요."
            };
        }
        if (participationRate === null) {
            return {
                isValid: false,
                message: "참여율을 선택해주세요."
            };
        }
        if (!portfolioIntro) {
            return {
                isValid: false,
                message: "소개글 입력해주세요."
            };
        }
        if (!portfolioStartDate || !portfolioEndDate ) {
            return {
                isValid: false,
                message: "프로젝트 기간 선택해주세요."
            };
        }
        if (!customerNm) {
            return {
                isValid: false,
                message: "고객사 명 입력해주세요."
            };
        }
        if (!customerRole) {
            return {
                isValid: false,
                message: "고객사 역할 입력해주세요."
            };
        }
        for (let i = 0; i < selectedPrjOutputFileUrls.length; i++) {
            if (!prjOutputDescriptions[i]?.trim()) {
                return {
                    isValid: false,
                    message: "산출물 이미지 설명을 입력해주세요."
                };
            }
        }
        // if (selectedPrjOutputFileUrls.length > 0 && selectedIndex === '') {
        //     return {
        //         isValid: false,
        //         message: "썸네일 이미지 선택해주세요."
        //     };
        // }

        // for (let i = 0; i < existingCertiUrls.length; i++) {
        //     if (!existingCertiDescriptions[i]?.trim()) {
        //         return {
        //             isValid: false,
        //             message: "산출물 이미지 설명을 입력해주세요."
        //         };
        //     }
        // }

        for (let i = 0; i < newCertiUrls.length; i++) {
            if (!newCertiDescriptions[i]?.trim()) {
                return {
                    isValid: false,
                    message: "산출물 이미지 설명을 입력해주세요."
                };
            }
        }

        return { isValid: true };
    };

    // 포트폴리오 등록
    const loginUserId = localStorage.getItem('user-id');
    const  profileBasicUpd = () => {
        const { isValid, message } = validateFormData();

        if (!isValid) {
            setShowPopup(true);
            setPopupMsg(message);
            return;
        }
        const portfolioFormData = new FormData();

        console.log(selectedIndex);
        // 사용자 id
        // portfolioFormData.append("userId", loginUserId);
        // 프로젝트 제목
        portfolioFormData.append("prtfolioNm", portfolioNm);
        // 프로젝트 분야
        portfolioFormData.append("prjTyCd", selectedField);
        // 프로젝트 분야 (기타)
        portfolioFormData.append("prjTyCustom", prjTyCustom);
        // 프로젝트 기간 (start)
        portfolioFormData.append("startDe", portfolioStartDate);
        // 프로젝트 기간 (end)
        portfolioFormData.append("endDe", portfolioEndDate);
        // 참여율
        portfolioFormData.append("prtRate", participationRate);
        // 프로젝트 요약
        portfolioFormData.append("prjSumm", portfolioIntro);
        // 고객정보 비공개 유무
        portfolioFormData.append("clientPrivt", isPublic);
        // 고객사 명
        portfolioFormData.append("clientNm", customerNm);
        // 고객사 역할
        portfolioFormData.append("clientRole", customerRole);
        // 산출물 URL
        portfolioFormData.append("outputUrl", outputUrl);
        // 산출물 파일
        // portfolioFormData.append("outputFiles", selectedCertiFiles);
        for(let i = 0; i < selectedPrjOutputFiles.length; i++) {
            // portfolioFormData.append("outputFiles", JSON.stringify(newSelectCertiFileNames));
            portfolioFormData.append("outputFiles", selectedPrjOutputFiles[i]);
        } 
        // 산출물 이미지 이름
        portfolioFormData.append("outputCnArr", JSON.stringify(newCertiDescriptions));
        // portfolioFormData.append("outputCnArr", JSON.stringify(prjOutputDescriptions));
        // 산출물 메인 이미지 인덱스
        portfolioFormData.append("outputMainIdx", selectedIndex);

        axios({
            method:'post',
            url:`/api/prtfolio/add`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: portfolioFormData,
        })
        . then((result)=>{
            // console.log(result);
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오 등록되었습니다.");
                setIsSuccessOk(true);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오 등록에 실패하였습니다.");
            }
        })
        .catch((error) => {

        })
    }

    // 포트폴리오 삭제
    const navigate = useNavigate();
    const portfolioDel = () => {
        axios({
            method:'post',
            url:`/api/prtfolio/delete`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: {prtfolioId : selectPortfolio.prtfolio_id}, 
        })
        . then((result) => {
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오가 삭제되었습니다.");
                setIsSuccessOk(true);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오를 삭제하지 못하였습니다.");
            }
        })
        . catch((error) => {
            
        })
    }
    
    // 수정 닫기
    const handleCloseOk = () => {
        setShowPopup(false);
        if (isSuccessOk) {
            setProfileState('portfolio-mgt');
        }
    };

    const getUniqueValues = (arr1, arr2) => {
        const uniqueValues = [];
        // console.log(selectPortfolio.prj_output_cn_arr);
        // console.log(prjOutputDescriptions)
        if (arr1 !== null && arr1 !== undefined) {
            arr2.forEach(item => {
                if (!arr1.includes(item)) {
                    uniqueValues.push(item);
                }
            });
        } else {
            uniqueValues.push(...arr2);
        }
        return uniqueValues;
    };

    const getRemainingValues = (arr1, arr2) => {
        const startIdx = arr1 ? arr1.length : 0;
        return arr2.slice(startIdx);
    };
    // 포트폴리오 업데이트
    // const [modifyMode, setModifyMode] = useState(false);
    // console.log(modifyMode)
    
    // profileState, selectPortfolio, setProfileState
    // console.log(profileState);
    // console.log(selectPortfolio);
    // console.log(setProfileState);
    const  profileDocsUpd = () => {

        const { isValid, message } = validateFormData();

        if (!isValid) {
            setShowPopup(true);
            setPopupMsg(message);
            return;
        }
        
        // const uniquePrjOutputDescriptions = getUniqueValues(selectPortfolio.prj_output_cn_arr, prjOutputDescriptions);
        // 사용 예시
        const remainingPrjOutputDescriptions = getRemainingValues(selectPortfolio.prj_output_cn_arr, prjOutputDescriptions);

        // if (selectedPrjOutputFiles.length !== uniquePrjOutputDescriptions.length) {
        //     setShowPopup(true);
        //     setPopupMsg('새로 업로드한 이미지의 이름을 작성해주세요.');
        //     return;
        // }
        // console.log(selectedIndex)
        // console.log(typeof(selectedIndex))
        const selectedIndexData = selectedExistingIndex || selectedNewIndex;
        
        const portfolioFormData = new FormData();
        // 포트폴리오 아이디
        portfolioFormData.append("prtfolioId", selectPortfolio.prtfolio_id);
        // 포트폴리오 이름
        portfolioFormData.append("prtfolioNm", portfolioNm);
        // 프로젝트 분류 코드 (1:일반 레포츠 / 2:미디어 촬영 /  3:시설점검 / 4:공간정보 / 5:농업,방제 / 6.기타 - 직접입력)
        portfolioFormData.append("prjTyCd", selectedField);
        // [O] 프로젝트 분류 직접입력
        portfolioFormData.append("prjTyCustom", prjTyCustom);
        // 시작일
        portfolioFormData.append("startDe", portfolioStartDate);
        // 종료일
        portfolioFormData.append("endDe", portfolioEndDate);
        // 프로젝트 참여율
        portfolioFormData.append("prtRate", participationRate);
        // 프로젝트 요약
        portfolioFormData.append("prjSumm", portfolioIntro);
        // 고객사 비공개 여부 (true : 비공개 / false : 공개)
        portfolioFormData.append("clientPrivt", isPublic);
        // 고객사 이름
        portfolioFormData.append("clientNm", customerNm);
        // 고객사 역할
        portfolioFormData.append("clientRole", customerRole);
        // [O] 프로젝트 산출물 URL
        portfolioFormData.append("outputUrl", outputUrl);
        // [O] 프로젝트 산출물 파일 (멀티)
        // portfolioFormData.append("outputFiles", '');
        for(let i = 0; i < selectedPrjOutputFiles.length; i++) {
            // portfolioFormData.append("outputFiles", JSON.stringify(newSelectCertiFileNames));
            portfolioFormData.append("outputFiles", selectedPrjOutputFiles[i]);
        } 
        // [O] 프로젝트 설명 (배열)
        // portfolioFormData.append("outputCnArr", JSON.stringify(uniquePrjOutputDescriptions));
        portfolioFormData.append("outputCnArr", JSON.stringify(newCertiDescriptions));
        // [O] 프로젝트 산출물 삭제 인덱스
        console.log('selectedDelIndex > '+selectedDelIndex);
        if (selectedDelIndex) {
            if (selectedDelIndex.length>0) {
                portfolioFormData.append("outputDelArr", JSON.stringify(selectedDelIndex));
            }
        }
        // console.log(selectedDelIndex.filter(index => existingCertiUrls[index]))
        // console.log(selectedDelIndex.filter(index => existingCertiUrls[index] !== undefined))
        // const filteredIndexes = selectedDelIndex.filter(index => existingCertiUrls[index] !== undefined);

        // if (filteredIndexes.length > 0) {
        //     // console.log(filteredIndexes)
        //     portfolioFormData.append("outputDelArr", JSON.stringify(filteredIndexes));
        // }
        // portfolioFormData.append("outputDelArr", JSON.stringify(selectedDelIndex));
        // [O] 프로젝트 산출물 메인 이미지 인덱스
        // portfolioFormData.append("outputMainIdx", selectedIndex.toString());
        portfolioFormData.append("outputMainIdx", selectedIndexData !== null ? selectedIndexData.toString() : '');
        // portfolioFormData.append("outputMainIdx", selectedIndex !== null ? selectedIndex.toString() : '');
        // portfolioFormData.append("outputMainIdx", selectedIndex !== null ? selectedIndex.toString() : 'null');
        axios({
            method:'post',
            url:`/api/prtfolio/update`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: portfolioFormData,
        })
        . then((result)=>{
            // console.log(result);
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (포트폴리오) 업데이트 되었습니다.");
                setIsSuccessOk(true);
                // props.setLoginprops.profileData(result.data.data);
                // 업데이트 후 파일 업로드 초기화
                selectedPrjOutputFiles([]);
                setSelectedDelIndex([])
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (포트폴리오) 업데이트에 실패하였습니다.");
            }
        })
        .catch((error) => {

        })
    }

    return (
        <React.Fragment>
            <div className='full-width'>
                <div className='main-text'>프로젝트 정보</div>
                <div className='contents-row act-info'>
                    <label>프로젝트 제목</label>
                    <input
                        className='contents-row flex-center spacer border info-input'
                        type='text'
                        placeholder='프로젝트 정보를 입력해 주세요'
                        onChange={handlePortfolioNm}
                        value={portfolioNm || ''}
                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                    />
                </div>
                <div className='contents-row act-info company-history'>
                    <label>프로젝트 분야</label>
                    <div className='contents-col full-width'>
                        <div className='contents-row full-width'>
                            {fields.map(field => (
                                <div className='contents-row field' key={field.value}>
                                    <input
                                        type='checkbox'
                                        onChange={() => handleCheckboxChange(field.value)}
                                        checked={selectedField === field.value}
                                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                                    />
                                    <label onClick={() => handleCheckboxChange(field.value)}>{field.label}</label>
                                </div>
                            ))}
                        </div>
                        {selectedField === 6 && (
                            <input
                                className='contents-row flex-center spacer border info-input'
                                type='text'
                                onChange={handlePrjTyCustom}
                                value={prjTyCustom || ''}
                                readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                            />
                        )}
                    </div>
                </div>
                {/* <div className='contents-row act-info'>
                    <label>프로젝트 분야</label>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>일반 레포츠</label>
                    </div>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>미디어촬영</label>
                    </div>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>시설점검</label>
                    </div>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>공간정보</label>
                    </div>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>농업,방제</label>
                    </div>
                    <div className='contents-row field'>
                        <input type='checkbox'></input>
                        <label>기타</label>
                    </div>
                </div> */}
                <div className='contents-row act-info'>
                    <label>참여율</label>
                    <Select
                        options={participationRateOption}
                        className={`profile-select border participation-rate ${profileState !== 'portfolio-add' && !modifyMode ? 'read-only' : ''}`}
                        // className='profile-select border participation-rate'
                        placeholder='OO%'
                        onChange={handleParticipationRate}
                        value={participationRate}
                        // disabled={!modifyMode}
                        // readOnly={modifyMode? false : true}
                        // readOnly={modifyMode? false : true}
                    />
                </div>
                <div className='contents-row act-info intro'>
                    <label>소개글</label>
                    <textarea
                        className='border'
                        value={portfolioIntro || ''}
                        onChange={handlePortfolioIntro}
                        // className="contents"
                        // cols="30"
                        // rows="10"
                        // value={contents}
                        // onChange={onChange}
                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                    ></textarea>
                </div>
                <div className='contents-row act-info'>
                    <label>프로젝트 기간</label>
                    <div className='contents-row flex-center full-width rrn-info'>
                        <input
                            className='contents-row flex-center border info-input date' 
                            type='date'
                            value={portfolioStartDate || ''}
                            onChange={(e) => handlePortfolioStartDate(e)}
                            readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                        />
                        <i className="fa-solid fa-minus"></i>
                        <input
                            className='contents-row flex-center border info-input date'
                            type='date'
                            value={portfolioEndDate || ''}
                            onChange={(e) => handlePortfolioEndDate(e)}
                            min={portfolioStartDate}
                            disabled={!portfolioStartDate}
                            // disabled={!isEndDateEnabled}
                            readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                        />
                        {/* <input className='contents-row flex-center border info-input date' type='date'></input>
                        <i className="fa-solid fa-minus"></i>
                        <input className='contents-row flex-center border info-input date' type='date'></input> */}
                    </div>
                </div>
            </div>
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='contents-row flex-center portfolio-menubar main-title-box'>
                    <div className='main-text'>고객사 정보</div>
                    <div className='contents-row field'>
                        <input type='checkbox' checked={isPublic} onChange={handleIsPublic} />
                        <label>비공개 입니다.</label>
                    </div>
                </div>
                <div className='contents-row act-info'>
                    <label>고객사 명</label>
                    <input
                        className='contents-row flex-center spacer border info-input doc-info'
                        type='text'
                        placeholder='고객사 명을 입력해 주세요'
                        onChange={handleCustomerNm}
                        value={customerNm || ''}
                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                    />
                </div>
                <div className='contents-row act-info'>
                    <label>역할</label>
                    <input
                        className='contents-row flex-center spacer border info-input doc-info'
                        type='text'
                        placeholder='고객사 역할을 입력해 주세요 EX) 발주처, PL'
                        onChange={handleCustomerRole}
                        value={customerRole || ''}
                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                    />
                </div>
            </div>
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='main-text'>산출물 정보</div>
                <div className='contents-row act-info'>
                    <label>산출물 URL</label>
                    <input
                        className='contents-row flex-center spacer border info-input'
                        type='text'
                        placeholder='산출물 URL을 입력해 주세요'
                        onChange={handleOutputUrl}
                        value={outputUrl || ''}
                        readOnly={profileState !== 'portfolio-add' && !modifyMode === true}
                    />
                </div>
                {/* profileState */}
                <div className='contents-row act-info'>
                    <label>산출물 이미지</label>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={prjOutputFileRef}
                        onChange={handlePrjOutputFileUpload}
                        multiple
                    />
                    <div className={`gc-btn flex-center act-btn info-btn add-file ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`} onClick={handleCertiFileClick}><i className="fa-solid fa-plus"></i>이미지 추가</div>
                    {/* <div className={`gc-btn flex-center act-btn info-btn`} onClick={handleCertiFileClick}><i className="fa-solid fa-plus"></i>이미지 추가</div> */}
                    {/* <div className={`gc-btn flex-center act-btn info-btn ${profileState === 'portfolio-view' ? 'none' : ''}`} onClick={handleCertiFileClick}><i className="fa-solid fa-plus"></i>이미지 추가</div> */}
                </div>
                {/* <div className='contents-row full-width flex-center portfolio-list'>
                    {selectedPrjOutputFileUrls && selectedPrjOutputFileUrls.map((file, index) => (
                    <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}` }>
                        <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index)}></div>
                                <div className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index)}></div>
                            </div>
                        <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{maxWidth: '100%', objectFit: 'contain'}}/>
                        <input
                            className={`contents-row flex-center spacer border info-input doc-info`}
                            type='text'
                            placeholder='이미지 설명을 입력해주세요'
                            value={prjOutputDescriptions[index] || ''} 
                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                            // disabled={profileState === 'portfolio-view' ? selectPortfolio.prj_output_cn_arr && selectPortfolio.prj_output_cn_arr.includes(prjOutputDescriptions[index]) : null}
                            disabled={profileState === 'portfolio-view' && index < selectPortfolio.prj_output_cn_arr.length}
                        />
                        </div>
                    </div>
                    ))}
                </div> */}
                {/* <div className='contents-row full-width flex-center portfolio-list'>
                    {existingCertiUrls && existingCertiUrls.map((file, index) => (
                    <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}` }>
                        <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, true)}></div>
                                <div className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index)}></div>
                            </div>
                        <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{maxWidth: '100%', objectFit: 'contain'}}/>
                        <input
                            className={`contents-row flex-center spacer border info-input doc-info`}
                            type='text'
                            placeholder='이미지 설명을 입력해주세요'
                            value={existingCertiDescriptions[index] || ''} 
                            onChange={(e) => handleDescriptionChange(index, e.target.value, true)}
                            // disabled={profileState === 'portfolio-view' ? selectPortfolio.prj_output_cn_arr && selectPortfolio.prj_output_cn_arr.includes(prjOutputDescriptions[index]) : null}
                            disabled={profileState === 'portfolio-view' && index < selectPortfolio.prj_output_cn_arr.length}
                        />
                        </div>
                    </div>
                    ))}
                    {newCertiUrls && newCertiUrls.map((file, index) => (
                    <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}` }>
                        <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, false)}></div>
                                <div className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index)}></div>
                            </div>
                        <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{maxWidth: '100%', objectFit: 'contain'}}/>
                        <input
                            className={`contents-row flex-center spacer border info-input doc-info`}
                            type='text'
                            placeholder='이미지 설명을 입력해주세요'
                            value={newCertiDescriptions[index] || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value, false)}
                        />
                        </div>
                    </div>
                    ))}
                </div> */}
                {/* <div className='contents-row full-width flex-center portfolio-list'>
                    {existingCertiUrls && existingCertiUrls.map((file, index) => (
                    <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}` }>
                        <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, true)}></div>
                                <div className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index)}></div>
                            </div>
                        <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{maxWidth: '100%', objectFit: 'contain'}}/>
                        <input
                            className={`contents-row flex-center spacer border info-input doc-info`}
                            type='text'
                            placeholder='이미지 설명을 입력해주세요'
                            value={existingCertiDescriptions[index] || ''} 
                            onChange={(e) => handleDescriptionChange(index, e.target.value, true)}
                            disabled={profileState === 'portfolio-view' && index < selectPortfolio.prj_output_cn_arr.length}
                        />
                        </div>
                    </div>
                    ))}
                    {newCertiUrls && newCertiUrls.map((file, index) => (
                    <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}` }>
                        <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, false)}></div>
                                <div className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index)}></div>
                            </div>
                        <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{maxWidth: '100%', objectFit: 'contain'}}/>
                        <input
                            className={`contents-row flex-center spacer border info-input doc-info`}
                            type='text'
                            placeholder='이미지 설명을 입력해주세요'
                            value={newCertiDescriptions[index] || ''}
                            onChange={(e) => handleDescriptionChange(index, e.target.value, false)}
                        />
                        </div>
                    </div>
                    ))}
                </div> */}
                <div className='contents-row full-width flex-center portfolio-list'>
                    {existingCertiUrls && existingCertiUrls.map((file, index) => (
                        <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} >
                                <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                    <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, true)}></div>
                                    <div className={`portfolio-btn portfolio-check ${selectedExistingIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index, true)}></div>
                                </div>
                                <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{ maxWidth: '100%', objectFit: 'contain' }} />
                                <input
                                    className='contents-row flex-center spacer border info-input doc-info'
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={existingCertiDescriptions[index] || ''}
                                    onChange={(e) => handleDescriptionChange(index, e.target.value, true)}
                                    disabled={profileState === 'portfolio-view' && index < selectPortfolio.prj_output_cn_arr.length}
                                />
                            </div>
                        </div>
                    ))}
                    {(profileState === 'portfolio-add' || modifyMode) && newCertiUrls && newCertiUrls.map((file, index) => (
                        <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                            <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} >
                                <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                    <div className='portfolio-btn portfolio-minus' onClick={() => handleDelete(index, false)}></div>
                                    <div className={`portfolio-btn portfolio-check ${selectedNewIndex === index.toString() ? 'selected' : ''}`} onClick={() => handleCheck(index, false)}></div>
                                </div>
                                <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{ maxWidth: '100%', objectFit: 'contain' }} />
                                <input
                                    className='contents-row flex-center spacer border info-input doc-info'
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={newCertiDescriptions[index] || ''}
                                    onChange={(e) => handleDescriptionChange(index, e.target.value, false)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='contents-row full-width flex-center portfolio-list'>
                    {existingCertiUrls && existingCertiUrls.map((file, index) => (
                        <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`}>
                            <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                                <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                    <div
                                        className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() && selectPortfolio.prj_output_main_idx === index ? 'selected' : ''}`}
                                        onClick={() => handleCheck(index)}
                                    ></div>
                                </div>
                                <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{ maxWidth: '100%', objectFit: 'contain' }} />
                                <input
                                    className={`contents-row flex-center spacer border info-input doc-info`}
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={existingCertiDescriptions[index] || ''}
                                    onChange={(e) => handleDescriptionChange(index, e.target.value, true)}
                                    disabled={profileState === 'portfolio-view' && index < selectPortfolio.prj_output_cn_arr.length}
                                />
                            </div>
                        </div>
                    ))}
                    {newCertiUrls && newCertiUrls.map((file, index) => (
                        <div className={`contents-row flex-center rrn-img-box portfolio-box ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`}>
                            <div className={`contents-col flex-center portfolio-box act-info ${profileState === 'portfolio-add' || profileState === 'portfolio-view' ? 'no-outline' : ''}`} key={index}>
                                <div className={`contents-row portfolio-btn-box ${profileState === 'portfolio-add' || modifyMode ? '' : 'none'}`}>
                                    <div
                                        className={`portfolio-btn portfolio-check ${selectedIndex === index.toString() && selectPortfolio.prj_output_main_idx === index ? 'selected' : ''}`}
                                        onClick={() => handleCheck(index)}
                                    ></div>
                                </div>
                                <img src={file} alt={file} className='rrn-img portfolio-img-img' style={{ maxWidth: '100%', objectFit: 'contain' }} />
                                <input
                                    className={`contents-row flex-center spacer border info-input doc-info`}
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={newCertiDescriptions[index] || ''}
                                    onChange={(e) => handleDescriptionChange(index, e.target.value, false)}
                                />
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
            {/* <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn ${profileState === 'portfolio-view' ? 'none' : ''}`} onClick={profileBasicUpd}>저장하기</div>
            <div className='contents-row'>
                <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn del ${profileState === 'portfolio-add' ? 'none' : ''}`} onClick={portfolioDel}>삭제하기</div>
                <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn ${profileState === 'portfolio-add' ? 'none' : ''}`} onClick={profileDocsUpd}>수정하기</div>
            </div> */}
            
            {profileState === 'portfolio-add' ? (
                <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn`} onClick={profileBasicUpd}>저장</div>
            ) : (
                <div className='contents-row'>
                {modifyMode ? (
                    <>
                    <div 
                        className='gc-btn gc-btn-line contents-row flex-center profile-btn'
                        onClick={()=>{
                            setPopupState('modPortfolio');
                            setShowQuestionPopup(true);
                            setPopupMsg('포트폴리오를 수정하시겠습니까?');
                        }}
                    >
                        수정
                    </div>
                    <div 
                        className='gc-btn gc-btn-green contents-row flex-center profile-btn info-btn close' 
                        onClick={()=>{setModifyMode(false)}}
                    >
                        취소
                    </div>
                    </>
                ) : (
                    <>
                    <div 
                        className={`gc-btn gc-btn-line contents-row flex-center profile-btn del`}
                        onClick={()=>{
                            setPopupState('delPortfolio');
                            setShowQuestionPopup(true);
                            setPopupMsg('프로젝트를 삭제하시겠습니까?');
                        }}
                    >
                        삭제
                    </div>
                    <div
                        className={`gc-btn gc-btn-line contents-row flex-center profile-btn`}
                        onClick={()=>{
                            setModifyMode(true);
                        }}
                    >
                        수정    
                    </div>
                    </>
                )}
                </div>
            )}
            
            
            
            {/* <div className={`gc-btn gc-btn-green contents-row flex-center save`}>저장하기</div> */}
            <Popup
                show={showQuestionPopup}
            >
                <div className='contents-col full-width flex-center popup-info'>
                    <p>{popupMsg}</p>
                    <div className='contents-row'>
                    <div 
                        className="contents-row gc-btn flex-center ok_btn" 
                        onClick={() => {
                            if (popupState == 'modPortfolio') {
                                setShowQuestionPopup(false);
                                profileDocsUpd();
                            } else if (popupState == 'delPortfolio') {
                                setShowQuestionPopup(false);
                                portfolioDel()
                            }
                        }}  
                    >{popupState == 'modPortfolio'? '수정' : popupState == 'delPortfolio'? '삭제' : '확인'}</div>
                    <div 
                        className="contents-row gc-btn flex-center close_btn" 
                        onClick={() => {
                            setShowQuestionPopup(false);
                        }}  
                    >닫기</div>
                    </div>
                </div>
            </Popup>
            <Popup
                show={showPopup}
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p>{popupMsg}</p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={handleCloseOk}
                >닫기</div>
                </div>
            </Popup>
        </React.Fragment>
        
    );
}

export default Portfolio;