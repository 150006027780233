import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const globalStyle = createGlobalStyle`
    ${reset};

    :root {
      --default-text-color : #424242;
      --default-text-color-hint : #B7B7B7;
      --default-text-color-hint-1 : #707070;
    
      --color-primary : #487FF9;
      --color-primary-1 : #81A8FF;
      --color-primary-2 : #C2D5FF;
      --color-primary-3 : #E0EAFF;
      --color-primary-p1 : #3F6BCC;
      --color-primary-p2 : #214390;
    
      --color-secondary : #00A4BD;
      --color-secondary-1 : #21ABBF;
      --color-secondary-2 : #D4FFF0;
      --color-secondary-3 : #DCFBFF;
      --color-secondary-p1 : #038CA0;
      --color-secondary-p2 : #035C69;

      --color-tertiary : #875EFF;
      --color-tertiary-2 : #E6DEFC;
      --color-tertiary-p1 : #663FD4;
    
      --color-gray : #424242;
      --color-gray-1 : #808080;
      --color-gray-2 : #D0D0D0;
      --color-gray-3 : #F6F6F6;
      --color-gray-p1 : #2B2B2B;
      --color-gray-p2 : #1C1C1C;
      
      --color-green : #71E599;
      --color-green-hover : #2EB564;
      --color-red : #FF2828;
      --header-height : 50px;
    }
    
    .contents-row {
      display: flex;
      flex-direction: row;
    }
    
    .contents-col {
      display: flex;
      flex-direction: column;
    }

    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .justify-center {
      display: flex;
      justify-content: center;
    }

    .align-center {
      display: flex;
      align-items: center;
    }
    
    .flex-spacer {
      flex: 1;
      z-index: 1;
    }
    
    .part-divider {
      border: 1px solid #EEE;
      margin: 20px 10px;
    }
    
    .header-text {
      font-weight: 700;
      font-size: 16px;
    }
    
    textarea {
      width: 100%;
      border: none;
      resize: none;
    }

    .content-text-typeA {
      font-weight: 500;
      font-size: 14px;
    }

    .content-text-typeB {
      font-weight: 700;
      font-size: 14px;
    }

    .content-text-typeC {
      font-weight: 500;
      font-size: 14px;
      color: var(--default-text-color-hint);
    }

    ::placeholder {
      color: var(--default-text-color-hint);
    }

    .fa-star {
      color: #FBB01F;
    }

    .gc-btn {
      color: white;
      background-color: var(--color-primary);
      padding : 4px 14px;
      display: inline-block;
      border-radius: 4px;
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
    }
    
    .gc-btn:hover {
      cursor: pointer;
      background-color: var(--color-primary-p2);
      color: white;
    }
    
    .gc-btn-wide {
      padding: 4px 20px;
    }
    
    .gc-btn-big {
      font-size: 18px;
      padding: 12px 26px
    }
    
    .gc-btn-red {
      background-color: var(--color-red);
    }
    
    .gc-btn-red:hover {
      background-color: var(--color-red-hover);
    }

    .gc-btn-green {
      background-color: var(--color-secondary-1);
    }
    
    .gc-btn-green:hover {
      cursor: pointer;
      background-color: var(--color-secondary-p1 );
      color: white;
    }

    .gc-btn-green-line {
      color: var(--color-secondary-p1 );
      background-color: white;
      border: 1px solid var(--color-secondary-p1 );
    }

    .gc-btn-line {
      color: var(--color-primary);
      background-color: white;
      border: 1px solid var(--color-primary);
    }
    
    .spacer {
      width: 100%;
      height: 100%;
      flex-grow : 1;
    }
    
    .code.x.icon {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    
    .code.x.icon:hover {
      background-color: white;
      color: black;
    }
    
    .full-width {
      width: 100%;
    }
    
    .half-width {
      width: 50%;
    }

    .full-height {
      height: 100%;
    }
    
    .half-height {
      height: 50%;
    }

    .border-top {
      border-top: 1px solid var(--default-text-color-hint);
    }

    .border-bottom {
      border-bottom: 1px solid var(--default-text-color-hint);
    }

    .ui.dropdown .menu {
      height: auto !important;
    }

    .ui.dropdown.none-border {
      border: none;
    }

    .ui.dropdown.none-border .menu {
      border: none;
    }

    .ui.selection.dropdown {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 10px !important;
      max-height: 35px !important;
    }

    .ui.selection.dropdown>.text {
      color: var(--default-text-color);
      font-weight: 500;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break:break-all;
    }

    .ui.selection.dropdown>.default.text {
      color: var(--default-text-color);
      font-weight: 500;
      font-size: 14px;
    }
    
    // .ui.selection.dropdown> .divider.text {
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    .ui.selection.dropdown>.icon, 
    .ui.selection.dropdown>.dropdown.icon {
      padding: 0;
      margin: 5px;
      position: absolute;
      top: auto;
      right: 0;
    }

    .ui.selection.dropdown .menu .item {
      font-weight: 500;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break:break-all;
    }

    .ui.pagination.menu {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 30px;
      width: 100%;
    }
    
    .ui.pagination.menu .item {
      padding: 0;
      min-width: 10%;
      justify-content: center;
      height: 100%;
    }
    
    .ui.pagination.menu .active.item {
      padding: 0;
    }

    .plus.icon {
      color: black;
      cursor: pointer;
    }

    .plus.icon:hover {
      background-color: black;
      color: #FFF;
      border-radius: 5px;
    }

    .x.icon {
      color: black;
      cursor: pointer;
    }

    .x.icon:hover {
      background-color: black;
      color: #FFF;
      border-radius: 5px;
    }

    .loading-container {
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.7);
    }
    
    #loading {
      position: absolute;
      top: 40%;
      left: 50%;
      display: inline-block;
      width: 100px;
      height: 100px;
      border: 10px solid rgba(255,255,255,.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
    }
    
    @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }
    
    @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadein-moveup {
      from {
        opacity: 0;
        margin-top: 50px;
      }
      to {
        opacity: 1;
        margin-top: 0px;
      }
    }
    @keyframes fadein-moveleft {
      from {
        opacity: 0;
        margin-left: 50px;
      }
      to {
        opacity: 1;
        margin-left: 0px;
      }
    }
`;

export default globalStyle;
