import './Header.css';

import React, { useState } from 'react';
import { Link, useNavigate, useLocation  } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { googleLogout } from '@react-oauth/google';

function Header(props) {
  const location = useLocation();
  const activeMenu = 
  location.pathname === '/project' ? 'project' : 
  location.pathname === '/partner' ? 'partner' : 
  location.pathname === '/map' ? 'map' : 
  location.pathname === '/helpdesk' ? 'helpdesk' : '';
  // const activeMenu = location.pathname === '/project' ? 'project' : '/partner' ? 'partner' : '/map' ? 'map' : '/helpdesk' ? 'helpdesk' : '';
  // const activeMenu = location.pathname === '/project' ? 'project' : '';

  const userName = localStorage.getItem('user-name');
  const userType = localStorage.getItem('user-ty');
  const loginUserLevel = localStorage.getItem('user-level');
  
  // const [activeMenu, setActiveMenu] = useState('Home');

  const navigate = useNavigate();
  
  // 로그아웃
  const handleLogout = () => {
    if (props.userProfile.id_type_cd == 3) {
      localStorage.removeItem("com.naver.nid.access_token");
      // window.location.reload();
      // naverLogin.logout();
    } else if (props.userProfile.id_type_cd == 4) {
      // if (window.Kakao && window.Kakao.Auth.getAccessToken()) {
      //   window.Kakao.Auth.logout(() => {
      //     // console.log(window.Kakao.Auth.getAccessToken());
      // })}
      // if (window.Kakao && window.Kakao.Auth && window.Kakao.Auth.getAccessToken()) {
      //   window.Kakao.Auth.logout(() => {
      //       // console.log(window.Kakao.Auth.getAccessToken());
      // });
      // } else {
      //     console.error('Kakao SDK is not initialized or the user is not logged in.');
      // }
    } else if (props.userProfile.id_type_cd == 2) {
      // handleGoogleLogout();
      googleLogout();

    }
    props.logout();
    navigate('/login');
  }

  return (
    <div className='Header-container'>
      <div className='logo-container'>
        <Link to={''}>
          <div className='logo'></div>
        </Link>
      </div>

      <div className='menu-container'>
        <Link to={'/project'} className='link' onClick={()=>{props.setActiveSubmenu(false)}}>
          <div className={`menu ${activeMenu === 'project' ? 'active' : ''}`}>프로젝트</div>
        </Link>
      </div>
      <div className='menu-container'>
        <Link to={'/partner'} className='link' onClick={()=>{props.setActiveSubmenu(false)}}>
          <div className={`menu ${activeMenu === 'partner' ? 'active' : ''}`}>파트너</div>
        </Link>
      </div>
      <div className='menu-container'>
        <Link to={'/map'} className='link' onClick={()=>{props.setActiveSubmenu(false)}}>
          <div className={`menu ${activeMenu === 'map' ? 'active' : ''}`}>산출물 조회</div>
        </Link>
      </div>
      <div className='menu-container'>
        <Link to={'/helpdesk'} className='link' onClick={()=>{props.setActiveSubmenu(false)}}>
          <div className={`menu ${activeMenu === 'helpdesk' ? 'active' : ''}`}>이용방법</div>
        </Link>
      </div>

      <div className='header-spacer'></div>

      {
        props.appInfo?
        <div className='appinfo'>v{props.appInfo.version}</div>:null
      }

      <div className='sub-menu-container flex-center contents-row'>
        {
          userName ? 
          <Link to={'/message'}>
          <div className='flex center- contents-row alarm-box' style={{ position: 'relative' }}>
            {props.userProfile && props.userProfile.unread_msg_co > 0 ? <div className='new-alarm'></div> : null}
            <FontAwesomeIcon icon="fa-regular fa-bell" style={{ fontSize: "25px" }}/>
          </div>
          </Link>
          : 
          null
        }

        <div className='flex-center contents-row sub-menu-click-box'>   
        {
          !userName ? 
          <Link to={'/login'} className='login-button flex-center'>로그인</Link> 
          : 
          <div className='full-height'>
            <div className='login-button sub-menu-click contents-row flex-center' onClick={() => {props.setActiveSubmenu(!props.activeSubmenu)}}>
              {
                !props.userProfile || props.userProfile.photoUrl === null || props.userProfile.photoUrl === undefined ?
                  // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                  <div className='user-logo-img none'></div>
                  :
                  <img
                    className="user-logo-img"
                    src={props.userProfile.photoUrl}
                    alt="Uploaded Image"
                  />
              }
              {props.userProfile && props.userProfile.user_nm} 님
            </div>

            <div className={`submenu ${props.activeSubmenu? 'active':''}`}>
              {loginUserLevel !== '100' ?
                <>
                <Link to={'/mydropia'} className='sub-menu'><div className='submenu-text' onClick={()=>{props.setActiveSubmenu(false)}}>My DROPIA</div></Link>
                <div className='part-divider'></div>
                </>
              : null}
              <Link to={'/profile'} className='sub-menu'><div className='submenu-text' onClick={()=>{props.setActiveSubmenu(false)}}>프로필 관리</div></Link>    
              <div className='part-divider'></div>         
              {userType == 3?
              <Link to={'/manager'} className='sub-menu'><div className='submenu-text' onClick={()=>{props.setActiveSubmenu(false)}}>매니저 페이지</div></Link>    
              :
              null
              }      
              <div className='submenu-text' onClick={handleLogout}>로그아웃</div>          
            </div>
          </div>
        }
        </div>

      </div>
    </div>
  );
}

export default Header;
