import './Project.css';

import React, { useState, useCallback, useEffect } from 'react';

import { Checkbox, Dropdown, Icon, Pagination } from 'semantic-ui-react';

// import { Router, Route, useNavigate, Routes, useLocation} from 'react-router-dom';
import {BrowserRouter as Router, Route, useNavigate, Routes } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';

// AXIOS
import axios from "axios";

function Project(props) {
  
  const APIToken = localStorage.getItem("login-token");
  const userType = localStorage.getItem('user-ty');
  
  const navigate = useNavigate();

  const [projectList, setProjectList] = useState(null);   // 프로젝트 리스트
  const [sortMenu, setSortMenu] = useState(false);        // (정렬) 활성화
  const [sortType, setSortType] = useState(1);            // (정렬) 기능 1:최신순 2:마감순 3:높은금액 4:낮은금액
  const [prjType, setPrjType] = useState('');             // (필터) 프로젝트 유형
  const [costLower, setCostLower] = useState('');         // (필터) 최저금액
  const [costUpper, setCostUpper] = useState('');         // (필터) 최고금액
  const [sidoType, setSidoType] = useState('');           // 지역 코드
  const [sidoArray, setSidoArray] = useState();           // 지역 배열

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePagination = (event, data) => {
    setActivePage(data.activePage);
  };

  const pageSize = 5;

  // 금액
  const handleCostLower = (event) => {
    event.preventDefault();
    const value = event.target.value.replace(/\D/g, '');
    setCostLower(value);
  }
  
  const handleCostUpper = (event) => {
    event.preventDefault();
    const value = event.target.value.replace(/\D/g, '');
    setCostUpper(value);
  }

  // 필터
  const onChangePrjType = (value) => {
    if(prjType.includes(value)){
      setPrjType(prjType.filter((item) => item !== value));
    }
    else{
      setPrjType((prev) => [...prev, value]);
    }
  }

  const onChangeSidoType = (value) => {
    if(sidoType.includes(value)){
      setSidoType(sidoType.filter((item) => item !== value));
    }
    else{
      setSidoType((prev) => [...prev, value]);
    }
  }

  // 모든 프로젝트 보기 
  const [isChecked, setIsChecked] = useState(false); // 체크 여부 관리
  
  const handleCheckboxChange = (e, { checked }) => {
    setIsChecked(checked);  // 체크 상태를 업데이트
    setActivePage(1);
  };

  useEffect (() => {
    getProjectList();
  } , [sortType, prjType, costLower, costUpper, sidoType, isChecked])  // 필터링

  // 프로젝트 리스트 로드
  function getProjectList () {

    let userId = 0;
    let prjSttsCd = 2;  // Default 진행상태 '지원' 

    if(userType == 3){  // 관리자일 경우
      prjSttsCd = -1;   // 진행상태 전체요청
    }

    if(localStorage.getItem('client-id')){            // '다른 프로젝트 보기' 일 경우
      userId = localStorage.getItem('client-id');
      prjSttsCd = -1;                                 // 진행상태 전체요청
    }

    // 모든 프로젝트 보기
    if (isChecked) {
      prjSttsCd = -1;
    }

    let url = `/api/project/list`
      +`?prjSttsCd=${prjSttsCd}`
      +`&userId=${userId}`
      +`&sortTy=${sortType}`
      +`&prjTyCdArr=${JSON.stringify(prjType)}`
      +`&overCost=${costLower}`
      +`&underCost=${costUpper}`
      +`&sidoCdArr=${JSON.stringify(sidoType)}`
      +`&page=1`
      +`&perPage=0`
    if (sortType === 2) {
      url += '&exptApplyOver=true'; // 원하는 값으로 설정
    }
    
    axios({
      method:'get',
      url: url,

      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        let project_list = result.data.data;
        setProjectList(project_list);
        setTotalPages(Math.ceil(project_list.length / pageSize));
      }
      localStorage.removeItem('client-id');
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  // 시도 코드 조회
  useEffect (() => {
    axios({
      method:'get',
      url:`/api/util/sido`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        setSidoArray(result.data.data);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  } , [])

  // 필터 시도 배치
  function checkSidoNm (code) {
    let array = [<div key={1} className='project-location'>전국</div>];
    sidoArray && sidoArray.map((sido, i)=>{
      if (code == sido.sido_cd){
        array.splice(0, 1);
        array.push(<div key={i} className='project-location'>{sido.sido_nm}</div>)
      }
    })
    return array;
  }

  // 프로젝트 화면
  return (
    <div className='Project-container'>
      <div className='project-view'>
        <div className='project-view-filter contents-col'>
          <div className='filtering-option'>
            <div className='filtering-header'>
              <div className='text'>필터</div>
            </div>
            <div className='filtering-main'>
              <div className='filtering-type'>
                <div className='text'>촬영 분야</div>

                <div className='contents-row'>
                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-1' value={1} checked={prjType.includes(1)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-1' className='textB'>일반 레포츠</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-2' value={2} checked={prjType.includes(2)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-2' className='textB'>미디어 촬영</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-3' value={3} checked={prjType.includes(3)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-3' className='textB'>시설점검</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>

                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-4' value={4} checked={prjType.includes(4)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-4' className='textB'>공간정보</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-5' value={5} checked={prjType.includes(5)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-5' className='textB'>농업/방제</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-6' value={6} checked={prjType.includes(6)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-6' className='textB'>기타</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>
                </div>

              </div>

              <div className='part-divider'></div> 

              <div className='filtering-cost'>
                <div className='text'>금액</div>
                <div className='contents-col'>
                  <div className='contents-row'>
                    {/* <input className='input cost lower' type='text' onChange={(e)=>{setCostLower(e.target.value)}}></input> */}
                    <input className='input cost lower' type='text' value={costLower} onChange={handleCostLower}></input>
                    <div className='textB flex-center'>~</div>
                    <input className='input cost upper' type='text' value={costUpper} onChange={handleCostUpper}></input>
                    {/* <input className='input cost upper' type='text' onChange={(e)=>{setCostUpper(e.target.value)}}></input> */}
                    <div className='textB flex-center'>만원</div>
                  </div>
                </div>
              </div>

              <div className='part-divider'></div> 

              <div className='filtering-location'>
                <div className='text'>지역</div>
                <div className='contents-col sido-group'>
                  {sidoArray && sidoArray.map((sido)=>{

                    return(
                      <div className='filtering-factor contents-row' key={sido.sido_cd}>
                        <Checkbox 
                          className='checkbox' 
                          id={`filter-sido-${sido.sido_cd}`} 
                          value={sido.sido_cd} 
                          checked={sidoType.includes(sido.sido_cd)} 
                          onChange={(e, data)=>{onChangeSidoType(data.value)}}
                        ></Checkbox>
                        <label htmlFor={`filter-sido-${sido.sido_cd}`} className='textB'>
                          {
                          sido.sido_nm == '서울특별시' ? '서울' :
                          sido.sido_nm == '부산광역시' ? '부산' :
                          sido.sido_nm == '대구광역시' ? '대구' :
                          sido.sido_nm == '인천광역시' ? '광주' :
                          sido.sido_nm == '광주광역시' ? '광주' :
                          sido.sido_nm == '대전광역시' ? '대전' :
                          sido.sido_nm == '울산광역시' ? '울산' :
                          sido.sido_nm == '세종특별자치시' ? '세종' :
                          sido.sido_nm == '경기도' ? '경기' :
                          sido.sido_nm == '강원도' ? '강원' :
                          sido.sido_nm == '충청북도' ? '충북' :
                          sido.sido_nm == '충청남도' ? '충남' :
                          sido.sido_nm == '전라북도' ? '전북' :
                          sido.sido_nm == '전라남도' ? '전남' :
                          sido.sido_nm == '경상북도' ? '경북' :
                          sido.sido_nm == '경상남도' ? '경남' :
                          sido.sido_nm == '제주특별자치도' ? '제주' : sido.sido_nm
                          }
                        </label>
                      </div>
                    )
                  })}
                </div>
                {userType !== '3' && ( 
                <>
                <div className='part-divider'></div> 
                
                <div className='contents-row filtering-factor'>
                  <Checkbox 
                    className='checkbox' 
                    id='all-project' 
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    // value={sido.sido_cd} 
                    // checked={sidoType.includes(sido.sido_cd)} 
                    // onChange={(e, data)=>{onChangeSidoType(data.value)}}
                  ></Checkbox>
                  <label htmlFor={`all-project`} className=''>
                    모든 프로젝트 보기
                  </label>
                </div>
                </>
                )} 
              </div>
            </div>
          </div>
        {
        userType == 2 || userType == 3?
        <div 
          className='button flex-center' 
          onClick={()=>{
          if(props.isLoggedIn)
            {navigate('/projectreg')}
          else
            {navigate('/login')}
          }}
        >프로젝트 등록하기</div>
        :
        null
        }
        </div>
        <div className='project-view-list contents-col'>
          <div className='list-header contents-row'>
            <div className='header-text contents-row'>
              <div className='main-text'>프로젝트 찾기</div>
              <div className='sub-text'>( {projectList? projectList.length:'0'} 개의 프로젝트 )</div>
            </div>
            <div className='flex-spacer'></div>
            {
            sortMenu?
            <div className={`flex-center contents-row sort-button`}>
              <div className='sort-image'></div>
              <div className='sort-text active' onClick={()=>{setSortType(1);setSortMenu(false);}}>최신 순</div>
              <div className='sort-text active' onClick={()=>{setSortType(2);setSortMenu(false);}}>마감임박 순</div>
              <div className='sort-text active' onClick={()=>{setSortType(3);setSortMenu(false);}}>높은 금액 순</div>
              <div className='sort-text active' onClick={()=>{setSortType(4);setSortMenu(false);}}>낮은 지원 순</div>
            </div>
            :
            <div className='flex-center contents-row  sort-button' onClick={()=>{setSortMenu(true)}}>
              <div className='sort-image'></div>
              <div className='sort-text'>
                {
                sortType == 1? '최신 순' :
                sortType == 2? '마감 임박 순' : 
                sortType == 3? '높은 금액 순' : 
                sortType == 4? '낮은 지원 순' :
                '정렬방식'
                }
              </div>
            </div>
            }
          </div>

          <div className='list-main'>
            {
            projectList && projectList.map((project, i) => {
              return (
                i >= (activePage - 1) * pageSize &&
                i < activePage * pageSize && (
                  <div key={i} className='project-summary-box contents-row flex-center'>
                  
                    <div className='contents-col part-main'>
                      <div className='contents-row'>
                        {
                        moment(project.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0 && project.prj_stts_cd <= 4?
                        <div className='box recruit flex-center'>모집 중</div> 
                        : 
                        <div className='box recruit end flex-center'>모집 마감</div>
                        }
                        {
                        moment(moment(), "YYYY-MM-DD").diff(moment(project.prj_reg_de), 'days') <= 3?
                        <div className='box new flex-center'>New</div>
                        :
                        null
                        }
                        <div className='flex-spacer'></div>
                        <div className='reg-date flex-center'>등록일자 : {moment(project.prj_reg_de).format("YY.MM.DD")}</div>
                      </div>
                      <div className='project-name'>{project.prj_nm}</div>
                      <div className='contents-row'>
                        <div className='project-option textA'>예상금액</div>
                        
                        <div className='project-option textB'>{project.exptd_cost_tt > 10000? (project.exptd_cost_tt/10000).toFixed(0) : project.exptd_cost_tt} {project.exptd_cost_tt > 10000? '억원':'만원'}</div>
                        <div className='small-divider'>|</div> 
                        <div className='project-option textA'>예상일정</div>
                        <div className='project-option textB'>{project.exptd_duration_day} 일</div>                      
                        <div className='flex-center'>
                          <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${project.prj_qc_yn? '':'none'}`}/>
                          <div className={`project-option textC ${project.prj_qc_yn? '':'none'}`}>{project.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                        </div>
                      </div>
                      <div className='contents-row'>
                        <div className='flex-center'>
                          <FontAwesomeIcon icon='fa-solid fa-location-dot'/>
                        </div>
                        {checkSidoNm(project.sido_cd)}
                        {/* <div className='project-location'>{checkSidoNm(project.sido_cd)}</div> */}
                        <div className='project-type flex-center'>{project.prj_ty_nm }</div>
                        <div className='flex-spacer'></div>
                        <div className='contents-row flex-center'>
                          {
                            project.prj_owner_user_photo != null?
                            <img className='client-mark flex-center' src={project.prj_owner_user_photo} alt=""></img>
                            :
                            <div className='client-mark null'></div>
                          }
                          <div className='client-name flex-center'>{project.prj_owner_user_nm}</div>
                        </div>
                      </div>
                    </div>
                        
                    <div className='part-divider'></div>
                        
                    <div className='contents-col part-sub'>
                      <div className='contents-row'>
                        <div className='flex-center'>
                          <FontAwesomeIcon icon='fa-solid fa-clock-rotate-left' className='option-icon'/>
                        </div>
                        <div className='project-option textD'>마감일자</div>
                        {/* {
                          project && moment(project.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0? 
                          <div className='project-option textE'>
                          {moment(project.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days')} 일 남음</div>
                          :
                          <div className='project-option textE red'>모집 마감</div>
                        }  */}
                        {
                          project && project.prj_apply_de && moment(project.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0? 
                          <div className='project-option textE'>
                          {moment(project.prj_apply_de, "YYYY-MM-DD").startOf('day').diff(moment().startOf('day'), 'days')} 일 남음</div>
                          :
                          <div className='project-option textE red'>모집 마감</div>
                        } 
                      </div>
                      <div className='contents-row'>
                        <div className='flex-center'>
                          <FontAwesomeIcon icon='fa-solid fa-user' className='option-icon'/>
                        </div>
                        <div className='project-option textD'>지원자</div>
                        <div className='project-option textE'>{project.prj_apply_co} 명</div>
                      </div>
                      <div className='contents-row'>
                        <div className='flex-center'>
                          <FontAwesomeIcon icon='fa-regular fa-heart' className='option-icon'/>
                        </div>
                        <div className='project-option textD'>찜</div>
                        <div className='project-option textE'>{project.prj_itrst_co} 명</div>
                      </div>
                      {/* {console.log(project)} */}
                      <div 
                        className='apply-button flex-center' 
                        onClick={()=>{
                          if(props.isLoggedIn)
                            // {navigate(`/partner/${partner.user_id}`)}
                            navigate(`/project/${project.prj_id}`, { state: { project } });
                          else
                            {navigate('/login')}
                          }}
                        
                        //onClick={()=>{
                        //   if(props.isLoggedIn)
                        //     navigate(`/partner/${partner.user_id}`, { state: { partner } });
                        //     {navigate(`/project/${project.prj_id}`, { state: { project } })}
                        //   else
                        //     {navigate('/login')}
                        //   }}
                      >상세보기</div>
                    </div>
                  </div>
                )
              )
            })
            }
          </div>
          <div className="pagination-container flex-center">
            <Pagination
              className="pagination"
              activePage={activePage}
              totalPages={totalPages}
              ellipsisItem={{ key: 'ellipsisItem-1', content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              onPageChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
