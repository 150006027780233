import './Changepw.css'

import axios from "axios";
import React, { useEffect, useState } from 'react'

// import Popup from '../../shared/components/uiElements/Popup'
// import Popup from '../../../shared/components/uiElements/Popup';
import Popup from '../../shared/components/uiElements/Popup';


function ChangePwModal(props) {

    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('');

    // // const APIToken = state.pwToken;
    const APIToken = localStorage.getItem("login-token");
    // const APIToken = localStorage.getItem('login-token');
    const loginUserId = localStorage.getItem('user-id');
    const loginUserEmail = localStorage.getItem('user-email');

    // const findPwClick = () => {

    //     let isOK = validation();
    //     // console.log(APIToken)
    //     if (isOK) {
    //         axios({
    //             method: 'post',
    //             url: `/api/account/update/pw`,
    //             headers: {
    //                 // 'Authorization': `Bearer {${state ? state.pwToken : null}}`
    //                 'Authorization': `Bearer {${APIToken}}`
    //             },
    //             data: {
    //                 pw:newPw
    //             },
    //         })
    //         .then((result) => {
    //             // console.log(result.data);
    //             if (result.data.success) {
    //                 // setPopupMsg(result.data.msg);
    //                 setPopupMsg('비밀번호가 변경되었습니다.');
    //                 setShowAddPopup(true);
    //             } else {
    //                 setPopupMsg('유효하지않는 답변입니다<br>확인 후 다시 시도해주세요');
    //                 setShowAddPopup(true);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //     } else {
    //         setShowAddPopup(true);
    //     }
    // };

    // const [newPw, setNewPw] = useState('');
    // const [newPwCheck, setNewPwCheck] = useState('');
    // const [pwMatching, setPWMatcing] = useState(false);
    // const [pwMsg, setPWMsg] = useState('비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요.');

    // const handleNewPw = (e) => {
    //     e.preventDefault();
    //     setNewPw(e.target.value);
    // }
    // const handleNewPwChenck = (e) => {
    //     e.preventDefault();
    //     setNewPwCheck(e.target.value);
    // }

    // useEffect(()=>{
    //     if (newPw === '') {
    //         setPWMsg('비밀번호는 8자 이상 문자/숫자/특수문자 조합으로 설정하세요.');
    //     }
    //     if(newPw){
    //         if (newPw === newPwCheck){
    //             setPWMsg("비밀번호가 일치합니다.");
    //             setPWMatcing(true);
    //         }
    //         else{
    //             setPWMsg("비밀번호가 일치하지 않습니다.");
    //             setPWMatcing(false);
    //         }
    //     }
    // }
    // ,[newPw, newPwCheck])

    // var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
    // function validation () {
    //     if (!regexPw.test(newPw))
    //     {setPopupMsg("비밀번호는 문자/숫자/특수문자를<br>모두 포함하여야 합니다.<br>※사용 가능 특수문자 : ! @ $ % ^ & +"); return false};
    //     return true;
    // }

    // const brCount = (popupMsg.match(/<br>/g) || []).length;
    // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
    // const fontSizeValue = brCount === 0 ? '18px' : '16px';


    
    const [newPw, setNewPw] = useState('');
    const [newPwCheck, setNewPwCheck] = useState('');

    const handleNewPw = (e) => {
        e.preventDefault();
        setNewPw(e.target.value);
    }
    const handleNewPwChenck = (e) => {
        e.preventDefault();
        setNewPwCheck(e.target.value);
    }

    
    // 비밀번호 보이기/숨기기
    const [showNewPw, setShowPw] = useState(false);
    const [showCheckNewPw, setShowCheckNewPw] = useState(false);
    const toggleShowNewPw = () => {
        setShowPw(!showNewPw);
    };
    const toggleShowCheckNewPw = () => {
        setShowCheckNewPw(!showCheckNewPw);
    };

    // 비밀번호 확인 일치 여부
    const [pwMatching, setPWMatcing] = useState(false);
    const [pwMsg, setPWMsg] = useState('');
    useEffect(()=>{
        if(newPw){
          if (newPw === newPwCheck){
            setPWMsg("비밀번호가 일치합니다.")
            setPWMatcing(true);
          }
          else{
            setPWMsg("비밀번호가 일치하지 않습니다.")
            setPWMatcing(false);
          }
        }
    },[newPw, newPwCheck])

    // 비밀번호 유효성 검사,
    var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
    function validation () {
        if(!regexPw.test(newPw)) {
            // alert('비밀번호는 문자/숫자/특수문자 모두 포함하여야 합니다.');
            setShowPopup(true);
            setPopupMsg("비밀번호는 문자/숫자/특수문자<br>모두 포함하여야 합니다.");
            return false;
        }
        return true;
    }
    
    const chnagePwClick = () => {
        let isOK = validation();
        if (isOK) {
            axios({
                method: 'post',
                url: `/api/account/update/pw`,
                headers: {
                    // 'Authorization': `Bearer {${state ? state.pwToken : null}}`
                    'Authorization': `Bearer {${APIToken}}`
                },
                data: {
                    userId: loginUserId,
                    pw:newPw
                },
            })
            .then((result) => {
                // console.log(result.data);
                if (result.data.success) {
                    // setPopupMsg(result.data.msg);
                    setShowPopup(true);
                    setPopupMsg('비밀번호가 변경되었습니다.');
                    
                } else {
                    setShowPopup(true);
                    setPopupMsg('유효하지않는 답변입니다<br>확인 후 다시 시도해주세요');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

    const brCount = (popupMsg.match(/<br>/g) || []).length;
    // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
    const fontSizeValue = brCount === 0 ? '18px' : '16px';
    
    
    return (
        <Popup
            className="changepw"
            show={props.showChangePwPopup}
            // onCancel={() => props.setShowChagePwPopup(false)}
            // header='비밀번호 변경'
            // icon={<i className="code x icon"  onClick={() => props.setShowChagePwPopup(false)} /> }
        >
        <div className="contents-col flex-center changepw-container">
            <div className="title">비밀번호 재설정</div>
            <div className="contents-col full-width flex-center changepw-inputuser">
                <div className="flex-center field">
                    <div className="label">사용자 아이디</div>
                    <input 
                        type="text" 
                        className="input-chagepw"
                        value={loginUserEmail}
                        // onChange={handleNewPw}
                    />
                </div>
                <div className="flex-center field">
                    <div className="label">새 비밀번호</div>
                    <input 
                        type={showNewPw  ? "text" : "password"}
                        className="input-chagepw" 
                        onChange={handleNewPw}
                    />
                    <i className={showNewPw ? "fa-solid fa-eye showPwIcon" : "fa-solid fa-eye-slash showPwIcon"} onClick={toggleShowNewPw}></i>
                </div>
                <div className="flex-center field">
                    <div className="label">비밀번호 확인</div>
                    <input 
                        type={showCheckNewPw  ? "text" : "password"}
                        className="input-chagepw" 
                        onChange={handleNewPwChenck}
                    />
                    <i className={showCheckNewPw ? "fa-solid fa-eye showPwIcon" : "fa-solid fa-eye-slash showPwIcon"} onClick={toggleShowCheckNewPw}></i>
                </div>
                <p className={`contents-row sb sign-up-center ${pwMatching? 'passwordmatching' : 'passwordunmatching'}`}>{pwMsg}</p>
            </div>

            <div className="gc-btn changepw-btn" onClick={chnagePwClick}>변경</div>
     
            <div className="changepw-back" onClick={() => props.setShowChagePwPopup(false)}> 이전</div>
        </div>  
            {/* <Popup
                show={showPopup}
                onCancel={() => setShowPopup(false)}
                // header="알림"
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             setShowPopup(false);
                //         }
                //     } 
                // /> }
            >
                <div className='modal-info'>
                    <p
                        style={{
                            fontSize: fontSizeValue,
                            padding: paddingValue,
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p>
                    <button 
                        className="close_btn" 
                        onClick={() => {
                            setShowPopup(false);
                            props.setShowChagePwPopup(false);
                        }}  
                    >닫기</button>

                    
                </div>
            </Popup>      */}

            <Popup
                show={showPopup}
                // onCancel={() => setShowAddPopup(false)}
                header=""
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             // setShowAddPopup(false);
                //             setShowPopup(false);
                //         }} 
                //     /> }
            >
                <div className='contents-col full-width flex-center popup-info'>
                    {/* <p>{popupMsg}</p> */}
                    <p
                        style={{
                            fontSize: fontSizeValue,
                            // padding: paddingValue,
                        }}
                        dangerouslySetInnerHTML={{ __html: popupMsg }}
                    ></p>
                <div 
                    className="contents-row gc-btn flex-center close_btn ok" 
                    onClick={() => {
                        setShowPopup(false);
                        props.setShowChagePwPopup(false);
                    }}  
                >닫기</div>
                </div>
            </Popup>
        </Popup>
    )
}

export default ChangePwModal;