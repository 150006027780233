import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import snsLoginConfig from '../../config/snsLoginConfig';

const { naver } = window;

const NaverCallback = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleHash = () => {
        const hash = window.location.hash.substring(1);
        // console.log(hash);
        const params = new URLSearchParams(hash);
        // console.log(params);
        const accessToken = params.get('access_token');
        const state = params.get('state');
        const tokenType = params.get('token_type');
        const expiresIn = params.get('expires_in');

        if (accessToken && state && tokenType && expiresIn) {
            // 네이버 로그인 초기화 함수 호출
            const naverLogin = new naver.LoginWithNaverId({
                // clientId: NAVER_CLIENT_ID,
                // callbackUrl: NAVER_CALLBACK_URL,
                clientId: snsLoginConfig.NAVER_CLIENT_ID,
                callbackUrl: snsLoginConfig.NAVER_CALLBACK_URL,
                isPopup: false,
                callbackHandle: true,
            });

            naverLogin.init();

            naverLogin.getLoginStatus(async function (status) {
                if (status) {
                    const user = {
                        email: naverLogin.user.getEmail(),
                        id: naverLogin.user.getId(),
                    };
                    window.opener.postMessage({ type: 'NAVER_LOGIN_SUCCESS', user }, '*');
                    if (window.opener) {
                        // window.opener.location.reload();
                        window.close();
                    }
                } else {
                }
            });
        }
    };

    handleHash();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default NaverCallback;
