import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../../shared/components/uiElements/Popup';

import axios from "axios";

import snsLoginConfig from '../../config/snsLoginConfig';

const KakaoLogin = ({ clientId, callbackUrl, isLoggedIn, userProfile, setIsLoggedIn, setUserInfoState }) => {
    const navigate = useNavigate();
    const kakaoButtonRef = useRef(null);

    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
    const [showChoosePopup, setShowChoosePopup] = useState(false);

    // 사용자 정보 상태
    const [kakaoId, setKakaoId] = useState('');
    const [kakaoEmail, setKakaoEmail] = useState('');
    const [kakaoExist, setKakaoExist] = useState();

    useEffect(() => {
        if (window.Kakao && !window.Kakao.isInitialized()) {
            window.Kakao.init(snsLoginConfig.KAKAO_JAVASCRIPT_KEY); // Kakao SDK 초기화
        }
    }, []);

    // 로그인 핸들러
    const handleLogin = () => {
        if (window.Kakao && !window.Kakao.isInitialized()) {
            window.Kakao.init(snsLoginConfig.KAKAO_JAVASCRIPT_KEY); // 초기화가 안 되어 있으면 초기화
        }
        if (window.Kakao) {
            window.Kakao.Auth.login({
                success: (authObj) => {
                    // console.log(authObj);
                    window.Kakao.API.request({
                        url: '/v2/user/me',
                        success: (res) => {
                            setKakaoId(res.id);
                            setKakaoEmail(res.kakao_account.email);
                        },
                        fail: (error) => {
                            console.error(error);
                        },
                    });
                },
                fail: (err) => {
                    console.error(err);
                },
            });
        } else {
            console.error("Kakao 객체를 찾을 수 없습니다.");
        }
    };

    const kakaoLoginHandler = () => {
        handleLogin();
    };

    const emailCheckKakao = () => {
        axios.get(`/api/account/check?email=${kakaoEmail}`)
        .then((result) => {
            // console.log(result.data);
            if (result.data.success) {
                // setKakaoExist(result.data.data.exist);
                const emailCheckData = result.data.data;
                setKakaoExist(emailCheckData.exist);
                if (emailCheckData.exist) {
                    if (emailCheckData.id_type_cd === 2) {
                    setShowPopup(true);
                    setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다 (구글)");
                    // setIsLoggedIn(false);
                    } else if (emailCheckData.id_type_cd === 3) {
                    setShowPopup(true);
                    setPopupMsg("이미 해당 이메일로 가입한 정보가 있습니다 (네이버)");
                    // setIsLoggedIn(false);
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (kakaoEmail) {
            emailCheckKakao();
        }
    }, [kakaoEmail]);

    
    const [idTyCd, setIdTyCd] = useState('');

    useEffect(() => {
        // const idTyCd = 4; // 아이디 타입 코드 설정
        setIdTyCd(4);
        if (kakaoExist === false) {
            signupKakao();
        } else if (kakaoExist === true) {
            loginKakao();
        }
    }, [kakaoExist, kakaoId, kakaoEmail]);

    const signupKakao = () => {
        axios({
            method:'post',
            url:`/api/sns-signup`,
            data: { 
                idTyCd: idTyCd, 
                snsId: kakaoId, 
                email: kakaoEmail
            },
        })
        .then((result)=>{
            // console.log(result.data);
            // navigate("/login");
            if (result.data.success) {
                emailCheckKakao();
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
            }
        })
        .catch((error)=>{
            console.log(error)  
        })
    }

    const loginKakao = () => {
        axios({
            method:'post',
            url:`/api/sns-login`,
            data: { 
            idTyCd: idTyCd, 
            snsId: kakaoId, 
            email: kakaoEmail
            },
        })
        .then((result)=>{
            console.log(result.data);
            if (result.data.success) {
                localStorage.setItem('login-token', result.data.token);
                localStorage.setItem('user-name', result.data.data.userNm);
                localStorage.setItem('user-level', result.data.data.levelCd);
                localStorage.setItem('user-id', result.data.data.userId);
                localStorage.setItem('user-ty', result.data.data.userTy);
                localStorage.setItem('user-email', result.data.data.email);
                localStorage.setItem('user-photoUrl', result.data.data.photoUrl);
                localStorage.setItem('user-coTy', result.data.data.coTy);
                setIsLoggedIn(true);
            } else {
                setShowPopup(true);
                setPopupMsg('가입정보가 일치하지 않습니다');
            }
            // window.location.reload();
        })
        .catch((error)=>{
            console.log(error)  
        })
    }

    const logout = () => {
        if (window.Kakao && window.Kakao.Auth.getAccessToken()) {
            window.Kakao.Auth.logout(() => {
                // console.log('로그아웃 되었습니다.');
                setKakaoId('');
                setKakaoEmail('');
                setIsLoggedIn(false);
            });
        } else {
            // console.log('로그인 상태가 아닙니다.');
        }
    };

    const brCount = (popupMsg.match(/<br>/g) || []).length;
    // const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
    const fontSizeValue = brCount === 0 ? '18px' : '16px';

    return (
        <>
        {/* <div ref={kakaoButtonRef} id=""></div> */}
        <div className='flex-center sns-login kakao' onClick={kakaoLoginHandler}>
          <div className='symbol kakao-symbol'></div>
          <span className='sns-text'>카카오톡 로그인</span>
        </div>

        <Popup
            show={showPopup}
            header=""
        >
            <div className='contents-col full-width flex-center popup-info'>
                {/* <p>{popupMsg}</p> */}
                <p
                    style={{
                        fontSize: fontSizeValue,
                        // padding: paddingValue,
                    }}
                    dangerouslySetInnerHTML={{ __html: popupMsg }}
                ></p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => setShowPopup(false)}  
                >닫기</div>
            </div>
        </Popup>
        
        </>
    )
};

export default KakaoLogin;
