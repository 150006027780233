import './Manager.css';

import MyProject from './MyProject';
import ManagerAccountMgt from './ManagerAccountMgt';
import ManagerProfile from './ManagerProfile';

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Checkbox, Pagination, Icon } from 'semantic-ui-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// AXIOS
import axios from "axios";

function Manager(props) {
  // /localStorage
  const APIToken = localStorage.getItem("login-token");
  
  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePageChange = (e, { activePage }) => {
      setActivePage(activePage);
  };

  const navigate = useNavigate();

  // 서브메뉴
  const [profileState, setProfileState] = useState('entire-project');
  const handleStateChange = (newState) => {
    setProfileState(newState);
    // window.history.pushState({ profileState: newState }, '', '/profile');
  };

  // main-title
  let titleText;
  switch (profileState) {
    case 'entire-project': titleText = '전체 프로젝트'; break;
    case 'my-project': titleText = '내 프로젝트'; break;
    case 'account-mgt': titleText = '계정 관리'; break;
    case 'my-profile': titleText = '프로필'; break;
  }

  const [sidoType, setSidoType] = useState('');           // 지역 코드
  const onChangeSidoType = (value) => {
    if(sidoType.includes(value)){
      setSidoType(sidoType.filter((item) => item !== value));
    }
    else{
      setSidoType((prev) => [...prev, value]);
    }
  }

  const [sidoArray, setSidoArray] = useState();           // 지역 배열
  // 시도 코드 조회
  useEffect (() => {
    axios({
      method:'get',
      url:`/api/util/sido`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        setSidoArray(result.data.data);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  } , [])


  // 진행중인 프로젝트 (첫 렌더링 데이터 : 1)
  const [managerPrjSttsCd, setManagerPrjSttsCd] = useState(1);
    // const [prjSttsCd, setPrjSttsCd] = useState('');
    const progressSteps = [
      { id: 1, label: '생성' },
      { id: 2, label: '지원' },
      { id: 3, label: '미팅' },
      { id: 4, label: '계약' },
      { id: 5, label: '진행' },
      { id: 7, label: '완료' }
  ];

  // 전체 프로젝트
  const [managerProjectList1, setManagerProjectList1] = useState([]); // 생성
  const [managerProjectList2, setManagerProjectList2] = useState([]); // 지원
  const [managerProjectList3, setManagerProjectList3] = useState([]); // 미팅
  const [managerProjectList4, setManagerProjectList4] = useState([]); // 계약
  const [managerProjectList5, setManagerProjectList5] = useState([]); // 진행
  const [managerProjectList7, setManagerProjectList7] = useState([]); // 완료
  const [managerProjectList100, setManagerProjectList100] = useState([]); // 실패
  
  // 필터 (금액)
  const [costLower, setCostLower] = useState('');         // (필터) 최저금액
  const [costUpper, setCostUpper] = useState('');         // (필터) 최고금액
  
  const handleCostLower = (event) => {
    event.preventDefault();
    const value = event.target.value.replace(/\D/g, '');
    setCostLower(value);
  }
  
  const handleCostUpper = (event) => {
    event.preventDefault();
    const value = event.target.value.replace(/\D/g, '');
    setCostUpper(value);
  }

  const [entireProjectList, setEntireProjectList] = useState([]);
  const projectPageSize = 6;
  const fetchManagerData = (prjSttsCd) => {
    if (prjSttsCd) {
      axios({
          method:'get',
          url:`/api/project/list?prjSttsCd=${prjSttsCd}&overCost=${costLower}&underCost=${costUpper}&sidoCdArr=${JSON.stringify(sidoType)}&page=${activePage}&perPage=${projectPageSize}`,
          headers: {
              'Authorization': `Bearer {${APIToken}}`
          },
      })
      .then((result)=>{
          // console.log(result.data.data);
          if(result.data.success === true) {
              let projectList = result.data;
              // setManagerProjectList(projectList.data);

              let data = result.data.data;
              switch (prjSttsCd) {
                case 1:
                  setManagerProjectList1(data);
                  break;
                case 2:
                  setManagerProjectList2(data);
                  break;
                case 3:
                  setManagerProjectList3(data);
                  break;
                case 4:
                  setManagerProjectList4(data);
                  break;
                case 5:
                  setManagerProjectList5(data);
                  break;
                case 7:
                  setManagerProjectList7(data);
                  break;
                case 100:
                  setManagerProjectList100(data);
                  break;
                default:
                  break;
              }
              setTotalPages(Math.ceil(projectList.total / projectPageSize));
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }
  };


  const fetchProjectTotals = async () => {
    const statuses = [1, 2, 3, 4, 5, 7];
    const newTotals = { ...projectTotals };
  
    try {
      const fetchData = statuses.map(status =>
        axios({
          method: 'get',
          url: `/api/project/list?prjSttsCd=${status}&page=${activePage}&perPage=${projectPageSize}`,
          // url: `/api/project/list?prjSttsCd=${status}&page=${activePage}&perPage=${projectPageSize}`,
          headers: {
            'Authorization': `Bearer ${APIToken}`
          }
        })
      );
  
      const responses = await Promise.all(fetchData);
      responses.forEach((response, index) => {
        if (response.data.success) {
          newTotals[statuses[index]] = response.data.total;
        }
      });
  
      setProjectTotals(newTotals);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchProjectTotals();
  }, []);

  // 전체 프로젝트
  // useEffect (() => {
  //   fetchManagerData(1, setManagerProjectList1);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(2, setManagerProjectList2);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(3, setManagerProjectList3);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(4, setManagerProjectList4);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(5, setManagerProjectList5);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(7, setManagerProjectList7);
  // }, []);
  // useEffect (() => {
  //   fetchManagerData(100, setManagerProjectList100);
  // }, []);
  useEffect(() => {
    fetchManagerData(managerPrjSttsCd);
    // fetchManagerData(managerPrjSttsCd, getSetterByStatus(managerPrjSttsCd));
  }, [managerPrjSttsCd, activePage, sidoType, costLower, costUpper]);

  const getSetterByStatus = (status) => {
    switch (status) {
      case 1: return setManagerProjectList1;
      case 2: return setManagerProjectList2;
      case 3: return setManagerProjectList3;
      case 4: return setManagerProjectList4;
      case 5: return setManagerProjectList5;
      case 7: return setManagerProjectList7;
      case 100: return setManagerProjectList100;
      default: return setManagerProjectList1;
    }
  };
  
  // 첫 렌더링 데이터
  useEffect(() => {
    if (managerPrjSttsCd === 1) {
      setManagerProjectList(managerProjectList1);
    }
  }, [managerProjectList1]);

  const [managerProjectList, setManagerProjectList] = useState([]); 
  // const handleManagerProgressClick = (statusNumber) => {
  //   let selectedProjectList = [];
  //   switch (statusNumber) {
  //     case 1:
  //       selectedProjectList = managerProjectList1;
  //       break;
  //     case 2:
  //       selectedProjectList = managerProjectList2;
  //       break;
  //     case 3:
  //       selectedProjectList = managerProjectList3;
  //       break;
  //     case 4:
  //       selectedProjectList = managerProjectList4;
  //       break;
  //     case 5:
  //       selectedProjectList = managerProjectList5;
  //       break;
  //     case 7:
  //       selectedProjectList = managerProjectList7;
  //       break;
  //     case 100:
  //       selectedProjectList = managerProjectList100;
  //       break;
  //     default:
  //       selectedProjectList = [];
  //   }
  //   setManagerProjectList(selectedProjectList);
  //   setManagerPrjSttsCd(statusNumber);
  // };
  const handleManagerProgressClick = (statusNumber) => {
    setManagerPrjSttsCd(statusNumber);
    setActivePage(1);  // 페이지 변경 시 1페이지로 초기화
    // setManagerProjectList(getListByStatus(statusNumber));
  };

  const getListByStatus = (status) => {
    switch (status) {
      case 1: return managerProjectList1;
      case 2: return managerProjectList2;
      case 3: return managerProjectList3;
      case 4: return managerProjectList4;
      case 5: return managerProjectList5;
      case 7: return managerProjectList7;
      case 100: return managerProjectList100;
      default: return [];
    }
  };


  const getManagerProjectListLength = (stepId) => {
    switch (stepId) {
        case 1:
        return managerProjectList1.length;
        case 2:
          return managerProjectList2.length;
        case 3:
          return managerProjectList3.length;
            break;
        case 4:
          return managerProjectList4.length;
        case 5:
          return managerProjectList5.length;
        case 7:
          return managerProjectList7.length;
        case 100:
          return managerProjectList100.length;
        default:
          return 0
    }
  };

  // 프로젝트 진행상태
  const statusTextMap = {
    1: '생성',
    2: '지원',
    3: '미팅',
    4: '계약',
    5: '진행',
    6: '검수',
    7: '완료',
    // 7: '지급',
    // 8: '완료',
    100: '실패'
  };

  const [projectTotals, setProjectTotals] = useState({
    1: 0, // 생성
    2: 0, // 지원
    3: 0, // 미팅
    4: 0, // 계약
    5: 0, // 진행
    7: 0  // 완료
  });

  // 분야 (1:일반 레포츠 / 2:미디어 촬영 /  3:시설점검 / 4:공간정보 / 5:농업, 방제 / 6.기타 - 직접입력)
  const fieldLabels = {
    1: '일반 레포츠',
    2: '미디어 촬영',
    3: '시설점검',
    4: '공간정보',
    5: '농업,방제',
    6: '기타',
  };

  const [fieldStates, setFieldStates] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });

  const handleCheckboxChange = (fieldKey) => {
    // 기타 체크박스가 해제되었을 때만 prjTyCustom 상태를 초기화합니다.
    if (fieldKey === '6' && fieldStates[fieldKey]) {
        setPrjTyCustom('');
    }

    setFieldStates(prevState => ({
        ...prevState,
        [fieldKey]: !prevState[fieldKey],
    }));
    // setOnChangeClick(true);
  };

  // 분야 - 기타 (직접입력)
  const [prjTyCustom, setPrjTyCustom] = useState('');

  const handlePrjTyCustom = (event) => {
      event.preventDefault();
      setPrjTyCustom(event.target.value);
      // setOnChangeClick(true);
  }

  // 세 개씩 나눠서 묶어주기
  const groupedFields = Object.keys(fieldStates).reduce((acc, fieldKey, index) => {
    const chunkIndex = Math.floor(index / 3);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(fieldKey);
    return acc;
  }, []);


  // 원하는 순서로 필드 배열 정의
  const customFieldOrder = [
    ['1', '4'], // 첫 번째 줄: 일반, 정보
    ['2', '5'], // 두 번째 줄: 촬영, 농업
    ['3', '6'], // 세 번째 줄: 시설, 기타
  ];

  
  return (
    // <div className='Manager-page-container'>

    // </div>
    <React.Fragment>
      <div className='Manager-page-container'>
        <div className='contents-row manager'>
          <div className='contents-col manager-main'>
            <div className='contents-row full-width main-title-container'>
              <div className='contents-row full-width flex-center main-title-box'>
                <div className='main-title'>{titleText}</div>
                <div className='gc-btn flex-center manager-tag none-hover'>매니저</div>
                {/* <div className='gc-btn flex-center info-btn client none-hover'>매니저</div> */}
              </div>
            </div>
            
            <div className='hr-solid'/>

            {
              profileState === 'entire-project' ? 
                <React.Fragment>
                  <div className='full-width'>
                    <div className='main-text'>진행중인 프로젝트</div>
                    <div className='contents-row flex-center project-progress'>
                    {progressSteps && progressSteps.map((step, index) => {
                      const projectCount = projectTotals[step.id] || 0;
                      return (
                        <React.Fragment key={step.id}>
                          <div
                            className={`flex-center contents-col progress-circle ${managerPrjSttsCd === step.id ? 'click' : ''}`}
                            onClick={() => handleManagerProgressClick(step.id)}
                          >
                            <span>{step.label}</span>
                            <span>{projectCount}</span>
                          </div>
                          {index !== progressSteps.length - 1 && <FontAwesomeIcon icon="fa-solid fa-chevron-right" />}
                        </React.Fragment>
                      );
                    })}
                    </div>
                  </div>
                  {managerPrjSttsCd &&  (
                  <>
                  <div className='hr-solid'/>
                  <div className='full-width'>
                      {/* <div className='main-text'>완료 프로젝트</div> */}
                      <div className='main-text'>
                          {progressSteps.map(step => {
                              if (step.id === parseInt(managerPrjSttsCd)) {
                                  return `${step.label} 프로젝트`;
                              }
                          })}
                      </div>
                      {getListByStatus(managerPrjSttsCd).length !== 0 ? 
                        <React.Fragment>
                        {getListByStatus(managerPrjSttsCd).map((managerPrjItem, index) => {
                            // console.log(myPartnerProjectList);
                            return (
                              <div className='contents-row full-width flex-center project-progress req' key={index}>
                                {/* {console.log(clientPrjItem)} */}
                                <div className='contents-col flex-center spacer progress-box req'>
                                    <p>{managerPrjItem.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                    <p>예상금액 {managerPrjItem.exptd_cost_tt} 만원 | 예상일정 {managerPrjItem.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[managerPrjItem.prj_stts_cd]}</span></p>
                                </div>
                                <div className='contents-row spacer progress-box req'>
                                    <div className='gc-btn info-btn video'>{managerPrjItem.prj_ty_nm}</div>
                                    <div className='contents-col req-state'>
                                        {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{clientPrjItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                        <div className='flex-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${managerPrjItem.prj_qc_yn? '':'none'}`}/>
                                            <div className={`prj-qc-yn ${managerPrjItem.prj_qc_yn? '':'none'}`}>{managerPrjItem.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                        </div>
                                        
                                        <div className='contents-row flex-center project-user-info'>
                                            {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                                            {managerPrjItem.prj_owner_user_photo === null ? (
                                              // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                              <div className='user-logo-img small none'></div>
                                            ) : (
                                              <img
                                                  className="user-logo-img small"
                                                  src={managerPrjItem.prj_owner_user_photo}
                                                  alt="Uploaded Image"
                                                  style={{ maxWidth: '100%', maxHeight: '22px' }}
                                              />
                                            )}
                                            {managerPrjItem.prj_owner_user_nm}
                                        </div>
                                    </div>
                                    <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${managerPrjItem.prj_id}`)}}>상세보기</div>
                                </div>
                              </div>
                            );
                        })}
                        <div className="flex-center">
                          <div className="flex-center">
                          <Pagination
                            className="pagination"
                            activePage={activePage}
                            totalPages={totalPages}
                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                            onPageChange={handlePageChange}
                          >                        
                          </Pagination>
                          </div>
                        </div>
                        </React.Fragment>
                      : 
                          <div className='contents-row full-width flex-center'>
                              <p className='result-info'>
                                  {progressSteps.map(step => {
                                      if (step.id === parseInt(managerPrjSttsCd)) {
                                          return `${step.label} 프로젝트가 없습니다.`;
                                      }
                                  })}
                              </p>
                              {/* <p className='result-info'>회사 연혁 없습니다.</p> */}
                          </div>
                      }
                      {/* {projectList.map((project, i) => (
                          <div className='contents-row flex-center project-progress req' key={i}>
                              <div className='contents-col flex-center spacer progress-box req apply'>
                                  <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                                  <p className='apply'>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[project.prj_stts_cd]}</span></p>
                              </div>
                              <div className='contents-row spacer progress-box req'>
                                  <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                                  <div className='contents-col req-state'>
                                      <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{project.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                                      <p className='contents-row flex-center'>
                                          <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>
                                          e****e
                                      </p>
                                  </div>
                                  <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                              </div>
                          </div>
                      ))} */}
                  </div>
                  </>
                  )}
                </React.Fragment>
              : profileState === 'my-project' ? 
                <MyProject></MyProject>
              : profileState === 'account-mgt' ? 
                <ManagerAccountMgt></ManagerAccountMgt>
              : profileState === 'my-profile' ? 
                <ManagerProfile profileData={props.userProfile}></ManagerProfile>
              :null
            }
          </div>

          <div className='contents-col'>
            <div className='contents-col manager-sub flex-center'>
              <div className='contents-row flex-center progress-box req user-info'>
                {!props.userProfile.photoUrl ? (
                  // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                  <div className='user-logo-img middle none'></div>
                ) : (
                  <img
                      className="user-logo-img circle"
                      src={props.userProfile.photoUrl}
                      alt="Uploaded Image"
                  />
                )}
                <div className='contents-col user-info-box'>
                  <p className='user-nm'>{props.userProfile.user_nm}</p>
                  <p className='user-email'>{props.userProfile.email}</p>
                </div>
                {/* {props.userProfile_user_ty === 1? 
                  <div className='gc-btn gc-btn-green flex-center info-btn login-user-ty-state partner none-hover'>파트너</div>
                : props.userProfile_user_ty === 2 ?   
                  <div className='gc-btn flex-center info-btn login-user-ty-state client none-hover'>클라이언트</div>
                : null} */}
                <div className='gc-btn flex-center manager-tag login-user-ty-state client none-hover'>매니저</div>
              </div>
              <div className='contents-col project-status-box'>
                <div className={`contents-row project-status profile ${profileState === 'entire-project' ? 'active' : ''}`} onClick={() => handleStateChange('entire-project')}>
                  <p>전체 프로젝트</p>
                </div>
                <div className={`contents-row project-status profile ${profileState === 'my-project' ? 'active' : ''}`} onClick={() => handleStateChange('my-project')}>
                  <p>내 프로젝트</p>
                </div>
                <div className={`contents-row project-status profile ${profileState === 'account-mgt' ? 'active' : ''}`} onClick={() => handleStateChange('account-mgt')}>
                  <p>계정 관리</p>
                </div>
                {/* <div className='hr-solid'/> */}
                {/* <div className={`contents-row project-status profile ${profileState === 'my-profile' ? 'active' : ''}`} onClick={() => handleStateChange('my-profile')}>
                  <p>프로필</p>
                </div> */}
                {/* <div className='contents-row project-status profile' onClick={handleLogout}>
                  <p>로그아웃</p>
                </div> */}
              </div>
              <div className='contents-col flex-center dropia-btn-box'></div>
            </div>

            <div className='contents-col flex-center filtering-option'>
              <div className='contents-row filtering-header'>필터</div>
              <div className='contents-col full-width project-status-box filtering-main'>
                <p>촬영 분야</p>
                {/* {customFieldOrder.map((group, groupIndex) => (
                  <div className=" contents-row field-row" key={groupIndex}>
                    {group.map((fieldKey) => (
                      <div className="contents-row field flex-center" key={fieldKey}>
                        <input
                          type="checkbox"
                          checked={fieldStates[fieldKey]}
                          onChange={() => handleCheckboxChange(fieldKey)}
                        />
                        <label onClick={() => handleCheckboxChange(fieldKey)}>
                          {fieldLabels[fieldKey]}
                        </label>
                      </div>
                    ))}
                  </div>
                ))} */}
                {/* {Object.keys(fieldStates).map((fieldKey, index) => (
                  <div className='contents-row field flex-center' key={index}>
                      <input
                          type='checkbox'
                          checked={fieldStates[fieldKey]}
                          onChange={() => handleCheckboxChange(fieldKey)}
                      />
                      <label onClick={() => handleCheckboxChange(fieldKey)}>{fieldLabels[fieldKey]}</label>
                  </div>
                ))} */}
                
                <div className={`contents-row full-width filtering-type`}>
                  <div className='contents-col spacer'>
                    
                    {/* <Checkbox 
                      className='checkbox' 
                      value={1} 
                      // checked={prjType.includes(1)} 
                      // onChange={(e, data)=>{onChangePrjType(data.value)}}
                    ></Checkbox>
                    <label htmlFor='filter-type-1' className='textB'>일반 레포츠</label> */}
                    {Object.keys(fieldStates)
                      .filter((key) => key <= 3)
                      .map((fieldKey, index) => (
                        <div className="contents-row field " key={index}>
                          <Checkbox
                            type="checkbox"
                            checked={fieldStates[fieldKey]}
                            onChange={() => handleCheckboxChange(fieldKey)}
                          />
                          <label onClick={() => handleCheckboxChange(fieldKey)}>
                            {fieldLabels[fieldKey]}
                          </label>
                        </div>
                      ))}
                  </div>
                  <div className="contents-col spacer">
                    {Object.keys(fieldStates)
                      .filter((key) => key > 3)
                      .map((fieldKey, index) => (
                        <div className="contents-row field " key={index}>
                          <Checkbox
                            type="checkbox"
                            checked={fieldStates[fieldKey]}
                            onChange={() => handleCheckboxChange(fieldKey)}
                          />
                          <label onClick={() => handleCheckboxChange(fieldKey)}>
                            {fieldLabels[fieldKey]}
                          </label>
                        </div>
                      ))}
                  </div>
                  {/* {groupedFields.map((group, groupIndex) => (
                    <div className="field-row" key={groupIndex}>
                      {group.map((fieldKey) => (
                        <div className="contents-row field flex-center" key={fieldKey}>
                          <input
                            type="checkbox"
                            checked={fieldStates[fieldKey]}
                            onChange={() => handleCheckboxChange(fieldKey)}
                          />
                          <label onClick={() => handleCheckboxChange(fieldKey)}>
                            {fieldLabels[fieldKey]}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))} */}
                </div>
                <div className='part-divider'></div>
                <p>금액</p>
                <div className={`contents-row project-status profile filtering-type`}>
                  
                    {/* <input className='input cost lower' type='text' onChange={(e)=>{setCostLower(e.target.value)}}></input> */}
                    <input 
                      className='input cost lower' 
                      type='text' 
                      value={costLower} 
                      onChange={handleCostLower}
                    ></input>
                    <div className='textB flex-center'>~</div>
                    <input 
                      className='input cost upper' 
                      type='text' 
                      value={costUpper} 
                      onChange={handleCostUpper}
                    ></input>
                    {/* <input className='input cost upper' type='text' onChange={(e)=>{setCostUpper(e.target.value)}}></input> */}
                    <div className='textB flex-center'>만원</div>
                </div>
                <div className='part-divider'></div>
                <p>지역</p>
                <div className={`contents-row project-status profile filtering-type`}>
                <div className='contents-col sido-group'>
                  {sidoArray && sidoArray.map((sido)=>{

                    return(
                      <div className='filtering-factor contents-row' key={sido.sido_cd}>
                        <Checkbox 
                          className='checkbox' 
                          id={`filter-sido-${sido.sido_cd}`} 
                          value={sido.sido_cd} 
                          checked={sidoType.includes(sido.sido_cd)} 
                          onChange={(e, data)=>{onChangeSidoType(data.value)}}
                        ></Checkbox>
                        <label htmlFor={`filter-sido-${sido.sido_cd}`} className=''>
                          {
                          sido.sido_nm == '서울특별시' ? '서울' :
                          sido.sido_nm == '부산광역시' ? '부산' :
                          sido.sido_nm == '대구광역시' ? '대구' :
                          sido.sido_nm == '인천광역시' ? '광주' :
                          sido.sido_nm == '광주광역시' ? '광주' :
                          sido.sido_nm == '대전광역시' ? '대전' :
                          sido.sido_nm == '울산광역시' ? '울산' :
                          sido.sido_nm == '세종특별자치시' ? '세종' :
                          sido.sido_nm == '경기도' ? '경기' :
                          sido.sido_nm == '강원도' ? '강원' :
                          sido.sido_nm == '충청북도' ? '충북' :
                          sido.sido_nm == '충청남도' ? '충남' :
                          sido.sido_nm == '전라북도' ? '전북' :
                          sido.sido_nm == '전라남도' ? '전남' :
                          sido.sido_nm == '경상북도' ? '경북' :
                          sido.sido_nm == '경상남도' ? '경남' :
                          sido.sido_nm == '제주특별자치도' ? '제주' : sido.sido_nm
                          }
                        </label>
                      </div>
                    )
                  })}
                </div>
                
                </div>


                {/* <div className='hr-solid'/> */}
                {/* <div className={`contents-row project-status profile ${profileState === 'my-profile' ? 'active' : ''}`} onClick={() => handleStateChange('my-profile')}>
                  <p>프로필</p>
                </div> */}
                {/* <div className='contents-row project-status profile' onClick={handleLogout}>
                  <p>로그아웃</p>
                </div> */}
              </div>
              <div className='contents-col flex-center dropia-btn-box'></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Manager;
