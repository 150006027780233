import './Login.css';

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

function Signup(props) {
  // const [showPw, setShowPw] = useState(false);
  // const handleShowPw = (e) => {
  //   setPassword(e.target.value);
  // };
  
  // Popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
  const [showChoosePopup, setShowChoosePopup] = useState(false);
  // const [userInfoState, setUserInfoState] = useState('');

  // 회원가입
  const [userEmail, setUserEmail] = useState();
  const [userNm, setUserNm] = useState();
  const [userTelNum, setUserTelNum] = useState();
  const [userPw, setUserPw] = useState();
  const [checkUserPw, setCheckUserPw] = useState();

  const handleUserEmail = (event) => {
    event.preventDefault();
    setUserEmail(event.target.value);
  }
  const handleUserNm = (event) => {
    event.preventDefault();
    setUserNm(event.target.value);
  }
  
  // 전화번호 - 하이픈 자동 생성
  const formatPhoneNumber = (number) => {
    const numericValue = number.replace(/[^0-9]/g, '');
    
    if (numericValue.length <= 3) {
      return numericValue;
    } else if (numericValue.length <= 7) {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
    } else {
        return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 7)}-${numericValue.slice(7, 11)}`;
    }
  };

  const handleUserTelNum = (event) => {
    event.preventDefault();
    const inputTelValue = event.target.value;
    // const numericValue = inputTelValue.replace(/[^0-9]/g, '');
    // setUserTelNum(numericValue);
    const formattedValue = formatPhoneNumber(inputTelValue);
    setUserTelNum(formattedValue);
    
    // 길이가 11자를 넘지 않도록 제한
    // if (numericValue.length <= 11) {
      // setUserTelNum(numericValue);
    // }
    // setUserTelNum(event.target.value);
  }
  const handleUserPw = (e) => {
    setUserPw(e.target.value);
  };
  const handleCheckUserPw = (e) => {
    setCheckUserPw(e.target.value);
  };

  // 비밀번호 보이기/숨기기
  const [showPw, setShowPw] = useState(false);
  const [showCheckPw, setShowCheckPw] = useState(false);
  const toggleShowPw = () => {
    setShowPw(!showPw);
  };
  const toggleShowCheckPw = () => {
    setShowCheckPw(!showCheckPw);
  };

  // 
  const [selectedUserType, setSelectedUserType] = useState(null);

  const handleSelection = (type) => {
    setSelectedUserType(type);
  };

  // 회원가입 유효성 검사
  // var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@$%^&+]).*$/;
  var regexPw = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]).*$/
  // /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]).*$/
  var regexEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/
  var regNmEnglishKoreanMix = /(?=.*[a-zA-Z])(?=.*[ㄱ-ㅎㅏ-ㅣ가-힣])/;
  // var regexEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  // var regexEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  // var regexEmail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  function validation () {
    if (userEmail === undefined && userPw === undefined) {
      // alert('필수 정보를 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("필수 정보를 입력해주세요.");
      return false;
    }

    if (!userEmail) {
      // alert('이메일 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("이메일 입력해주세요.");
      return false;
    }

    if (!userNm) {
      // alert('이름을 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("이름을 입력해주세요.");
      return false;
    }

    if (!userTelNum) {
      // alert('전화번호를 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("전화번호를 입력해주세요.");
      return false;
    }
    
    if (!userPw) {
      // alert('비밀번호를 입력해주세요.');
      setShowPopup(true);
      setPopupMsg("비밀번호를 입력해주세요.");
      return false;
    }

    if (!checkUserPw) {
      // alert('비밀번호 확인 해주세요.');
      setShowPopup(true);
      setPopupMsg("비밀번호 확인 해주세요.");
      return false;
    }
    
    if(!regexEmail.test(userEmail)) {
      // alert('이메일 형식을 확인하세요.');
      setShowPopup(true);
      setPopupMsg("이메일 형식을 확인하세요.");
      return false;
    }

    if(!regexPw.test(userPw)) {
      // alert('비밀번호는 문자/숫자/특수문자 모두 포함하여야 합니다.');
      setShowPopup(true);
      setPopupMsg("비밀번호는 8자 이상 16자 이하<br>문자/숫자/특수문자 모두 포함하여야 합니다.");
      return false;
    }

    // if (regNmEnglishKoreanMix.test(userNm)) {
    //   setShowPopup(true);
    //   setPopupMsg("영어와 한글을 혼합하여<br>입력할 수 없습니다.");
    // }

    if (!selectedUserType) {
      setShowPopup(true);
      setPopupMsg("계정 유형을 선택해주세요.");
      return false;
    }

    return true;
  }

  // 비밀번호 확인 일치 여부
  const [pwMatching, setPWMatcing] = useState(false);
  const [pwMsg, setPWMsg] = useState('');
  useEffect(()=>{
    if(userPw){
      if (userPw === checkUserPw){
        setPWMsg("비밀번호가 일치합니다.")
        setPWMatcing(true);
      }
      else{
        setPWMsg("비밀번호가 일치하지 않습니다.")
        setPWMatcing(false);
      }
    }
  },[userPw, checkUserPw])

  // 회원가입
  const navigate = useNavigate();
  const postSignUp = () => {
    let isOK = validation();
    if(isOK){
      axios({
        method:'post',
        url:`/api/signup`,
        data: { 
          email: userEmail, 
          userNm: userNm,
          tellNo: userTelNum,
          pw: userPw,
          userTy: selectedUserType
        },
      })
      .then((result)=>{
        // console.log(result);
        // navigate("/login");
        if(result.data.success) {
          navigate("/login");
        } else {
          setShowPopup(true);
          setPopupMsg(result.data.msg);
        }
      })
      .catch((error)=>{
          console.log(error)  
      })
    }
  };

  // SNS 회원가입
  // 카카오톡 회원가입
  // const Rest_api_key='a5d018191ff2044e02fa3f46d6735e7a' //REST API KEY
  // const redirect_uri = 'http://localhost:4000/login' //Redirect URI
  // // aoauth 요청 URL
  // const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`
  // const handleLogin = ()=>{
  //     window.location.href = kakaoURL
  // }
  
  
  
  // 네이버 (NAVER)
	const NAVER_CLIENT_ID = "74ot6yROEbINPB6pg7E4" // 발급 받은 Client ID 입력 
	const NAVER_CALLBACK_URL = "http://localhost:4000/auth/naver" // 작성했던 Callback URL 입력
  // REACT_APP_NAVER_CLIENT_ID="74ot6yROEbINPB6pg7E4" 
  // REACT_APP_NAVER_CALLBACK_URL="http://localhost:4000/auth/naver"
  // 네이버 로그인 후 안내창 표출

  // 구글 (Google)
  const GOOGLE_CLIENT_ID = "596412713335-b0g23ll80n4ps3mgo0j1decb5tgo46c7.apps.googleusercontent.com";
  const GOOGLE_REDIRECT_URI = "http://localhost";
  // props.userProfile.id_type_cd===4 추가
  // useEffect(() => {
  //   // console.log(props.isLoggedIn)
  //   // console.log(props.userProfile)
  //   // console.log(props.userProfile.user_nm)
  //   // console.log(props.userProfile.phone_no)
  //   // console.log(props.userProfile.user_ty)
  //   // console.log(props.userProfile.id_type_cd)
  //   if (props.isLoggedIn && props.userProfile) {
  //     if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
  //       if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
  //         setShowChoosePopup(true);
  //         setPopupMsg('부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //       }
  //       // setShowChoosePopup(true);
  //       // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     } else if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
  //       setUserInfoState('required');
  //       setShowPopup(true);
  //       setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     } else  {
  //       navigate("/mydropia");
  //     }
  //     // if (props.userProfile.user_nm === null || props.userProfile.phone_no === null || props.userProfile.user_ty === null || props.userProfile.user_nm === undefined || props.userProfile.phone_no === undefined || props.userProfile.user_ty === undefined) {
  //     //   setUserInfoState('required');
  //     //   setShowPopup(true);
  //     //   setPopupMsg('필수 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     // } else if (props.userProfile.user_nm && props.userProfile.phone_no && props.userProfile.user_ty ) {
  //     //   if (props.userProfile.intro === '' || !props.userProfile.prj_ty_cd_arr || props.userProfile.prj_ty_cd_arr.length === 0) {
  //     //     setShowChoosePopup(true);
  //     //     setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     //   }
  //     //   // setShowChoosePopup(true);
  //     //   // setPopupMsg('!!!부가 정보가 입력되지 않았습니다.<br>입력하시겠습니까?');
  //     // } else {
  //     //   navigate("/mydropia");
  //     // }
  //   }
  // }, [props.isLoggedIn, props.userProfile])

  // CapsLock 키 활성화 여부
  const [capsLockFlag, setCapsLockFlag] = useState(false);
  const checkCapsLock = (e) => {
    let capsLock = e.getModifierState("CapsLock");
    setCapsLockFlag(capsLock);
  };
  const [capsLockFlagCheck, setCapsLockFlagCheck] = useState(false);
  const checkCapsLockCheck = (e) => {
    let capsLock = e.getModifierState("CapsLock");
    setCapsLockFlagCheck(capsLock);
  };

  const brCount = (popupMsg.match(/<br>/g) || []).length;
  const paddingValue = brCount === 0 ? '35px 0' : brCount === 1 ? '25px 0' : '15px 0';
  const fontSizeValue = brCount === 0 ? '18px' : '16px';

  return (
    <React.Fragment>
      <div className='flex-center contents-col Login-container sign-up'>
        <label className='login-text'>회원가입</label>
        <input 
          className='login email'
          type="text"
          placeholder='이메일'
          onChange={handleUserEmail}
          value={userEmail || ''}
        ></input>
        <input 
          className='login email'
          type="text"
          placeholder='이름'
          onChange={handleUserNm}
          value={userNm || ''}
        ></input>
        <input 
          className='login email'
          type="text"
          maxLength="13" 
          placeholder='전화번호'
          onChange={handleUserTelNum}
          value={userTelNum || ''}
        ></input>
        <div className="input-container checkpw-box">
          <input 
            className='login pw'
            type={showPw  ? "text" : "password"}
            // value={pw} 
            // onChange={handlePw}
            value={userPw || ''} 
            onChange={handleUserPw}
            placeholder='비밀번호'
            onKeyUp={(e) => checkCapsLock(e)}
          ></input>
          {/* <input 
            className='login pw'
            type={showPw  ? "text" : "password"}
            value={pw}
            onChange={handlePw}
            placeholder='비밀번호'
          ></input> */}
          <i className={showPw ? "fa-solid fa-eye showPwIcon" : "fa-solid fa-eye-slash showPwIcon"} onClick={toggleShowPw}></i>
          {/* <p className={`contents-row sb sign-up-center ${capsLockMsg? 'passwordmatching' : 'passwordunmatching'}`}>{capsLockMsg}</p> */}
          {/* <p className={`contents-row sb sign-up-center ${capsLockFlag? 'capsLockFlag' : ''}`}>{capsLockFlag ? "Caps Lock이 켜져 있습니다" : ""}</p> */}
        </div>
        <p className={`contents-row sb sign-up-center ${capsLockFlag? 'sub-msg' : ''}`}>{capsLockFlag ? "Caps Lock이 켜져 있습니다" : ""}</p>
        <div className="input-container checkpw-box">
          <input 
            className='login pw'
            type={showCheckPw  ? "text" : "password"}
            // value={pw} 
            // onChange={handlePw}
            value={checkUserPw || ''} 
            onChange={handleCheckUserPw}
            placeholder='비밀번호 확인'
            onKeyUp={(e) => checkCapsLockCheck(e)}
          ></input>
          {/* <input
            className='login pw'
            type={showCheckPw  ? "text" : "password"}
            value={checkPw}
            onChange={handleCheckPw}
            placeholder='비밀번호 확인'
          ></input> */}
          <i className={showCheckPw ? "fa-solid fa-eye showPwIcon" : "fa-solid fa-eye-slash showPwIcon"} onClick={toggleShowCheckPw}></i>
        </div>
        <p className={`contents-row sb sign-up-center ${capsLockFlagCheck? 'sub-msg' : ''}`}>{capsLockFlagCheck ? "Caps Lock이 켜져 있습니다" : ""}</p>
        <p className={`contents-row sb sign-up-center ${pwMatching? 'sub-msg pw' : ''}`}>{pwMsg}</p>
        <div className='contents-row full-width choose-user-type'>
          <div
            className={`contents-col flex-center border spacer user-type-box ${selectedUserType === 1 ? 'selected' : ''}`}
            onClick={() => handleSelection(1)}
          >
            <span>파트너</span>
            <p className='user-type-info'>드론 촬영 용역을 수행하는 사용자</p>
          </div>
          <div
            className={`contents-col flex-center border spacer user-type-box ${selectedUserType === 2 ? 'selected' : ''}`}
            onClick={() => handleSelection(2)}
          >
            <span>클라이언트</span>
            <p className='user-type-info'>드론 촬영 용역을 의뢰하는 사용자</p>
          </div>
        </div>
        <p className='contents-row sb sign-up-center'><span className='signup'>이용약관</span>&nbsp;및&nbsp;<span className='signup'>개인정보&nbsp;처리방침</span>에&nbsp;동의합니다.</p>
        <div className='gc-btn login-btn' onClick={postSignUp}>회원가입</div>
        <div className='hr-solid'/>
        {/* <label>SNS 계정으로 회원가입</label> */}
        {/* <div className='flex-center sns-login kakao' onClick={handleLogin}> */}
        {/* <div className='flex-center sns-login kakao' onClick={() => handleLoginClick(3)}>
          <div className='symbol kakao-symbol'></div>
          <span className='sns-text'>카카오톡 회원가입</span>
        </div> */}
        {/* <div className='flex-center sns-login naver' onClick={() => handleLoginClick(2)}>
          <div className='symbol naver-symbol'></div>
          <span className='sns-text'>네이버 회원가입</span>
        </div> */}
        {/* <div className='flex-center sns-login naver' onClick={() => handleLoginClick(2)}> */}
          {/* <div className='symbol naver-symbol'></div>
          <span className='sns-text'>네이버 회원가입</span> */}

        {/* <div>
          <div id="naverIdLogin"></div>
        </div> */}


      {/* {user && user ? (
        <div>
          <h2>네이버 로그인 성공!</h2>
          <h3>사용자 이름</h3>
          <div>{user.name}</div>
          <h3>사용자 이메일</h3>
          <div>{user.email}</div>
          <h3>사용자 프로필사진</h3>
          <img src={user.profile_image} alt="프로필 사진" />
          <button onClick={naverLogout}>로그아웃</button>
        </div>
      ) : (
        // 네이버 로그인 버튼
        <div>
          <div ref={naverButtonRef} id="naverIdLogin"></div>
        </div>
      )} */}

        {/* <NaverLogin 
          clientId={NAVER_CLIENT_ID} 
          callbackUrl={NAVER_CALLBACK_URL}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        />
        <KakaoLogin 
          // clientId={NAVER_CLIENT_ID} 
          // callbackUrl={NAVER_CALLBACK_URL}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        />
        <GoogleLogin 
          clientId={GOOGLE_CLIENT_ID} 
          callbackUrl={GOOGLE_REDIRECT_URI}
          isLoggedIn={props.isLoggedIn}
          userProfile={props.userProfile}
          setIsLoggedIn={props.setIsLoggedIn}
          setUserInfoState={setUserInfoState}
          // onSuccess={handleSuccess} 
          // onFailure={handleFailure} 
        /> */}
        <p>이미&nbsp;<span className='dropia'>Dropia</span>회원 이신가요?<span >&emsp;<Link to={'/login'} className='signup'>로그인</Link></span></p>
      </div>
      
      <Popup
          show={showPopup}
          header=""
      >
          <div className='contents-col full-width flex-center popup-info'>
          {/* <p>{popupMsg}</p> */}
          <p
            style={{
                fontSize: fontSizeValue,
                // padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
                  setShowPopup(false);
              }}  
          >닫기</div>
          </div>
      </Popup>

      <Popup
        show={showChoosePopup}
        header=""
      >
        <div className='contents-col full-width flex-center popup-info'>
          {/* <p>{popupMsg}</p> */}
          <p
            style={{
                // fontSize: fontSizeValue,
                // padding: paddingValue,
            }}
            dangerouslySetInnerHTML={{ __html: popupMsg }}
          ></p>
          <div className='contents-row flex-center'>
            <div 
              className="contents-row gc-btn flex-center close_btn no" 
              onClick={() => {
                setShowChoosePopup(false);
                navigate("/mydropia");
              }}  
            >
              아니요
            </div>
            <div 
              className="contents-row gc-btn flex-center gc-btn close_btn yes" 
              onClick={() => {
                setShowChoosePopup(false);
                navigate("/profile");
                // console.log('네이버')
              }}  
            >
              네
            </div>
          </div>
        </div>
      </Popup>
</React.Fragment>
  );
}

export default Signup;
