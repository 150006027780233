import './App.css';

import React, { useState, useCallback, useEffect } from 'react';
import {BrowserRouter as Router, Route, useNavigate, Routes, Navigate} from 'react-router-dom';

import GlobalStyle from './shared/styles/GlobalStyle.js';       // Global Style
import {AuthContext} from './shared/context/auth-context.js'

import Home from './routes/Home/Home.js'                        // 메인화면
import Header from './shared/components/header/Header.js';      // Header
import Footer from './shared/components/footer/Footer.js'       // Footer (사용X)

import Login from './routes/User/Login.js';                     // 로그인
import Signup from './routes/User/Signup.js'                    // 회원가입
import Mydropia from './routes/Mydropia/Mydropia.js'            // MyDropia
import Profile from './routes/Profile/Profile.js';              // 프로필
import Manager from './routes/Manager/Manager.js'               // 매니저 페이지

import Project from './routes/Project/Project.js'               // 프로젝트 화면
import ProjectDetail from './routes/Project/ProjectDetail.js'   // 프로젝트 상세보기
import ProjectReg from './routes/Project/ProjectReg.js'         // 프로젝트 등록

import Partner from './routes/Partner/Partner.js';              // 파트너 화면
import Helpdesk from './routes/Helpdesk/Helpdesk.js';           // 이용방법
import Store from './routes/Store/Store.js';                    // 스토어

import Map from './shared/util/Map.js'                          // 산출물 조회

import ReceivedMsg from './routes/Manager/ReceivedMsg';

// SNS 로그인
import NaverCallback from './routes/User/NaverCallback';
// import KakaoCallback from './routes/User/KakaoCallback';
// import GoogleCallback from './routes/User/GoogleCallback';

import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

// AXIOS
import axios from "axios";
import PartnerProfile from './routes/Partner/PartnerProfile.js';

import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import snsLoginConfig from './config/snsLoginConfig';

function App() {
  
  const GOOGLE_CLIENT_ID = "596412713335-avi2skn8i088qd8a9a02rhl42s3jtsve.apps.googleusercontent.com";

  // LocalStorage
  const APIToken = localStorage.getItem('login-token');
  const loginUserId = localStorage.getItem('user-id');
  const userType = localStorage.getItem('user-ty');

  // 로그인 상태
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // 로그인 상태 유지
  const [autoLoginCheckbox, setAutoLoginCheckbox] = useState(false);
  // 프로필 정보
  const [userProfile, setUserProfile] = useState({});
  
  // ??
  const [selectedFile, setSelectedFile] = useState(null);
  // ??
  const [imageUrl, setImageUrl] = useState('');
  // 서브메뉴
  const [activeSubmenu, setActiveSubmenu] = useState(false);
  // 앱정보
  const [appInfo, setAppInfo] = useState();

  const handleAutoLoginCheckbox = () => {
    setAutoLoginCheckbox(!autoLoginCheckbox);
  };

  // 로그인
  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  // 로그아웃
  const logout = useCallback(() => {

    setIsLoggedIn(false);

    setUserProfile({})

    localStorage.removeItem("login-token");         // 로그인 토큰
    localStorage.removeItem("user-name");           // 사용자 이름
    localStorage.removeItem("user-level");          // 사용자 레벨
    localStorage.removeItem("user-id");             // 사용자 ID
    localStorage.removeItem('user-ty');             // 사용자 유형 1:파트너 2:클라이언트 3:매니저
    localStorage.removeItem('user-email');          // 사용자 E-mail
    localStorage.removeItem('user-photoUrl');       // 사용자 프로필사진
    localStorage.removeItem('user-coTy');           // 사용자 업체유형 1:개인 2:개인사업자 3:법인사업자

    // localStorage.setItem('auto-login', 'false');    // 자동로그인 체크여부

    
  }, []);

  // 새로고침, 윈도우 창 닫았을때 
  // unload 이벤트: 윈도우 창이 완전히 닫힐 때 unload 이벤트가 발생합니다. 하지만 이 이벤트도 일부 브라우저에서는 새로고침 시에도 발생할 수 있습니다.
  // 브라우저 차이: 각 브라우저는 윈도우 창을 닫을 때와 새로고침할 때의 이벤트 발생 시점을 다르게 처리할 수 있습니다. 예를 들어, Chrome과 Firefox는 일부 상황에서 unload 이벤트를 새로고침 시에도 발생시키는 경향이 있습니다
  // window.addEventListener('beforeunload', 콜백함수); : 웹 브러우저 윈도우 창 종료 직전 이벤트
  // window.addEventListener('unload', 콜백함수); : 웹 브라우저 윈도우 창 종료 이벤트
  // useEffect(()=>{
  //   if(localStorage.getItem('auto-login') == 'false') {
  //     window.addEventListener('unload', localStorage.removeItem('login-token'));
  //     window.addEventListener('unload', localStorage.removeItem('user-name'));
  //     window.addEventListener('unload', localStorage.removeItem('user-level'));
  //     window.addEventListener('unload', localStorage.removeItem('user-id'));
  //     window.addEventListener('unload', localStorage.removeItem('user-ty'));
  //     window.addEventListener('unload', localStorage.removeItem('user-email'));
  //     window.addEventListener('unload', localStorage.removeItem('user-photoUrl'));
  //     window.addEventListener('unload', localStorage.removeItem('user-coTy'));
  //   }
  // },[])
  
  // 새로고침, 윈도우 창 닫았을때 
  const [isRefresh, setIsRefresh] = useState(false);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setIsRefresh(false);
      }
    };

    const handleBeforeUnload = (event) => {
      if (isRefresh) {
        setIsRefresh(false);
      } else if (sessionStorage.getItem('auto-login') === 'false') {
        sessionStorage.removeItem('login-token');
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRefresh]);

  const handleKeyDown = (event) => {
    if ((event.key === 'F5') || (event.ctrlKey && event.key === 'r')) {
      setIsRefresh(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if ((event.key === 'F5') || (event.ctrlKey && event.key === 'r')) {
  //       setIsRefresh(true);
  //     }
  //   };

  //   const handleBeforeUnload = (event) => {
  //     if (isRefresh) {
  //       // 새로고침일 때는 플래그를 초기화
  //       setIsRefresh(false);
  //     } else if (localStorage.getItem('auto-login') === 'false') {
  //       // 브라우저가 완전히 닫힐 때만 실행
  //       localStorage.removeItem('login-token');
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [isRefresh]);

  
  useEffect(()=>{
    if (localStorage.getItem('login-token')) {
      login();
    }
    else {
      logout();
    }
    
    if(localStorage.getItem('auto-login') == 'true') {
      setAutoLoginCheckbox(true);
    }
    else if(localStorage.getItem('auto-login') == 'false') {
      setAutoLoginCheckbox(false);
    }

    if(new Date().getTime() > localStorage.getItem('login-time')) {
      logout();
    }
  },[])

  useEffect(() => {
    axios({
      method:'get',
      url:`/api/util/appinfo`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
    },
    })
    .then((result)=>{
      if (result.data.success) {
        setAppInfo(result.data.data);
      } 
    })
    .catch((error)=>{
      console.log(error)  
    })
  }, [])

  useEffect(() => {
    if (loginUserId) {
      axios({
        method:'get',
        url:`/api/account/profile?userId=${loginUserId}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
      })
      .then((result)=>{
        // console.log(result.data);
        if (result.data.success) {
          setUserProfile(result.data.data);
        } 
        else {

        }
      })
      .catch((error)=>{
        console.log(error)  
      })
    }
  }, [loginUserId])

  // Props
  const loginProps = {
    isLoggedIn, 
    setIsLoggedIn,
    handleAutoLoginCheckbox,
    autoLoginCheckbox,
    activeSubmenu,
    setActiveSubmenu,
    userProfile,
  };

  const profileProps = {
    logout,
    userProfile,
    setUserProfile,
    imageUrl,
    setImageUrl,
    selectedFile,
    setSelectedFile,
    activeSubmenu,
    setActiveSubmenu,

    logout,
    setIsLoggedIn,
  };

  let routes;

  if (isLoggedIn || APIToken) {
    routes = (
      <Routes>
        <Route path="/" element={<Home {...loginProps}></Home>}/>                                                                       
        <Route path="/login" element={<Login {...loginProps}></Login>}/>
        <Route path="/signup" element={<Signup {...loginProps}></Signup>}/>
        <Route path="/mydropia" element={<Mydropia {...profileProps}></Mydropia>}/>
        <Route path="/profile" element={<Profile {...profileProps}></Profile>}/>
        <Route path="/manager" element={<Manager userProfile={userProfile}></Manager>}/>

        <Route path="/project" element={<Project {...loginProps}></Project>}/>
        <Route path="/project/:id" element={<ProjectDetail {...loginProps}></ProjectDetail>}/>
        <Route path="/projectreg" element={<ProjectReg {...loginProps}></ProjectReg>}/>

        <Route path="/partner" element={<Partner {...loginProps}></Partner>}/>
        <Route path="/partner/:id" element={<PartnerProfile {...loginProps}></PartnerProfile>}/>
        <Route path="/helpdesk" element={<Helpdesk {...loginProps}></Helpdesk>}/>
        <Route path="/store" element={<Store {...loginProps}></Store>}/>

        <Route path="/map" element={<Map {...loginProps}></Map>}/>
        <Route path="/message" element={<ReceivedMsg ></ReceivedMsg>}/>
      </Routes>
    );
  }
  else {
    routes = (
      <Routes>
        <Route path="/" element={<Home {...loginProps}></Home>}/>
        <Route path="/login" element={<Login {...loginProps}></Login>}/>
        <Route path="/signup" element={<Signup {...loginProps}></Signup>}/>
        <Route path="/project" element={<Project {...loginProps}></Project>}/>
        <Route path="/partner" element={<Partner {...loginProps}></Partner>}/>
        <Route path="/map" element={<Map {...loginProps}></Map>}/>

        {/* 로그인 안되어있을때 로그인 화면으로 */}
        <Route path="/mydropia" element={<Navigate to="/login" replace/>}/>
        <Route path="/profile" element={<Navigate to="/login" replace />}/>
        <Route path="/manager" element={<Navigate to="/login" replace/>}/>
        <Route path="/message" element={<Navigate to="/login" replace/>}/>
        
        {/* SNS 로그인 */}
        {/* <Route path="/auth/kakao" element={<KakaoCallback />} /> */}
        <Route path="/auth/naver" element={<NaverCallback />} />
        {/* <Route path="/auth/google" element={<GoogleCallback />} /> */}
      </Routes>
    );
  }

  library.add(fas, far, faTwitter, faFontAwesome);
  
  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <GlobalStyle/>                                                 
      <Router>
        <Header                                                    
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          logout={logout}
          userProfile={userProfile} 
          activeSubmenu={activeSubmenu}
          setActiveSubmenu={setActiveSubmenu}
          appInfo={appInfo}
        ></Header>
        <main                                                      
          onClick={(e)=>{
            if(activeSubmenu){setActiveSubmenu(false);}
          }}
        >
          <GoogleOAuthProvider 
            clientId={snsLoginConfig.GOOGLE_CLIENT_ID}>
            {routes}                                                
          </GoogleOAuthProvider>
        </main>
        {/* <Footer></Footer> */}
      </Router>
    </AuthContext.Provider>  
  );
}

export default App;
