import './Review.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Router, Routes, Route, Link, NavLink, useNavigate } from 'react-router-dom'
import { Select, Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// AXIOS
import axios from "axios";

function Review( { portfolioList, reviewList, reviewRankList } ) {

    const APIToken = localStorage.getItem("login-token");
    
    const loginUserId = localStorage.getItem('user-id');
    // const loginUserTyState = localStorage.getItem('user-ty-state');

    const inputRef = useRef();

    const navigate = useNavigate();

    // 가로 막대바
    // professionalism, proactiveness, satisfaction, scheduleCompliance, communication, defectRepair
    // 전문성
    const [professionalism_Cnt, setProfessionalism] = useState(0);
    // 적극성
    const [proactiveness_Cnt, setProactiveness] = useState(5.00);
    // 만족도
    const [satisfaction_Cnt, setSatisfaction] = useState(3.52);
    // 일정준수
    const [scheduleCompliance_Cnt, setScheduleCompliance] = useState(4.15);
    // 의사소통
    const [communication_Cnt, setCommunication] = useState(4.60);
    // 하자보수
    const [defectRepair_Cnt, setDefectRepair] = useState(5.00);

    // 세로 막대바
    // 최고
    const [best_Cnt, setBestCnt] = useState(18);
    // 좋음
    const [good_Cnt, setGoodCnt] = useState(8);
    // 보통
    const [average_Cnt, setAverageCnt] = useState(12);
    // 별로
    const [notSoGood_Cnt, setNotSoGoodCnt] = useState(2);
    // 최악
    const [worst_Cnt, setWorstCnt] = useState(0);

    const columnCalculateBackgroundColor = (value) => {
        if (isNaN(value) || value === 0) {
            return '#D9D9D9';
        } else {
            const ratio = value / reviewList.total;
            return `linear-gradient(to top, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
        }
    };

    const rowCalculateBackgroundColor = (rowValue) => {
        const value = parseFloat(rowValue);
        if (isNaN(value) || value === 0) {
            return '#D9D9D9';
        } else {
            const ratio = value / 5;
            return `linear-gradient(to right, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
        }
    };

    // 
    // const handleChange = (e) => {
    //     let value = e.target.value;
    //     if (!isNaN(value) && value >= 0 && value <= 5) {
    //         setInputValue(value);
    //     }
    // };

    // const handleKeyDown = (e) => {
    //     // 사용자가 엔터 키를 눌렀을 때 값의 범위를 확인하고 업데이트합니다.
    //     if (e.key === 'Enter') {
    //         let value = parseFloat(inputRef.current.value);
    //         if (!isNaN(value) && value >= 0 && value <= 5) {
    //             setInputValue(value.toFixed(2));
    //         }
    //     }
    // };

    // const calculateBackgroundColor = () => {
    //     const value = parseFloat(inputValue);
    //     if (isNaN(value) || value === 0) {
    //         return '#D9D9D9';
    //     } else {
    //         const ratio = value / 5;
    //         return `linear-gradient(to right, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
    //     }
    // };

    // // 리뷰 입력
    // const [professionalism_Cnt, setProfessionalism] = useState(4.25);
    // const [proactiveness_Cnt, setProactiveness] = useState(5.00);
    // const [satisfaction_Cnt, setSatisfaction] = useState(3.52);
    // const [scheduleCompliance_Cnt, setScheduleCompliance] = useState(4.15);
    // const [communication_Cnt, setCommunication] = useState(4.60);
    // const [defectRepair_Cnt, setDefectRepair] = useState(5.00);
    const [inputValue, setInputValue] = useState('');

    // 전문성
    const [professionalism_Input, setProfessionalism_Input] = useState('');
    // 적극성
    const [proactiveness_Input, setProactiveness_Input] = useState('');
    // 만족도
    const [satisfaction_Input, setSatisfaction_Input] = useState('');
    // 일정준수
    const [scheduleCompliance_Input, setScheduleCompliance_Input] = useState('');
    // 의사소통
    const [communication_Input, setCommunication_Input] = useState('');
    // 하자보수
    const [defectRepair_Input, setDefectRepair_Input] = useState('');
    // 일반화된 함수
    const handleInputChange = (e, setInputValue) => {
        let value = e.target.value;
        if (!isNaN(value) && value >= 0 && value <= 5) {
            setInputValue(value);
        }
    };

    const handleInputKeyDown = (e, setInputValue) => {
        // 사용자가 엔터 키를 눌렀을 때 값의 범위를 확인하고 업데이트합니다.
        if (e.key === 'Enter') {
            let value = parseFloat(e.target.value);
            // let value = e.target.value;
            if (!isNaN(value) && value >= 0 && value <= 5) {
                setInputValue(value.toFixed(2));
            }
        }
    };

    // 가로 막대기바
    const calculateBarBackgroundColor = (inputValue) => {
        const value = parseFloat(inputValue);
        if (isNaN(value) || value === 0) {
            return '#D9D9D9';
        } else {
            const ratio = value / 5;
            return `linear-gradient(to right, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
        }
    };
    
    // const [inputValue, setInputValue] = useState('');

    // const handleChange = (e, setInputValue) => {
    //     const value = e.target.value;
        
    //     // // 입력값이 유효한지 확인하고, 최대 5까지 입력값 제한
    //     // if (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) <= 5) {
    //     //     setInputValue(value);
    //     // } else if (value === '' || value === '.' || value === '0') {
    //     //     // 입력값이 없거나 '.' 또는 '0'인 경우에는 그대로 처리
    //     //     setInputValue(value);
    //     // }

    //     if (!isNaN(value) && value >= 0 && value <= 5) {
    //         setInputValue(value);
    //     }
    // };

    const filledWidth = inputValue ? `${(parseFloat(inputValue) / 5) * 100}%` : '0%';
    

    // const [inputs, setInputs] = useState([
    //     { id: 1, label: '전문성', value: '' },
    //     { id: 2, label: '적극성', value: '' },
    //     { id: 3, label: '만족도', value: '' },
    //     { id: 4, label: '일정준수', value: '' },
    //     { id: 5, label: '의사소통', value: '' },
    //     { id: 6, label: '하자보수', value: '' },
    // ]);
    
    // const handleChange = (e, id) => {
    //     const { value } = e.target;
    
    //     // 입력값이 유효한지 확인하고, 최대 5까지 입력값 제한
    //     if (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) <= 5) {
    //         setInputs(inputs.map(input => input.id === id ? { ...input, value } : input));
    //     } else if (value === '' || value === '.' || value === '0') {
    //         // 입력값이 없거나 '.' 또는 '0'인 경우에는 그대로 처리
    //         setInputs(inputs.map(input => input.id === id ? { ...input, value } : input));
    //     }
    
    //     // 각 항목별 데이터를 구분해서 콘솔에 출력
    //     console.log(inputs.map(input => `${input.label}: ${input.value}`).join(', '));
    // }
    
    // const getFilledWidth = (value) => {
    //     return value ? `${(parseFloat(value) / 5) * 100}%` : '0%';
    // };

    // useEffect(() => {
    //     // 모든 input의 value가 채워져 있는지 확인
    //     const allInputsFilled = inputs.every(input => input.value !== '' && input.value !== '.' && input.value !== '0');
    
    //     // 모든 input의 value가 채워져 있다면 평균 계산
    //     if (allInputsFilled) {
    //         const filledInputs = inputs.filter(input => input.value !== '' && input.value !== '.' && input.value !== '0');
    //         const sum = filledInputs.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.value), 0);
    //         const average = sum / filledInputs.length;
    //         console.log(average.toFixed(2)); // 평균값 출력
    //     }
    // }, [inputs]);

    // useEffect(() => {
    //     // 모든 input의 value가 채워져 있는지 확인
    //     const allInputsFilled = inputs.every(input => input.value !== '' && input.value !== '.' && input.value !== '0');
    
    //     // 모든 input의 value가 채워져 있다면 평균 계산
    //     if (allInputsFilled) {
    //         const filledInputs = inputs.filter(input => input.value !== '' && input.value !== '.' && input.value !== '0');
    //         const sum = filledInputs.reduce((accumulator, currentValue) => accumulator + (parseFloat(currentValue.value) / 5), 0); // 각 입력 값을 5로 나누어 최대값을 5로 설정
    //         const average = sum / filledInputs.length * 5; // 평균 계산 후 다시 5를 곱해 최대값을 되돌림
    //         console.log(average.toFixed(2)); // 평균값 출력
    //     }
    // }, [inputs]);
    // let average = 0;
    // const [starAverage, setStarAverage] = useState('0'); // 초기 너비를 '0px'로 설정
    // useEffect(() => {
    //     const allInputsFilled = inputs.every(input => input.value !== '' && input.value !== '.' && input.value !== '0');

    //     if (allInputsFilled) {
    //         const filledInputs = inputs.filter(input => input.value !== '' && input.value !== '.' && input.value !== '0');
    //         const sum = filledInputs.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.value), 0);
    //         average = sum / filledInputs.length;
    //         // const average = sum / filledInputs.length;
    //         // console.log(average.toFixed(2));
    //         setStarAverage(average.toFixed(2));
    //     }
    // }, [inputs]);

    // console.log((Number(starAverage)));

    // let roundedAverage;
    // if (!isNaN(average) && average !== null && average !== undefined) {
    //     roundedAverage = average.toFixed(2);
    // } else {
    //     // roundedAverage = 2.5; // Default value
    // }
    // 색별
    // const fillWidth = (2.3/5*90) + 'px'; // Calculate width
    // const fillWidth = (4/5)*100+'%'; // Calculate width
    // const width = (((2.5) / 5) * 90) + 'px';
    // const width = (((Number(starWidth)) / 5) * 100) + 'px';
    // const width = (((3.5) / 5) * 101.25) + 'px';
    // const width = `${(Number(starWidth) / 5) * 100}%`;
    // const width = ((Number(starAverage) / 5) * 101.25) + 'px';
    // const fillStyle = {
    //     position: 'absolute',
    //     // width: fillWidth,
    //     width: width,
    //     overflow: 'hidden',
    //     // whiteSpace: 'nowrap',
    //     // color: 'blue'
    // };
    // // 라인별
    // const lineStyle = {
    //     position: 'relative',
    //     width: '101.25px',
    //     // width: '90px',
    //     overflow: 'hidden',
    //     whiteSpace: 'nowrap',
    //     // color: 'blue'
    // };

    const inputs = [professionalism_Input, proactiveness_Input, satisfaction_Input, scheduleCompliance_Input, communication_Input, defectRepair_Input];
    const total = inputs.reduce((acc, val) => acc + parseFloat(val || 0), 0);
    const average = total / inputs.length;
   
    const width = ((average / 5) * 101.25) + 'px';
    const fillStyle = {
        position: 'absolute',
        // width: width,
        overflow: 'hidden',
    };

    const lineStyle = {
        position: 'relative',
        width: '101.25px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    };
    
    // 리뷰 목록 조회
    // 내가 받은 리뷰 (toUserId = 로그인 유저) 
    // const [reviewList, seReviewList] = useState(null);
    // const [myReviewList, seMyReviewList] = useState([]);
    // useEffect (() => {
    //     axios({
    //         method:'get',
    //         url:`/api/project/review/list?toUserId=1`,
    //         // url:`/api/project/review/list?fromUserId=${loginUserId}`,
    //         headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //     })
    //     .then((result)=>{
    //         if(result.data.success === true) {
    //             // console.log(result.data);
    //             seReviewList(result.data);
    //             // seMyReviewList(result.data.data);
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }, [])
    
    // const [reviewRankList, setReviewRankList] = useState({});
    // useEffect(() => {
    //     if (reviewList !== null) {
    //         if (reviewList.data) {
    //             seMyReviewList(reviewList.data);
    //         }
    //         if (reviewList.rank) {
    //             setReviewRankList(reviewList.rank);
    //         }
    //     }
    // }, [reviewList])

    // React를 이용하여 두 배열에서 일치하는 프로젝트 ID를 가진 객체를 추출하여 새로운 형태로 저장하는 함수
    function combineMatchingObjects(portfolioList, myReviewList) {
        // 일치하는 객체를 저장할 배열
        const combinedObjects = [];

        // portfolioList를 기준으로 순회하며 일치하는 프로젝트 ID를 가진 객체들을 찾음
        for (const portfolioItem of portfolioList) {
            // myReviewList에서 동일한 프로젝트 ID를 가진 객체 찾기
            const matchingReviewItem = myReviewList && myReviewList.find(reviewItem => reviewItem.prj_id === portfolioItem.prj_id);

            // 일치하는 reviewItem이 있다면 쌍을 만들어서 배열에 추가
            if (matchingReviewItem) {
                combinedObjects.push({ portfolioItem, reviewItem: matchingReviewItem });
            }
        }

        // 합쳐진 객체 배열 반환
        return combinedObjects;
    }

    // 함수 호출하여 일치하는 객체들 합치기
    // const combinedObjects = combineMatchingObjects(portfolioList, myReviewList);

    // 최근 파트너 리뷰
    // const [recentPartnerReviews, setRecentPartnerReviews] = useState(null);
    // const [recentClientReviews, setRecentClientReviews] = useState(null);
    // useEffect (() => {
    //     axios({
    //         method:'get',
    //         url:`/api/project/review/list?toUserId=1`,
    //         // url:`/api/project/review/list?toUserId=${loginUserId}`,
    //         headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //     })
    //     .then((result)=>{
    //         if(result.data.success === true && loginUserTyState === '1') {
    //             // console.log(result.data.data);
    //             setRecentClientReviews(result.data.data);
    //             // setRecentPartnerReviews(result.data.data);
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }, [loginUserTyState])
    
    // useEffect (() => {
    //     axios({
    //         method:'get',
    //         url:`/api/project/review/list?toUserId=1`,
    //         // url:`/api/project/review/list?toUserId=${loginUserId}`,
    //         headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //     })
    //     .then((result)=>{
    //         if(result.data.success === true) {
    //             // console.log(result.data.data);
    //             setRecentPartnerReviews(result.data.data);
    //             // setRecentPartnerReviews(result.data.data);
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }, [])

    // 최근 클라이언트 리뷰 (fromUserId = 로그인 유저) 
    const [recentReviews, setRecentReviews] = useState(null);
    // const [recentClientReviews, setRecentClientReviews] = useState(null);
    // useEffect (() => {
    //     axios({
    //         method:'get',
    //         url:`/api/project/review/list?fromUserId=1`,
    //         // url:`/api/project/review/list?fromUserId=${loginUserId}`,
    //         headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //     })
    //     .then((result)=>{
    //         if(result.data.success === true && loginUserTyState === '2') {
    //             // console.log(result.data.data)
    //             setRecentClientReviews(result.data.data);
    //         }
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    // }, [loginUserTyState])

    useEffect (() => {
        axios({
            method:'get',
            // url:`/api/project/review/list?fromUserId=1`,
            url:`/api/project/review/list?fromUserId=${loginUserId}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            if(result.data.success === true) {
                // console.log(result.data.data);
                // setRecentClientReviews(result.data.data);
                setRecentReviews(result.data.data);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }, [])

    const [myReviewList, setMyReviewList] = useState([]);
    useEffect(() => {
        if (reviewList !== null) {
            if (reviewList.data) {
                setMyReviewList(reviewList.data);
            }
        }
    }, [reviewList])

    // const [recentReviews, setRecentReviews] = useState(loginUserTyState === '1' ? recentPartnerReviews : loginUserTyState === '2' ? recentClientReviews : null);
    // useEffect (() => {
    //     console.log(loginUserTyState)
    //     if (loginUserTyState === '1') {
    //         setRecentReviews(recentPartnerReviews);
    //     } else if (loginUserTyState === '2') {
    //         setRecentReviews(recentClientReviews);
    //     }
    // }, [loginUserTyState, recentPartnerReviews, recentClientReviews])

    // console.log(reviewList);
    // console.log(reviewList.avgAvr);
    return (
        <React.Fragment>
            {reviewList && (
            <div className='contents-row full-width'>
                <div className='contents-col rating-container'>
                    <div className='contents-row flex-center portfolio-menubar rating-box'>
                        <div className='main-text'>내가 받은 리뷰 요약</div>
                        <div className='contents-row flex-center'>
                            <div className='contents-row '>
                                <div className='contents-row star-rating-box' style={lineStyle}>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                                <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewList.data.length === 0 ? 0 : reviewList.avgAvr / 5) * 101.25) + 'px' }}>
                                {/* <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewList.avgScore5 / 5) * 101.25) + 'px' }}> */}
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                            </div>
                            {/* <p>{reviewList.avgScore5} &nbsp;<span className='rating'>/5</span></p> */}
                            <p>{reviewList.data.length === 0 ? '0' : reviewList.avgAvr} &nbsp;<span className='rating'>/5</span></p>
                            {/* <p>{reviewList.avgAvr} &nbsp;<span className='rating'>/5</span></p> */}
                            <p className='peoplee-num'>{reviewList.data.length === 0 ? '0' : reviewList.total}명 평가</p>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>전문성</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore1), position: 'relative' }}></div>
                            <label>{reviewList.avgScore1}</label>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>적극성</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore2), position: 'relative' }}></div>
                            <label>{reviewList.avgScore2}</label>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>만족도</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore3), position: 'relative' }}></div>
                            <label>{reviewList.avgScore3}</label>
                        </div>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>일정준수</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore4), position: 'relative' }}></div>
                            <label>{reviewList.avgScore4}</label>
                        </div>
                    </div>
                    <div className='contents-row full-width rating-factors-box'>
                        <div className='contents-row full-width flex-center rating-factors'>
                            <label>의사소통</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore5), position: 'relative' }}></div>
                            <label>{reviewList.avgScore5}</label>
                        </div>
                        <div className='contents-row full-width lex-center rating-factors'>
                            {/* <label>하자보수</label>
                            <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(defectRepair_Cnt), position: 'relative' }}></div>
                            <label>{defectRepair_Cnt.toFixed(2)}</label> */}
                        </div>
                    </div>
                </div>
                {/* <div className='contents-row full-width flex-center spacer'>
                    
                </div> */}
                <div className='contents-row full-width flex-center rating-container overall-rating-box'>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_a}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_a), position: 'relative' }}></div>
                        <span>최고</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_b}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_b), position: 'relative' }}></div>
                        <span>좋음</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_c}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_c), position: 'relative' }}></div>
                        <span>보통</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_d}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_d), position: 'relative' }}></div>
                        <span>별로</span>
                    </div>
                    <div className='contents-col flex-center overall-rating-factors'>
                        <span>{reviewRankList.rank_e}</span>
                        <div className='full-width bar-chart' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_e), position: 'relative' }}></div>
                        <span>최악</span>
                    </div>
                </div>
            </div>
            )}
            <div className='hr-solid'/>
            {/* <div className='main-text'>내가 받은 리뷰</div> */}
            <div className='contents-row full-width flex-center portfolio-menubar'>
                <div className='main-text'>내가 받은 리뷰</div>
                {/* <div className='view-all'>전체보기</div> */}
            </div>
            {myReviewList && myReviewList.length !== 0 ? 
            myReviewList.map((reviewItem, index) => (
            <div className='contents-col full-width flex-center project-progress req' key={index}>
                <div className='contents-row flex-center full-width rating-factors-box'>
                    <div className='contents-col flex-center spacer progress-box req review'>
                        <p>{reviewItem.prj_nm}</p>
                        <p>예상금액 {reviewItem.contrct_cost_tt} 만원 | 예상일정 {reviewItem.prj_start_de.substring(2)} ~ {reviewItem.prj_end_de.substring(2)}</p>
                    </div>
                    <div className='contents-row spacer progress-box req review'>
                        <div className='contents-col req-state'>
                            <div className='gc-btn info-btn video'>{reviewItem.prj_ty_nm}</div>
                            {/* <p className='contents-row flex-center'><FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e</p> */}
                        </div>
                        <div className='contents-col req-state'>
                            <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{reviewItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                            <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${reviewItem.prj_id}`)}}>상세보기</div>
                        </div>
                        {/* <div className='contents-row flex-center rating-box'>
                            <div className='contents-row star-rating-box'>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                            </div>
                            <p>3.56 &nbsp;<span className='rating'>/5</span></p>
                        </div> */}
                        <div className='contents-row flex-center rating-box'>
                            <div className='contents-row '>
                                <div className='contents-row star-rating-box' style={lineStyle}>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                                <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewItem.prj_review_score_avr / 5) * 101.25) + 'px' }}>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                            </div>
                            {/* <div className='contents-row star-rating-box'>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                            </div> */}
                            <p>{reviewItem.prj_review_score_avr} &nbsp;<span className='rating'>/5</span></p>
                            {/* <p>{starAverage} &nbsp;<span className='rating'>/5</span></p> */}
                        </div>
                    </div>
                </div>
                <div className='hr-solid'/>
                    <div className='contents-row full-width' key={reviewItem.prj_id}>
                        <div className='contents-col rating-container detail'>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>전문성</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewItem.prj_review_score_1), position: 'relative' }}></div>
                                    <label>{reviewItem.prj_review_score_1}</label>
                                    {/* <label>{professionalism_Cnt.toFixed(2)}</label> */}
                                    {/* <label>{professionalism_Cnt.toFixed(2)}</label> */}
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>적극성</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewItem.prj_review_score_2), position: 'relative' }}></div>
                                    <label>{reviewItem.prj_review_score_2}</label>
                                </div>
                            </div>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>사전준비</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewItem.prj_review_score_3), position: 'relative' }}></div>
                                    <label>{reviewItem.prj_review_score_3}</label>
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>일정준수</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewItem.prj_review_score_4), position: 'relative' }}></div>
                                    <label>{reviewItem.prj_review_score_4}</label>
                                </div>
                            </div>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>의사소통</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewItem.prj_review_score_5), position: 'relative' }}></div>
                                    <label>{reviewItem.prj_review_score_5}</label>
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    {/* <label>하자보수</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(defectRepair_Cnt), position: 'relative' }}></div>
                                    <label>{defectRepair_Cnt.toFixed(2)}</label> */}
                                </div>
                            </div>
                        </div>
                        <div className='contents-row full-width flex-center rating-container detail overall-rating-box text'>
                            {reviewItem.prj_review_text}
                        </div>
                    </div>
            </div>
            ))
            : <p className='result-info'>내가 받은 리뷰가 없습니다.</p>}
            {/* <div className='contents-col full-width flex-center project-progress req'>
                <div className='hr-solid'/>
                <div className='contents-row full-width'>
                <>
                <div className='contents-col rating-container detail'>
                    <div className='contents-row full-width rating-factors-box add'>
                        {inputs.map((input, index) => (
                            index % 2 === 0 && (
                                <div key={input.id} className='contents-row full-width flex-center rating-factors'>
                                    <div className='contents-row full-width flex-center rating-factors'>
                                        <label>{inputs[index].label}</label>
                                        <div className='full-width bar-chart' style={{ backgroundColor: '#D9D9D9', position: 'relative' }}>
                                            <div className='bar' style={{ backgroundColor: '#FBB01F', width: getFilledWidth(inputs[index].value), position: 'absolute', top: 0, left: 0, height: '100%' }}></div>
                                        </div>
                                        <input
                                            className='contents-row flex-center spacer border info-input rating-input'
                                            type='number'
                                            value={inputs[index].value}
                                            onChange={(e) => handleChange(e, inputs[index].id)}
                                        />
                                    </div>
                                    {inputs[index + 1] && (
                                        <div className='contents-row full-width flex-center rating-factors'>
                                            <label>{inputs[index + 1].label}</label>
                                            <div className='full-width bar-chart' style={{ backgroundColor: '#D9D9D9', position: 'relative' }}>
                                                <div className='bar' style={{ backgroundColor: '#FBB01F', width: getFilledWidth(inputs[index + 1].value), position: 'absolute', top: 0, left: 0, height: '100%' }}></div>
                                            </div>
                                            <input
                                                className='contents-row flex-center spacer border info-input rating-input'
                                                type='number'
                                                value={inputs[index + 1].value}
                                                onChange={(e) => handleChange(e, inputs[index + 1].id)}
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                    </div>
                </div>
                </>
                    <div className='contents-col rating-container detail'>
                        <div className='contents-row full-width rating-factors-box'>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>전문성</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(professionalism_Input), position: 'relative' }}>
                                </div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={professionalism_Input}
                                    onChange={(e) => handleInputChange(e,  setProfessionalism_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e,  setProfessionalism_Input)}
                                />
                            </div>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>적극성</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(proactiveness_Input), position: 'relative' }}></div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={proactiveness_Input}
                                    onChange={(e) => handleInputChange(e,  setProactiveness_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e,  setProactiveness_Input)}
                                />
                            </div>
                        </div>
                        <div className='contents-row full-width rating-factors-box'>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>만족도</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(satisfaction_Input), position: 'relative' }}></div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={satisfaction_Input}
                                    onChange={(e) => handleInputChange(e, setSatisfaction_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e, setSatisfaction_Input)}
                                />
                            </div>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>일정준수</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(scheduleCompliance_Input), position: 'relative' }}></div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={scheduleCompliance_Input}
                                    onChange={(e) => handleInputChange(e,  setScheduleCompliance_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e,  setScheduleCompliance_Input)}
                                />
                            </div>
                        </div>
                        <div className='contents-row full-width rating-factors-box'>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>의사소통</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(communication_Input), position: 'relative' }}></div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={communication_Input}
                                    onChange={(e) => handleInputChange(e,  setCommunication_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e,  setCommunication_Input)}
                                />
                            </div>
                            <div className='contents-row full-width flex-center rating-factors'>
                                <label>하자보수</label>
                                <div className='full-width bar-chart' style={{ background: calculateBarBackgroundColor(defectRepair_Input), position: 'relative' }}></div>
                                <input
                                    className='contents-row flex-center spacer border info-input rating-input'
                                    type='number'
                                    value={defectRepair_Input}
                                    onChange={(e) => handleInputChange(e,   setDefectRepair_Input)}
                                    onKeyDown={(e) => handleInputKeyDown(e,   setDefectRepair_Input)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='hr-solid'/>
            {/* <div className='main-text'>최근 클라이언트 리뷰</div> */}
            <div className='contents-row full-width flex-center portfolio-menubar'>
                {/* <div className='main-text'>최근 클라이언트 리뷰</div> */}
                <div className='main-text'>
                    내가 쓴 리뷰
                    {/* {loginUserTyState === '1'? 
                        '최근 파트너 리뷰'
                    :   
                        '최근 클라이언트 리뷰'
                    } */}
                </div>
                {/* <div className='view-all'>전체보기</div> */}
            </div>
            {recentReviews && recentReviews.length !== 0 ? 
            recentReviews.map((recentReviewItem, index) => {
                return (
                <div className='contents-col full-width flex-center project-progress req' key={index}>
                    <div className='contents-row flex-center full-width rating-factors-box'>
                        <div className='contents-col flex-center spacer progress-box req review'>
                            <p>{recentReviewItem.prj_nm}</p>
                            <p>예상금액 {recentReviewItem.contrct_cost_tt} 만원 | 예상일정 {recentReviewItem.prj_start_de.substring(2)} ~ {recentReviewItem.prj_end_de.substring(2)}</p>
                        </div>
                        <div className='contents-row spacer progress-box req review'>
                            <div className='contents-col req-state'>
                                <div className='gc-btn info-btn video'>{recentReviewItem.prj_ty_nm}</div>
                                {/* <p className='contents-row flex-center'>
                                    <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>e****e
                                </p> */}
                            </div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{recentReviewItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                                <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${recentReviewItem.prj_id}`)}}>상세보기</div>
                            </div>
                            <div className='contents-row flex-center rating-box'>
                            <div className='contents-row '>
                                <div className='contents-row star-rating-box' style={lineStyle}>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                                <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((recentReviewItem.prj_review_score_avr / 5) * 101.25) + 'px' }}>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                                </div>
                            </div>
                            {/* <div className='contents-row star-rating-box'>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                                <FontAwesomeIcon icon="fa-solid fa-star"/>
                            </div> */}
                            <p>{recentReviewItem.prj_review_score_avr} &nbsp;<span className='rating'>/5</span></p>
                            {/* <p>{starAverage} &nbsp;<span className='rating'>/5</span></p> */}
                        </div>
                        </div>
                    </div>
                    <div className='hr-solid'/>
                    <div className='contents-row full-width'>
                        <div className='contents-col rating-container detail'>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>전문성</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(recentReviewItem.prj_review_score_1), position: 'relative' }}></div>
                                    <label>{recentReviewItem.prj_review_score_1}</label>
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>적극성</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(recentReviewItem.prj_review_score_2), position: 'relative' }}></div>
                                    <label>{recentReviewItem.prj_review_score_2}</label>
                                </div>
                            </div>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>만족도</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(recentReviewItem.prj_review_score_3), position: 'relative' }}></div>
                                    <label>{recentReviewItem.prj_review_score_3}</label>
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>일정준수</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(recentReviewItem.prj_review_score_4), position: 'relative' }}></div>
                                    <label>{recentReviewItem.prj_review_score_4}</label>
                                </div>
                            </div>
                            <div className='contents-row full-width rating-factors-box'>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    <label>의사소통</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(recentReviewItem.prj_review_score_5), position: 'relative' }}></div>
                                    <label>{recentReviewItem.prj_review_score_5}</label>
                                </div>
                                <div className='contents-row full-width flex-center rating-factors'>
                                    {/* <label>하자보수</label>
                                    <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(defectRepair_Cnt), position: 'relative' }}></div>
                                    <label>{defectRepair_Cnt.toFixed(2)}</label> */}
                                </div>
                            </div>
                        </div>
                        <div className='contents-row full-width flex-center rating-container detail overall-rating-box text'>
                            {recentReviewItem.prj_review_text}
                        </div>
                    </div>
                </div>
                );
            })
            : <p className='result-info'>내가 쓴 리뷰가 없습니다.</p>}
        </React.Fragment>
    );
}

export default Review;