import './ProjectDetail.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate , useParams, useLocation } from 'react-router-dom';

import { Checkbox, Dropdown } from 'semantic-ui-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProposalDetail from './ProposalDetail';

import Popup from '../../shared/components/uiElements/Popup';

import moment from 'moment';

// AXIOS
import axios from "axios";

function ProjectDetail(props) {

  const location = useLocation();  
  const { project } = location.state || {};
  // console.log(project);
  // console.log(props);

  const APIToken = localStorage.getItem("login-token");
  const userID = localStorage.getItem('user-id');
  const userType = localStorage.getItem('user-ty');

  const navigate = useNavigate();

  const projectID = useParams();                                      // 선택 프로젝트 ID

  const [prjNm, setPrjNm] = useState('');                             // 1.프로젝트 이름
  const [prjTyCd, setPrjTyCd] = useState('');                         // 2.프로젝트 분류 코드
  const [prjTyCustom, setPrjTyCustom] = useState('');                 // 3.프로젝트 분류 직접입력
  const [exptdCost, setExptdCost] = useState('');                     // 4.예상금액 (만원)
  const [exptdDay, setExptdDay] = useState('');                       // 5.예상일정 (일)
  const [sidoCd, setSidoCd] = useState('');                           // 6.지역 (시도코드)
  const [applyDe, setApplyDe] = useState('');                         // 7.모집 마감일자
  const [exptdStarDe, setExptdStarDe] = useState('');                 // 8.예상 시작일자
  const [qcYn, setQcYn] = useState('');                               // 9.검수 포함여부
  const [prjDetail, setPrjDetail] = useState('');                     // 10.상세 내용
  const [prjAttchFiles, setPrjAttchFiles] = useState('');             // 11.프로젝트 첨부파일
  const [prjAttchNames, setPrjAttchNames] = useState('');             // 12.프로젝트 첨부파일명
  const [prjAttchDelArr, setPrjAttchDelArr] = useState('');           // 13.삭제할 첨부파일 인덱스
    
  const [abstractView, setAbstractView] = useState(true);             // 요약정보 보기&숨기기
  const [selectedMenu, setSelectedMenu] = useState('partner');        // 선택메뉴
  const [projectApply, setProjectApply] = useState(false);             // 지원하기 boolen
  const [projectModify, setProjectModify] = useState(false);          // 수정하기 boolen
    
  const [projectInfo, setProjectInfo] = useState(null);               // 프로젝트 정보
  const [projectOwnerInfo, setProjectOwnerInfo] = useState();         // 프로젝트 생성자 정보
  const [progressInfo, setProgressInfo] = useState();                 // 프로젝트 진행 정보
    
  const [proposalList, setProposalList] = useState(null);             // 지원서 목록
  const [selectProposal, setSelectProposal] = useState();             // 선택 지원서
  const [myProposal, setMyProposal] = useState(null);                 // 내 지원서
  const [portfolioList, setPortfolioList] = useState(null);           // 포트폴리오 목록
  const [portfolioMenu, setPortfolioMenu] = useState(false);          // 포트폴리오 선택메뉴

  const [proposalCost, setProposalCost] = useState('');               // 제안금액
  const [proposalDay, setProposalDay] = useState('');                 // 제안일정
  const [proposalDetail, setProposalDetail] = useState('');           // 제안내용
  const [proposalPrt, setProposalPrt] = useState([]);                 // 제안 포트폴리오 ID
  const [proposalPrtInfo, setProposalPrtInfo] = useState([]);         // 제안 포트폴리오 정보

  const [popupState, setPopupState] = useState();                     // 팝업 상태
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);  // 질문팝업 boolen
  const [showAlarmPopup, setShowAlarmPopup] = useState(false);        // 알림팝업 boolen
  const [popupMsg, setPopupMsg] = useState('');                       // 팝업메시지 
  const [showFileDelPopup, setShowFileDelPopup] = useState(false);

  const [paymentClient, setPaymentClient] = useState();               // 결제정보 Client
  const [paymentClientJson, setPaymentClientJson] = useState();       // 결제정보 Client JSON
  const [paymentPartner, setPaymentPartner] = useState();             // 지급정보 Partner
  const [paymentPartnerJson, setPaymentPartnerJson] = useState();     // 지급정보 Partner JSON
  
  const [inputContractMod, setInputContractMod] = useState(false);    // 계약내용 수정
  const [inputClientMod, setInputClientMod] = useState(false);        // 결제정보 수정
  const [inputPartnerMod, setInputPartnerMod] = useState(false);      // 지급정보 수정
  const [templateList, setTemplateList] = useState();                 // 템플릿 목록
  const [templateID, setTemplateID] = useState();                     // 템플릿 선택 ID

  const [contractInfo, setContractInfo] = useState();                 // 계약정보
  const [contractStartDate, setContractStartDate] = useState();       // (계약정보) 프로젝트 시작일
  const [contractEndDate, setContractEndDate] = useState();           // (계약정보) 프로젝트 종료일
  const [contractCost, setContractCost] = useState();                 // (계약정보) 계약금액
  const [contract, setContract] = useState(1);                        // 계약서 생성 모드 선택

  const [process, setProcess] = useState(1);                          // 프로젝트 진행상태
  const [processDate, setProcessDate] = useState();                   // 프로젝트 진행상태 일자
  const [processDateBool, setProcessDateBool] = useState(true);       // 프로젝트 진행상태 일자 포함여부
  // const [processFile, setProcessFile] = useState([]);                 // 프로젝트 진행 파일추가
  const [productOutputFile, setProductOutputFile] = useState();       // 세금계산서&지급명세서 업로드
  const [sidoArray, setSidoArray] = useState();                       // 시도 배열

  // 프로젝트 진행 상세 내용 (단계별)
  const [projectWritingDetails, setProjectWritingDetail] = useState('');
  // const [projectWritingDetails, setProjectWritingDetail] = useState(progressInfo && progressInfo.prj_prcs_detail || '');
  const [projectFiles, setProjectFiles] = useState([]);
  const handleWritingDetail = (e) => {
      e.preventDefault();
      setProjectWritingDetail(e.target.value);
  };
  const handleProjectFiles = (e) => {
    e.preventDefault();
  };

  // 지역 시도 코드 옵션
  const locationOptions = [
    {text: '서울특별시', value: 11},
    {text: '부산광역시', value: 26},
    {text: '대구광역시', value: 27},
    {text: '인천광역시', value: 28},
    {text: '광주광역시', value: 29},
    {text: '대전광역시', value: 30},
    {text: '울산광역시', value: 31},
    {text: '세종특별자치시', value: 36},
    {text: '경기도', value: 41},
    {text: '강원도', value: 42},
    {text: '충청북도', value: 43},
    {text: '충청남도', value: 44},
    {text: '전라북도', value: 45},
    {text: '전라남도', value: 46},
    {text: '경상북도', value: 47},
    {text: '경상남도', value: 48},
    {text: '제주특별자치도', value: 50},
  ]

  // 프로젝트 진행상태 옵션
  const processOption = [
    {text: '생성', value: 1},
    {text: '지원', value: 2},
    {text: '미팅', value: 3},
    {text: '계약', value: 4},
    {text: '진행', value: 5},
    {text: '검수', value: 6},
    {text: '완료', value: 7},
    {text: '실패', value: 100},
  ]

  // 프로젝트 유형 옵션
  const typeOptions = [
    {text: '일반 레포츠', value: 1},
    {text: '미디어 촬영', value: 2},
    {text: '시설점검', value: 3},
    {text: '공간정보', value: 4},
    {text: '농업,방제', value: 5},
    {text: '기타(직접입력)', value: 6},
  ]

  // 검수여부 체크 옵션
  const checkOptions = [
    {text: '예', value: 'true'},
    {text: '아니오', value: 'false'},
  ]

  // 초기값
  useEffect (() => {
    if (progressInfo) {
      console.log(progressInfo)
      setProjectWritingDetail(progressInfo.prj_prcs_detail);
      // console.log(progressInfo.prj_prcs_files_json);
      // console.log(progressInfo.prj_prcs_files_orgnm_json);
      // console.log(progressInfo.prj_prcs_files_url);
      if (progressInfo.prj_prcs_files_url === null) {
        setProcessFile([]);
        setProcessFileNames([]);
      } else {
        setProcessFile(progressInfo.prj_prcs_files_url);
        setSelectedFiles(progressInfo.prj_prcs_files_url);
        setProcessFileNames(progressInfo.prj_prcs_files_orgnm_json);
        console.log(progressInfo.prj_prcs_files_orgnm_json)
        setFileNames(progressInfo.prj_prcs_files_orgnm_json);
      }
    }
  }, [progressInfo])

  
  // 첨부 파일
  const [processFile, setProcessFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]); // 파일을 저장할 상태
  
  const [processFileNames, setProcessFileNames] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [deletedFileNames, setDeletedFileNames] = useState([]);

  const fileRef = useRef(null);

  // const onFileChangeHandler = async (e) => {
  //   let array = [...processFile];
  //   array.push(e.target.files[0]);
  //   setProcessFile(array);
  //   e.target.value = '';
  // }
  // const onFileChangeHandler = async (e) => {
  //   const files = Array.from(e.target.files);
  //   const fileNames = files.map(file => file.name);
    
  //   setProcessFile((prevFiles) => (Array.isArray(prevFiles) ? [...prevFiles, ...files] : [...files]));
  //   setProcessFileNames((prevFileNames) => (Array.isArray(prevFileNames) ? [...prevFileNames, ...fileNames] : [...fileNames]));

  //   e.target.value = ''; 
  // }
  const onFileChangeHandler = (e) => {
    const files = Array.from(e.target.files); // 파일 배열로 변환
    const newFileNames = files.map(file => file.name); // 파일명 추출

    // 이전 상태가 배열이 아닐 경우 빈 배열로 처리
    setSelectedFiles((prevFiles) => [...(Array.isArray(prevFiles) ? prevFiles : []), ...files]); // 파일 추가
    setFileNames((prevNames) => [...(Array.isArray(prevNames) ? prevNames : []), ...newFileNames]); // 파일명 추가
  };
  
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState([]);

  // const handleDeleteFileOk = (i) => {
  //   if (props.activeBbs === 'bbs-mod') {
  //       // setPopupMsg('업로드한 해당 파일을 삭제 하시겠습니까?');
  //       // setShowFileDelPopup(true);
  //       setFileToDeleteIndex(i);
  //   } else {
  //       setFileToDeleteIndex(i);
  //       handleDeleteFile();
  //   }
  // };
  // const handleDeleteFileOk = (index) => {
  //   setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // 파일 삭제
  //   setFileNames((prevNames) => prevNames.filter((_, i) => i !== index)); // 파일명 삭제
  // };
  const handleDeleteFileOk = (index) => {
    // 파일과 파일명을 삭제
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFileNames((prevNames) => prevNames.filter((_, i) => i !== index));
  
    // 삭제할 파일의 인덱스를 상태에 저장
    setFileToDeleteIndex((prevIndices) => [...(prevIndices || []), index]); // 배열로 확실히 처리
  };

  const handleDeleteFile = (index) => {
    setProcessFile((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.splice(index, 1);
        return updatedFiles;
    });

    setProcessFileNames((prevFileNames) => {
        // const updatedFileNames = [...prevFileNames];
        // updatedFileNames.splice(index, 1);
        // return updatedFileNames;
        const updatedFileNames = [...prevFileNames];
        const deletedFileName = updatedFileNames.splice(index, 1)[0];
        setDeletedFileNames((prevDeletedFileNames) => [...prevDeletedFileNames, deletedFileName]);
        return updatedFileNames;
    });
  };

  const handleDeleteAllFiles = () => {
    setProcessFile([]);
    setProcessFileNames([]);
  };

  // const handleFileAClear = () => {setProcessFile([]);}
  const handleFileAClear = () => {
    setSelectedFiles([]);
    setFileNames([]);
    setFileToDeleteIndex([]); // 삭제 인덱스 초기화
  };

  const productRef = useRef(null);

  const handleProductClick = () => {if(productRef.current){productRef.current.click();}};

  const onProductChangeHandler = async (e, num, type) => {
    if (!e.target.files || !e.target.files[0]) return;
    setProductOutputFile(
      {
        "file" : e.target.files[0],
        "num" : num,
        "type" : type
      }
    );
  }

  // 프로젝트 정보 불러오기
  useEffect (() => {
    getProjectInfo();
  } , [])

  function getProjectInfo () {
    if(projectID){

      // axios({
      //   method:'post',
      //   url:`/api/project/views`,
      //   headers: {
      //     'Authorization': `Bearer {${APIToken}}`
      //   },
      //   data: {prjId: projectID.id}
      // })
      // .then(()=>{
        axios({
          method:'get',
          url:`/api/project/info?prjId=${projectID.id}`,
          headers: {
            'Authorization': `Bearer {${APIToken}}`
          },
        })
        .then((result)=>{
          console.log(result.data);
          if(result.data.success == true){
            let project_info = result.data.data;
            setProjectInfo(project_info);
            setProcess(project_info.prj_stts_cd);
          }
        })
        .catch((error)=>{
          console.log(error);
        })
      // })
      // .catch((error)=>{
      //   console.log(error);
      // })
    }
  }
  // 조회수 증가
  // const [projectDetailInfo, setProjectDetailInfo] = useState('');
  const [projectDetailViews, setProjectDetailViews] = useState(0);
  useEffect(() => {
    axios({
      method:'post',
        url:`/api/project/views`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
        data: {prjId: projectID.id}
    })
    .then((result)=>{
        // console.log(result.data);
        if (result.data.success) {
          setProjectDetailViews(result.data.data.prj_views_co);
            // setBbsInfo(result.data.data);
            // setBbsViews(result.data.data.inq_cnt);
        } else {
            // setPopupMsg('조회수 갱신에 실패하였습니다.');
            // setShowPopup(true);
        }
    })
    .catch((error)=>{
        // props.setLoading(false);
        console.log(error);
    })
  }, [])

  // 프로젝트 정보 초기화
  useEffect(()=>{
    initProjectInfo();
  },[projectInfo])

  function initProjectInfo() {
    if(projectInfo){
      setPrjNm(projectInfo.prj_nm);            
      setPrjTyCd(projectInfo.prj_ty_cd);
      setPrjTyCustom(projectInfo.prj_ty_custom);
      setExptdCost(projectInfo.exptd_cost_tt);
      setExptdDay(projectInfo.exptd_duration_day);
      setSidoCd(projectInfo.sido_cd);
      setApplyDe(projectInfo.prj_apply_de);
      setExptdStarDe(projectInfo.prj_exptd_start_de);
      setQcYn(projectInfo.prj_pc_yn);
      setPrjDetail(projectInfo.prj_detail_cn);
      setPrjAttchFiles(projectInfo.attch_files_arr);
      setPrjAttchNames(projectInfo.attch_names_arr);
      setPrjAttchDelArr();
    }
  }

  // 지원서 정보 초기화
  function initProposalInfo() {
    setMyProposal();
    setProposalCost();
    setProposalDay();
    setProposalDetail();
    setProposalPrt([]);
    setProposalPrtInfo([]);
  }

  // (파트너) 프로젝트 클라이언트 정보 & 포트폴리오 정보 불러오기
  useEffect(()=>{
    if(projectInfo){
      axios({
        method:'get',
        url:`/api/account/profile?userId=${projectInfo.prj_owner_user_id}&prjInfo=true`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          // console.log(result.data);
          let owner_info = result.data.data;
          setProjectOwnerInfo(owner_info);
        }
      })
      .catch((error)=>{
        console.log(error);
      })

      axios({
        method:'get',
        url:`/api/prtfolio/list?userId=${userID}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          let portfolio_list = result.data.data;
          setPortfolioList(portfolio_list);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  },[projectInfo])

  // 지원서 목록 불러오기
  useEffect (() => {
    getProposalList();
  },[projectApply])

  function getProposalList () {
    if(projectID){
      axios({
        method:'get',
        url:`/api/project/apply/list?prjId=${projectID.id}&submitted=false`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          // console.log(result.data);
          let proposal_list = result.data.data;
          setProposalList(proposal_list);
          proposal_list && proposal_list.map((proposal)=>{
            if(proposal.user_id == userID){
              setMyProposal(proposal);
              if(projectApply == true){
                // setPopupMsg('기존에 작성한 제안서가 있습니다.\n저장된 제안서를 불러옵니다.');
                // setShowAlarmPopup(true);
                setProposalCost(proposal.prj_apply_cost_tt);
                setProposalDay(proposal.prj_apply_duration_day);
                setProposalDetail(proposal.prj_apply_content);
                setProposalPrt(proposal.relatd_prtfolio_arr? proposal.relatd_prtfolio_arr : []);
                setProposalPrtInfo();
              }
            }
          })
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  }

  // 프로젝트 지원하기 버튼 클릭하기 전에 프로젝트 지원하기 폼 나오기
  // useEffect (() => {
  //   if (projectInfo && myProposal && myProposal.prj_apply_stts_cd >= 2) {
  //     setProjectApply(false);
  //   }
  // }, [])

  // 메뉴 선택시 정보 불러오기
  useEffect (() => {
    let postNumber = 0;

    if (selectedMenu == 'progress'){postNumber = 5;}
    else if (selectedMenu == 'inspection'){postNumber = 6;}
    else if (selectedMenu == 'complete'){postNumber = 7;}
    else if (selectedMenu == 'payment'){postNumber = 7;}

    if(selectedMenu != 'partner' && projectInfo && projectInfo.prj_stts_cd >= 5){
      setProgressInfo(null);
      axios({
        method:'get',
        url:`/api/process/detail?prjId=${projectID.id}&sttsCd=${postNumber}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true  && result.data.data){
          console.log(result.data.data)
          let progress_info = result.data.data;
          setProgressInfo(progress_info);
        }
        else{
          setProgressInfo('empty');
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }

    if(selectedMenu == 'payment'){
      getPaymentInfo();
    }
    
  },[selectedMenu, projectInfo])

  // 대금 정보 불러오기
  function getPaymentInfo () {
    axios({
      method:'get',
      url:`/api/payment/info`
      + `?prjId=${projectID.id}`
      + `&payTyCd=1`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        let payment_info = result.data.data;
        setPaymentClient(payment_info);

        let array = [];
        payment_info && payment_info.map((e)=>{
          array.push(
            {
              "numInstallment" : e.pay_num_installmnt,
              "amountInstallmnt" : e.amount_installmnt,
              "payDe" : e.pay_de,
              "payNm" : e.pay_nm
            }
          )
        })
        setPaymentClientJson(array);
        setInputClientMod(false);
        setInputPartnerMod(false);
      }
      else{
      }
    })
    .catch((error)=>{
      console.log(error);
    })

    axios({
      method:'get',
      url:`/api/payment/info`
      + `?prjId=${projectID.id}`
      + `&payTyCd=2`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        let payment_info = result.data.data;
        setPaymentPartner(payment_info);

        let array = [];
        payment_info && payment_info.map((e)=>{
          array.push(
            {
              "numInstallment" : e.pay_num_installmnt,
              "amountInstallmnt" : e.amount_installmnt,
              "payDe" : e.pay_de,
              "payNm" : e.pay_nm
            }
          )
        })
        setPaymentPartnerJson(array);
      }
      else{
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 지역 코드
  useEffect (() => {
    axios({
      method:'get',
      url:`/api/util/sido`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        setSidoArray(result.data.data);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  } , [])
  
  // 프로젝트 정보 수정
  function modifyProject () {
    const formData = new FormData();
    formData.append("prjId", projectID.id)             
    formData.append("prjNm", prjNm);                    // 1.프로젝트 이름
    formData.append("prjTyCd", prjTyCd);                // 2.프로젝트 분류 코드
    formData.append("prjTyCustom", prjTyCustom);        // 3.프로젝트 분류 직접입력
    formData.append("exptdCost", exptdCost);            // 4.예상금액 (만원)
    formData.append("exptdDay", exptdDay);              // 5.예상일정 (일)
    formData.append("sidoCd", sidoCd);                  // 6.지역 (시도코드)
    formData.append("applyDe", applyDe);                // 7.모집 마감일자
    formData.append("exptdStarDe", exptdStarDe);        // 8.예상 시작일자
    formData.append("qcYn", qcYn);                      // 9.검수 포함여부
    formData.append("prjDetail", prjDetail);            // 10.상세 내용
    // formData.append("prjAttchFiles", prjAttchFiles);    // 11.프로젝트 첨부파일
    // formData.append("prjAttchNames", prjAttchNames);    // 12.프로젝트 첨부파일명
    // formData.append("prjAttchDelArr", prjAttchDelArr);  // 13.삭제할 첨부파일 인덱스

    axios({
      method:'post',
      url:`/api/project/update`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: formData,
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('프로젝트가 정보가 수정되었습니다.');
        setShowAlarmPopup(true);
        setProjectModify(false);
      }
      else if(result.data.success == false){
        setPopupMsg('프로젝트 수정 실패 (입력값 확인)');
        setShowAlarmPopup(true);
        setProjectModify(false);
      }
    })
    .catch((error)=>{
      console.log(error);
      setPopupMsg('프로젝트 수정 실패 (요청 오류)');
      setShowAlarmPopup(true);
      setProjectModify(false);
    })
  }

  // 프로젝트 삭제
  // const [prjDeleteOk, setPrjDeleteOk] = useState(false);
  function deleteProject () {
    axios({
      method:'post',
      url:`/api/project/delete`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { prjId: projectID.id },
    })
    .then((result)=>{
      // console.log(result);
      if(result.data.success == true){
        setShowAlarmPopup(true);
        setPopupMsg('프로젝트가 삭제되었습니다.');
        navigate('/project');
      }
      else if(result.data.success == false){
        // console.log(result.data);
        setShowAlarmPopup(true);
        setPopupMsg('해당 프로젝트는 삭제할 수 없습니다');
        // setPopupMsg(result.data.msg.detail);
        // setPopupMsg(result.data.msg.detail);
      }
    })
    .catch((error)=>{
      console.log(error);
      setShowAlarmPopup(true);
      setPopupMsg('프로젝트 삭제 실패');
      
    })
  }

  // 지원서 생성
  function createProposal () {

    axios({
      method:'post',
      url:`/api/project/apply/add`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { 
        prjId: projectID.id, 
        cost: proposalCost, 
        duration: proposalDay, 
        content: proposalDetail, 
        relPrtfolioArr: JSON.stringify(proposalPrt),
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('지원서가 저장되었습니다.');
        setShowAlarmPopup(true);
        let proposal = result.data.data;
        setMyProposal(proposal);
      }
      else if(result.data.success == false){
        setPopupMsg(result.data.msg);
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
      setPopupMsg('지원서 생성 실패');
      setShowAlarmPopup(true);
    })
  }

  // 지원서 수정
  function modifyProposal () {

    if(myProposal){
      axios({
        method:'post',
        url:`/api/project/apply/update`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
        data: {
          applyId: myProposal.prj_apply_id,
          proposed: false,
          cost: proposalCost,
          duration: proposalDay,
          content: proposalDetail,
          relPrtfolioArr: JSON.stringify(proposalPrt),
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          setPopupMsg('지원서가 수정되었습니다.');
          setShowAlarmPopup(true);
          setProjectApply(false);
          initProposalInfo();
        }
        else if(result.data.success == false){
          setPopupMsg(result.data.msg);
          setShowAlarmPopup(true);
        }
      })
      .catch((error)=>{
        console.log(error);
        setPopupMsg('지원서 삭제 실패');
        setShowAlarmPopup(true);
      })
    }
  }

  // 지원서 삭제
  function deleteProposal () {
    if(myProposal){
      axios({
        method:'post',
        url:`/api/project/apply/delete`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
        data: { applyId : myProposal.prj_apply_id},
      })
      .then((result)=>{
        if(result.data.success == true){
          setPopupMsg('지원서가 삭제되었습니다.');
          setShowAlarmPopup(true);
          setProjectApply(false);
          initProposalInfo();
        }
        else if(result.data.success == false){
          setPopupMsg(result.data.msg);
          setShowAlarmPopup(true);
        }
      })
      .catch((error)=>{
        console.log(error);
        setPopupMsg('지원서 삭제 실패');
        setShowAlarmPopup(true);
      })
    }
  }

  // 프로젝트 지원하기
  function applyProject () {
    if(myProposal){
      axios({
        method:'post',
        url:`/api/project/apply/submit`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
        data: { applyId : myProposal.prj_apply_id},
      })
      .then((result)=>{
        if(result.data.success == true){
          setPopupMsg('지원서 제출이 완료되었습니다.');
          setShowAlarmPopup(true);
          setProjectApply(false);
          initProposalInfo();
          getProjectInfo();
        }
        else if(result.data.success == false){
          setPopupMsg(result.data.msg);
          setShowAlarmPopup(true);
        }
      })
      .catch((error)=>{
        console.log(error);
        setPopupMsg('지원서 제출 실패');
        setShowAlarmPopup(true);
      })
    }
    else{
      setPopupMsg('저장된 지원서가 없습니다.');
      setShowAlarmPopup(true);
    }
  }

  // 찜하기
  function checkItrst (id, bool) {
    axios({
      method:'post',
      url:`/api/project/interest`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        prjId: id,
        itrst: bool,
      }
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        getProjectInfo();
      }
      else{
        getProjectInfo();
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  // 프로젝트 진행상태 업데이트
  function updateProcess () {

    let updateDate = moment().format("YYYY-MM-DD");

    if(processDateBool == true){
      updateDate = processDate;
    }

    axios({
      method:'post',
      url:`/api/project/process`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { 
        prjId: projectID.id, 
        sttsCd: process, 
        updateDe: updateDate,
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('프로젝트 진행상태를 업데이트하였습니다.');
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        console.log(result);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 결제 지급 정보 업데이트
  function postPaymentInfo (type) {

    let total = 0;
    let json = [];

    if (type == 1){
      total = paymentClientJson.length;
      json = [...paymentClientJson];
    }
    else if (type == 2){
      total = paymentPartnerJson.length;
      json = [...paymentPartnerJson];
    }
    axios({
      method:'post',
      url:`/api/payment/info`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { 
        prjId: projectID.id, 
        payTyCd: type, 
        numTotal: total,
        amountTotal: projectInfo.exptd_cost_tt,
        payment: JSON.stringify(json),
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('결제 지급 상태를 업데이트하였습니다.');
        setShowAlarmPopup(true);
        if (type == 1){setPaymentClientJson(json);}
        else if(type == 2){setPaymentPartnerJson(json);}
        getPaymentInfo();
      }
      else if(result.data.success == false){
        console.log(result);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 결제 지급 완료여부 업데이트
  function postPaymentComplete (type, num, bool) {

    axios({
      method:'post',
      url:`/api/payment/complete`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { 
        prjId: projectID.id, 
        payTyCd: type, 
        numInstallment: num + 1,
        complete: bool,
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('결제 지급 상태를 업데이트하였습니다.');
        setShowAlarmPopup(true);
        getPaymentInfo();
      }
      else if(result.data.success == false){
        console.log(result);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // (클라이언트) 계약 정보 수정
  useEffect(()=>{
    if(inputClientMod == true){
      if(paymentClient && paymentClient.length == 0){
        let array = [];
        array.push(
          {
            "numInstallment" : 1,
            "amountInstallmnt" : 0,
            "payDe" : moment().format("YYYY-MM-DD"),
            "payNm" : ''
          }
        )
        setPaymentClientJson(array);
      }
    }
  },[inputClientMod])

  // (파트너) 지급 정보 수정
  useEffect(()=>{
    if(inputPartnerMod == true){
      if(paymentPartner && paymentPartner.length == 0){
        let array = [];
        array.push(
          {
            "numInstallment" : 1,
            "amountInstallmnt" : 0,
            "payDe" : moment().format("YYYY-MM-DD"),
            "payNm" : ''
          }
        )
        setPaymentPartnerJson(array);
      }
    }
  },[inputPartnerMod])

  // 세금계산서 & 지급명세서 업로드
  useEffect(()=>{
    if(productOutputFile){

      const formData = new FormData();

      formData.append("prjId", projectID.id);
      formData.append("payTyCd", productOutputFile.type);
      formData.append("numInstallment", productOutputFile.num);
      formData.append("statemnt", productOutputFile.file);

      axios({
        method:'post',
        url:`/api/payment/statemnt`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
        data: formData,
      })
      .then((result)=>{
        if(result.data.success == true){
          setPopupMsg('업로드 완료');
          setShowAlarmPopup(true);
          getPaymentInfo();
        }
        else if(result.data.success == false){
          setPopupMsg('업로드 실패');
          setShowAlarmPopup(true);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  },[productOutputFile])

  // 템플릿 목록 조회
  useEffect(()=>{
    if(userType == 3 && selectedMenu == 'payment')
    {
      axios({
        method:'get',
        url:`/api/contrct/template/list`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          let array = [];
          const template = result.data.data;
          template && template.map((e, i)=>{
            array.push(
              {
                text: e.title, 
                value: e.id, 
                date: e.updatedAt, 
                count: e.partiCount
              }
            )
          });
          setTemplateList(array);
        }
        else if(result.data.success == false){
          console.log(result);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  },[selectedMenu])

  // 템플릿 목록 조회
  useEffect(()=>{
    if(userType == 3 && selectedMenu == 'payment')
    {
      axios({
        method:'get',
        url:`/api/contrct/info?prjId=${projectID.id}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.data){
          setContractInfo(result.data.data);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  },[selectedMenu])

  // 계약정보 업데이트
  function postContractUpdate () {
    axios({
      method:'post',
      url:`/api/contrct/update`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        prjId: projectID.id,
        contrctStartDe: contractStartDate,
        contrctEndtDe: contractEndDate,
        contrctAmount: Number(contractCost),
        vatInc: true,
      }
    })
    .then((result)=>{
      if(result.data.success == true) {
        setInputContractMod(false);
        setPopupMsg("계약정보를 저장하였습니다.")
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg("계약정보 저장을 실패하였습니다. \n 프로젝트 일자정보와 계약금액을 확인하세요.")
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 서명 요청
  function postRequireSign () {
    axios({
      method:'post',
      url:`/api/contrct/request`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        prjId: projectID.id,
        templateId: templateID
      }
    }).then((result)=>{
      if(result.data.success == true) {
        setPopupMsg("계약서 서명요청을 완료했습니다.")
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg("계약서 서명요청을 실패하였습니다. \n 관리자에게 문의해주세요.")
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 서명 날짜 포맷 표출
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // 날짜 부분: 'YYYY-MM-DD' 그대로 사용
    const formattedDate = date.toISOString().slice(0, 10); // '2024-10-10'
  
    // 시간 부분: 'HH시 MM분 SS초'로 직접 포맷팅
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const formattedTime = `${hours}시 ${minutes}분 ${seconds}초`;
  
    return `${formattedDate} ${formattedTime}`;
  };
  

  // 작성 날짜 (오늘 날짜)
  const today = new Date();
  const formattedDate = `${today.getFullYear()}년 ${today.getMonth() + 1}월 ${today.getDate()}일`;

  // useEffect(()=>{console.log(selectedMenu)},[selectedMenu])
  
  // 파트너 선정 리스트
  function returnProposalBox () {

    let count = 0;
    if(proposalList){
      for (let i = 0; i < proposalList.length; i++){
        if (proposalList[i].prj_apply_stts_cd != 100) {count++}
      }
    }

    if(count == 0){
      return(
        <div className='alert flex-center'>※ 프로젝트에 지원한 파트너가 없습니다.</div>
      )
    }
    else if (count >= 1){

      let array = [];

      for (let i = 0; i < proposalList.length; i++){
        if(userType == 1 && proposalList[i].user_id != userID){
          continue;
        }
        else{
          if(proposalList[i].prj_apply_stts_cd != 100){
          array.push(
          <div 
            key={i} 
            className='partner-summary-box contents-col'
            onClick={()=>{
              if(proposalList[i].prj_apply_stts_cd != 100){
                setSelectProposal(proposalList[i])
              }
            }}
          >
            <div className='contents-row'>
              <div className='header-text'>지원서</div>

              <div className='contents-row'>
                {
                proposalList[i].prj_apply_stts_cd == 5?<div className='contract flex-center'><FontAwesomeIcon icon="fa-regular fa-handshake"/>계약 검토</div> :
                proposalList[i].prj_apply_stts_cd == 100?<div className='contract off flex-center'><FontAwesomeIcon icon="fa-regular fa-handshake"/>미팅 미선정</div> :
                proposalList[i].prj_apply_stts_cd == 101?<div className='contract off flex-center'><FontAwesomeIcon icon="fa-regular fa-handshake"/>계약 미선정</div> :
                proposalList[i].prj_apply_stts_cd == 102?<div className='contract off flex-center'><FontAwesomeIcon icon="fa-regular fa-handshake"/>계약 실패</div> :
                proposalList[i].prj_apply_stts_cd == 200?<div className='contract flex-center'><FontAwesomeIcon icon="fa-regular fa-handshake"/>계약 완료</div> :
                null
                }
                {
                proposalList[i].prj_apply_stts_cd == 4?
                <div className='meeting-date flex-center'><FontAwesomeIcon icon="fa-solid fa-business-time"/>미팅 : {moment(proposalList[i].meeting_dt).format('YYYY-MM-DD')}</div> 
                : null
                }
              </div>

              <div className='flex-spacer'></div>
            </div>
            <div className='contents-row'>
              {
              proposalList[i] && proposalList[i].photo_url != null? 
              <img className='partner-logo' src={proposalList[i] && proposalList[i].photo_url} alt=""></img> 
              : 
              <div className='partner-logo null'></div>
              }
              <div className='contents-col part-main'>
                <div className='contents-row'>
                  <div className='partner-name'>{proposalList[i].user_nm}</div>
                  <div className='flex-spacer'></div>
                  {
                    proposalList[i].co_ty == 1?
                    <div className='contents-row'>
                      <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-user"/></div>
                      <div className='partner-type'>개인</div>
                    </div>
                    :
                    proposalList[i].co_ty == 2?
                    <div className='contents-row'>
                      <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-building"/></div>
                      <div className='partner-type'>개인사업자</div>
                    </div>
                    :
                    proposalList[i].co_ty == 3?
                    <div className='contents-row'>
                      <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-building"/></div>
                      <div className='partner-type'>법인사업자</div>
                    </div>
                    :
                    null
                  }
                  {
                    proposalList[i].certified_com_yn?
                    <div className='contents-row'>
                      <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award" /></div>
                      <div className='partner-certification'>인증업체</div>
                    </div>
                    :
                    null
                    // <div className='contents-row'>
                    //   <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award none" /></div>
                    //   <div className='partner-certification'>비인증업체</div>
                    // </div>
                  }
                  {
                    proposalList[i].user_itrst_yn?
                    <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-heart"/></div>
                    :
                    <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-heart"/></div>
                  }
                </div>
                <div className='contents-row'>
                  <div className='textA'>제시금액</div>
                  <div className='textB'>{proposalList[i].prj_apply_cost_tt} 만원</div>
                  <div className='textA'>예상일정</div>
                  <div className='textB'>{proposalList[i].prj_apply_duration_day} 일</div>
                </div>
                <div className='partner-introduce'>{proposalList[i].prj_apply_content}</div>
              </div>

              <div className='contents-col part-sub'>
                {/* <div className='contents-row score'>
                  <FontAwesomeIcon icon="fa-solid fa-star"/>
                  <FontAwesomeIcon icon="fa-solid fa-star"/>
                  <FontAwesomeIcon icon="fa-solid fa-star"/>
                  <FontAwesomeIcon icon="fa-regular fa-star"/>
                  <FontAwesomeIcon icon="fa-regular fa-star"/>
                  <div className='text'>{(proposalList[i].review_score_avr).toFixed(1)} / 0명</div>
                </div> */}
                <div className='contents-row'>
                  <div className='contents-row score' style={lineStyle}>
                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                    {/* <div className='text'>{partner.review_score_avr.toFixed(1)} / 0명</div> */}
                  </div>
                  {/* {console.log(partner)} */}
                  <div className='contents-row score' style={{ position: 'absolute', overflow: 'hidden', width: ((proposalList[i].review_score_avr / 5) * 101.25) + 'px' }}>
                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                    <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                    {/* <div className='text'>{partner.review_score_avr.toFixed(1)} / 0명</div> */}
                  </div>
                </div>
                <div className='text'>{(proposalList[i].review_score_avr).toFixed(1)} / 0명</div>
                <div className='contents-row'>
                  <div className='partner-option textA'>계약한 프로젝트</div>
                  {/* {console.log(proposalList[i])} */}
                  <div className='partner-option textB'>{proposalList[i].contract_co} 건</div>
                </div>
                <div className='contents-row'>
                  <div className='partner-option textA'>포트폴리오</div>
                  <div className='partner-option textB'>{proposalList[i].prtfolio_co} 건</div>
                </div>
                <div className='contents-row'>
                  <div className='partner-option textA'>DROPIA 활동 기간</div>
                  <div className='partner-option textB'>{moment(moment(), "YYYY-MM-DD").diff(proposalList[i].user_join_dt, 'month')} 개월</div>
                </div>
              </div>
            </div>
          </div>
          )
          }
        }
      }

      return array;
    }
  }

  // (필터)시도
  function checkSidoNm (code) {
    let array = [<div key={1} className='project-location'>전국</div>];
    sidoArray && sidoArray.map((sido, i)=>{
      if (code == sido.sido_cd){
        array.splice(0, 1);
        array.push(<div key={i} className='project-location'>{sido.sido_nm}</div>)
      }
    })
    return array;
  }

  // 다운로드
  function downloadProduct (path) {
    if(path){
      window.location.href = path;
    }
  }

  // 별점
  const lineStyle = {
    position: 'relative',
    width: '101.25px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  // 모집마감 계산
  // projectInfo가 없거나 prj_apply_de가 없을 경우를 처리
  if (!projectInfo || !projectInfo.prj_apply_de) {
    // return <div className='option-input-sub'>정보 없음</div>;
    return null;
  }

  // 마감일과 현재 날짜를 시작 시간으로 설정
  const deadline = moment(projectInfo && projectInfo.prj_apply_de, "YYYY-MM-DD").startOf('day');
  const now = moment().startOf('day');

  // 남은 일수를 계산합니다
  // const daysRemaining = deadline.diff(now, 'days');

  // 프로젝트 진행 상세 내용 (단계별)
  // 프로젝트 진행 상세 내용 업데이트
  
  const progressContentSave = () => {
    
    const baslicFormData = new FormData();
    if (projectID) {
      baslicFormData.append("prjId",projectID.id);
    }

    let prjSttsCd;
     
    // if (selectedMenu === 'progress') {
    //   prjSttsCd = 5
    // }
    if (selectedMenu == 'progress'){prjSttsCd = 5;}
    else if (selectedMenu == 'inspection'){prjSttsCd = 6;}
    else if (selectedMenu == 'complete'){prjSttsCd = 7;}
    else if (selectedMenu == 'payment'){prjSttsCd = 7;}

    baslicFormData.append("sttsCd", prjSttsCd);
    
    baslicFormData.append("detail", projectWritingDetails);
    console.log(processFile)
    if (processFile !== undefined) {
      processFile.forEach(file => {
        baslicFormData.append("sttsFiles", file);
      });
    }
    // console.log(processFile);
    // for(let i = 0; i < processFile.length; i++) {
    //   baslicFormData.append("sttsFiles", processFile[i]);
    // } 
    
    // 선택한 파일들을 FormData에 추가
    if (selectedFiles) {
      selectedFiles.forEach((file) => {
        baslicFormData.append("sttsFiles", file); // 파일 추가
      });
    }
    

    // 삭제할 파일 인덱스가 있으면 추가
    console.log(fileToDeleteIndex);
    if (fileToDeleteIndex) {
      baslicFormData.append("sttsFileDelArr", fileToDeleteIndex);
    }
    // baslicFormData.append("sttsCd", userNm);
    // baslicFormData.append("detail", userEmail);
    // baslicFormData.append("sttsFiles", userTelNum);

    axios({
      method:'post',
      url:`/api/process/detail`,
      headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
      data: baslicFormData,
    })
    . then((result)=>{
        console.log(result.data);
        if (result.data.success) {
          setFileToDeleteIndex([]); // 초기화

            // setProfileUpdOk(true);
            // setShowPopup(true);
            // setPopupMsg(result.data.msg);
            // setPopupMsg("프로필 (기본정보) 업데이트 되었습니다.");
            // props.setUserProfile(result.data.data);
            // setOnChangeClick(false);
            // props.setLoginprops.profileData(result.data.data);
        } else {
            // setShowPopup(true);
            // setPopupMsg(result.data.msg);
            // setOnChangeClick(false);
            // setPopupMsg("프로필 (기본정보)에 실패하였습니다.");
        }
    })
    .catch((error) => {

    })
  }

  const progressContentCancel = () => {
    setProjectWritingDetail(progressInfo && progressInfo.prj_prcs_detail || '');
  }

  return (
  <React.Fragment> 
    <div className={`project-view-detail ${selectProposal? 'hide':''}`}>
      <div className='contents-col'>
        <div className='project-view-detail-main contents-col'>
          <div className='contents-row'>
            {
              // moment(projectInfo && projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0 && project.prj_stts_cd <= 4?
              // projectInfo && projectInfo.prj_apply_de && moment(projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0 && project.prj_stts_cd <= 4?
              moment(projectInfo?.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0 && project?.prj_stts_cd <= 4?
              <div className='box recruit flex-center'>모집 중</div> 
              : 
              <div className='box recruit end flex-center'>모집 마감</div>
            }
            {
              moment(moment(), "YYYY-MM-DD").diff(moment(projectInfo && projectInfo.prj_reg_de), 'days') <= 3?
              <div className='box new flex-center'>New</div>
              :
              null
            }
            <div className='flex-spacer'></div>
            {
              userType == 2 && projectInfo && projectInfo.prj_owner_user_id == userID && projectApply == false || userType == 3?
              <div className='contents-row'>
                {
                projectModify?
                <div className='contents-row'>
                  <div className='box modify flex-center'
                    onClick={()=>{setPopupState('modifyProject');setShowQuestionPopup(true);setPopupMsg('프로젝트를 수정하시겠습니까?');}}
                  ><FontAwesomeIcon icon="fa-solid fa-pen" />저장</div>
                  <div className='box cancel flex-center'
                    onClick={()=>{setProjectModify(false);initProjectInfo();}}
                  ><FontAwesomeIcon icon="fa-solid fa-ban" />취소</div>
                </div>
                :
                <div className='contents-row'>
                  <div className='box modify flex-center'
                    onClick={()=>{setProjectModify(true);}}
                  ><FontAwesomeIcon icon="fa-solid fa-pen" />수정</div>
                  <div className='box delete flex-center'
                    onClick={()=>{setPopupState('deleteProject');setShowQuestionPopup(true);setPopupMsg('프로젝트를 삭제하시겠습니까?');}}
                  ><FontAwesomeIcon icon="fa-solid fa-trash" />삭제</div>
                </div>
                }
              </div>
              :
              null
            }
            <div className='reg-date flex-center'>등록일자 : {projectInfo? moment(projectInfo.prj_reg_de).format("YY.MM.DD") : '정보없음'}</div>
          </div>
    
          <div className='contents-row border-bottom'>
            <input 
              className='full-width project-name' 
              readOnly={projectModify? false : true} 
              value={prjNm} 
              onChange={(e)=>{setPrjNm(e.target.value)}}
            ></input>
            <div className='flex-spacer'></div>
            <div className='contents-row flex-center'>
              <FontAwesomeIcon icon="fa-regular fa-eye" />
              <div className='count-text'>조회</div>
              <div className='count-num'>{projectDetailViews? projectDetailViews:0}</div>
              {/* <div className='count-num'>{projectInfo? projectInfo.prj_views_co:null}</div> */}
            </div>
            <div className='contents-row flex-center'>
              <FontAwesomeIcon icon='fa-regular fa-heart' />
              <div className='count-text'>찜</div>
              <div className='count-num'>{projectInfo? projectInfo.prj_itrst_co:null}</div>
            </div>
          </div>

          {abstractView == true?
          <div>
            <div className='contents-row'>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>예상금액</div>
                {/* <div className='flex-spacer'></div> */}
                <div className='contents-row'>
                  <input 
                    className='sub-input flex-center'
                    readOnly={projectModify? false : true} 
                    value={exptdCost? (exptdCost > 10000? (exptdCost/10000).toFixed(0) : exptdCost) : ''} 
                    onChange={(e)=>{setExptdCost(e.target.value)}}
                  ></input>
                  <div className='sub-text flex-center'>{projectInfo && projectInfo.exptd_cost_tt > 10000? '억원':'만원'}</div>
                </div>
              </div>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>예상일정</div>
                {/* <div className='flex-spacer'></div> */}
                <div className='contents-row'>
                  <input 
                    className='sub-input flex-center'
                    readOnly={projectModify? false : true} 
                    value={exptdDay} 
                    onChange={(e)=>{setExptdDay(e.target.value)}}
                  ></input>
                  <div className='sub-text flex-center'>일</div>
                </div>
              </div>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>지역</div>
                {/* <div className='flex-spacer'></div> */}
                {
                projectModify?
                <Dropdown
                  className='dropdown none-border'
                  selection
                  fluid
                  icon='angle down'
                  options={locationOptions}
                  defaultValue={sidoCd}
                  onChange={(e, data)=>{setSidoCd(data.value)}}
                ></Dropdown>
                :
                <div className='sub-text flex-center'>{projectInfo? checkSidoNm(projectInfo.sido_cd):''}</div>
                }
              </div>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>지원자 수</div>
                {/* <div className='flex-spacer'></div> */}
                <div className='sub-text flex-center'>{projectInfo? projectInfo.prj_apply_co:''} 명</div>
              </div>
            </div>
          
            <div className='contents-row'>
              <div className='contents-col'>
                <div className='option-icon flex-center'>●</div>
                <div className='option-icon flex-center'>●</div>
                <div className='option-icon flex-center'>●</div>
                <div className='option-icon flex-center'>●</div>
                <div className='option-icon flex-center'>●</div>
              </div>
              <div className='contents-col'>
                <div className='option-name'>모집 마감일</div>
                <div className='option-name'>예상 시작일</div>
                <div className='option-name'>예상 종료일</div>
                <div className='option-name'>분류</div>
                <div className='option-name'>검수 여부</div>
              </div>
              {
              projectModify?
              <div className='contents-col'>
                <input 
                  className='option-input date' 
                  type='date'
                  value={applyDe}
                  onChange={(e)=>{setApplyDe(e.target.value)}}
                ></input>
                <input 
                  className='option-input date' 
                  type='date'
                  value={exptdStarDe}
                  onChange={(e)=>{setExptdStarDe(e.target.value)}}
                ></input>
                <div className='option-input'></div>
                <Dropdown 
                  className='option-input dropdown type none-border'
                  placeholder='선택'
                  fluid
                  selection
                  icon='angle down'
                  options={typeOptions}
                  value={prjTyCd}
                  onChange={(e, data)=>{setPrjTyCd(data.value)}}
                >
                </Dropdown>
                <Dropdown 
                  className='option-input dropdown check none-border'
                  placeholder='선택'
                  fluid
                  selection
                  icon='angle down'
                  options={checkOptions}
                  value={qcYn}
                  onChange={(e, data)=>{setQcYn(data.value)}}
                >
                </Dropdown>
              </div>
              :
              <div className='contents-col'>
                <div className='option-input'>
                  {projectInfo && projectInfo.prj_apply_de? moment(projectInfo.prj_apply_de).format("YY.MM.DD"):'정보없음'}
                </div>
                <div className='option-input'>
                  {projectInfo && projectInfo.prj_exptd_start_de? moment(projectInfo.prj_exptd_start_de).format("YY.MM.DD"):'정보없음'}
                </div>
                <div className='option-input'>
                  {projectInfo && projectInfo.prj_exptd_start_de? moment(projectInfo.prj_exptd_start_de, "YYYY-MM-DD").add(projectInfo.exptd_duration_day, 'days').format('YY.MM.DD'):'정보없음'}
                </div>
                <div className='option-input'>
                  {projectInfo && projectInfo.prj_ty_nm? projectInfo.prj_ty_nm:null} {projectInfo && projectInfo.prj_ty_cd == 6? `(${projectInfo.prj_ty_custom})` : null}
                </div>
                <div className='option-input'>
                  {projectInfo && projectInfo.prj_qc_yn? "검수 포함" : "검수 미포함"}
                </div>
              </div>
              }
              {
              projectModify?
              null
              :
              <div className='contents-col'>
              {/* {
                projectInfo && daysRemaining >= 0
                  ? <div className='option-input-sub'>{daysRemaining} 일 남음</div>
                  : <div className='option-input-sub'>모집 마감</div>
              } */}
              {
                projectInfo && projectInfo.prj_apply_de && moment(projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0? 
                <div className='option-input-sub'>
                {moment(projectInfo.prj_apply_de, "YYYY-MM-DD").startOf('day').diff(moment().startOf('day'), 'days')} 일 남음</div>
                :
                <div className='option-input-sub'>모집 마감</div>
              } 
              {/* {
                projectInfo && moment(projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') >= 0? 
                <div className='option-input-sub'>
                {moment(projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days')} 일 남음</div>
                :
                <div className='option-input-sub'>모집 마감</div>
              }  */}
              </div>
              }
            </div>     
          
            <div className='part-divider'></div>
          
            <div className='textA'>과제내용</div>
          
            <div className='project-explanation'>
              {projectInfo? projectInfo.prj_detail_cn:null}
            </div>
          
            <div className='abstract-button flex-center' onClick={()=>{setAbstractView(false)}}>
              프로젝트 요약정보 숨기기
              <FontAwesomeIcon icon="fa-solid fa-angles-up" className='abstract-icon'/>
            </div>
          </div>
          :
          <div className='abstract-button flex-center' onClick={()=>{setAbstractView(true)}}>
            프로젝트 요약정보 보기
            <FontAwesomeIcon icon="fa-solid fa-angles-down" className='abstract-icon'/>
          </div>
          }
        </div>
        
        {
        // userType == 1 ?
        userType == 1 && projectApply == true?
        <div className='project-view-detail-menu'>
          <div className='project-apply'>
            <div className='header-text'>프로젝트 지원하기</div>
            <div className='contents-row'>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>제안금액</div>
                <input 
                  className='proposal-input text' 
                  placeholder='미입력'
                  // readOnly={myProposal? true:false}
                  defaultValue={myProposal? myProposal.prj_apply_cost_tt:''}
                  value={proposalCost}
                  onChange={(e)=>{setProposalCost(e.target.value.replace(/\D/g, ''))}}
                ></input>
                <div className='sub-text flex-center'> 만원</div>
              </div>
              <div className='option contents-row flex-center'>
                <div className='option-name flex-center'>제안일정</div>
                <input 
                  className='proposal-input text' 
                  placeholder='미입력'
                  // readOnly={myProposal? true:false}
                  defaultValue={myProposal? myProposal.prj_apply_duration_day:''}
                  value={proposalDay}
                  onChange={(e)=>{setProposalDay(e.target.value.replace(/\D/g, ''))}}
                ></input>
                <div className='sub-text flex-center'> 일</div>
              </div>
            </div>
            <div className='header-text'>제안 내용</div>
            <textarea
              className='contents-input' 
              placeholder='제안 내용을 입력해 주세요.'
              // readOnly={myProposal? true:false}
              defaultValue={myProposal? myProposal.prj_apply_content:''}
              value={proposalDetail}
              onChange={(e)=>{setProposalDetail(e.target.value)}}
            ></textarea>
            <div className='part-divider'></div>
            <div className='header-text'>관련 포트폴리오</div>
            <div className='portfolio-group contents-row'>
              {
                portfolioList && portfolioList.map((prt, i)=>{
                  if(proposalPrt && proposalPrt.length != 0 && proposalPrt.includes(prt.prtfolio_id)){
                    return(
                      <div className='portfolio-item contents-col' key={i}>
                        <i 
                          className='portfolio-item-del code x icon'
                          onClick={()=>{
                            let array = [...proposalPrt];
                            let arrayInfo = [...proposalPrtInfo];
                            if(array.includes(prt.prtfolio_id)){
                              array.splice(i,1);
                              arrayInfo.splice(i,1);
                            }
                            setProposalPrt(array);
                            setProposalPrtInfo(arrayInfo);
                            setPortfolioMenu(false);
                          }}
                        ></i>
                        {
                          prt.prj_output_urls && prt.prj_output_urls[prt.prj_output_main_idx] ?       
                            <img 
                            src={prt.prj_output_urls && prt.prj_output_urls[prt.prj_output_main_idx]}
                            alt={prt.prj_output_urls && prt.prj_output_urls[prt.prj_output_main_idx]}
                              className='rrn-img portfolio-img-img'
                              style={{
                                maxWidth: '100%',
                                // maxHeight: '130px',
                                objectFit: 'contain'
                              }}
                            />
                            : 
                            <div 
                              className='contents-row flex-center portfolio-img-img'
                              style={{maxWidth: '100%', height: '200px', objectFit: 'contain'}}
                            >
                              썸네일 이미지 없음
                            </div>
                        }
                        {/* <img className='portfolio-item-img' src={prt.prj_output_urls[prt.prj_output_main_idx]}></img> */}
                        <div className='portfolio-item-name'>{prt.prtfolio_nm}</div>
                        <div className='portfolio-item-type'>{prt.prj_ty_nm}</div>
                        <div className='portfolio-item-date'>{prt.prj_start_de}~{prt.prj_end_de}</div>
                      </div>
                    )
                  }
                })
              }
              <div className='portfolio-button flex-center' onClick={()=>{
                setPortfolioMenu(true);
              }}> + 포트폴리오 추가하기</div>
              
              {
                portfolioMenu == true && portfolioList && portfolioList.map((prt, i)=>{
                  return (
                  <div key={i} className='portfolio-option flex-center' onClick={()=>{
                    let array = [...proposalPrt];
                    let arrayInfo = [...proposalPrtInfo];
                    if(!array.includes(prt.prtfolio_id)){
                      array.push(prt.prtfolio_id);
                      arrayInfo.push(prt);
                    }
                    setProposalPrt(array);
                    setProposalPrtInfo(arrayInfo);
                    setPortfolioMenu(false);
                  }}>
                  {prt.prtfolio_nm}
                  </div>)
                })
              }
            </div>
          </div>
        </div>
        :
        
        !projectApply || userType == 1 && myProposal && myProposal.user_id == userID || userType == 2  && projectInfo && projectInfo.prj_owner_user_id == userID || userType == 3?
        <div className='project-view-detail-menu'>
          <div className='contents-row'>
            {
            userType != 1? 
            <div 
              className={`menu-tap flex-center ${selectedMenu == 'partner' ? 'active' : ''}`} 
              onClick={()=>{setSelectedMenu('partner')}}
            >파트너 선정</div> 
            : null
            }
            {
            projectInfo && projectInfo.prj_stts_cd >= 5? 
            <div 
              className={`menu-tap flex-center ${selectedMenu == 'progress' ? 'active' : ''}`} 
              onClick={()=>{setSelectedMenu('progress')}}
            >프로젝트 진행</div> 
            : null
            }
            {
            (projectInfo && projectInfo.prj_qc_yn) && (projectInfo && projectInfo.prj_stts_cd >= 6)? 
            <div 
              className={`menu-tap flex-center ${selectedMenu == 'inspection' ? 'active' : ''}`} 
              onClick={()=>{setSelectedMenu('inspection')}}
            >작업 검수</div> 
            : null
            }
            {
            projectInfo && projectInfo.prj_stts_cd >= 7? 
            <div 
              className={`menu-tap flex-center ${selectedMenu == 'complete' ? 'active' : ''}`} 
              onClick={()=>{setSelectedMenu('complete')}}
            >프로젝트 완료</div> 
            : null
            }
            {
            projectInfo && projectInfo.prj_stts_cd >= 4? 
            <div 
              className={`menu-tap flex-center ${selectedMenu == 'payment' ? 'active' : ''}`} 
              onClick={()=>{setSelectedMenu('payment')}}
            >계약/대금</div> 
            : null
            }
          </div>
          {
          selectedMenu == 'partner' && userType != 1?
          <div className='menu-container contents-col'>
            <div className='menu-content contents-col'>
            {/* {proposalList && proposalList.map((proposal, i) => (returnProposalBox(proposal, i)))} */}
            {returnProposalBox()}
            </div>
          </div>
          :
          selectedMenu == 'progress' || selectedMenu == 'inspection' || selectedMenu == 'complete' ?
          <div className='menu-container contents-col'>
            <div className='menu-content contents-col'>
              <div className='contents-row border-bottom header'>
                <div className='header-text flex-center'>
                {
                selectedMenu == 'progress'? '프로젝트 진행 정보' :
                selectedMenu == 'inspection'?  '검수 진행 정보' :
                selectedMenu == 'complete'?  '프로젝트 완료 보고' :
                null
                }
                </div>
                <div className='flex-spacer'></div>
                {/* {userType == 2 || userType == 3? <div className='content-text-typeA modify flex-center'><FontAwesomeIcon icon="fa-solid fa-pen"/>작성하기</div> : null} */}
                {/* {console.log(progressInfo)} */}
                {
                // progressInfo && progressInfo.update_de != null && progressInfo.user_nm != null? 
                progressInfo && props.userProfile.user_nm != null? 
                <div className='contents-row'>
                  <div className='content-text-typeA'>작성일 : 2024년 10월 15일 | 작성자 : {props.userProfile.user_nm}</div>
                  {/* <div className='content-text-typeA'>작성일 : {formattedDate} | 작성자 : {props.userProfile.user_nm}</div> */}
                  
                  
                  {/* <div className='content-text-typeA'>작성일 : {formattedDate} | 작성자 : {progressInfo.user_nm}</div> */}
                  {/* <div className='content-text-typeA'>작성일 : {progressInfo.update_de} | 작성자 : {progressInfo.user_nm}</div> */}
                </div>
                : 
                null
                }

              </div>
              {console.log(progressInfo && progressInfo.prj_prcs_detail)}
              {console.log(projectOwnerInfo.user_ty)}
              <div className='contents-row text-content'>
                <textarea 
                  className='input-textarea'
                  readOnly={props.userProfile.user_ty === 2}
                  value={projectWritingDetails || ''} 
                  onChange={handleWritingDetail}
                  placeholder='※ 작성된 상세 내용이 없습니다.'
                >
                  {progressInfo && progressInfo.prj_prcs_detail != null? progressInfo.prj_prcs_detail : null}
                </textarea>
                {/* <textarea
                    className='input-textarea'
                    readOnly={props.userProfile.user_ty === 2}
                    value={progressInfo && progressInfo.prj_prcs_detail !== null ? progressInfo.prj_prcs_detail : projectWritingDetails || ''} 
                    onChange={handleWritingDetail}
                    placeholder='※ 작성된 상세 내용이 없습니다.'
                /> */}
                {/* <textarea 
                  className='input-textarea'
                  value={projectWritingDetails || ''} 
                  onChange={handleWritingDetail}
                  placeholder='※ 작성된 상세 내용이 없습니다.'
                /> */}
                
                {userType !== '2' &&
                <div className='contents-row progress-btn-box'>
                  <div className='gc-btn flex-center progress-btn cancel' onClick={progressContentCancel}>취소</div>
                  <div className='gc-btn flex-center progress-btn save' onClick={progressContentSave}>저장하기</div>
                </div>
                }
              </div>
              {/* <textarea 
                className='input-textarea'
                // readOnly={true}
                value={projectWritingDetails || ''} 
                onChange={handleWritingDetail}
                placeholder='※ 작성된 상세 내용이 없습니다.'
              >
                {progressInfo && progressInfo.prj_prcs_detail != null? progressInfo.prj_prcs_detail : null}
              </textarea>
              <div className='contents-row'>
                <div className='gc-btn'>취소</div>
                <div className='gc-btn'>저장하기</div>
              </div> */}
              <div className='project-reg-add'>
                <div className='header-text'>첨부 파일</div>
                <div className='contents-row'>
                  <div className='file-list'>
                    {/* {
                      processFileNames && processFileNames.map((fileName, i)=>{
                        return(
                          <div className='file-list-item flex-center contents-row'>
                            {fileName}
                            <div className='part-divider'></div>
                            <i className={`code x icon`} onClick={() => {handleDeleteFileOk(i);}}></i>
                          </div>
                        )
                      }) 
                    } */}
                    {/* {selectedFiles.map((file, i) => (
                      <div key={i} className='file-list-item flex-center contents-row'>
                        {file.name}
                        <div className='part-divider'></div>
                        <i className={`code x icon`} onClick={() => handleDeleteFileOk(i)}></i>
                      </div>
                    ))} */}
                    {console.log(fileNames)}
                    {Array.isArray(fileNames) && fileNames.map((fileName, i) => (
                      <div key={i} className='file-list-item flex-center contents-row'>
                        {fileName} {/* 파일명 표시 */}
                        <div className='part-divider'></div>
                        <i className={`code x icon`} onClick={() => handleDeleteFileOk(i)}></i>
                      </div>
                    ))}
                    {/* {
                    processFile && processFile.map((file, i)=>{
                      return(
                        <div className='file-list-item flex-center contents-row'>
                          {file.name}
                          <div className='part-divider'></div>
                          <i className="code x icon" onClick={()=>{
                            let array = [...processFile];
                            array.splice(i,1);
                            setProcessFile(array);
                          }}/>
                        </div>)
                    }) 
                    } */}
                  </div>
                  <div className='contents-col'>
                    <input 
                      type='file'
                      multiple
                      id='fileinputA'
                      style={{ display: 'none' }}
                      onChange={onFileChangeHandler}
                      ref={fileRef}
                    ></input>
                    {userType !== '2' && (
                      <>
                        <label className='button flex-center' htmlFor='fileinputA'>파일 추가</label>
                        <div className='button flex-center' onClick={handleFileAClear}>모두 삭제</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {
              selectedMenu == 'complete'?
              <div className='menu-content contents-col'>
                <div className='contents-row border-bottom header'>
                  <div className='header-text flex-center'>
                    클라이언트 의견 / 평가
                  </div>
                  <div className='flex-spacer'></div>
                  {userType == 2 || userType == 3? <div className='content-text-typeA modify flex-center'><FontAwesomeIcon icon="fa-solid fa-pen"/>작성하기</div> : null}

                  {
                  progressInfo && progressInfo.update_de != null && progressInfo.user_nm != null? 
                  <div className='contents-row'>
                    <div className='content-text-typeA'>작성일 : {progressInfo.update_de} | 작성자 : {progressInfo.user_nm}</div>
                  </div>
                  : 
                  null
                  }

                </div>

                <div className='contents-row'>
                  <div className='score-group'>

                  </div>
                  <textarea 
                    className='client-message'
                    // readOnly={true}
                    placeholder='※ 클라이언트 리뷰를 작성해 주세요.'
                  >
                    {progressInfo && progressInfo.prj_prcs_detail != null? progressInfo.prj_prcs_detail : null}
                  </textarea>
                </div>
              </div>
              :
              null
            }
          </div>
          :
          selectedMenu == 'payment' ?
          <div className='menu-container contents-col'>
            <div className='menu-content contents-col'>
              <div className='contents-row flex-center border-bottom header'>
                <div className='header-text'>계약 관리</div>
                <div className='flex-spacer'></div>
                <div className='download-button'>계약서 다운로드</div>
                <div className='download-button'>검수결과서 다운로드</div>
              </div>
              <div className='payment-info contents-row'>
                <div className='contents-col'>
                  <div className='option-icon flex-center'>●</div>
                  <div className='option-icon flex-center'>●</div>
                  <div className='option-icon flex-center'>●</div>
                  <div className='option-icon flex-center'>●</div>
                  <div className='option-icon flex-center'>●</div>
                  <div className='option-icon flex-center'>●</div>
                </div>
                <div className='contents-col'>
                  <div className='option-name'>계약일자</div>
                  <div className='option-name'>프로젝트 시작일</div>
                  <div className='option-name'>프로젝트 종료일</div>
                  <div className='option-name'>프로젝트 계약금액</div>
                  <div className='option-name'>클라이언트 결제</div>
                  <div className='option-name'>파트너 지급</div>
                </div>
                <div className='contents-col'>
                  <div className='option-input'>
                    {/* 24-09-09 */}
                    {projectInfo && projectInfo.prj_contrct_de != null? moment(projectInfo.prj_contrct_de).format("YY.MM.DD") : '정보없음'}
                  </div>
                  {
                  inputContractMod? 
                  <input type='date' className='option-input' value={contractStartDate} onChange={(e)=>{setContractStartDate(e.target.value)}}></input>
                  : 
                  <div className='option-input'>
                    {projectInfo && projectInfo.prj_start_de != null? moment(projectInfo.prj_start_de).format("YY.MM.DD") : '정보없음'}
                  </div>
                  }
                  {
                  inputContractMod? 
                  <input type='date' className='option-input' value={contractEndDate} onChange={(e)=>{setContractEndDate(e.target.value)}}></input>
                  : 
                  <div className='option-input'>
                    {projectInfo && projectInfo.prj_end_de != null? moment(projectInfo.prj_end_de).format('YY.MM.DD'):'정보없음'}
                  </div>
                  }
                  {
                  inputContractMod? 
                  <input type='text' className='option-input'  value={contractCost} onChange={(e)=>{setContractCost(e.target.value)}}></input> 
                  : 
                  <div className='option-input'>
                    {/* {projectInfo && projectInfo.contrct_cost_tt != null? `${(projectInfo.contrct_cost_tt * 1.10).toFixed(0)}만원 ( 계약금액 ${projectInfo.contrct_cost_tt} 만원 + 부가세 10% )` : '정보없음'} */}
                    500만원
                  </div>
                  }
                  <div className='option-input'>
                    {paymentClient && paymentClient.length}회 분납 {paymentClient && paymentClient.map((e)=>{return(<div>({moment(e.pay_de).format("YY.MM.DD")})</div>)})}</div>
                  <div className='option-input'>
                    {paymentPartner && paymentPartner.length}회 분납 {paymentPartner && paymentPartner.map((e)=>{return(<div>({moment(e.pay_de).format("YY.MM.DD")})</div>)})}</div>
                </div>
                
                <div className='flex-spacer'></div>
                <div className='contents-col button-group'>
                  {/* {
                  inputContractMod?
                  <div className='contract-info-button flex-center' onClick={()=>{postContractUpdate()}}>계약내용 저장</div>
                  :
                  <div className='contract-info-button flex-center' onClick={()=>{setInputContractMod(true)}}>계약내용 수정</div>
                  } */}
                </div>
              </div>

              <div className='part-divider'></div>

              <div className='contents-row'>
              
                <div className='contents-col'>
                  <div className='payment-title client flex-center'>클라이언트</div>
                  <div className='payment-content client contents-col'>
                    <div className='contents-col full-height'>

                      <div className='total-payment'>
                        {/* {projectInfo && projectInfo.contrct_cost_tt? (projectInfo.contrct_cost_tt * 1.10).toFixed(0) : '000'} */}
                        {/* 1000 */}
                        500
                          만원
                      </div>

                      {
                        inputClientMod? 
                        <div>
                        {
                          paymentClientJson && paymentClientJson.map((e, i)=>{
                            return (
                              <div key={i} className='payment-step'>
                                <div className='step'>
                                  {e.numInstallment}차 결제 
                                  ({moment().format("YY.MM.DD")})
                                </div>
                                <div className='payment contents-row'>
                                  <input 
                                    className='payment-input' 
                                    value={paymentClientJson[i].amountInstallmnt} 
                                    onChange={(e)=>{
                                      let array = [...paymentClientJson];
                                      array[i].amountInstallmnt = e.target.value;
                                      setPaymentClientJson(array);
                                    }}
                                  ></input>
                                  <div className='payment-text flex-center'>만원</div>
                                </div>
                                <div className='contents-row'>
                                  <input 
                                    className='payment-input detail' 
                                    value={paymentClientJson[i].payNm} 
                                    onChange={(e)=>{
                                      let array = [...paymentClientJson];
                                      array[i].payNm = e.target.value;
                                      setPaymentClientJson(array);
                                    }}
                                  ></input>
                                  <div className='detail-button del flex-center' onClick={()=>{
                                    let array = [...paymentClientJson];
                                    array.splice(i,1);
                                    setPaymentClientJson(array);
                                    }}
                                  >-</div>
                                  {
                                  i == paymentClientJson.length - 1?
                                  <div className='detail-button add flex-center' onClick={()=>{
                                    let array = [...paymentClientJson];
                                    array.push(           
                                    {
                                      "numInstallment" : paymentClientJson.length + 1,
                                      "amountInstallmnt" : 0,
                                      "payDe" : "2024-06-19",
                                      "payNm" : '잔여금'
                                    });
                                    setPaymentClientJson(array);
                                  }}>+</div>
                                  :
                                  null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                        </div> 
                        :
                        <div>
                        {
                          paymentClient && paymentClient.length == 0?
                          <div className='flex-center payment-step'>※ 결제정보가 없습니다.</div>
                          :
                          <div>
                          {
                          paymentClient && paymentClient.map((e, i)=>{
                            return (
                              <div key={i} className='payment-step'>
                                <div className='step'>
                                  {e.pay_num_installmnt}차 결제 
                                  (24.10.02)
                                  {/* ({moment(e.pay_de).format("YY.MM.DD")}) */}
                                </div>
                                <div className='payment contents-row'>
                                  <div className='payment-text flex-center'>{e.amount_installmnt}</div>
                                  <div className='payment-text flex-center'>만원</div>
                                  {
                                  e.pay_complete?
                                  <div className='payment-mark client flex-center' onClick={()=>{postPaymentComplete(1, i, 'false')}}>완납</div>
                                  :
                                  <div className='payment-mark none flex-center' onClick={()=>{postPaymentComplete(1, i, 'true')}}>미납</div>
                                  }
                                  {
                                  e.pay_statemnt_file_path?
                                  <div className='download-button payment-bill flex-center' onClick={()=>{downloadProduct(e.pay_statemnt_file_path)}}>세금계산서 다운로드 </div>
                                  :
                                  <div className='download-button payment-bill flex-center'>
                                    <input
                                      type="file"
                                      accept=".jpg, .pdf, .txt, .zip"
                                      style={{ visibility: "hidden", display: "none" }}
                                      onChange={(event)=>{onProductChangeHandler(event, e.pay_num_installmnt, 1)}}
                                      ref={productRef}
                                    ></input>
                                    <label className='' onClick={handleProductClick}>세금계산서 업로드</label>
                                  </div>
                                  }
                                </div>
                                <div className='name'>{e.pay_nm}</div>
                              </div>
                            )
                          })
                          }
                          </div>
                        }
                        </div>
                      }

                      <div className='flex-spacer'></div>

                      <div className='button-group contents-row'>
                      {/* {
                        inputClientMod?
                        <div className='flex-center button save' onClick={()=>{postPaymentInfo(1);}}>결제관리 저장</div>
                        :
                        <div className='flex-center button save' onClick={()=>{setInputClientMod(true);}}>결제관리 수정</div>
                      } */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='contents-col'>
                  <div className='payment-title partner flex-center'>파트너</div>
                  <div className='payment-content partner contents-col'>
                    <div className='contents-col full-height'>

                      <div className='total-payment'>
                        {/* {projectInfo && projectInfo.contrct_cost_tt? (projectInfo.contrct_cost_tt * 1.10).toFixed(0) : '000'} */}
                        450
                         만원
                      </div>

                      {                      
                        inputPartnerMod?
                        <div>
                        {                  
                          paymentPartnerJson && paymentPartnerJson.map((e, i)=>{
                            return (
                              <div key={i} className='payment-step'>
                                <div className='step'>{e.numInstallment}차 지급 ({moment().format("YY.MM.DD")})</div>
                                <div className='payment contents-row'>
                                  <input 
                                    className='payment-input' 
                                    value={paymentPartnerJson[i].amountInstallmnt} 
                                    onChange={(e)=>{
                                      let array = [...paymentPartnerJson];
                                      array[i].amountInstallmnt = e.target.value;
                                      setPaymentPartnerJson(array);
                                    }}
                                  ></input>
                                  <div className='payment-text flex-center'>만원</div>
                                </div>
                                <div className='contents-row'>
                                  <input 
                                    className='payment-input detail' 
                                    value={paymentPartnerJson[i].payNm} 
                                    onChange={(e)=>{
                                      let array = [...paymentPartnerJson];
                                      array[i].payNm = e.target.value;
                                      setPaymentPartnerJson(array);
                                    }}
                                  ></input>
                                  <div className='detail-button del flex-center' onClick={()=>{
                                    let array = [...paymentPartnerJson];
                                    array.splice(i,1);
                                    setPaymentPartnerJson(array);
                                    }}
                                  >-</div>
                                  {
                                  i == paymentPartnerJson.length - 1?
                                  <div className='detail-button add flex-center' onClick={()=>{
                                    let array = [...paymentPartnerJson];
                                    array.push(           
                                    {
                                      "numInstallment" : paymentPartnerJson.length + 1,
                                      "amountInstallmnt" : 0,
                                      "payDe" : "2024-06-19",
                                      "payNm" : '잔여금'
                                    });
                                    setPaymentPartnerJson(array);
                                  }}>+</div>
                                  :
                                  null
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                        </div>
                        :
                        <div>
                        {
                          paymentPartner && paymentPartner.length == 0?
                          <div className='flex-center payment-step'>※ 지급정보가 없습니다.</div>
                          :
                          <div>
                          {
                          paymentPartner && paymentPartner.map((e, i)=>{
                            return (
                              <div key={i} className='payment-step'>
                                <div className='step'>
                                  {e.pay_num_installmnt}차 지급 
                                  (24-10-28)
                                  {/* ({moment(e.pay_de).format("YY.MM.DD")}) */}
                                </div>
                                <div className='payment contents-row'>
                                  {/* <div className='payment-text flex-center'>{e.amount_installmnt}</div>*/}
                                  {/* 900 */}
                                  450
                                  <div className='payment-text flex-center'>만원</div>
                                  {
                                  e.pay_complete?
                                  <div className='payment-mark partner flex-center' onClick={()=>{postPaymentComplete(2, i, 'false')}}>완납</div>
                                  :
                                  <div className='payment-mark none flex-center' onClick={()=>{postPaymentComplete(2, i, 'true')}}>미납</div>
                                  }
                                  {
                                  e.pay_statemnt_file_path?
                                  <div className='download-button payment-bill flex-center' onClick={()=>{window.location.href = e.pay_statemnt_file_path}}>지급명세서 다운로드 </div>
                                  :
                                  <div className='download-button payment-bill flex-center'>
                                    <input
                                      type="file"
                                      accept=".jpg, .pdf, .txt, .zip"
                                      style={{ visibility: "hidden", display: "none" }}
                                      onChange={(event)=>{onProductChangeHandler(event, e.pay_num_installmnt, 2)}}
                                      ref={productRef}
                                    ></input>
                                    <label className='' onClick={handleProductClick}>지급명세서 업로드</label>
                                  </div>
                                  }
                                </div>
                                <div className='name'>안동문화관광단지 정사영상 제작</div>
                                {/* <div className='name'>{e.pay_nm}</div> */}
                              </div>
                            )
                          })
                          }
                          </div>
                        }
                        </div>
                      }

                      <div className='flex-spacer'></div>

                      <div className='button-group contents-row'>
                      {/* {
                        inputPartnerMod?
                        <div className='flex-center button save' onClick={()=>{postPaymentInfo(2)}}>결제관리 저장</div>
                        :
                        <div className='flex-center button save' onClick={()=>{setInputPartnerMod(true)}}>결제관리 수정</div>
                      } */}
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          selectedMenu == 'product' ?
          <div className='menu-container contents-col'>
            <div className='menu-content contents-col'>
            </div>
          </div>
          :
          null
          }
        </div>
        :
        null
        }
      </div>

      {
      (userType == 1 && myProposal && myProposal.prj_apply_stts_cd < 2 || myProposal && myProposal.prj_apply_stts_cd < 2) && projectApply == true?   // (파트너) 지원하기 후
      <div className='project-view-detail-sub contents-col'>
        <div className='sub-header flex-center contents-col'>
          {/* <div 
            className='cancel button flex-center' 
            onClick={()=>{setPopupState('cancelApply');setShowQuestionPopup(true);setPopupMsg('지원을 취소하시겠습니까?');}}>
          지원 취소하기</div> */}
          {
          myProposal?
          <div className='contents-col'>
          <div 
            className='save button flex-center'
            onClick={()=>{setPopupState('modifyProposal');setShowQuestionPopup(true);setPopupMsg('지원서를 수정하시겠습니까?');}}
          >지원서 수정하기</div>
          <div 
            className='save button flex-center'
            onClick={()=>{setPopupState('deleteProposal');setShowQuestionPopup(true);setPopupMsg('지원서를 삭제하시겠습니까?');}}
          >지원서 삭제하기</div>
          </div>
          :
          <div 
            className='save button flex-center'
            onClick={()=>{setPopupState('createProposal');setShowQuestionPopup(true);setPopupMsg('지원서를 저장하시겠습니까?');}}
          >지원서 저장하기</div>
          }
          {
          myProposal?
          <div 
            className='complete button flex-center'
            onClick={()=>{setPopupState('applyProject');setShowQuestionPopup(true);setPopupMsg('지원서를 제출하시겠습니까?');}}
          >지원 완료하기</div>
          :
          null
          }
        </div>
      </div>
      :
      userType == 1 || (userType == 2 && projectInfo && projectInfo.prj_owner_user_id.toString() !== userID) ?
      // userType == 1 && projectApply == false?   // (파트너) 지원하기 전
      <div className='project-view-detail-sub contents-col'>
        {/* {console.log(projectInfo)} */}
        {/* {console.log(myProposal)} */}
        {
        // (userType == 1 && myProposal && myProposal.prj_apply_stts_cd >= 2) ?   // (파트너) 지원하기 전
        userType == 1 && projectApply == false?   // (파트너) 지원하기 전
        <div className='sub-header flex-center contents-col'>
          {/* {console.log(myProposal && myProposal.prj_apply_stts_cd)} */}
          {/* {console.log(projectInfo && projectInfo)} */}
          {
          // myProposal && myProposal.prj_apply_stts_cd == 2 || myProposal && myProposal.prj_apply_stts_cd == 100 ?
          // myProposal && myProposal.prj_apply_stts_cd == 2 || myProposal && myProposal.prj_apply_stts_cd == 4 || myProposal && myProposal.prj_apply_stts_cd == 100?
          myProposal && myProposal.prj_apply_stts_cd !== 1 ?
          <div className='none button flex-center'>
            지원 완료</div>
          :
          <div 
            className='apply button flex-center' 
            onClick={()=>{
              if(projectInfo && moment(projectInfo.prj_apply_de, "YYYY-MM-DD").diff(moment(), 'days') < 0){
                setPopupMsg("모집 마감된 프로젝트입니다.")
                setShowAlarmPopup(true);
              }
              else{
                setProjectApply(true);
              }
            }}>
            프로젝트 지원하기
          </div>
          }

          {
          projectInfo && projectInfo.prj_itrst_yn?
          <div className='reg button flex-center' onClick={()=>{checkItrst(projectInfo.prj_id, 'false')}}><FontAwesomeIcon icon="fa-solid fa-heart"/>프로젝트 찜하기</div>
          :
          <div className='reg button flex-center' onClick={()=>{checkItrst(projectInfo.prj_id, 'true')}}><FontAwesomeIcon icon="fa-regular fa-heart"/>프로젝트 찜하기</div>
          }
        </div>
        : null}
        <div className='sub-main contents-col'>
          <div className='contents-row align-center'>
            <img className='client-logo' src={projectOwnerInfo? projectOwnerInfo.photoUrl : null}></img>
            <div className='client-name content-text-typeA flex-center'>{projectOwnerInfo? projectOwnerInfo.user_nm : null}</div>
            <div className='client-mark flex-center'>클라이언트</div>
          </div>
          <div className='contents-row'>
            <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-star"/></div>
            <div className='flex-center client-score'>{projectOwnerInfo? projectOwnerInfo.review_score_avr.toFixed(1) : null}</div>
            {projectOwnerInfo && projectOwnerInfo.review_score_avr >= 4 && (
              <>
                <div className='flex-center'>
                  <FontAwesomeIcon icon="fa-solid fa-award" />
                </div>
                <div className='flex-center client-score'>평가우수</div>
              </>
            )}
            {/* <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award"/></div>
            <div className='flex-center client-score'>평가우수</div> */}
          </div>
          <div className='client-etc contents-col'>
            {projectOwnerInfo && projectOwnerInfo.intro != 'null'? projectOwnerInfo.intro : '※ 소개 문구가 없습니다.'}
          </div>
          <div className='part-divider'></div>
          <div className='contents-row'></div>
          <div className='contents-row'>
            <div className='contents-col'>
              <div className='client-text'>프로젝트 등록</div>
              <div className='client-text'>계약한 프로젝트</div>
              <div className='client-text'>진행중 프로젝트</div>
              <div className='client-text'>완료 프로젝트</div>
            </div>
            <div className='flex-spacer'></div>
            <div className='contents-col'>
              <div className='client-text'>{projectOwnerInfo && projectOwnerInfo.prj_info.prj_owner_co} 건</div>
              <div className='client-text'>{projectOwnerInfo && projectOwnerInfo.prj_info.prj_owner_contrct_co} 건</div>
              <div className='client-text'>{projectOwnerInfo && projectOwnerInfo.prj_info.prj_owner_prcss_co} 건</div>
              <div className='client-text'>{projectOwnerInfo && projectOwnerInfo.prj_info.prj_owner_complete_co} 건</div>
              {/* <div className='client-text'>{'?'} 건</div>
              <div className='client-text'>{'?'} 건</div>
              <div className='client-text'>{'?'} 건</div>
              <div className='client-text'>{'?'} 건</div> */}
            </div>
          </div>
          <div className='view-button flex-center' onClick={()=>{navigate('/project');localStorage.setItem('client-id', projectOwnerInfo.user_id);}}>이 클라이언트의 다른 프로젝트 보기</div>
        </div>
      </div>
      :
      (userType == 2 && projectInfo && projectInfo.prj_owner_user_id.toString() === userID) || userType ==  3?  // (클라이언트) 프로젝트 진행 이력
      // userType == 2 || 3?  // (클라이언트) 프로젝트 진행 이력
      // projectInfo && projectInfo.prj_owner_user_id === userID || userType == 3?  // (클라이언트) 프로젝트 진행 이력
      <div>
      <div className='sub-project contents-col'>
      {/* {console.log(projectOwnerInfo && projectOwnerInfo.prj_owner_user_id)} */}
      {/* {console.log(projectInfo.prj_owner_user_id)}
      {console.log(userID)}
      {console.log(projectInfo.prj_owner_user_id.toString() === userID)} */}
        <div className='header-text'>프로젝트 진행 이력</div>
        <div className='part-divider'></div>
        <div className='contents-col'>
          <div className='contents-row'>
            <div className={`${process == 1? 'project-step-pro':'project-step-com'} flex-center`}>프로젝트 작성</div>
            <div className='project-step-pro-text flex-center'>{projectInfo && projectInfo.prj_reg_de !=null? projectInfo.prj_reg_de : '정보없음'}</div>
          </div>
          <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 2 || process == 3? 'project-step-pro':process < 2? 'project-step-pre':'project-step-com'} flex-center`}>프로젝트 공지</div>
            <div className={`${process >= 2? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 2? `${projectInfo && projectInfo.prj_open_de != null? projectInfo.prj_open_de : '정보없음'}` : '미진행'}</div>
          </div>
          <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 4? 'project-step-pro':process < 4? 'project-step-pre':'project-step-com'} flex-center`}>파트너 선정</div>
            <div className={`${process >= 4? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 4? `${projectInfo && projectInfo.prj_contrct_de != null? projectInfo.prj_contrct_de : '정보없음'}` : '미진행'}</div>
          </div>
          <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 5? 'project-step-pro':process < 5? 'project-step-pre':'project-step-com'} flex-center`}>프로젝트 시작</div>
            <div className={`${process >= 5? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 5? `${projectInfo && projectInfo.prj_start_de != null? projectInfo.prj_start_de : '정보없음'}` : '미진행'}</div>
          </div>
          {projectInfo.prj_qc_yn && (
            <>
            <div className='part-connecter'></div>
              <div className='contents-row'>
                <div className={`${process == 6? 'project-step-pro':process < 6? 'project-step-pre':'project-step-com'} flex-center`}>작업 검수</div>
                <div className={`${process >= 6? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 6? `${projectInfo && projectInfo.prj_verified_de != null? projectInfo.prj_verified_de : '정보없음'}` : '미진행'}</div>
            </div> 
            </>
          )}
          {/* <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 6? 'project-step-pro':process < 6? 'project-step-pre':'project-step-com'} flex-center`}>작업 검수</div>
            <div className={`${process >= 6? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 6? `${projectInfo && projectInfo.prj_verified_de != null? projectInfo.prj_verified_de : '정보없음'}` : '미진행'}</div>
          </div> */}
          <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 7? 'project-step-pro':process < 7? 'project-step-pre':'project-step-com'} flex-center`}>프로젝트 완료</div>
            <div className={`${process >= 7? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process >= 7? `${projectInfo && projectInfo.prj_end_de != null? projectInfo.prj_end_de : '정보없음'}` : '미진행'}</div>
          </div>
          {/* <div className='part-connecter'></div>
          <div className='contents-row'>
            <div className={`${process == 8? 'project-step-pro':process < 8? 'project-step-pre':'project-step-com'} flex-center`}>대금 지급</div>
            <div className={`${process >= 8? 'project-step-pro-text':'project-step-pre-text'} flex-center`}>{process < 8? '미진행':`${projectInfo && projectInfo.prj_payment_de != null? projectInfo.prj_payment_de : '정보없음'}`}</div>
          </div> */}
          {
            projectInfo && projectInfo.prj_owner_user_id == userID && userType == 2 && projectApply == false?
            <div className='result-product flex-center'>산출물 관리</div>
            :
            null
          }
        </div>
      </div>
      {
        userType == 3?
        <div className='sub-project-manager'>
          <div className='header-text'>진행상태 관리</div>
          <div className='part-divider'></div>
          <div className='contents-col'>
            <div className='contents-row flex-center'>
              <div className='content-text flex-center'>진행상태</div>
              <div className='flex-spacer'></div>
              <Dropdown
                className='dropdown'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={processOption}
                value={process}
                onChange={(e, data)=>{setProcess(data.value)}}
              ></Dropdown>
            </div>
            <div className='contents-row  flex-center'>
              <div className='content-text flex-center'>변경일자</div>
              <div className='flex-spacer'></div>
              <Checkbox
                className='checkbox' 
                id='process-date' 
                // value={processDateBool} 
                checked={processDateBool}
                onChange={()=>{setProcessDateBool(!processDateBool)}}
              ></Checkbox>
              <input 
                type='date'
                className='process-date'
                value={processDate} 
                onChange={(e)=>{setProcessDate(e.target.value)}}
              ></input>
            </div>
          </div>
          <div 
            className='button flex-center header-text'
            onClick={()=>{updateProcess()}}
          >저장하기</div>
        </div>
        :
        null
      }
      {
        contractInfo && userType == 3 && selectedMenu == 'payment'?
        <div className='sub-project-manager info'>
          <div className='contents-row'>
            <div className='header-text'>계약서 서명 정보</div>
            <div className='flex-spacer'></div>
            <div className='button small flex-center' onClick={()=>{setContractInfo();}}>계약서 재생성</div>
          </div>
          <div className='part-divider'></div>

            <div className='contents-row'>
              <div className='title'>계약서 ID</div>
              <div className='content'>{contractInfo.contrct_id? contractInfo.contrct_id : '정보없음'}</div>
            </div>

          <div className='part-divider'></div>

          <div className='header'>중개사 (지오캡쳐)</div>
          <div className='contents-row'>
            <div className='contents-col'>
              <div className='title'>주소</div>
              <div className='title'>이름</div>
              <div className='title'>이메일</div>
              <div className='title'>서명일시</div>
            </div>
            <div className='contents-col'>
              <div className='content'>경기도 화성시 동탄첨단산업1로 51-9 동탄M타워 1614호</div>
              <div className='content'>대표 김동욱</div>
              <div className='content'>helpdesk@geocapture.co.kr</div>
              {/* <div className='content'>{contractInfo.agent_sign_dt? contractInfo.agent_sign_dt : '-'}</div> */}
              <div className='content'>{contractInfo.agent_sign_dt ? formatDate(contractInfo.agent_sign_dt) : '-'}</div>
            </div>
          </div>

          <div className='part-divider'></div>
          
          <div className='header'>클라이언트 ({contractInfo.client_user_nm})</div>
          <div className='contents-row'>
            <div className='contents-col'>
              <div className='title'>주소</div>
              <div className='title'>이름</div>
              <div className='title'>이메일</div>
              <div className='title'>서명일시</div>
            </div>
            <div className='contents-col'>
              <div className='content'>{contractInfo.client_addr? contractInfo.client_addr : '정보없음'}</div>
              <div className='content'>{contractInfo.client_signer_rank? contractInfo.client_signer_rank : '정보없음'} {contractInfo.client_signer_nm? contractInfo.client_signer_nm : '정보없음'}</div>
              <div className='content'>{contractInfo.client_email? contractInfo.client_email : '정보없음'}</div>
              {/* <div className='content'>{contractInfo.client_sign_dt? contractInfo.client_sign_dt : '-'}</div> */}
              <div className='content'>{contractInfo.client_sign_dt ? formatDate(contractInfo.client_sign_dt) : '-'}</div>
            </div>
          </div>

          <div className='part-divider'></div>

          <div className='header'>파트너 ({contractInfo.partner_user_nm})</div>
          <div className='contents-row'>
            <div className='contents-col'>
              <div className='title'>주소</div>
              <div className='title'>이름</div>
              <div className='title'>이메일</div>
              <div className='title'>서명일시</div>
            </div>
            <div className='contents-col'>
              <div className='content'>{contractInfo.partner_addr? contractInfo.partner_addr : '정보없음'}</div>
              <div className='content'>{contractInfo.partner_signer_rank? contractInfo.partner_signer_rank : '정보없음'} {contractInfo.partner_signer_nm? contractInfo.partner_signer_nm : '정보없음'}</div>
              <div className='content'>{contractInfo.partner_email? contractInfo.partner_email : '정보없음'}</div>
              {/* <div className='content'>{contractInfo.partner_sign_dt? contractInfo.partner_sign_dt : '-'}</div> */}
              <div className='content'>{contractInfo.client_sign_dt ? formatDate(contractInfo.client_sign_dt) : '-'}</div>
            </div>
          </div>
        </div>
        :
        !contractInfo && userType == 3 && selectedMenu == 'payment'?
        <div className='sub-project-manager'>
          <div className='header-text'>계약서 생성</div>
          <div className='part-divider'></div>
          <div className='contents-row'>
            <Checkbox 
              className='contract-checkbox'
              label='템플릿으로 생성'
              value={1}
              checked={contract == 1}
              onChange={(e, data)=>{setContract(data.value)}}
            >
            </Checkbox>
            <div className='flex-spacer'></div>
            <Checkbox 
              className='contract-checkbox'
              label='계약서 ID 직접 입력'
              value={2}
              checked={contract == 2}
              onChange={(e, data)=>{setContract(data.value)}}
            >
            </Checkbox>
          </div>  
          {
            contract == 1?
            <div className='contents-col'>
              <div className='contents-row flex-center'>
                <div className='content-text flex-center'>템플릿 제목</div>
                <div className='flex-spacer'></div>
                <Dropdown
                  className='dropdown template'
                  placeholder='선택'
                  fluid
                  selection
                  icon='angle down'
                  options={templateList}
                  value={templateID}
                  onChange={(e, data)=>{setTemplateID(data.value)}}
                ></Dropdown>
              </div>
            </div>
            :
            <div className='contents-col'>
              <div className='contents-row flex-center'>
                <div className='content-text flex-center'>계약서 ID</div>
                <div className='flex-spacer'></div>
                <input className='contract-id'></input>
              </div>
            </div>
          }
          {
            contract == 1?
            <div 
              className='button flex-center header-text'
              onClick={()=>{postRequireSign()}}
            >계약서 서명요청</div>
            :
            <div 
              className='button flex-center header-text'
              onClick={()=>{}}
            >계약서 ID 입력 (검증)</div>
          }
        </div>
        :
        null
      }
      </div>
      :
      null
      }

      {
        showQuestionPopup?
        <Popup
          show={showQuestionPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center ok_btn" 
                onClick={() => {
                  if(popupState == 'modifyProject'){
                    setShowQuestionPopup(false);
                    modifyProject();
                  }
                  else if(popupState == 'deleteProject'){
                    setShowQuestionPopup(false);
                    deleteProject();
                  }
                  else if(popupState == 'cancelApply'){
                    setShowQuestionPopup(false);
                    setProjectApply(false);
                    setMyProposal(null);
                    setProposalCost(null);
                    setProposalDay(null);
                    setProposalDetail(null);

                    setProposalCost('');
                    setProposalDay('');
                    setProposalDetail('');
                    setProposalPrt([]);
                    // setProposalPrtInfo();
                  }
                  else if(popupState == 'modifyProposal'){
                    setShowQuestionPopup(false);
                    modifyProposal();
                  }
                  else if(popupState == 'deleteProposal'){
                    setShowQuestionPopup(false);
                    deleteProposal();
                  }
                  else if(popupState == 'createProposal'){
                    setShowQuestionPopup(false);
                    createProposal();
                  }
                  else if(popupState == 'applyProject'){
                    setShowQuestionPopup(false);
                    applyProject();
                  }
                  else if(popupState == 'addportfolio'){
                    setShowQuestionPopup(false);
                    navigate('/profile');
                  }
                }}  
              >{popupState == 'modify'? '수정' : popupState == 'delete'? '삭제' : '확인'}</div>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowQuestionPopup(false);
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

      {
        showAlarmPopup?
        <Popup
          show={showAlarmPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowAlarmPopup(false);
                  getProjectInfo();
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

      <Popup
        show={showFileDelPopup}
        onCancel={() => setShowFileDelPopup(false)}
        header="알림"
        icon={<i className="code x icon"  onClick={() => setShowFileDelPopup(false)} /> }
      >
        <div className='modal-info'>
            <p>{popupMsg}</p>
            {/* <p>업로드한 파일을 삭제하시겠습니까?</p> */}
            <button 
                className="zoneinfo_close_btn" 
                onClick={() => {
                    setFileToDeleteIndex(null);
                    setShowFileDelPopup(false);
                }}>
            아니오</button>
            <button 
                className="zoneinfo_ok_btn" 
                onClick={() => {
                    setShowFileDelPopup(false);
                    if (popupMsg.includes('전체')) {
                        handleDeleteAllFiles();
                    } else {
                        handleDeleteFile(fileToDeleteIndex);
                    }
                }}
            >예</button>
        </div>
      </Popup>
    </div>

    {
      selectProposal?
      <ProposalDetail
        selectProposal={selectProposal}
        setSelectProposal={setSelectProposal}
        getProposalList={getProposalList}
      >
      </ProposalDetail>
      :
      null
    }
  </React.Fragment>
  )
}

export default ProjectDetail;