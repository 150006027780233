import './ManagerAccountMgt.css'

import React, {useEffect, useState } from 'react';
import { Pagination, Icon } from 'semantic-ui-react';

// import Modal from '../../../shared/components/uiElements/Modal'
// import ReactSlider from 'react-slider'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// AXIOS
import axios from "axios";
// import config from '../../config/config';

function ReceivedMsg (props) {
    // LocalStorage
    const APIToken = localStorage.getItem('login-token');
    const loginUserId = localStorage.getItem('user-id');
    const loginUserLevel = localStorage.getItem('user-level');

    // Pagination
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    // 메시지 읽음 (업데이트)
    const [readMsgState, setReadMsgState] = useState(false);
    const readMsg = () => {
        if (msgId) {
            axios({
                method:'post',
                url:`/api/msg/read`,
                headers: {
                    'Authorization': `Bearer {${APIToken}}`
                },
                data: {
                    msgId : msgId,
                }, 
            })
            . then((result) => {
                // console.log(result.data);
                if (result.data.success) {
                    setReadMsgState(true);
                    // setShowPopup(true);
                    // setPopupMsg(result.data.msg);
                    // setPopupMsg("포트폴리오가 삭제되었습니다.");
                    // setIsSuccessOk(true);
                } else {
                    // setShowPopup(true);
                    // setPopupMsg(result.data.msg);
                    // setPopupMsg("포트폴리오를 삭제하지 못하였습니다.");
                }
            })
            . catch((error) => {
                
            })
        }
    }

    // 전체보기
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

 
    // 메시지 목록 조회
    const [messageList, setMessageList] = useState();
    const portfoPageSize = 10;
    // useEffect(() => {
    //     axios({
    //       method:'get',
    //       url:`/api/msg/list?page=${activePage}&perPage=${portfoPageSize}`,
    //       url:`/api/msg/list?toUserId=${loginUserId}&page=${activePage}&perPage=${portfoPageSize}`,
    //       //   url:`/api/account/list?inactivated=true`,
    //       headers: {
    //         'Authorization': `Bearer {${APIToken}}`
    //     },
    //     })
    //     .then((result)=>{
    //         if (result.data.success) {
    //             const entireList = result.data;
    //             // console.log(result.data.data);
    //             setMessageList(entireList.data);
    //             setTotalPages(Math.ceil(entireList.total / portfoPageSize));
    //         } 
    //         setReadMsgState(false);
    //     })
    //     .catch((error)=>{
    //         console.log(error)  
    //     })
    // }, [activePage, readMsgState])

    useEffect(() => {
        const url = loginUserLevel === '100' && isChecked
            ? `/api/msg/list?page=${activePage}&perPage=${portfoPageSize}`
            : `/api/msg/list?toUserId=${loginUserId}&page=${activePage}&perPage=${portfoPageSize}`;
        axios({
          method:'get',
          url: url,
        //   url:`/api/msg/list?page=${activePage}&perPage=${portfoPageSize}`,
        //   url:`/api/msg/list?toUserId=${loginUserId}&page=${activePage}&perPage=${portfoPageSize}`,
          //   url:`/api/account/list?inactivated=true`,
          headers: {
            'Authorization': `Bearer {${APIToken}}`
        },
        })
        .then((result)=>{
            if (result.data.success) {
                const entireList = result.data;
                // console.log(result.data.data);
                setMessageList(entireList.data);
                setTotalPages(Math.ceil(entireList.total / portfoPageSize));
            } 
            setReadMsgState(false);
        })
        .catch((error)=>{
            console.log(error)  
        })
    }, [activePage, readMsgState, isChecked])

    // useEffect(() => {
    //     const url = loginUserLevel === '100' && isChecked
    //     ? `/api/msg/list?page=${activePage}&perPage=${portfoPageSize}`
    //     : `/api/msg/list?toUserId=${loginUserId}&page=${activePage}&perPage=${portfoPageSize}`;

    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: {
    //             'Authorization': `Bearer ${APIToken}`
    //         },
    //     })
    //     .then((result) => {
    //         if (result.data.success) {
    //             const entireList = result.data;
    //             setMessageList(entireList.data);
    //             setTotalPages(Math.ceil(entireList.total / portfoPageSize));
    //         }
    //         setReadMsgState(false);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }, [activePage, readMsgState, isChecked]);
    
    // 메시지 제목 보이기
    // const [visibleContentIndex, setVisibleContentIndex] = useState(null);

    // const handleTitleClick = (index) => {
    //     setVisibleContentIndex(visibleContentIndex === index ? null : index);
    // };

    const [visibleIndexes, setVisibleIndexes] = useState({});
    // 메시지 아이디
    const [msgId, setMsgId] = useState(null);
    // const handleTitleClick = (index) => {
    //     setVisibleIndexes(prevState => ({
    //       ...prevState,
    //       [index]: !prevState[index]
    //     }));
    // };
    const handleTitleClick = (index, msgId) => {
        setVisibleIndexes(prevState => ({
          ...prevState,
          [index]: !prevState[index]
        }));
        setMsgId(msgId); 
        // readMsg(msgId);
    };

    const handleIconClick = (index) => {
        setVisibleIndexes(prevState => ({
          ...prevState,
          [index]: false
        }));
    };
    
    useEffect(() => {
        if (msgId) {
            readMsg(msgId);
        }
    }, [msgId])

    // useEffect(() => {
    //     axios({
    //         method:'post',
    //         url:`/api/msg/read`,
    //         headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //         data: {
    //             // msgId : ,
    //         }, 
    //     })
    //     . then((result) => {
    //         console.log(result.data);
    //         if (result.data.success) {
    //             // setShowPopup(true);
    //             // setPopupMsg(result.data.msg);
    //             // setPopupMsg("포트폴리오가 삭제되었습니다.");
    //             // setIsSuccessOk(true);
    //         } else {
    //             // setShowPopup(true);
    //             // setPopupMsg(result.data.msg);
    //             // setPopupMsg("포트폴리오를 삭제하지 못하였습니다.");
    //         }
    //     })
    //     . catch((error) => {
            
    //     })
    // }, [])
    
    return (
        <React.Fragment>
            <div className='Manager-page-container'>
                <div className='contents-row manager'>
                <div className='contents-col manager-main'>
                    <div className='contents-row full-width main-title-container'>
                        <div className='contents-row full-width flex-center main-title-box'>
                            <div className='main-title'>받은 메시지</div>
                        </div>
                        {loginUserLevel === '100' ?
                        <div className='contents-row flex-center field'>
                            <input
                                id="partnerCheckbox"
                                type='checkbox'
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                // onChange={handlePartnerChange}
                            />
                            <label htmlFor="partnerCheckbox">전체보기</label>
                        </div>
                        : null
                        }
                    </div>
                    
                    {/* <div className='hr-solid'/>  */}
                        <React.Fragment>
                        {/* <div className='full-width'>
                            <div className='main-text'>받은 메시지</div>
                            
                        </div> */}

                        <div className="full-width account-mgt-table">
                            <div className="contents-row full-width table-label column-nm">
                                <div className="table-cell reveive flex-center">읽음</div>
                                <div className="table-cell reveive flex-center">보낸사람</div>
                                {isChecked ? <div className="table-cell reveive flex-center">받는사람</div> : null}
                                <div className="table-cell reveive flex-center email">제목</div>
                                <div className="table-cell reveive flex-center">시간</div>
                            </div>
                            {messageList && messageList.length !== 0 ? 
                            messageList.map((msg, index) => {
                                return (
                                <div className='contents-col full-width' key={index}>
                                    <div className="contents-row full-width table-label">
                                        <div className="table-cell flex-center">
                                            {/* {console.log(msg.msg_read_dt === null)} */}
                                            {msg.msg_read_dt === null ? <div className='msg-icon not-read'></div> : <div className='msg-icon read'></div>}
                                        </div>
                                        <div className="table-cell flex-center">{msg.from_user_nm}</div>
                                        {isChecked ? <div className="table-cell flex-center">{msg.to_user_nm}</div> : null}
                                        {/* <div className="table-cell flex-center email" onClick={() => handleTitleClick(index)}>{msg.msg_title}</div> */}
                                        <div 
                                            className="table-cell flex-center email data"
                                            onClick={() => { 
                                                handleTitleClick(index, msg.msg_id);
                                            }}
                                            // style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                            >
                                            {msg.msg_title}
                                        </div>
                                        <div className="table-cell flex-center">{msg.msg_send_dt.slice(0, 16)}</div>
                                    </div>
                                    {visibleIndexes[index] && (
                                        <>
                                        <div className="contents-row full-width msg-contents">
                                            {msg.msg_contents}
                                        </div>
                                        <FontAwesomeIcon icon="fa-solid fa-angles-up" className="fa-angles-up" onClick={() => handleIconClick(index)} />
                                        </>
                                    )}
                                </div>
                                );
                            
                                })
                                :
                                <div className='contents-row full-width flex-center'>
                                    <p className='result-info received-msg'>받은 메시지가 없습니다.</p>
                                </div>
                            } 
                            {messageList &&messageList.length !== 0 ?
                                <div className='flex-center'>
                                    <div className='flex-center'>
                                        <Pagination
                                            className="pagination"
                                            activePage={activePage}
                                            totalPages={totalPages}
                                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                            nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                                            onPageChange={handlePageChange}
                                        >                        
                                        </Pagination>
                                    </div>
                                </div>
                            : null}
                        </div>
                        
                        {/* <ManagerAccountMgt></ManagerAccountMgt> */}
                            {/* 표 */}
                        </React.Fragment>
                        {/* <ManagerAccountMgt></ManagerAccountMgt> */}
                        {/* 표 */}
                        
                </div>
                </div>
            </div>
                {/* <div className="full-width account-mgt-table">
                        <div className="contents-row full-width table-label">
                            <div className="table-cell flex-center">읽음</div>
                            <div className="table-cell flex-center">보낸사람</div>
                            <div className="table-cell flex-center email">제목</div>
                            <div className="table-cell flex-center">시간</div>
                        </div>
                        {/* {userList && userList.map((user, index) => (
                        <div className="contents-row full-width table-label" key={index}>
                            <div className="table-cell flex-center">{index + 1}</div>
                            <div className="table-cell flex-center">{user.user_nm}</div>
                            <div className="table-cell flex-center email">{user.email}</div>
                            <div className="table-cell flex-center">{user.phone_no}</div>
                            <div className="table-cell flex-center">{user.user_ty === 1 ? '파트너' : user.user_ty === 2 ? '클라이언트' : '미정'}</div>
                            <div className="table-cell flex-center">{user.co_ty === 1 ? '개인' : user.user_ty === 2 ? '개인사업자' : user.user_ty === 3 ? '법인사업자' : '미정'}</div>
                            <div className="table-cell flex-center">{user.actvtn_cd ==  1 ? '활성' : user.actvtn_cd ==  2 ? '비활성' : user.actvtn_cd ==  3 ? '탈퇴' : null}</div>
                            <div className="table-cell flex-center">
                                <div className="gc-btn gc-btn-line send-msg" onClick={() => {setShowSendMsg(true); setSendMsgUserData(user)}}>보내기</div>
                            </div>
                        </div>
                        ))} 
                        {/* {userList &&userList.length !== 0 ?
                            <Pagination
                                className="pagination"
                                activePage={activePage}
                                totalPages={totalPages}
                                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                                onPageChange={handlePageChange}
                            >                        
                            </Pagination>
                        : null} 
                    </div> */}
        
        </React.Fragment>
    )
}

export default ReceivedMsg;