import './SendMsg.css'
import Modal from '../../shared/components/uiElements/Modal';

import React, { useState } from 'react';

// import Modal from '../../../shared/components/uiElements/Modal'
// import ReactSlider from 'react-slider'

// import Popup from './Popup';
import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";
// import config from '../../config/config';

function SendMsg (props) {
    // console.log(props.sendMsgUserData);
    
    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

    // LocalStorage
    const APIToken = localStorage.getItem('login-token');

    // 메시지 제목 
    const [msgTitle, setMsgTitle] = useState();
    const handleMsgTitle = (e) => {
        e.preventDefault();
        setMsgTitle(e.target.value);
    };
    
    // 메시지 분류 코드 (프로젝트 제안 : 1 / 기타 : 100)
    const [classificationCode, setClassificationCode] = useState();
    const handleCheckboxChange = (code) => {
        setClassificationCode(code);
    };

    // 메시지 내용
    const [msgContents, setMsgContents] = useState();
    const handleMsgContents = (e) => {
        e.preventDefault();
        setMsgContents(e.target.value);
    };

    // 메시지 보내기 (추가)
    // console.log(props.sendMsgUserData.user_nm)
    const sendMsg = () => {
        
        const msgTitle = `매니저가 보낸 메시지 입니다.`
        // const msgTitle = `${props.sendMsgUserData.user_nm}에게 받은 메시지 입니다.`
        axios({
            method:'post',
            url:`/api/msg/send`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: {
                msgTyCd : 100,
                toUserId : props.sendMsgUserData.user_id,
                msgTitle : msgTitle,
                msgContents : msgContents,
                // prjId : 
            }, 
        })
        . then((result) => {
            // console.log(result.data);
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오가 삭제되었습니다.");
                // setIsSuccessOk(true);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("포트폴리오를 삭제하지 못하였습니다.");
            }
        })
        . catch((error) => {
            
        })
    }

    
    return (
        <React.Fragment>
            <Modal
                show={props.showSendMsg}
                // onCancel={()=>{props.setShowModifyLegend(false)}}
                // header="주제도 범례 변경"
                icon={<i className="code x icon" onClick={()=>{props.setShowSendMsg(false)}} /> }
                // className={`modifyLegend ${props.isMobile ? 'mobile' : ''}`}
                className='contents-row send-msg-container'
                // className='contents-row product-reg-modal-container'
            >
                <div className='contents-col full-width send-msg-container-box'>
                    <div className='contents-row flex-center send-msg-who'>
                        {/* <div>아이콘</div> */}
                        {
                            !props.sendMsgUserData || props.sendMsgUserData.photoUrl === null || props.sendMsgUserData.photoUrl === undefined ?
                                // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                <div className='user-logo-img small none'></div>
                            :
                            <img
                                className="user-logo-img"
                                src={props.sendMsgUserData.photoUrl}
                                alt="Uploaded Image"
                            />
                        }
                        <div>{props.sendMsgUserData.user_nm}</div>
                        <p>에게 쪽지 보내기</p>
                    </div>
                    <div className='contents-row full-width flex-center msg-box'>
                        {/* <input
                            className='input-msg msg-title full-width'
                            placeholder='메시지 제목을 입력해 주세요.'
                            type='text'
                            onChange={handleMsgTitle}
                            value={msgTitle || ''}
                        >
                        </input>
                        <div className='contents-row flex-center msg-code'>
                            <div className='contents-row field'>
                                <input
                                    id="partnerCheckbox"
                                    type='checkbox'
                                    checked={classificationCode === 1}
                                    onChange={() => handleCheckboxChange(1)}
                                />
                                <label htmlFor="partnerCheckbox">프로젝트 제안</label>
                            </div>
                            <div className='contents-row field'>
                                <input
                                    id="clientCheckbox"
                                    type='checkbox'
                                    checked={classificationCode === 100}
                                    onChange={() => handleCheckboxChange(100)}
                                />
                                <label htmlFor="clientCheckbox">기타</label>
                            </div>
                        </div> */}
                    </div>

                    <textarea 
                        className='input-msg  msg-contents'
                        placeholder='보낼 내용을 입력해 주세요.'
                        // rows="5"
                        onChange={handleMsgContents}
                        value={msgContents || ''}
                    >
                    </textarea>
                    <div className='flex-center send-btn-box'>
                        <div className='gc-btn send-btn cancel' onClick={()=>{props.setShowSendMsg(false)}}>취소</div>
                        <div className='gc-btn send-btn send' onClick={sendMsg}>보내기</div>
                    </div>
                </div>
                
            </Modal>

            {/* <Popup
                show={showPopup}
                onCancel={() => {
                    setShowPopup(false);
                }}
                // header="알림"
                // icon={<i className="code x icon"  onClick={() => {setShowPopup(false);}} /> }
                >
                <div className='contents-col full-width flex-center popup-info'>
                <p>{popupMsg}</p>
                <button 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                    }}  
                >닫기</button>
                </div>
            </Popup> */}
            <Popup
                show={showPopup}
                header=""
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p>{popupMsg}</p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                        props.setShowSendMsg(false);
                        // setOnChangeClick(false);
                    }}  
                >닫기</div>
                </div>
            </Popup>
        
        </React.Fragment>
    )
}

export default SendMsg;