import './ProjectReg.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Dropdown } from 'semantic-ui-react';

import {BrowserRouter as Router, Route, useNavigate, Routes, Navigate} from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popup from '../../shared/components/uiElements/Popup';

import moment from 'moment';

// AXIOS
import axios from "axios";

function ProjectReg() {

  const APIToken = localStorage.getItem("login-token");

  const navigate = useNavigate();

  const [helpState, setHelpState] = useState(false);

  const [prjNm, setPrjNm] = useState('');                     // 1.프로젝트 이름
  const [prjTyCd, setPrjTyCd] = useState('');                 // 2.프로젝트 분류 코드
  const [prjTyCustom, setPrjTyCustom] = useState('');         // 3.프로젝트 분류 직접입력
  const [exptdCost, setExptdCost] = useState('');             // 4.예상금액 (만원)
  const [exptdDay, setExptdDay] = useState('');               // 5.예상일정 (일)
  const [sidoCd, setSidoCd] = useState('');                   // 6.지역 (시도코드)
  const [applyDe, setApplyDe] = useState('');                 // 7.모집 마감일자
  const [exptdStarDe, setExptdStarDe] = useState('');         // 8.예상 시작일자
  const [qcYn, setQcYn] = useState('');                       // 9.검수 포함여부
  const [prjDetail, setPrjDetail] = useState('');             // 10.상세 내용
  const [prjAttchFiles, setPrjAttchFiles] = useState('');     // 11.프로젝트 첨부파일

  const [regSuccess, setRegSuccess] = useState(false);

  const [showQuestionPopup, setShowQuestionPopup] = useState(false);
  const [showAlarmPopup, setShowAlarmPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');

  const locationOptions = [
    {text: '전국', value: 0},
    {text: '서울특별시', value: 11},
    {text: '부산광역시', value: 26},
    {text: '대구광역시', value: 27},
    {text: '인천광역시', value: 28},
    {text: '광주광역시', value: 29},
    {text: '대전광역시', value: 30},
    {text: '울산광역시', value: 31},
    {text: '세종특별자치시', value: 36},
    {text: '경기도', value: 41},
    {text: '강원도', value: 42},
    {text: '충청북도', value: 43},
    {text: '충청남도', value: 44},
    {text: '전라북도', value: 45},
    {text: '전라남도', value: 46},
    {text: '경상북도', value: 47},
    {text: '경상남도', value: 48},
    {text: '제주특별자치도', value: 50},
  ]

  const typeOptions = [
    {text: '일반 레포츠', value: 1},
    {text: '미디어 촬영', value: 2},
    {text: '시설점검', value: 3},
    {text: '공간정보', value: 4},
    {text: '농업,방제', value: 5},
    {text: '기타', value: 6},
  ]

  const checkOptions = [
    {text: '예', value: true},
    {text: '아니오', value: false},
  ]

  const fileRef = useRef(null);

  const onFileChangeHandler = async (e) => {
    let array = [...prjAttchFiles];
    array.push(e.target.files[0]);
    setPrjAttchFiles(array);
    e.target.value = '';
  }

  const handleFileClear = () => {setPrjAttchFiles('');}

  function registerProject () {
    console.log(sidoCd)
    const formData = new FormData();
    formData.append("prjNm", prjNm);
    formData.append("prjTyCd", prjTyCd);
    formData.append("prjTyCustom", prjTyCustom);
    formData.append("exptdCost", exptdCost);
    formData.append("exptdDay", exptdDay);
    formData.append("sidoCd", sidoCd);
    formData.append("applyDe", applyDe);
    formData.append("exptdStarDe", exptdStarDe);
    formData.append("qcYn", qcYn);
    formData.append("prjDetail", prjDetail);
    formData.append("prjAttchFiles", prjAttchFiles);

    axios({
      method:'post',
      url:`/api/project/add`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: formData,
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('프로젝트가 저장되었습니다.');
        setRegSuccess(true);
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg('프로젝트 생성에 실패하였습니다.\n입력값을 확인하세요.');
        setRegSuccess(false);
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
      setPopupMsg('프로젝트 생성 실패\n(요청 오류)');
      setRegSuccess(false);
      setShowAlarmPopup(true);
    })
  }

  return (
    <div className='project-reg'>
      <div className='contents-row'>
        <div className='project-reg-main'>
        <div className='project-reg-info'>
          <div className='main-text'>프로젝트 생성</div>  
          <div className='contents-row'>
            <div className='box recruit flex-center'>모집중</div>
            <div className='box new flex-center'>New</div>
            <div className='flex-spacer'></div>
            <div className='reg-date flex-center'>등록일자 : {moment().format('YYYY-MM-DD')}</div>
          </div>    
          <div className='contents-row border-bottom'>
            <input 
              className='title' 
              placeholder='제목을 입력해 주세요.' 
              value={prjNm}
              onChange={(e)=>{setPrjNm(e.target.value)}}
            ></input>
          </div>
          <div className='contents-row'>
            <div className='option contents-row flex-center'>
              <div className='option-name'>예상금액</div>
              <input 
                className='option-input text' 
                placeholder='미입력'
                value={exptdCost}
                onChange={(e)=>{setExptdCost(e.target.value)}}
              ></input>
              <div className='sub-text'>만원</div>
            </div>
            <div className='option contents-row flex-center'>
              <div className='option-name'>예상일정</div>
              <input 
                className='option-input text' 
                placeholder='미입력'
                value={exptdDay}
                onChange={(e)=>{setExptdDay(e.target.value)}}
              ></input>
              <div className='sub-text'>일</div>
            </div>
            <div className='option contents-row flex-center'>
              <div className='option-name'>지역</div>
              {/* <Dropdown
                className='option-input dropdown location none-border'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={locationOptions}
                value={sidoCd}
                onChange={(e, data)=>{setSidoCd(data.value)}}
              >
              </Dropdown> */}
              <Dropdown
                className='option-input dropdown location none-border'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={locationOptions}
                // options={sidoList.map(sido => ({ value: sido.sido_cd, text: sido.sido_nm }))}
                value={sidoCd}
                onChange={(e, data)=>{setSidoCd(data.value)}}
              >
              </Dropdown>
            </div>
          </div>
          <div className='contents-row'>
            <div className='contents-col'>
              <div className='option-icon flex-center'>●</div>
              <div className='option-icon flex-center'>●</div>
              <div className='option-icon flex-center'>●</div>
              <div className='option-icon flex-center'>●</div>
            </div>
            <div className='contents-col'>
              <div className='option-name'>모집 마감일자</div>
              <div className='option-name'>예상 시작일자</div>
              <div className='option-name'>분류</div>
              <div className='option-name'>검수 여부</div>
            </div>
            <div className='contents-col flex-center input-group'>
              <input 
                className='option-input date' 
                type='date'
                placeholder='선택'
                value={applyDe}
                onChange={(e)=>{setApplyDe(e.target.value)}}
              ></input>
              <input 
                className='option-input date' 
                type='date'
                placeholder='선택'
                value={exptdStarDe}
                onChange={(e)=>{setExptdStarDe(e.target.value)}}
              ></input>
              <Dropdown 
                className='option-input dropdown type none-border'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={typeOptions}
                value={prjTyCd}
                onChange={(e, data)=>{setPrjTyCd(data.value)}}
              >
              </Dropdown>
              <Dropdown 
                className='option-input dropdown check none-border'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={checkOptions}
                value={qcYn}
                onChange={(e, data)=>{setQcYn(data.value)}}
              >
              </Dropdown>

            </div>
            {
            prjTyCd == 6?
            <div className='contents-col'>
              <div className='option-icon flex-center'></div>
              <div className='option-icon flex-center'></div>
              <input
                className='option-input-sub'
                type='text'
                placeholder='직접입력'
                value={prjTyCustom}
                onChange={(e)=>{setPrjTyCustom(e.target.value)}}
              ></input>
            </div>
            :
            null
            }
          </div>  
        </div>

        <div className='project-reg-contents'>
          <div className='main-text'>과제 내용</div>
          <textarea 
            className='contents-input' 
            placeholder='과제 내용을 입력해 주세요.'
            value={prjDetail}
            onChange={(e)=>{setPrjDetail(e.target.value)}}
          ></textarea>
        </div>

        <div className='project-reg-add'>
          <div className='main-text'>첨부 파일</div>
          <div className='contents-row'>
            <div className='file-list'>
              {
              prjAttchFiles && prjAttchFiles.map((file, i)=>{
                return(
                  <div className='file-list-item flex-center contents-row'>
                    {file.name}
                    <div className='part-divider'></div>
                    <i className="code x icon" onClick={()=>{
                      let array = [...prjAttchFiles];
                      array.splice(i,1);
                      setPrjAttchFiles(array);
                    }}/>
                  </div>)
              }) 
              }
            </div>
            <div className='contents-col'>
              <input 
                type='file'
                id='fileinput'
                style={{ display: 'none' }}
                onChange={onFileChangeHandler}
                ref={fileRef}
              ></input>
              <label className='button flex-center' htmlFor='fileinput'>파일 추가</label>
              <div className='button flex-center' onClick={handleFileClear}>모두 삭제</div>
            </div>
          </div>
        </div>
        </div>

        <div className='project-reg-sub contents-col'>
          <div className='help button flex-center' onClick={()=>{setHelpState(true)}}>
            <FontAwesomeIcon icon="fa-regular fa-circle-question" />도움말</div>
          <div className='reg button flex-center' onClick={()=>{setShowQuestionPopup(true);setPopupMsg('프로젝트를 저장하시겠습니까?');}}>
            <FontAwesomeIcon icon="fa-regular fa-floppy-disk" />저장하기</div>
        </div>
      </div>


      {
        helpState?
        <div className='help-container flex-center'>
          <div className='close-button flex-center' onClick={()=>{setHelpState(false)}}>×</div>
        </div>
        :
        null
      }

      {
        showQuestionPopup?
        <Popup
          show={showQuestionPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center ok_btn" 
                onClick={() => {
                setShowQuestionPopup(false);
                registerProject();
                }}  
              >저장</div>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                setShowQuestionPopup(false);
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

      {
        showAlarmPopup?
        <Popup
          show={showAlarmPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowAlarmPopup(false);
                  if(regSuccess == true){
                    navigate('/project');
                  }
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

      </div>
  )
}

export default ProjectReg;