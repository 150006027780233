import './Profile.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Select, Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popup from '../../shared/components/uiElements/Popup';
import ChangePwModal from '../User/Changepw';

// AXIOS
import axios from "axios";

function BasicInformation(props) {
    // console.log(props);
    const APIToken = localStorage.getItem("login-token");
    const loginUserLevel = localStorage.getItem('user-level');
    // const APIToken = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMCwidXNlcl90eSI6MSwidXNlcl9sZXZlbCI6MSwiYWN0dnRuX2NkIjoxLCJpYXQiOjE3MTM4NjAxNDQsImV4cCI6MTc0OTg2MDE0NH0.XZd0IwFLDt8qXAsLbEc7z_0-oljIe51zkth2oD-uVC1IcFBuDPa-hVUAvsO2ifyOeRH6VIMo6w7tb7dXSu6hMw"

    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

    // onChage 클릭 변경
    const [onChangeClick, setOnChangeClick] = useState(false);

    // 비밀번호 변경 Popup
    // const [showChangePwPopup, setShowChangePwPopup] = useState(false);
    // 비밀번호 변경 
    const [showChangePwPopup, setShowChangePwPopup] = useState(false);
    // const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');
    
    // 프로필 요청
    const loginUserId = localStorage.getItem('user-id');
    // const [props.profileData, setprops.profileData] = useState({});
    
    // useEffect(() => {
    //     if (loginUserId) {
    //         axios({
    //             method:'get',
    //             // url:`/api/account/profile?userId=3`,
    //             url:`/api/account/profile?userId=${loginUserId}`,
    //             headers: {
    //             'Authorization': `Bearer {${APIToken}}`
    //         },
    //         })
    //         .then((result)=>{
    //             if (result.data.success) {
    //                 setprops.profileData(result.data.data);
    //             } else {
                
    //             }
    //         })
    //         .catch((error)=>{
    //             console.log(error);
    //         })
    //     }
    // }, [])
    
    useEffect(() => {
        if (props.profileData) {
            // 이메일
            setUserEmail(props.profileData.email);
            // 이름
            setUserNm(props.profileData.user_nm);
            // 전화번호
            setUserTelNum(props.profileData.phone_no);
            // 계정 유형
            setUserType(props.profileData.user_ty);
            // 이미지 변경
            setImageUrl(props.profileData.photoUrl);
            //
            // setSelectedFile()
            // setImageUrl(props.profileData.regUrl);
            // 지역 (시, 구)
            if (props.profileData.sido_cd === '0') {
                setSidoCd(Number(props.profileData.sido_cd));
            } else {
                setSidoCd(props.profileData.sido_cd);
            }
            // 지역 (시, 군, 구)
            if (props.profileData.sigungu_cd === '0') {
                setSigunguCd(Number(props.profileData.sigungu_cd));
            } else {
                setSigunguCd(props.profileData.sigungu_cd);
            }
            // 회사 형태
            setCompanyCd(props.profileData.co_ty);
            // 회사 기간
            setCompanyYears(props.profileData.co_years)
            // 회사 분야 V
            // setSelectedFields(props.profileData.prj_ty_cd_arr)
            // setFieldStates(props.profileData.prj_ty_nm_arr);
            // 회사 분야 (기타 - 직접입력) prj_ty_custom
            setPrjTyCustom(props.profileData.prj_ty_custom === 'null' ? '' : props.profileData.prj_ty_custom);
            // 소개글
            setIntro(props.profileData.intro === 'null' ? '' : props.profileData.intro);
            // 협력업체 / 기관 V
            // setContractors(props.profileData.contractors_arr);
            setContractors(props.profileData.contractors_arr || []);
            // 회사 연혁 V
            // setHistoryData(props.profileData.co_history_arr);
        }
    }, [props.profileData])

    // 프로필 요청
    const [userEmail, setUserEmail] = useState();
    const [userNm, setUserNm] = useState();
    const [userTelNum, setUserTelNum] = useState();
    const [Intro, setIntro] = useState('');

    const handleUserEmail = (event) => {
        event.preventDefault();
        setUserEmail(event.target.value);
        setOnChangeClick(true);
    }
    const handleUserNm = (event) => {
        event.preventDefault();
        setUserNm(event.target.value);
        setOnChangeClick(true);
    }

    // 전화번호 - 하이픈 자동 생성
    const formatPhoneNumber = (number) => {
        const numericValue = number.replace(/[^0-9]/g, '');
        
        if (numericValue.length <= 3) {
          return numericValue;
        } else if (numericValue.length <= 7) {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
        } else {
            return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 7)}-${numericValue.slice(7, 11)}`;
        }
    };

    const handleUserTelNum = (event) => {
        event.preventDefault();
        const inputTelValue = event.target.value;
        const formattedValue = formatPhoneNumber(inputTelValue);
        setUserTelNum(formattedValue);
        // setUserTelNum(event.target.value);
        setOnChangeClick(true);
    }
    const handleIntro = (e) => {
        e.preventDefault();
        setIntro(e.target.value);
        setOnChangeClick(true);
    }

    // 이미지 변경
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageUrl(reader.result);
            event.target.value = ''; // 같은 파일 올리면 인지 못함 > 초기화
        };
        reader.readAsDataURL(file);
        setOnChangeClick(true);
    };

    const handleImageChange = () => {
        fileInputRef.current.click();
    };

    // 분야 (1:일반 레포츠 / 2:미디어 촬영 /  3:시설점검 / 4:공간정보 / 5:농업, 방제 / 6.기타 - 직접입력)
    const fieldLabels = {
        1: '일반 레포츠',
        2: '미디어 촬영',
        3: '시설점검',
        4: '공간정보',
        5: '농업,방제',
        6: '기타',
    };

    const [fieldStates, setFieldStates] = useState({
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    });

    const [selectedFields, setSelectedFields] = useState([]);
    // console.log(props.profileData.prj_ty_nm_arr);
    // console.log(props.profileData.prj_ty_cd_arr);

    useEffect(() => {
        const projectTypes = props.profileData.prj_ty_nm_arr || [];
        const updatedFieldStates = { ...fieldStates };
        for (const field in updatedFieldStates) {
            updatedFieldStates[field] = projectTypes.includes(fieldLabels[field]);
        }
        setFieldStates(updatedFieldStates);
    }, [props.profileData.prj_ty_nm_arr]);

    // useEffect(() => {
    //     const projectTypes = [];
    //     const updatedFieldStates = { ...fieldStates };
    //     for (const field in updatedFieldStates) {
    //         updatedFieldStates[field] = projectTypes.includes(fieldLabels[field]);
    //     }
    //     setFieldStates(updatedFieldStates);
    // }, []);

    // function areArraysEqual(arr1, arr2) {
    //     // 두 배열이 모두 비어 있지 않은지 확인
    //     if (arr1.length === 0 || arr2.length === 0) {
    //         return false;
    //     }
    
    //     // 두 배열의 길이가 다르면 false 반환
    //     if (arr1.length !== arr2.length) {
    //         return false;
    //     }
    
    //     // 배열의 모든 요소를 순회하며 비교
    //     for (let i = 0; i < arr1.length; i++) {
    //         // 요소의 값과 타입이 다르면 false 반환
    //         if (arr1[i] !== arr2[i] || typeof arr1[i] !== typeof arr2[i]) {
    //             return false;
    //         }
    //     }
    
    //     // 모든 요소가 동일하면 true 반환
    //     return true;
    // }

    // 배열 데이터 변경 여부
    const areArraysEqual = (arr1, arr2) => {
        if (arr1 && arr2) {
            if (arr1.length !== arr2.length) return false;
            arr1.sort();
            arr2.sort();
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        }
    };

    

    useEffect(() => {
        const selectedFieldsArray = [];
        for (const key in fieldStates) {
            if (fieldStates[key]) {
                selectedFieldsArray.push(parseInt(key));
            }
        }
        setSelectedFields(selectedFieldsArray);
    }, [fieldStates]);

    // const handleCheckboxChange = (fieldKey) => {
    //     setFieldStates(prevState => ({
    //         ...prevState,
    //         [fieldKey]: !prevState[fieldKey],
    //     }));
    // };
    const handleCheckboxChange = (fieldKey) => {
        // 기타 체크박스가 해제되었을 때만 prjTyCustom 상태를 초기화합니다.
        if (fieldKey === '6' && fieldStates[fieldKey]) {
            setPrjTyCustom('');
        }
    
        setFieldStates(prevState => ({
            ...prevState,
            [fieldKey]: !prevState[fieldKey],
        }));
        setOnChangeClick(true);
    };

    // 분야 - 기타 (직접입력)
    const [prjTyCustom, setPrjTyCustom] = useState('');

    const handlePrjTyCustom = (event) => {
        event.preventDefault();
        setPrjTyCustom(event.target.value);
        setOnChangeClick(true);
    }

    // 계정 유형 (1: 파트너, 2: 클라이언트, 3: 둘다)
    const [isCheckedPartner, setIsCheckedPartner] = useState(false);
    const [isCheckedClient, setIsCheckedClient] = useState(false);
    const [userType, setUserType] = useState(0);
    
    // useEffect (() => {
    //     if (isCheckedPartner && isCheckedClient) {
    //         setUserType(3);
    //     } else if (isCheckedPartner) {
    //         setUserType(1);
    //     } else if (isCheckedClient) {
    //         setUserType(2);
    //     } else {
    //         setUserType(0);
    //     }
    // }, [isCheckedPartner, isCheckedClient])

    // useEffect(() => {
    //     if (props.profileData.user_ty === 1) {
    //         setIsCheckedPartner(true);
    //         setIsCheckedClient(false);
    //     } else if (props.profileData.user_ty === 2) {
    //         setIsCheckedPartner(false);
    //         setIsCheckedClient(true);
    //     } else if (props.profileData.user_ty === 3) {
    //         setIsCheckedPartner(true);
    //         setIsCheckedClient(true);
    //     }
    // }, [props.profileData.user_ty]);


    const [isPartnerChecked, setIsPartnerChecked] = useState(false);
    const [isClientChecked, setIsClientChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (props.profileData.user_ty === 1) {
            setIsPartnerChecked(true);
            setIsClientChecked(false);
            setIsDisabled(true);
        } else if (props.profileData.user_ty === 2) {
            setIsPartnerChecked(false);
            setIsClientChecked(true);
            setIsDisabled(true);
        } else {
            setIsPartnerChecked(false);
            setIsClientChecked(false);
            setIsDisabled(false);
        }
    }, [props.profileData.user_ty]);


    // useEffect(() => {
    //     if (props.profileData.user_ty !== null) {
    //         setUserType(props.profileData.user_ty);
    //     }
    // }, [props.profileData.user_ty]);


    const handlePartnerChange = (e) => {
        if (props.profileData.user_ty === null) {
            if (e.target.checked) {
                setUserType(1);
            } else {
                setUserType(null);
            }
        }
    };

    const handleClientChange = (e) => {
        if (props.profileData.user_ty === null) {
            if (e.target.checked) {
                setUserType(2);
            } else {
                setUserType(null);
            }
        }
    };

    const handlePartnerCheckboxChange = (e) => {
        setIsCheckedPartner(e.target.checked);
    };
  
    const handleClientCheckboxChange = (e) => {
        setIsCheckedClient(e.target.checked);
    };

    // const [isPartnerChecked, setIsPartnerChecked] = useState(props.profileData.user_ty === 1 || props.profileData.user_ty === 3);
    // const [isClientChecked, setIsClientChecked] = useState(props.profileData.user_ty === 2 || props.profileData.user_ty === 3);

    // const handlePartnerChange = () => {
    //     setIsPartnerChecked(!isPartnerChecked);
    // };
    
    // const handleClientChange = () => {
    //     setIsClientChecked(!isClientChecked);
    // };

    // 활동정보 - 지역 (시도 코드)
    const [sidoList, setSidoList] = useState([]);
    useEffect(() => {
        axios({
            method:'get',
            url:`/api/util/sido`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            if (result.data.success) {
                const modifiedSidoList = [{ sido_cd: 0, sido_nm: "전국" }, ...result.data.data];
                setSidoList(modifiedSidoList)
            } else {
            
            }
        })
        .catch((error)=>{
            console.log(error); 
        })
    }, [])

    const [sidoCd, setSidoCd] = useState('');

    const handleSido = (e, {value}) => {
        e.preventDefault();
        setSidoCd(value);
        setSigunguCd('');
        setOnChangeClick(true);
    }

    // 활동정보 - 지역 (시군구 코드)
    const [sigunguList, setSigunguList] = useState([]);

    useEffect(() => {
        if (sidoCd || sigunguList) {
            axios({
                method:'get',
                url:`/api/util/sigungu?sidoCd=${sidoCd}`,
                headers: {
                    'Authorization': `Bearer {${APIToken}}`
                },
            })
            .then((result)=>{
                if (result.data.success) {
                    const modifiedSigunguList = [{ sigungu_cd: 0, sigungu_nm: "전체" }, ...result.data.data];
                    setSigunguList(modifiedSigunguList);
                } else {
                
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    }, [sidoCd])

    // const [sigunguCd, setSigunguCd] = useState('');
    
    // const handleSigungu = (e, {value}) => {
    //     e.preventDefault();
    //     setSigunguCd(value);
    // }

    const [sigunguCd, setSigunguCd] = useState('');

    const handleSigungu = (e, {value}) => {
        e.preventDefault();
        setSigunguCd(value);
        setOnChangeClick(true);
    }

    // 회사 형태
    const companyOptions = [
        {key: 1, text: '개인', value: 1},
        {key: 2, text: '개인사업자', value: 2},
        {key: 3, text: '법인사업자', value: 3},
    ]

    const [companyCd, setCompanyCd] = useState('');
    const handleCompany = (e, {value}) => {
        e.preventDefault();
        setCompanyCd(value);
        setOnChangeClick(true);
    }

    // 회사 기간
    const [companyYears, setCompanyYears] = useState();

    const handleCompanyYears = (event) => {
        event.preventDefault();
        setCompanyYears(event.target.value);
        setOnChangeClick(true);
    }

    // 협력업체 / 기관

    // const initialContractors = props.profileData.contractors_arr || ['']; 
    // const initialContractors = props.profileData.contractors_arr || ['']; 
    // const initialContractors = props.profileData.contractors_arr && props.profileData.contractors_arr.length > 0 ? props.profileData.contractors_arr : [''];
    const [contractors, setContractors] = useState([]);

    const handleChange = (index, value) => {
        const updatedContractors = [...contractors];
        updatedContractors[index] = value;
        setContractors(updatedContractors);
        setOnChangeClick(true);
    };

    // const handleRemoveContractor = (index) => {
    //     const updatedContractors = [...contractors];
    //     updatedContractors.splice(index, 1);
    //     setContractors(updatedContractors);
    //     setOnChangeClick(true);
    // };

    const handleRemoveContractor = (index) => {
        const updatedContractors = contractors.filter((_, i) => i !== index);
        setContractors(updatedContractors);
        setOnChangeClick(true);
    };

    // const handleAddContractor = () => {
    //     // setContractors([...contractors, '']);
    //     if (Array.isArray(contractors) && contractors.length > 0) {
    //         setContractors([...contractors, '']);
    //     }
    // };
    
    // const handleAddContractor = () => {
    //     if (contractors === null || contractors.length === 0) {
    //         setContractors(['']);
    //     } else {
    //         setContractors([...contractors, '']);
    //     }
    //     // setOnChangeClick(true);
    // };
    
    // const handleAddContractor = () => {
    //     if (contractors.length === 0 || contractors[contractors.length - 1]) {
    //         setContractors([...contractors, '']);
    //     }
    //     setOnChangeClick(true);
    // };

    // const handleAddContractor = () => {
    //     if (Array.isArray(contractors) && (contractors.length === 0 || contractors[contractors.length - 1])) {
    //         setContractors([...contractors, '']);
    //     }
    //     setOnChangeClick(true);
    // };

    // 새로운 입력 필드 추가 처리
    // 새로운 입력 필드 추가 처리
    // console.log(props.profileData.contractors_arr)
    // console.log(contractors)
    const handleAddContractor = () => {
        // if (contractors.length === 0 || contractors[contractors.length - 1]) {
        //     setContractors([...contractors, '']);
        // }
        // setOnChangeClick(true);

        // contractors가 배열인지 확인하고 마지막 필드가 비어있지 않을 때만 추가합니다.
        if (Array.isArray(contractors) && (contractors.length === 0 || contractors[contractors.length - 1] !== '')) {
            setContractors([...contractors, '']);
        }
        setOnChangeClick(true);
    }
    
    // Helper function to determine if input should be displayed
    const shouldDisplayInput = (contractor) => contractor !== '';
    
    // const [contractors, setContractors] = useState([]);
    // const [contractors, setContractors] = useState(props.profileData.contractors_arr || ['']);

    // const handleInputChange = (index, value) => {
    //     const updatedContractors = [...contractors];
    //     updatedContractors[index] = value;
    //     setContractors(updatedContractors);
    // };

    // const handleAdd = () => {
    //     setContractors([...contractors, '']);
    // };

    // const handleRemove = (index) => {
    //     const updatedContractors = [...contractors];
    //     updatedContractors.splice(index, 1);
    //     setContractors(updatedContractors);
    // };

    // 회사 연혁
    const [rows, setRows] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [originalHistoryData, setOriginalHistoryData] = useState([]);
    useEffect(() => {
        if (props.profileData && props.profileData.co_history_arr) {
            // 처음 렌더링될 때 데이터 저장
            const clonedHistoryData = props.profileData.co_history_arr.map(item => ({ ...item }));
            setOriginalHistoryData(clonedHistoryData);
            setRows(clonedHistoryData.map((row, index) => ({ id: index + 1 })));
            setHistoryData(clonedHistoryData);
        } else {
            setOriginalHistoryData([{ date: '', content: '' }]);
            setRows([{ id: 1 }]);
            setHistoryData([{ date: '', content: '' }]);
        }
    }, [props.profileData]);
    
    // const handleAddRow = () => {
    //     const newRow = { id: rows.length + 1 };
    //     setRows([...rows, newRow]);
    //     setHistoryData([...historyData, { date: '', content: '' }]);
    // };

    const handleDateChange = (index, newValue) => {
        const updatedHistoryData = [...historyData];
        updatedHistoryData[index].date = newValue;
        setHistoryData(updatedHistoryData);
        setOnChangeClick(true);
    };

    const handleContentChange = (index, newValue) => {
        const updatedHistoryData = [...historyData];
        updatedHistoryData[index].content = newValue;
        setHistoryData(updatedHistoryData);
        setOnChangeClick(true);
    };

    const handleRemoveRow = (index) => {
        if (rows.length > 1) {
            const newRows = rows.filter((row, i) => i !== index);
            const newHistoryData = historyData.filter((data, i) => i !== index);
            setRows(newRows);
            setHistoryData(newHistoryData);
        }
    };

    const handleAddInput = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index + 1, 0, { id: rows.length + 1 });
        const updatedHistoryData = [...historyData];
        updatedHistoryData.splice(index + 1, 0, { date: '', content: '' });
        setRows(updatedRows);
        setHistoryData(updatedHistoryData);
    };

    const handleMoveUp = (index) => {
        if (index > 0) {
            const updatedRows = [...rows];
            const updatedHistoryData = [...historyData];
            // Swap rows
            [updatedRows[index], updatedRows[index - 1]] = [updatedRows[index - 1], updatedRows[index]];
            // Swap history data accordingly
            [updatedHistoryData[index], updatedHistoryData[index - 1]] = [updatedHistoryData[index - 1], updatedHistoryData[index]];
            setRows(updatedRows);
            setHistoryData(updatedHistoryData);
        }
    };

    const handleMoveDown = (index) => {
        if (index < rows.length - 1) {
            const updatedRows = [...rows];
            const updatedHistoryData = [...historyData];
            // Swap rows
            [updatedRows[index], updatedRows[index + 1]] = [updatedRows[index + 1], updatedRows[index]];
            // Swap history data accordingly
            [updatedHistoryData[index], updatedHistoryData[index + 1]] = [updatedHistoryData[index + 1], updatedHistoryData[index]];
            setRows(updatedRows);
            setHistoryData(updatedHistoryData);
        }
    };
    
    // 유효성검사
    const validateFormData = () => {
        if (!userEmail) {
            return {
                isValid: false,
                message: "이메일을 입력해주세요."
            };
        }
        if (!userNm) {
            return {
                isValid: false,
                message: "이름을 입력해주세요."
            };
        }
        if (!userTelNum) {
            return {
                isValid: false,
                message: "전화번호를 입력해주세요."
            };
        }
        if (!userType) {
            return {
                isValid: false,
                message: "계정을 선택해주세요."
            };
        }

        // 입력 필드 중 빈 값이 있는지 확인
        // const hasEmptyField = contractors.some(contractor => contractor.trim() === '');

        // if (hasEmptyField) {
        //     // setOnChangeClick(true); 
        //     return {
        //         isValid: false,
        //         message: "협력업체 입력하시거나 삭제해주세요."
        //     };
        // }

        return { isValid: true };
    };

    // 프로필 업데이트
    const [profileUpdOk, setProfileUpdOk] = useState(false);
    // console.log(props.profileData);
    const profileBasicUpd = () => {
        const { isValid, message } = validateFormData();
        
        if (!isValid) {
            // setOnChangeClick(true); 
            setShowPopup(true);
            setPopupMsg(message);
            return;
        }
        // if (isValid) {
        //     profileBasicUpd(); // 실제로 프로필을 업데이트하는 함수
        // } else {
        //     setOnChangeClick(true); // 빈 입력 필드가 있으면 버튼을 클릭할 수 있도록 합니다.
        //     setShowPopup(true); // 팝업 메시지를 표시합니다.
        //     setPopupMsg(message);
        // }
        
        // 협력업체 / 기관 데이터 비어있는 데이터 빼고 저장
        const filteredContractors = contractors.filter(contractor => contractor !== '');

        // console.log(Intro);
        const baslicFormData = new FormData();
        baslicFormData.append("userId", props.profileData.user_id);
        baslicFormData.append("userNm", userNm);
        baslicFormData.append("email", userEmail);
        baslicFormData.append("phoneNo", userTelNum);
        baslicFormData.append("userTy", userType);
        baslicFormData.append("photo", selectedFile);
        // baslicFormData.append("photo", imageUrl);
        baslicFormData.append("sidoCd", sidoCd);
        baslicFormData.append("sigunguCd", sigunguCd);
        baslicFormData.append("coTy", companyCd);
        baslicFormData.append("coYears", companyYears);
        // fieldStates
        baslicFormData.append("prjTyCdArr", JSON.stringify(selectedFields));
        baslicFormData.append("prjTyCustom", prjTyCustom);
        baslicFormData.append("intro", Intro);
        baslicFormData.append("cntrctArr", JSON.stringify(filteredContractors));
        // baslicFormData.append("cntrctArr", JSON.stringify(contractors));
        baslicFormData.append("coHistoryJson", JSON.stringify(historyData));
        axios({
            method:'post',
            url:`/api/account/update/basic`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: baslicFormData,
        })
        . then((result)=>{
            // console.log(result);
            if (result.data.success) {
                setProfileUpdOk(true);
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (기본정보) 업데이트 되었습니다.");
                props.setUserProfile(result.data.data);
                // setOnChangeClick(false);
                // props.setLoginprops.profileData(result.data.data);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setOnChangeClick(false);
                // setPopupMsg("프로필 (기본정보)에 실패하였습니다.");
            }
        })
        .catch((error) => {

        })
    }
    
    // 데이터 변경 여부
    // function areHistoryArraysEqual(arr1, arr2) {
    //     if (arr1.length !== arr2.length) {
    //         return true; // 배열의 길이가 다르면 무조건 다른 배열로 간주
    //     }
    //     for (let i = 0; i < arr1.length; i++) {
    //         const obj1 = arr1[i];
    //         const obj2 = arr2[i];
            
    //         if (obj1.date !== obj2.date || obj1.content !== obj2.content) {
    //             return true; // 요소들 중 하나라도 다르면 다른 배열로 간주
    //         }
    //     }
    
    //     return false; // 모든 요소가 같으면 같은 배열로 간주
    // }
    
    // useEffect(() => {
    //     if (props.profileData) {
    //         if (
    //             // 이메일
    //             userEmail !== props.profileData.email ||
    //             // 이름
    //             userNm !== props.profileData.user_nm ||
    //             // 전화번호
    //             userTelNum !== props.profileData.phone_no ||
    //             // 계정 유형
    //             userType !== props.profileData.user_ty ||
    //             // selectedFile 
    //             // 이미지 주소
    //             imageUrl !== props.profileData.photoUrl ||
    //             // 지역 (시, 구)
    //             // sidoCd
    //             // if (sidoCd === '0') {
    //             //     sidoCd !== props.profileData.sido_cd
    //             // } else {
    //             //     sido !== Number(props.profileData.sido_cd)
    //             // }
    //             props.profileData.sido_cd === '0' ? sidoCd !== Number(props.profileData.sido_cd) : sidoCd !== props.profileData.sido_cd ||
    //             // 지역 (시, 군, 구)
    //             // sigunguCd
    //             props.profileData.sigungu_cd === '0' ? sigunguCd !== Number(props.profileData.sigungu_cd) : sigunguCd !== props.profileData.sigungu_cd ||
    //             // 회사 형태
    //             companyCd !== props.profileData.co_ty ||
    //             // 회사 기간
    //             companyYears !== props.profileData.co_years ||
    //             // 회사 분야
    //             // selectedFields !== props.profileData.prj_ty_cd_arr ||
    //             // areArraysEqual(props.profileData.prj_ty_cd_arr, selectedFields) ||
    //             // props.profileData.prj_ty_cd_arr && selectedFields ? areArraysEqual(props.profileData.prj_ty_cd_arr, selectedFields) : null || 
    //             !areArraysEqual(props.profileData.prj_ty_cd_arr || [], selectedFields) || 
    //             // props.profileData.prj_ty_cd_arr && selectedFields ? areArraysEqual(props.profileData.prj_ty_cd_arr, selectedFields) : null ||
    //             // 회사 분야 (기타 - 직접입력)
    //             prjTyCustom !== props.profileData.prj_ty_custom ||
    //             // 소개글
    //             Intro !== props.profileData.intro ||
    //             // 협력업체 / 기관
    //             !areArraysEqual(props.profileData.contractors_arr || [],  contractors) ||
    //             // contractors !== props.profileData.contractors_arr
    //             // 회사 연혁
    //             areHistoryArraysEqual(historyData, props.profileData.co_history_arr)
    //         ) {
    //             props.setIsDataChanged(true);
    //         } else {
    //             props.setIsDataChanged(false);
    //         }
    //     }
    // }, [userEmail, userNm, userTelNum, userType, imageUrl, sidoCd, sigunguCd, companyCd, companyYears, selectedFields, prjTyCustom, Intro, contractors, historyData, props.profileData, props.setIsDataChanged]);

    return (
        <React.Fragment>
            <div className='main-text'>사용자 관리</div>
            <div className='contents-row flex-center full-width user-mgt-box'>
                <div className='contents-col user-mgt spacer'>
                    <div className='contents-row act-info'>
                        <label>이메일<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='이메일을 입력해 주세요'
                            onChange={handleUserEmail}
                            value={userEmail || ''}
                        />
                    </div>
                    <div className='contents-row act-info'>
                        <label>이름 (업체명)<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='이름을 입력해 주세요'
                            onChange={handleUserNm}
                            value={userNm || ''}
                        />
                    </div>
                    <div className='contents-row act-info'>
                        <label>전화번호<span className='required'>*</span></label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='전화번호를 입력해 주세요'
                            onChange={handleUserTelNum}
                            value={userTelNum || ''}
                        />
                    </div>
                    <div className='contents-row'>
                        <label>계정 유형<span className='required'>*</span></label>
                        <div className='contents-row flex-center spacer account-type'>
                            <div className='contents-row field'>
                                <input
                                    id="partnerCheckbox"
                                    type='checkbox'
                                    checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 1 : userType === 1}
                                    // disabled={props.profileData.user_ty !== null}
                                    onChange={handlePartnerChange}
                                    readOnly={props.profileData.user_ty !== null}
                                />
                                <label htmlFor="partnerCheckbox">파트너</label>
                            </div>
                            <div className='contents-row field'>
                                <input
                                    id="clientCheckbox"
                                    type='checkbox'
                                    checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 2 : userType === 2}
                                    // disabled={props.profileData.user_ty !== null}
                                    onChange={handleClientChange}
                                    readOnly={props.profileData.user_ty !== null}
                                />
                                <label htmlFor="clientCheckbox">클라이언트</label>
                            </div>
                            {loginUserLevel === '100' ?
                                <div className='contents-row field'>
                                    <input
                                        id="clientCheckbox"
                                        type='checkbox'
                                        checked={props.profileData.user_ty !== null ? props.profileData.user_ty === 3 : userType === 3}
                                        // disabled={props.profileData.user_ty !== null}
                                        readOnly
                                    />
                                    <label htmlFor="clientCheckbox">매니저</label>
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className='contents-row flex-center mod-user-info'>
                    {props.profileData.user_sns_id ? null : <div className='gc-btn gc-btn-line flex-center act-btn pw-change' onClick={() => {setShowChangePwPopup(true)}}>비밀번호 변경</div>}
                    {/* <div className='gc-btn gc-btn-line flex-center act-btn pw-change' onClick={() => {setShowChangePwPopup(true)}}>비밀번호 변경</div> */}
                    <div className='contents-col flex-center'>
                        {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo' /> */}
                        {/* {imageUrl && (
                            <img
                                className="user-logo-img"
                                src={imageUrl}
                                alt="Uploaded Image"
                                style={{ maxWidth: '100%', maxHeight: '125px' }}
                            />
                        )} */}
                        {!imageUrl ? (
                            // <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                            <div className='user-logo-img none'></div>
                        ) : (
                            <img
                                className="user-logo-img circle"
                                src={imageUrl}
                                alt="Uploaded Image"
                                // style={{ maxWidth: '230px', maxHeight: '130px' }}
                            />
                        )}
                        {/* {!props.profileData.photoUrl ? (
                            <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                        ) : (
                            <img
                                className="user-logo-img"
                                src={imageUrl}
                                alt="Uploaded Image"
                                style={{ maxWidth: '100%', maxHeight: '125px' }}
                            />
                        )} */}
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <div className='gc-btn gc-btn-green flex-center act-btn' onClick={handleImageChange}>이미지 변경</div>
                    </div>
                </div>
            </div>
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='main-text'>활동 정보</div>
                <div className='contents-row act-info'>
                    <label>지역</label>
                    <div className='cotents-row act-info full-width flex-center form-period'>
                        <Select
                            options={sidoList.map(sido => ({ value: sido.sido_cd, text: sido.sido_nm }))}
                            className='profile-select border select-act-info'
                            onChange={handleSido}
                            value={sidoCd}
                        />
                        <Select
                            options={sigunguList.map(sigungu => ({ value: sigungu.sigungu_cd, text: sigungu.sigungu_nm }))}
                            className='profile-select border select-act-info'
                            onChange={handleSigungu}
                            value={sigunguCd}
                            // disabled={!sidoCd}
                            disabled={sidoCd === 0}  // sidoCd가 0일 때 비활성화
                        />
                    </div>
                </div>
                <div className='contents-row act-info'>
                    <label>형태 / 기간</label>
                    <div className='cotents-row full-width flex-center form-period'>
                        <Select
                            options={companyOptions}
                            className='profile-select border select-act-info'
                            id=''
                            name=''
                            onChange={handleCompany}
                            value={companyCd}
                            // onChange={handleCamera}
                            // value={sensId_state}    
                            // onChange={handleCamera}
                            // value={''}
                        />
                        <div className='contents-row full width flex-center profile-year-box'>
                            <input
                                className='contents-row flex-center spacer border info-input profile-year'
                                type='text'
                                placeholder='경력기간 (년)'
                                onChange={handleCompanyYears}
                                value={companyYears || ''}
                            /> 
                            {/* <input
                                className='contents-row flex-center border info-input profile-year'
                                type='text'
                                placeholder='경력기간 (년)'
                                onChange={handleCompanyYears}
                                value={companyYears || ''}
                            />  */}
                            <span>년</span>
                        </div>
                    </div>
                </div>
                <div className='contents-row act-info company-history'>
                    <label>분야</label>
                    <div className='contents-col full-width'>
                        <div className='contents-row full-width'>
                            {/* {Object.entries(fields).map(([label, isChecked]) => (
                                <div className='contents-row field' key={label}>
                                    <input
                                        type='checkbox'
                                        name={label}
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label onClick={() => handleLabelClick(label)}>{label}</label>
                                </div>
                            ))} */}
                            {Object.keys(fieldStates).map((fieldKey, index) => (
                                <div className='contents-row field flex-center' key={index}>
                                    <input
                                        type='checkbox'
                                        checked={fieldStates[fieldKey]}
                                        onChange={() => handleCheckboxChange(fieldKey)}
                                    />
                                    <label onClick={() => handleCheckboxChange(fieldKey)}>{fieldLabels[fieldKey]}</label>
                                </div>
                            ))}
                            {/* {Object.keys(fieldStates).map((fieldName, index) => (
                                <div className='contents-row field flex-center' key={index}>
                                    <input
                                        type='checkbox'
                                        checked={fieldStates[fieldName]}
                                        onChange={() => handleCheckboxChange(fieldName)}
                                    />
                                    <label onClick={() => handleCheckboxChange(fieldName)}>{fieldName}</label>
                                </div>
                            ))} */}
                        </div>
                        {/* prjTyCustom */}
                        {fieldStates[6] && ( // 기타 (직접 입력)이 체크되었을 때만 텍스트 입력란 표시
                            <input
                                className='contents-row flex-center spacer border info-input'
                                type='text'
                                onChange={handlePrjTyCustom}
                                value={prjTyCustom || ''}
                            />
                        )}
                    </div>
                </div>
                <div className='contents-row act-info intro'>
                    <label>소개글</label>
                    <textarea
                        className="border"
                        value={Intro || ''}
                        onChange={handleIntro}
                        // cols="30"
                        // rows="10"
                        // value={contents}
                        // onChange={onChange}
                    ></textarea>
                </div>
                <div className='contents-row act-info'>
                    <label>협력업체 / 기관</label>
                    {contractors && contractors.map((contractor, index) => (
                        <div key={index} className='contents-row flex-center border info-input company-nm'>
                            <input
                                className='contents-row flex-center spacer contractors'
                                type='text'
                                value={contractor}
                                onChange={(e) => handleChange(index, e.target.value)}
                            />
                            {contractor && ( 
                                <FontAwesomeIcon
                                    icon="fa-solid fa-xmark"
                                    onClick={() => handleRemoveContractor(index)}
                                />
                            )}
                        </div>
                    ))}
                    <div className='gc-btn flex-center act-btn info-btn' onClick={handleAddContractor}>
                        <FontAwesomeIcon icon="fa-solid fa-plus"/>
                        추가
                    </div>
                </div>
                <div className='contents-row act-info company-history'>
                    <label>회사 연혁</label>
                    <div className='contents-col full-width'>
                        {rows.map((row, index) => (
                            <div key={row.id} className='contents-row full-width history-box'>
                                <input
                                    className='contents-row flex-center spacer history border info-input date'
                                    type='date'
                                    value={historyData[index]?.date || ''}
                                    onChange={(e) => handleDateChange(index, e.target.value)}
                                    // maxDate={new Date()}
                                    max={new Date().toJSON().slice(0, 10)}
                                />
                                <input
                                    className='contents-row flex-center spacer border info-input'
                                    type='text'
                                    placeholder='회사창립'
                                    value={historyData[index]?.content || ''}
                                    onChange={(e) => handleContentChange(index, e.target.value)}
                                />
                                <div className='contents-col'>
                                    <FontAwesomeIcon icon="fa-solid fa-caret-up" className='border move' onClick={() => handleMoveUp(index)} />
                                    <FontAwesomeIcon icon="fa-solid fa-caret-down" className='border move' onClick={() => handleMoveDown(index)} />
                                </div>
                                <div className='gc-btn flex-center info-btn ' onClick={() => handleRemoveRow(index)}>
                                    <FontAwesomeIcon icon="fa-solid fa-minus" />
                                </div>
                                <div className='gc-btn flex-center info-btn plus' onClick={() => handleAddInput(index)}>
                                    <FontAwesomeIcon icon="fa-solid fa-plus" className='history' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='full-width flex-center dropia-reg-bottom'>
                <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileBasicUpd : null}>저장하기</div>
                {/* <button className={`  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileBasicUpd : null}>저장하기</button> */}
                <span className='required-info'>* 필수항목</span>
            </div>
            <Popup
                show={showPopup}
                // onCancel={() => setShowAddPopup(false)}
                header=""
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             // setShowAddPopup(false);
                //             setShowPopup(false);
                //         }} 
                //     /> }
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p
                    // style={{
                    //   fontSize: fontSizeValue,
                    //   padding: paddingValue,
                    // }}
                    // dangerouslySetInnerHTML={{ __html: popupMsg }}
                >
                    {popupMsg}
                </p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                        if (profileUpdOk) {
                            setOnChangeClick(false);
                        }
                        setProfileUpdOk(false);
                    }}  
                >닫기</div>
                </div>
            </Popup>
            <ChangePwModal 
                showChangePwPopup={showChangePwPopup} 
                setShowChagePwPopup={setShowChangePwPopup}
            ></ChangePwModal>
            {/* <Popup
                className="changepw"
                show={showChangePwPopup}
                // onCancel={() => setShowAddPopup(false)}
                header=""
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             // setShowAddPopup(false);
                //             setShowPopup(false);
                //         }} 
                //     /> }
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p
                    // style={{
                    //   fontSize: fontSizeValue,
                    //   padding: paddingValue,
                    // }}
                    // dangerouslySetInnerHTML={{ __html: popupMsg }}
                >
                    testestestestest
                </p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowChangePwPopup(false);
                    }}  
                >닫기</div>
                </div>
            </Popup> */}
        </React.Fragment>
    );
}

export default BasicInformation;