import './ProductReg.css';

import React, { useState, useCallback, useEffect, useRef, Suspense } from 'react';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { Checkbox, Dropdown } from 'semantic-ui-react';

import Modal from '../../shared/components/uiElements/Modal'
import Popup from '../../shared/components/uiElements/Popup';

import moment from 'moment';

// AXIOS
import axios from "axios";

function ProductReg(props) {

  const APIToken = localStorage.getItem("login-token");

  const [productPrjId, setProductPrjId] = useState(0);                                      // 1.프로젝트 ID
  const [productTitle, setProductTitle] = useState();                                       // 2.산출물 제목
  const [productTyCd, setProductTyCd] = useState();                                         // 3.산출물 코드
  const [productAddr, setProductAddr] = useState();                                         // 4.산출물 위치
  const [productGeojson, setProductGeojson] = useState();                                   // 5.[O]산출물 Geojson
  const [productExtUrl, setProductExtUrl] = useState();                                     // 6.[O]외부 URL
  const [productCreateDt, setProductCreateDt] = useState(moment().format('YYYY-MM-DD'));    // 7.[O]생성일시
  const [productDescription, setProductDescription] = useState();                           // 8.[O]설명
  const [productOutputFile, setProductOutputFile] = useState();                             // 9.산출물 파일
  const [productPrevFile, setProductPrevFile] = useState();                                 // 10.[O]미리보기 파일
  const [productShareLv, setProductShareLv] = useState(10);                                 // 11.[O]공개범위

  // 미리보기 이미지
  const [previewImage, setPreviewImage] = useState();
  // 마커 영역 선택
  const [geoButtonMode, setGeoButtonMode] = useState('marker');   
  // 로딩화면
  const [loading, setLoading] = useState(false);

  const [popupState, setPopupState] = useState();                     // 팝업 상태
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);  // 질문팝업 boolen
  const [showAlarmPopup, setShowAlarmPopup] = useState(false);        // 알림팝업 boolen
  const [popupMsg, setPopupMsg] = useState('');                       // 팝업메시지 

  const productRef = useRef(null);
  const prevRef = useRef(null);

  const handleProductClick = () => {productRef.current.click();};
  const handlePrevClick = () => {prevRef.current.click();};

  const onProductClear=()=>{setProductOutputFile('');};
  const onPrevClear=()=>{setProductPrevFile('');setPreviewImage()};

  const onProductChangeHandler = async (e) => {
    if (!e.target.files || !e.target.files[0]) return;
    setProductOutputFile(e.target.files[0]);
  }

  const onPrevChangeHandler = async (e) => {
    if (!e.target.files || !e.target.files[0]) return;
    setProductPrevFile(e.target.files[0]);
  }

  const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        setPreviewImage(reader.result);
        resolve();
      };
    });
  };

  const typeOptions = [
    {text: '이미지', value: 1},
    {text: '비디오', value: 2},
    {text: '기타', value: 3},
    {text: '정사영상', value: 101},
    {text: 'DEM', value: 102},
    {text: '3D', value: 103},
  ]

  const mapContainer = useRef(null);
  const map = useRef(null);

  const MAPLIB_KEY = 'KpJ2HZtu5M21qcys8AVL';
	const VWORLD_URL = 'https://api.vworld.kr/req/wmts/1.0.0';
  const VWORLD_KEY = '18A51781-1604-33A6-8E99-EFEE908F7323'
	// const VWORLD_KEY = 'DEF9B04D-5D98-3A49-8D93-9479EF8F018C';

  // const NGII_KEY = '2F1F5B2899F9B29D36C6D2BA4856BF56';

	const vworldSat = VWORLD_URL + '/' + VWORLD_KEY + '/Satellite/{z}/{y}/{x}.jpeg'

	let V_WORLD_MAP_ID = "vworld_map_satellite";

	let V_WORLD_MAP_SRC = {
    type: "raster",
    minzoom: 6,
    maxzoom: 19,
    tiles: [vworldSat],
    tileSize: 256,
  };

	let V_WORLD_MAP_LAYER = {
    id: V_WORLD_MAP_ID,
    type: 'raster',
		source: V_WORLD_MAP_SRC,
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'raster-opacity': 1.0,
    },
  };

  useEffect(() => {
    if (map.current) return; // stops map from intializing more than once
  
    map.current = new maplibregl.Map({
      id: 'mapbox-map',
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/basic/style.json?key=${MAPLIB_KEY}`,
      // style: `http://map.ngii.go.kr/openapi/wmts_ngiiMap_v6.4.3.js?apikey=${NGII_KEY}`,
      center: [129, 37.33],
      zoom: 12,
    });

    map.current.on('load', ()=>{
      map.current.addLayer(V_WORLD_MAP_LAYER);
      map.current.setMaxBounds([[124.0, 33.0], [132.0, 39.0]]);
      map.current.addControl(new maplibregl.NavigationControl(), 'bottom-left');
    })
  }, []);

  const [drawControl, setDrawControl] = useState(
      new MapboxDraw({
      id: 'mapbox-draw',
      defaultMode: "draw_polygon",
      displayControlsDefault: false,
    })
  )

  let marker = null;

  function onClickMarker (e) {
    if(marker) {
      marker.remove();
    }

    marker = new maplibregl.Marker({color: "#FF0000"})
    .setLngLat([e.lngLat.lng, e.lngLat.lat])
    .addTo(map.current);

    setProductGeojson(JSON.stringify({"coordinates": [e.lngLat.lng, e.lngLat.lat], "type": "Point"}));
  }

  useEffect(()=>{
    if (map.current){
      if (geoButtonMode == 'marker') {
        if(map.current.hasControl(drawControl)){
          map.current.removeControl(drawControl);
        }
        map.current.on('click', onClickMarker);
        // console.log(map.current)
        // console.log(map.current._listeners.click)
      }
      else if (geoButtonMode == 'polygon') {
        if(!map.current.hasControl(drawControl)){
          map.current.addControl(drawControl);
        }
        map.current._listeners.click = [];
        // console.log(map.current)
        // console.log(map.current._listeners.click)

        // map.current.off('click', onClickMarker);
        // map.current.addControl(draw);
        // map.current.on('draw.create', ()=>{});
        // map.current.on('draw.delete', ()=>{});
        // map.current.on('draw.update', ()=>{});
      }
    }
  },[geoButtonMode])

  function regProduct () {

    setLoading(true);

    const formData = new FormData();
    formData.append("prjId", productPrjId);
    formData.append("title", productTitle);
    formData.append("tyCd", productTyCd);
    formData.append("addr", productAddr);
    if(productGeojson){formData.append("geojson", productGeojson);}
    if(productExtUrl)formData.append("extUrl", productExtUrl);
    if(productCreateDt)formData.append("createDt", productCreateDt);
    if(productDescription)formData.append("description", productDescription);
    formData.append("outputFile", productOutputFile);
    if(productPrevFile)formData.append("prevFile", productPrevFile);
    if(productShareLv)formData.append("shareLv", productShareLv);

    axios({
      method:'post',
      url:`/api/output/add`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: formData,
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        setLoading(false);
        setPopupMsg('산출물을 등록하였습니다.');
        setShowAlarmPopup(true);
      }
      else if (result.data.success == false)
      {
        setLoading(false);
        setPopupMsg(result.data.msg.severity);
        // setPopupMsg('산출물 등록 실패');
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
      setLoading(false);
      setPopupMsg('산출물 등록 요청 오류');
      setShowAlarmPopup(true);
    })
  }

  return (
    <Modal
      show={props.productRegModal}
      className='contents-row product-reg-modal-container'
      icon={<i className="code x icon"  onClick={() => props.setProductRegModal(false)} /> }
    >
      <div className='header-text modal-header'>산출물 등록</div>

      <div className='part-divider'></div>

      <div className='contents-row'>
        <div className='contents-col'>
          <div className='header-text product-reg'>제목</div>
          <div className='header-text product-reg'>구분</div>
          <div className='header-text product-reg'>주소</div>
          <div className='header-text product-reg'>파일</div>
          <div className='header-text product-reg'>미리보기</div>
          <div className='header-text product-reg'>URL</div>
          <div className='header-text product-reg'>공개범위</div>
          <div className='header-text product-reg'>설명</div>
        </div>
        <div className='contents-col'>
          <input 
            className='product-reg-input' 
            type='text' 
            value={productTitle} 
            onChange={(e)=>{setProductTitle(e.target.value)}}
          >
          </input>

          <Dropdown 
            className='product-reg-input'
            // placeholder='선택'
            fluid
            selection
            options={typeOptions}
            value={productTyCd}
            onChange={(e, data)=>{setProductTyCd(data.value)}}
          ></Dropdown>

          <input 
            className='product-reg-input' 
            type='text' 
            value={productAddr} 
            onChange={(e)=>{setProductAddr(e.target.value)}}
          ></input>

          <div className='product-reg-input contents-row'>
            <div className='product-name'>
              {productOutputFile? productOutputFile.name : null}
              <input
                type="file"
                accept=".tif, .jpg, .png, .zip"
                style={{ visibility: "hidden" }}
                onChange={onProductChangeHandler}
                ref={productRef}
              />
            </div>
            <div className='flex-center contents-row'>
              <a title="파일업로드">
                <label className='' onClick={handleProductClick}>
                <i className="plus icon" />
                </label>
              </a>
              <a title="파일업로드 삭제">
                <div className="" onClick={onProductClear}>
                <i className="x icon" />  
                </div>
              </a>
            </div>
          </div>

          <div className='product-reg-input contents-row'>
            <div className='product-name'>
              {productPrevFile? productPrevFile.name : null}
              <input
                type="file"
                accept=".jpg, .png"
                style={{ visibility: "hidden" }}
                // onChange={onPrevChangeHandler}
                ref={prevRef}
                onChange={(e) => {
                  encodeFileToBase64(e.target.files[0]);
                  onPrevChangeHandler(e);
                }}
              />
            </div>
            <div className='flex-center contents-row'>
              <a title="파일업로드">
                <label className='' onClick={handlePrevClick}>
                <i className="plus icon" />
                </label>
              </a>
              <a title="파일업로드 삭제">
                <div className="" onClick={onPrevClear}>
                <i className="x icon" />  
                </div>
              </a>
            </div>
          </div>

          <input 
            className='product-reg-input'
            type='text' 
            value={productExtUrl} 
            onChange={(e)=>{setProductExtUrl(e.target.value)}}
          ></input>

          <div className='product-reg-checkbox-group contents-row flex-center'>
            <Checkbox 
              className='product-reg-checkbox'
              label='전체공개'
              value={10}
              checked={productShareLv == 10}
              onChange={(e, data)=>{setProductShareLv(data.value)}}
            >
            </Checkbox>
            <div className='flex-spacer'></div>
            <Checkbox 
              className='product-reg-checkbox'
              label='비공개'
              value={1}
              checked={productShareLv == 1}
              onChange={(e, data)=>{setProductShareLv(data.value)}}
            >
            </Checkbox>
          </div>

          <textarea 
            className='product-reg-input' 
            type='text'
            value={productDescription}
            onChange={(e)=>{setProductDescription(e.target.value)}}
          ></textarea>
          
        </div>
        {
          previewImage?
          <img src={previewImage} className="product-viewer flex-center"/>
          :
          <div className='product-viewer flex-center'>※ 산출물 등록시 미리보기 화면입니다.
          </div>
        }
      </div>

      <div className='part-divider'></div>

      <div className='contents-row'>
        <div className='header-text product-reg'>지도</div>
        <div className='product-map contents-row'>
    	    <div ref={mapContainer} className="map">
          </div>
          <div className={`geobutton marker flex-center ${geoButtonMode == 'marker'? 'active':''}`} onClick={()=>{setGeoButtonMode('marker')}}>마커</div>
          <div className={`geobutton polygon flex-center ${geoButtonMode == 'polygon'? 'active':''}`} onClick={()=>{setGeoButtonMode('polygon')}}>영역</div>
      </div>
    </div>

    <div className='contents-row flex-center'>
      <div className='flex-center button cancel' onClick={()=>{props.setProductRegModal(false)}}>취소</div>
      <div className='flex-center button reg' onClick={()=>{setPopupState('regProduct');setShowQuestionPopup(true);setPopupMsg('산출물을 등록하시겠습니까?');}}>등록</div>
    </div>

    {
      showQuestionPopup?
      <Popup
        show={showQuestionPopup}
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p>{popupMsg}</p>
          <div className='contents-row'>
            <div 
              className="contents-row gc-btn flex-center ok_btn" 
              onClick={() => {
                if(popupState == 'regProduct'){
                  setShowQuestionPopup(false);
                  regProduct();
                }
              } 
              } 
            >{popupState == 'regProduct'? '등록' : '확인'}</div>
            <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
                setShowQuestionPopup(false);
              }}  
            >닫기</div>
          </div>
        </div>
      </Popup>
      :
      null
    }
    {
      showAlarmPopup?
      <Popup
        show={showAlarmPopup}
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p>{popupMsg}</p>
          <div className='contents-row'>
            <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
                if(popupState == 'regProduct'){
                  setShowAlarmPopup(false);
                  props.setProductRegModal(false);
                  props.getProductList();
                }
                else{
                  setShowAlarmPopup(false);
                }
              }}  
            >닫기</div>
          </div>
        </div>
      </Popup>
      :
      null
    }

    {
      loading == true?
      <div className="loading-container">
          <div id="loading"></div>
      </div>
      :null
    }

  </Modal>
  )
}

export default ProductReg;