import './Profile.css';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Select, Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

function Document(props) {
    const APIToken = localStorage.getItem("login-token");
        
    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState('필수 정보를 입력하세요.');

    const loginUserId = localStorage.getItem('user-id');
    const loginUserCoTy = localStorage.getItem('user-coTy');

    // onChage 클릭 변경
    const [onChangeClick, setOnChangeClick] = useState(false);

    // props.profileData
    useEffect(() => {
        // if (props.profileData) {
        //     // regType props.profileData.regType
        //     setSelectedOption(props.profileData.reg_ty);
        // }
        
        console.log(props.profileData);
        if (props.profileData) {
            // 주민등록번호
            if (props.profileData.reg_no) {
                const [partA, partB] = props.profileData.reg_no.split('-');
                setRegNo_A(partA);
                setRegNo_B(partB);
            }
            // 신분증 IMG
            setUplImageUrl(props.profileData.regUrl);
            // 자격증 관리 props.profileData.certiUrls

            // 사업장 주소
            setAddress(props.profileData.co_addr === 'null' ? '' :props.profileData.co_addr);
            // 서명자 (직급)
            setRank(props.profileData.signer_rank === 'null' ? '' : props.profileData.signer_rank);
            // 서명자 (이름)
            setName(props.profileData.signer_nm === 'null' ? '' : props.profileData.signer_nm);

            // if (props.profileData.certiUrls === null) {
            //     setSelectedCertiFiles([]);
            //     setCertiDescriptions([]);
            // } else {
            //     setSelectedCertiFileUrls(props.profileData.certiUrls);
            //     setCertiDescriptions(props.profileData.certi_nm_arr);
            // }
            setExistingCertiUrls(props.profileData.certiUrls || []);
            setExistingCertiDescriptions(props.profileData.certi_nm_arr || []);
            // setSelectedCertiFiles(props.profileData.certi_nm_arr)
            // setSelectedCertiFileNames(props.profileData.certi_nm_arr)
            // 은행명
            setSelectedBankOption(props.profileData.bank_nm);
            // 계좌 번호
            setAccountNumber(props.profileData.bank_acnt_no === 'null' ? '' : props.profileData.bank_acnt_no);
            // 예금주
            setAccountHolder(props.profileData.bank_acnt_nm === 'null' ? '' : props.profileData.bank_acnt_nm);
            // 서명 관리
            setSignFileUrl(props.profileData.signUrl);
        }
    }, [props.profileData])

    // 인증 관리

    // const [checkedItem, setCheckedItem] = useState('주민등록번호');

    // const handleCheckboxChange = (event) => {
    //   setCheckedItem(event.target.value);ㅇ
    // };

    // const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption, setSelectedOption] = useState(loginUserCoTy === 1 ? '1' : '2');

    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
    };
    
    const [regNo_A, setRegNo_A] = useState('');
    const [regNo_B, setRegNo_B] = useState('');

    const handleRegNo_A = (event) => {
        event.preventDefault();
        const value = event.target.value.replace(/\D/g, '');
        setRegNo_A(value);
        // setRegNo_A(event.target.value);
        setOnChangeClick(true);
    }
    const handleRegNo_B = (event) => {
        event.preventDefault();
        const value = event.target.value.replace(/\D/g, '');
        setRegNo_B(value);
        // setRegNo_B(event.target.value);
        setOnChangeClick(true);
    }

    const formatInputValue = (input) => {
        let formattedValue = input.replace(/\D/g, ''); // Remove non-digit characters
    
        if (props.profileData.co_ty === 1) {
          // Format for 주민등록번호
          if (formattedValue.length > 6) {
            formattedValue = `${formattedValue.slice(0, 6)}-${formattedValue.slice(6, 13)}`;
          }
        } else if (props.profileData.co_ty === 2 || props.profileData.co_ty === 3) {
          // Format for 사업자등록번호
          if (formattedValue.length > 3) {
            formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3, 5)}-${formattedValue.slice(5, 10)}`;
          }
        }
    
        return formattedValue;
    };
    const [regNum, setRegNum] = useState('');
    const handleRegNum = (e) => {
        console.log(props.profileData.co_ty)
        const newValue = e.target.value;
        setRegNum(formatInputValue(newValue));
        setOnChangeClick(true);
    };
    
    //  const combinedValue = `${regNo_A}-${regNo_B}`;

    // 인증 관리 (주민등록번호 or 사업자등록번호 이미지)
    const [uploadFile, setUploadFile] = useState(null);
    const [uplImageUrl, setUplImageUrl] = useState('');
    const [uplImageNm, setUplImageNm] = useState('');
    const uplfileRef = useRef(null);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    // const handleUploadFile = (event) => {
    //     const file = event.target.files[0];
    //     setUplImageNm(file.name);
    //     setUploadFile(file);
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //         setUplImageUrl(reader.result);
    //         event.target.value = ''; // 같은 파일 올리면 인지 못함 > 초기화
    //     };
    //     reader.readAsDataURL(file);
    // };
    
    const handleUploadFile = (event) => {
        const file = event.target.files[0];
        setUplImageNm(file.name);
        setUploadFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setUplImageUrl(reader.result);
            event.target.value = ''; // 같은 파일 올리면 인지 못함 > 초기화
        };
        reader.readAsDataURL(file);
        setOnChangeClick(true);
    };

    const handleUplFileClick = () => {
        uplfileRef.current.click();
    };

    // 신분증 이미지 크게보기
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleIconClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // 사업장 주소
    const [address, setAddress] = useState();
    
    const handleAddress = (event) => {
        event.preventDefault();
        setAddress(event.target.value);
        setOnChangeClick(true);
    }
    
    // 서명자 (직급, 이름)
    const [rank, setRank] = useState();
    const [name, setName] = useState();
    
    const handleRank = (event) => {
        event.preventDefault();
        setRank(event.target.value);
        setOnChangeClick(true);
    }
    const handleName = (event) => {
        event.preventDefault();
        setName(event.target.value);
        setOnChangeClick(true);
    }

    // 자격증 관리
    const [selectedCertiFiles, setSelectedCertiFiles] = useState([]);
    const [selectedCertiFileUrls, setSelectedCertiFileUrls] = useState([]);
    const [selectedCertiFileNames, setSelectedCertiFileNames] = useState([]);
    
    
    // const handleCertiFileUpload = (e) => {
    //     const files = Array.from(e.target.files);
    //     const fileNames = files.map(file => file.name);
        
    //     setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
    //     setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
    //     e.target.value = ''; 
    // };

    const certiFileRef = useRef();
    const handleCertiFileClick = () => {
        certiFileRef.current.click();
    };

    // const handleCertiFileUpload = (e) => {
    //     const files = Array.from(e.target.files);

    //     // const files = Array.from(e.target.files);
    //     // console.log("Uploaded files:", files);
    
    //     // const fileNames = files.map(file => file.name);
    //     // console.log("File names:", fileNames);
    
    //     // setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
    //     // setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
    //     // e.target.value = '';

    //     files.forEach(file => {
    //         const fileNames = files.map(file => file.name);
    //         setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
    //         setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
            
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             setSelectedCertiFileUrls((prevFiles) => [...prevFiles, event.target.result]);
    //         };
    //         reader.readAsDataURL(file);
    //     });

    //     // const fileNames = files.map(file => file.name);
    //     // setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);

    //     e.target.value = '';
    // };

    // const handleCertiFileUpload = (e) => {
    //     const files = Array.from(e.target.files);
    //     // const fileNames = files.map(file => file.name);
    //     setSelectedCertiFiles((prevFiles) => [...prevFiles, ...files]);
    //     // setNewSelectCertiFileNames(fileNames);
    //     // setSelectedCertiFileNames((prevFileNames) => [...prevFileNames, ...fileNames]);
        
    //     files.forEach(file => {
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             setSelectedCertiFileUrls((prevFiles) => [...prevFiles, event.target.result]);
    //         };
    //         reader.readAsDataURL(file);
    //     });
    //     e.target.value = '';
    //     setOnChangeClick(true);
    // };
    const [existingCertiUrls, setExistingCertiUrls] = useState([]);
    const [existingCertiDescriptions, setExistingCertiDescriptions] = useState([]);
    const [newCertiUrls, setNewCertiUrls] = useState([]);
    const [newCertiDescriptions, setNewCertiDescriptions] = useState([]);

    // 파일 업로드
    const handleCertiFileUpload = (e) => {
        const files = Array.from(e.target.files);
    
        // 새로운 파일을 selectedCertiFiles에 추가
        setSelectedCertiFiles(prevFiles => [...prevFiles, ...files]);
    
        // 파일을 미리보기 URL로 변환하여 newCertiUrls에 추가
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = (event) => {
                setNewCertiUrls(prevUrls => [...prevUrls, event.target.result]);
                setNewCertiDescriptions(prevDescriptions => [...prevDescriptions, '']); // Initialize descriptions
            };
            reader.readAsDataURL(file);
        });
    
        e.target.value = '';
        setOnChangeClick(true);
    };
    
    
    // 자격증 삭제
    // const handleDeleteCertiFile = (index) => {
    //     setSelectedCertiFiles((prevFiles) => {
    //         const newFiles = [...prevFiles];
    //         newFiles.splice(index, 1);
    //         return newFiles;
    //     });
    //     setSelectedCertiFileNames((prevFileNames) => {
    //         const newFileNames = [...prevFileNames];
    //         newFileNames.splice(index, 1);
    //         return newFileNames;
    //     });
    //     setDeletedCertiIndexes((prevIndexes) => [...prevIndexes, index]);
    // };
    const [deletedCertiIndexes, setDeletedCertiIndexes] = useState([]);

    // const handleDeleteCertiFile = (index) => {
    //     // 삭제된 인덱스를 문자열 배열로 저장
    //     // setDeletedCertiIndexes(prevIndexes => [...prevIndexes, index.toString()]);
    
    //     // setSelectedCertiFiles((prevFiles) => {
    //     //     const newFiles = [...prevFiles];
    //     //     newFiles.splice(index, 1);
    //     //     return newFiles;
    //     // });
    //     // setSelectedCertiFileNames((prevFileNames) => {
    //     //     const newFileNames = [...prevFileNames];
    //     //     newFileNames.splice(index, 1);
    //     //     return newFileNames;
    //     // });

    //     // 이미지 및 데이터를 삭제하는 로직 구현
    //     const updatedCertiUrls = [...selectedCertiFileUrls];
    //     const updatedCertiDescriptions = [...certiDescriptions];

    //     // 이미지 및 데이터 삭제
    //     updatedCertiUrls.splice(index, 1);
    //     updatedCertiDescriptions.splice(index, 1);

    //     // 상태 업데이트
    //     setSelectedCertiFileUrls(updatedCertiUrls);
    //     setCertiDescriptions(updatedCertiDescriptions);
    //     // setDeletedCertiIndexes(index);
    //     setDeletedCertiIndexes((prevIndexes) => {
    //         // prevIndexes가 undefined인 경우를 고려하여 초기화
    //         if (!prevIndexes) {
    //             prevIndexes = [];
    //         }
    //         return [...prevIndexes, index];
    //     });

    //     // console.log(index);
    //     // console.log(certiDescriptions);
    //     // const uniqueCertiDescriptions = getUniqueValues(props.profileData.certi_nm_arr, certiDescriptions);
    //     // console.log(uniqueCertiDescriptions)
    //     const imgArr = selectedCertiFiles.filter((el, idx) => 
    //         // console.log(idx)
    //         idx !== index-certiDescriptions.length
    //     );
    //     // console.log(imgArr)
    //     setSelectedCertiFiles([...imgArr]);

    //     setOnChangeClick(true);
    // };

    // const [deletedCertiIndexes, setDeletedCertiIndexes] = useState([]);
    
    // 파일 삭제
    const handleDeleteCertiFile = (index, isExisting) => {
        if (isExisting) {
            // Handle existing data deletion
            const updatedUrls = [...existingCertiUrls];
            const updatedDescriptions = [...existingCertiDescriptions];
            updatedUrls.splice(index, 1);
            updatedDescriptions.splice(index, 1);
    
            setExistingCertiUrls(updatedUrls);
            setExistingCertiDescriptions(updatedDescriptions);
    
            // Save deleted index for existing files
            setDeletedCertiIndexes(prevIndexes => [...prevIndexes, index]);
        } else {
            // Handle new data deletion
            const updatedUrls = [...newCertiUrls];
            const updatedDescriptions = [...newCertiDescriptions];
            updatedUrls.splice(index, 1);
            updatedDescriptions.splice(index, 1);
    
            setNewCertiUrls(updatedUrls);
            setNewCertiDescriptions(updatedDescriptions);
    
            // Remove file from selectedCertiFiles if it was deleted
            setSelectedCertiFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        }
    
        setOnChangeClick(true);
    };
    
    // 자격증 이미지 이름
    const [certiDescriptions, setCertiDescriptions] = useState([]);
    
    // useEffect(() => {
    //     if (selectedCertiFileUrls) {
    //         setCertiDescriptions(Array(selectedCertiFileUrls.length).fill(''));
    //     }
    // }, [])

    // const handleCertiDescriptionChange = (index, description) => {
    //     const updatedDescriptions = [...certiDescriptions];
    //     updatedDescriptions[index] = description;
    //     setCertiDescriptions(updatedDescriptions);
    // };

    const handleCertiDescriptionChange = (index, description, isExisting) => {
        if (isExisting) {
            const updatedDescriptions = [...existingCertiDescriptions];
            updatedDescriptions[index] = description;
            setExistingCertiDescriptions(updatedDescriptions);
        } else {
            const updatedDescriptions = [...newCertiDescriptions];
            updatedDescriptions[index] = description;
            setNewCertiDescriptions(updatedDescriptions);
        }
    };

    const getUniqueValues = (arr1, arr2) => {
        // const uniqueValues = [];
        // arr2.forEach(item => {
        //     if (!arr1.includes(item)) {
        //         uniqueValues.push(item);
        //     }
        // });
        // return uniqueValues;
        const uniqueValues = [];
        if (arr1 !== null && arr1 !== undefined) {
            arr2.forEach(item => {
                if (!arr1.includes(item)) {
                    uniqueValues.push(item);
                }
            });
        } else {
            uniqueValues.push(...arr2);
        }
        return uniqueValues;
    };

    // 자격증 이미지 크게보기
    const [isCertiHovered, setCertIsHovered] = useState(false);
    const [isCertiModalOpen, setCertiIsModalOpen] = useState(false);

    const handleCertiMouseEnter = () => {
        setCertIsHovered(true);
    };

    const handleCertiMouseLeave = () => {
        setCertIsHovered(false);
    };

    // const handleCertiIconClick = () => {
    //     setCertiIsModalOpen(true);
    // };

    const closeCertiModal = () => {
        setCertiIsModalOpen(false);
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleCertiIconClick = (file) => {
        setSelectedImage(file);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    // 계좌 관리
    // 은행 필요
    const bankOptions = [
        {text: '신한', value: 1},
        {text: '현대', value: 2},
        {text: '삼성', value: 3},
        {text: '국민', value: 4},
        {text: '비씨', value: 5},
        {text: '롯데', value: 6},
        {text: '농협', value: 7},
        {text: '하나', value: 8},
        {text: '우리', value: 9},
        {text: '씨티', value: 10},
    ]
    
    const options = [
        { text: '카카오뱅크', value: '신한' },
        { text: '농협', value: '현대' },
        { text: '국민', value: '삼성' },
        { text: '신한', value: '국민' },
        { text: '우리', value: '비씨' },
        { text: 'IBK기업', value: '롯데' },
        { text: '하나', value: '농협' },
    ];
    const [selectedBankOption, setSelectedBankOption] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolder, setAccountHolder] = useState('');

    useEffect(() => {
        if (props.profileData.bank_nm) {
            // props.profileData.bank_nm과 일치하는 옵션을 찾아서 선택
            const selectedOption = options.find(option => option.value === props.profileData.bank_nm);
            if (selectedOption) {
                setSelectedBankOption(selectedOption.value);
            }
        }
    }, [props.profileData.bank_nm]);

    const handleChange = (e, { value }) => {
        setSelectedBankOption(value);
        setOnChangeClick(true);
    };

    const handleAccountNumber = (event) => {
        const value = event.target.value.replace(/[^0-9-]/g, '');
        setAccountNumber(value);
        // setAccountNumber(event.target.value);
        setOnChangeClick(true);
    };

    const handleAccountHolder = (event) => {
        setAccountHolder(event.target.value);
        setOnChangeClick(true);
    };
    // const [selectedBankOption, setSelectedBankOption] = useState(null);

    // const handleChange = (e, { value }) => {
    //     setSelectedBankOption(value);
    //     // console.log(`Selected: ${value}`);
    // };

    // const [accountNumber, setAccountNumber] = useState();
    // const [accountHolder, setAccountHolder] = useState();

    // const handleAccountNumber = (event) => {
    //     event.preventDefault();
    //     setAccountNumber(event.target.value);
    // }
    // const handleAccountHolder = (event) => {
    //     event.preventDefault();
    //     setAccountHolder(event.target.value);
    // }

    // 서명 관리
    const signFileRef = useRef(null);
    const handleImageChange = () => {
        signFileRef.current.click();
    };
    const [selectedSignFile, setSelectedSignFile] = useState(null);
    const [signFileUrl, setSignFileUrl] = useState('');
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedSignFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setSignFileUrl(reader.result);
            event.target.value = ''; // 같은 파일 올리면 인지 못함 > 초기화
        };
        reader.readAsDataURL(file);
        setOnChangeClick(true);
    };

    const validateFormData = () => {
        // for (let i = 0; i < existingCertiUrls.length; i++) {
        //     if (!existingCertiDescriptions[i]?.trim()) {
        //         return {
        //             isValid: false,
        //             message: "자격증 이미지 설명을 입력해주세요."
        //         };
        //     }
        // }

        for (let i = 0; i < newCertiUrls.length; i++) {
            if (!newCertiDescriptions[i]?.trim()) {
                return {
                    isValid: false,
                    message: "자격증 이미지 설명을 입력해주세요."
                };
            }
        }

       
        return { isValid: true };
    };

    // 문서 관리 업데이트
    const  profileDocsUpd = () => {
        // 주민등록번호 / 사업자등록번호
        // const combinedValue = `${regNo_A}-${regNo_B}`;
        // if (combinedValue) {
        //     setCombinedRegNo(combinedValue);
        // }

        const { isValid, message } = validateFormData();

        if (!isValid) {
            setShowPopup(true);
            setPopupMsg(message);
            return;
        }


        const uniqueCertiDescriptions = getUniqueValues(props.profileData.certi_nm_arr, certiDescriptions);

        // console.log(uniqueCertiDescriptions);

        const baslicFormData = new FormData();
        // 사용자 ID
        baslicFormData.append("userId", loginUserId);
        // 등록 분류(주민등록번호 / 사업자등록번호)
        baslicFormData.append("regType", selectedOption);
        // 주민등록번호 / 사업자등록번호
        baslicFormData.append("regNo", `${regNo_A}-${regNo_B}`);
        // 신분증
        baslicFormData.append("regFile", uploadFile);
        // baslicFormData.append("certiNmArr", JSON.stringify(selectedCertiFileNames));
        // baslicFormData.append("certiFiles", selectedCertiFiles);

        // baslicFormData.append("certiNmArr", JSON.stringify(newSelectCertiFileNames));
        // baslicFormData.append("certiFiles", selectedCertiFiles);
        
        // 자격증 파일
        // for(let i = 0; i < selectedCertiFiles.length; i++) {
        //     baslicFormData.append("certiFiles", selectedCertiFiles[i]);
        //     // baslicFormData.append("certiNmArr", JSON.stringify(newSelectCertiFileNames));
        //     // baslicFormData.append("certiFiles", JSON.stringify([i]);
        // }
        selectedCertiFiles.forEach(file => {
            baslicFormData.append("certiFiles", file);
        });
        // 자격증 이름
        baslicFormData.append("certiNmArr", JSON.stringify(newCertiDescriptions));
        // baslicFormData.append("certiNmArr", JSON.stringify(uniqueCertiDescriptions));
        // baslicFormData.append("certiNmArr", uploadFile);
        // 자격증 파일 삭제
        // if (deletedCertiIndexes) {
        //     if (deletedCertiIndexes.length>0) {
        //         baslicFormData.append("certDelArr", JSON.stringify(deletedCertiIndexes));
        //     }
        // }
        
        // const filteredIndexes = deletedCertiIndexes.filter(index => existingCertiUrls[index] !== undefined);

        // if (filteredIndexes.length > 0) {
        //     console.log(filteredIndexes)
        //     baslicFormData.append("certDelArr", JSON.stringify(filteredIndexes));
        // }
        if (deletedCertiIndexes.length>0) {
            baslicFormData.append("certDelArr", JSON.stringify(deletedCertiIndexes));
        }
        // baslicFormData.append("certDelArr", JSON.stringify(deletedCertiIndexes));
        // 은행
        baslicFormData.append("bankNm", selectedBankOption);
        // 계좌번호
        baslicFormData.append("bankAcntNo", accountNumber);
        // 예금주
        baslicFormData.append("bankAcntNm", accountHolder);
        // 서명 파일
        baslicFormData.append("signFile", selectedSignFile);
        // 사업장 주소
        baslicFormData.append("coAddr", address);
        // 서명인 직급
        baslicFormData.append("signerRank", rank);
        // 서명인 이름
        baslicFormData.append("signerNm", name);
        axios({
            method:'post',
            url:`/api/account/update/docs`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: baslicFormData,
        })
        . then((result)=>{
            // console.log(result);
            if (result.data.success) {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (문서관리) 업데이트 되었습니다.");
                props.setUserProfile(result.data.data);
                // 업데이트 후 파일 업로드 초기화
                setSelectedCertiFiles([]);
                // setExistingCertiDescriptions([]);
                setDeletedCertiIndexes([]);
                // props.setLoginprops.profileData(result.data.data);
                setNewCertiUrls([]);
                setNewCertiDescriptions([]);
                setOnChangeClick(false);
            } else {
                setShowPopup(true);
                setPopupMsg(result.data.msg);
                // setPopupMsg("프로필 (문서관리)에 실패하였습니다.");
            }
        })
        .catch((error) => {

        })
    }
    const combined = regNo_A + '-' + regNo_B;
    // console.log(combined.length);
    // console.log(props.profileData.reg_no.length);
    // console.log(combined !== props.profileData.reg_no);
    useEffect(() => {
        if (props.profileData) {
            if (
                // 주민등록번호 or 사업자번호
                regNo_A + '-' + regNo_B !== props.profileData.reg_no ||
                // 신분증 이미지 주소
                // baslicFormData.append("regFile", uploadFile);
                uplImageUrl !== props.profileData.regUrl
                // 자격증 파일 주소
                // for(let i = 0; i < selectedCertiFiles.length; i++) {
                //     baslicFormData.append("certiFiles", selectedCertiFiles[i]);
                //     // baslicFormData.append("certiNmArr", JSON.stringify(newSelectCertiFileNames));
                //     // baslicFormData.append("certiFiles", JSON.stringify([i]);
                // }
                // // 자격증 이름
                // baslicFormData.append("certiNmArr", JSON.stringify(uniqueCertiDescriptions));
                // // baslicFormData.append("certiNmArr", uploadFile);
                // // 자격증 파일 삭제
                // if (deletedCertiIndexes) {
                //     if (deletedCertiIndexes.length>0) {
                //         baslicFormData.append("certDelArr", JSON.stringify(deletedCertiIndexes));
                //     }
                // }
                // // baslicFormData.append("certDelArr", JSON.stringify(deletedCertiIndexes));
                // // 은행
                // baslicFormData.append("bankNm", selectedBankOption);
                // // 계좌번호
                // baslicFormData.append("bankAcntNo", accountNumber);
                // // 예금주
                // baslicFormData.append("bankAcntNm", accountHolder);
                // // 서명 파일
                // baslicFormData.append("signFile", selectedSignFile);

                
            ) {
                props.setIsDataChanged(true);
            } else {
                props.setIsDataChanged(false);
            }
        }
    }, [regNo_A, regNo_B, props.profileData, props.setIsDataChanged]);

    return (
        <React.Fragment>
            <div className='main-text'>인증 관리</div>
            <div className='contents-row flex-center full-width auth-mgt-box'>
                <div className='contents-col full-width auth-mgt'>
                    <div className='contents-row act-info'>
                        <label>{props.profileData.co_ty === 1 ? '주민등록번호' : '사업자등록번호'}</label>
                        {/* <label>{loginUserCoTy === '1' ? '주민등록번호' : '사업자등록번호'}</label> */}
                        <div className='contents-row full-width flex-center full-width rrn-info'>
                            <input
                                className='full-width border info-input num'
                                type='text'
                                onChange={handleRegNum}
                                value={regNum}
                                maxLength={props.profileData.co_ty === 1 ? 14 : 12} 
                            />
                            {/* <input
                                // className='contents-row flex-center spacer border info-input doc-info'
                                className='full-width border info-input num'
                                type='text'
                                onChange={handleRegNo_A}
                                value={regNo_A}
                            />
                            <FontAwesomeIcon icon="fa-solid fa-minus"/>
                            <input
                                // className='contents-row flex-center spacer border info-input doc-info'
                                className='full-width border info-input num'
                                type='text'
                                onChange={handleRegNo_B}
                                value={regNo_B}
                            /> */}
                        </div>
                    </div>
                    <div className='contents-row act-info'>
                        <label>{props.profileData.co_ty === 1 ? '신분증' : '사업자 등록증'}</label>
                        <div className='contents-col full-width'>
                            <div className='contents-row flex-center full-width reg-img-add'>
                                {uplImageUrl && 
                                    <div 
                                        className="image-container"     
                                        onMouseEnter={handleMouseEnter} 
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <img
                                            className="user-logo-img"
                                            src={uplImageUrl}
                                            alt="Uploaded Image"
                                            style={{ maxWidth: '100%', maxHeight: '120px' }}
                                        />
                                        {isHovered && (
                                            <div className="zoom-icon" >
                                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={handleIconClick} aria-hidden="true"/>
                                            </div>
                                        )}
                                    </div>
                                }
                                {uplImageUrl ? (
                                    <div className='gc-btn flex-center act-btn info-btn change' onClick={handleUplFileClick}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrows-rotate"/>변경
                                    </div>
                                ) : (
                                    <div className='gc-btn flex-center act-btn info-btn' onClick={handleUplFileClick}>
                                        <FontAwesomeIcon icon="fa-solid fa-plus"/> 추가
                                    </div>
                                )}
                            </div>
                            {isModalOpen && (
                                <div className="modal-box">
                                    <div className="modal-content">
                                        <img
                                            src={uplImageUrl}
                                            alt="Uploaded Image"
                                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                                        />
                                        <span className="close" onClick={closeModal}>&times;</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='contents-row act-info'>
                        <label>사업장 주소</label>
                        <input
                            className='contents-row flex-center spacer border info-input'
                            type='text'
                            placeholder='사업장 주소 입력해 주세요'
                            onChange={handleAddress}
                            value={address || ''}
                        />
                    </div>
                    <div className='contents-row act-info'>
                        <label>서명자</label>
                        {/* <label>{loginUserCoTy === '1' ? '주민등록번호' : '사업자등록번호'}</label> */}
                        <div className='contents-row full-width flex-center full-width rrn-info signatory'>
                            <label>직급</label>
                            <input
                                // className='contents-row flex-center spacer border info-input doc-info'
                                className='full-width border info-input contents'
                                type='text'
                                onChange={handleRank}
                                value={rank}
                            />
                            <label>이름</label>
                            <input
                                // className='contents-row flex-center spacer border info-input doc-info'
                                className='full-width border info-input contents'
                                type='text'
                                onChange={handleName}
                                value={name}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='rrn-img-box'>
                </div> */}
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={uplfileRef}
                    onChange={handleUploadFile}
                />
            </div>
            <div className='hr-solid'/>
            <div className='main-text'>자격증 관리</div>
            {/* <div className='contents-row act-info cert-img-box'>
    {existingCertiUrls && existingCertiUrls.map((file, index) => (
        <div className='contents-col flex-center cert-img' key={`existing-${index}`}>
            <div className="cert-img-container" style={{ position: 'relative' }}>
                <img src={file} alt={file} className='rrn-img cert' style={{ maxWidth: '100%', maxHeight: '120px' }} />
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={() => handleCertiIconClick(file)} aria-hidden="true" className="magnifying-glass-icon" />
            </div>
            <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDeleteCertiFile(index, true)} />
            <input
                className='contents-row flex-center spacer border info-input adress'
                type='text'
                placeholder='이미지 설명을 입력해주세요'
                value={existingCertiDescriptions[index] || ''}
                onChange={(e) => handleCertiDescriptionChange(index, e.target.value, true)}
                disabled={props.profileData && props.profileData.certi_nm_arr && props.profileData.certi_nm_arr[index] !== undefined && props.profileData.certi_nm_arr[index] === existingCertiDescriptions[index]}
            />
        </div>
    ))}
    {newCertiUrls && newCertiUrls.map((file, index) => (
        <div className='contents-col flex-center cert-img' key={`new-${index}`}>
            <div className="cert-img-container" style={{ position: 'relative' }}>
                <img src={file} alt={file} className='rrn-img cert' style={{ maxWidth: '100%', maxHeight: '120px' }} />
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={() => handleCertiIconClick(file)} aria-hidden="true" className="magnifying-glass-icon" />
            </div>
            <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDeleteCertiFile(index, false)} />
            <input
                className='contents-row flex-center spacer border info-input adress'
                type='text'
                placeholder='이미지 설명을 입력해주세요'
                value={newCertiDescriptions[index] || ''}
                onChange={(e) => handleCertiDescriptionChange(index, e.target.value, false)}
            />
        </div>
    ))}
</div> */}

                <div className='contents-col flex-center full-width auth-mgt-box cert'>
                    <div className='gc-btn flex-center act-btn info-btn cert' onClick={handleCertiFileClick}><FontAwesomeIcon icon="fa-solid fa-plus"/>추가</div>
                    <div className='contents-row act-info cert-img-box'>
                        {existingCertiUrls && existingCertiUrls.map((file, index) => (
                            <div className='contents-col flex-center cert-img' key={`existing-${index}`}>
                                <div className="cert-img-container" style={{ position: 'relative' }}>
                                    <img src={file} alt={file} className='rrn-img cert' style={{ maxWidth: '100%', maxHeight: '120px' }} />
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={() => handleCertiIconClick(file)} aria-hidden="true" className="magnifying-glass-icon" />
                                </div>
                                <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDeleteCertiFile(index, true)} />
                                {/* <div className='contents-row flex-center spacer border info-input adress'>{existingCertiDescriptions[index] || ''}</div> */}
                                <input
                                    className='contents-row flex-center spacer border info-input adress'
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={existingCertiDescriptions[index] || ''}
                                    onChange={(e) => handleCertiDescriptionChange(index, e.target.value, true)}
                                    disabled
                                    // disabled={props.profileData && props.profileData.certi_nm_arr && props.profileData.certi_nm_arr[index] !== undefined && props.profileData.certi_nm_arr[index] === existingCertiDescriptions[index]}
                                />
                            </div>
                        ))}
                        {newCertiUrls && newCertiUrls.map((file, index) => (
                            <div className='contents-col flex-center cert-img' key={`new-${index}`}>
                                <div className="cert-img-container" style={{ position: 'relative' }}>
                                    <img src={file} alt={file} className='rrn-img cert' style={{ maxWidth: '100%', maxHeight: '120px' }} />
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={() => handleCertiIconClick(file)} aria-hidden="true" className="magnifying-glass-icon" />
                                </div>
                                <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDeleteCertiFile(index, false)} />
                                <input
                                    className='contents-row flex-center spacer border info-input adress'
                                    type='text'
                                    placeholder='이미지 설명을 입력해주세요'
                                    value={newCertiDescriptions[index] || ''}
                                    onChange={(e) => handleCertiDescriptionChange(index, e.target.value, false)}
                                />
                            </div>
                        ))}
                        {selectedImage && (
                            <div className="certi-modal">
                                <div className="certi-modal-content">
                                    <img src={selectedImage} alt="Selected" className="certi-modal-image" />
                                    <span className="certi-close" onClick={handleCloseModal}>&times;</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={certiFileRef}
                        onChange={handleCertiFileUpload}
                        multiple
                    />
                </div>
            {/* <div className='contents-row flex-center full-width auth-mgt-box cert'> */}
                {/* <div className='contents-col flex-center full-width auth-mgt-box cert'>
                    <div className='gc-btn flex-center act-btn info-btn cert' onClick={handleCertiFileClick}><FontAwesomeIcon icon="fa-solid fa-plus"/>추가</div>
                    <div className='contents-row act-info cert-img-box'>
                    {selectedCertiFileUrls && selectedCertiFileUrls.map((file, index) => (
                        <div className='contents-col flex-center cert-img' key={index}>
                            <div className="cert-img-container" style={{ position: 'relative' }}>
                                <img src={file} alt={file} className='rrn-img cert' style={{ maxWidth: '100%', maxHeight: '120px' }} />
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" onClick={() => handleCertiIconClick(file)} aria-hidden="true" className="magnifying-glass-icon" />
                            </div>
                            <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDeleteCertiFile(index)} />
                            <input
                                className='contents-row flex-center spacer border info-input adress'
                                type='text'
                                placeholder='이미지 설명을 입력해주세요'
                                value={certiDescriptions[index] || ''}
                                onChange={(e) => handleCertiDescriptionChange(index, e.target.value)}
                                disabled={props.profileData && props.profileData.certi_nm_arr && props.profileData.certi_nm_arr[index] !== undefined && props.profileData.certi_nm_arr[index] === certiDescriptions[index]}
                            />
                        </div>
                    ))}
                        {selectedImage && (
                            <div className="certi-modal">
                                <div className="certi-modal-content">
                                    <img src={selectedImage} alt="Selected" className="certi-modal-image" />
                                    <span className="certi-close" onClick={handleCloseModal}>&times;</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={certiFileRef}
                        onChange={handleCertiFileUpload}
                        multiple
                    />
                </div> */}
            {/* </div> */}
            <div className='hr-solid'/>
            <div className='full-width'>
                <div className='main-text'>계좌 관리</div>
                <div className='contents-row act-info'>
                    <label>은행</label>
                    <Select
                        className='profile-select border'
                        value={selectedBankOption}
                        onChange={handleChange}
                        options={options}
                        placeholder="은행명"
                    />
                    {/* <Select
                        className='profile-select border'
                        value={selectedBankOption}
                        onChange={handleChange}
                        options={options}
                        placeholder="은행명"
                    /> */}
                    {/* <Select
                        options={[]}
                        className='profile-select border'
                        placeholder='은행명'
                        id=''
                        name=''
                        value={''}
                    /> */}
                </div>
                <div className='contents-row act-info'>
                    <label>계좌 번호</label>
                    {/* <div className='contents-row doc-info'> */}
                        <input
                            className='contents-row flex-center spacer border info-input doc-info'
                            type='text'
                            placeholder='계좌번호를 입력해 주세요'
                            onChange={handleAccountNumber}
                            value={accountNumber || ''}
                        />
                    {/* </div> */}
                </div>
                {/* <div className='contents-row act-info'>
                    <label>예금주</label>
                    <div className='contents-row doc-info'>
                        <input
                            className='contents-row flex-center spacer company history info-input'
                            type='text'
                            placeholder='예금주명을 입력해 주세요'
                        />
                    </div>
                </div> */}
                <div className='contents-row act-info'>
                    <label>예금주</label>
                    <input
                        className='contents-row flex-center spacer border info-input doc-info'
                        type='text'
                        placeholder='예금주'
                        onChange={handleAccountHolder}
                        value={accountHolder || ''}
                    />
                </div>
            </div>

            <div className={`gc-btn gc-btn-green contents-row flex-center profile-btn  ${!onChangeClick ? 'disabled' : ''}`} onClick={onChangeClick ? profileDocsUpd : null}>저장하기</div>
            
            <Popup
                show={showPopup}
                // onCancel={() => setShowAddPopup(false)}
                header=""
                // icon={<i className="code x icon"  
                //         onClick={() => {
                //             // setShowAddPopup(false);
                //             setShowPopup(false);
                //         }} 
                //     /> }
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p
                    // style={{
                    //   fontSize: fontSizeValue,
                    //   padding: paddingValue,
                    // }}
                    // dangerouslySetInnerHTML={{ __html: popupMsg }}
                >
                    {popupMsg}
                </p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                    setShowPopup(false);
                    }}  
                >닫기</div>
                </div>
            </Popup>
        </React.Fragment>
    );
}

export default Document;