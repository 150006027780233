import './Helpdesk.css';

import React, { useState, useCallback, useEffect } from 'react';

function Helpdesk() {

  return (
    <div className='service-preparing'>
      <div className='image'></div>
      <div className='comment'>페이지 준비중입니다.</div>
      <div className='comment-sub'>보다 나은 서비스를 제공하기 위하여 페이지 준비중에 있습니다.</div>
      <div className='comment-sub'>빠른 시일 내에 준비하여 찾아뵙겠습니다.</div>
      <div className='comment-sub'>감사합니다.</div>
    </div>
  );
}

export default Helpdesk;
