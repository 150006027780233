import './ManagerAccountMgt.css';

import SendMsg from './SendMsg';

import React, { useState, useEffect } from 'react';
import { Pagination, Icon } from 'semantic-ui-react';

import { Dropdown } from 'semantic-ui-react';

// import Popup from '../../shared/components/uiElements/Popup';

// AXIOS
import axios from "axios";

function ManagerAccountMgt(props) {

    // LocalStorage
    const APIToken = localStorage.getItem('login-token');
    
    // Pagination
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    // 사용자 유형 변경 (1: 파트너, 2: 클라이언트, 3 : 매니저)
    // const userTyOptions = [
    //     // {key: 'undefined', text: '미정', value: '' },  // '미정' 항목 추가
    //     { key: '', text: '미정', value: '' },  // '미정' 항목
    //     {key: 1, text: '파트너', value: 1},
    //     {key: 2, text: '클라이언트', value: 2},
    //     {key: 3, text: '매니저', value: 3}
    // ]
    const userTyOptions = [
        { key: '', text: '미정', value: ' ' },
        { key: 1, text: '파트너', value: 1 },
        { key: 2, text: '클라이언트', value: 2 },
        { key: 3, text: '매니저', value: 3 }
    ];
    // const getDropdownValue = (user_ty) => {
    //     console.log('User TY:', user_ty);
    //     return [1, 2, 3].includes(user_ty) ? user_ty : '';
    // };
    // const getDropdownValue = (user_ty) => {
    //     const value = user_ty === null || user_ty === undefined || ![1, 2, 3].includes(user_ty) ? '' : user_ty;
    //     console.log('Dropdown Value:', value);  // 디버깅용
    //     return value;
    // };
    const getDropdownValue = (user_ty) => {
        if (user_ty === null || user_ty === undefined || ![1, 2, 3].includes(user_ty)) {
            return ' ';
        }
        return user_ty;
    };
     

    const [changeUserTy, setChangeUserTy] = useState(false);
    const handleUserTyChange = (event, data, userId) => {
        axios({
            method:'post',
            url:`/api/account/update/type`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { 
                userId: userId,
                userTy: data.value
            },
        })
        .then((result)=>{
            if (result.data.success) {
                // console.log(result.data);
                setChangeUserTy(true);
            }
        })
        .catch((error)=>{
        })
    };

    // 사용자 활성상태 변경 (1 : 활성화, 2 : 비활성화(정지), 3 : 탈퇴)
    const statusOptions = [
        // {key: 1, text: '대기 중', color: "blue", value: 1},
        // {key: 2, text: '승인 완료', color: "red", value: 2},
        // {key: 3, text: '승인 거절', color: "red", value: 3}
        {key: 1, text: '활성', value: 1},
        {key: 2, text: '사용 정지', value: 2},
        {key: 3, text: '강제 탈퇴', value: 3}
    ]
    const [actvtnCd, setActvtnCd] = useState(false);
    const handleActvtnCdChange = (event, data, userId) => {
        axios({
            method:'post',
            url:`/api/account/update/actvtn`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { 
                userId: userId,
                actvtnCd: data.value
            },
        })
        .then((result)=>{
            if (result.data.success) {
                // console.log(result.data);
                setActvtnCd(true);
            }
        })
        .catch((error)=>{
        })
    };

    // 사용자 인증여부 업데이트 (true : 인증, false : 미인증)
    const certifiedStateOptions = [
        {key: 1, text: '인증', value: true},
        {key: 2, text: '미인증', value: false},
    ]
    const [isCertified, setIsCertified] = useState(false);
    const handleIsCertifiedChange = (event, data, userId) => {
        axios({
            method:'post',
            url:`/api/account/update/certi`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
            data: { 
                userId: userId,
                certified: data.value.toString() 
            },
        })
        .then((result)=>{
            if (result.data.success) {
                // console.log(result.data);
                setIsCertified(true);
            }
        })
        .catch((error)=>{
        })
    };

    // 사용자 목록 조회 (inactivated=true)
    const [userList, setUserList] = useState();
    const portfoPageSize = 10;
    useEffect(() => {
        axios({
          method:'get',
          url:`/api/account/list?inactivated=true&page=${activePage}&perPage=${portfoPageSize}&sort=2`,
        //   url:`/api/account/list?inactivated=true&page=1&perPage=10`,
        //   url:`/api/account/list?inactivated=true&page=1&perPage=10`,
        //   url:`/api/account/list?inactivated=true`,
          headers: {
            'Authorization': `Bearer {${APIToken}}`
        },
        })
        .then((result)=>{
            if (result.data.success) {
                const entireList = result.data;
                // console.log(result.data.data);
                setUserList(entireList.data);
                setTotalPages(Math.ceil(entireList.total / portfoPageSize));
            } 
            // 상태변경 초기화
            setChangeUserTy(false);
            setActvtnCd(false);
            setIsCertified(false);
        })
        .catch((error)=>{
            console.log(error)  
        })
    }, [activePage, changeUserTy, actvtnCd, isCertified])


    // 메시지 모달창
    const [showSendMsg, setShowSendMsg] = useState(false);
    const [sendMsgUserData, setSendMsgUserData] = useState();

    return (
        <React.Fragment>
            {/* <div className='main-text'>인증 관리</div> */}
            <div className="full-width account-mgt-table">
                <div className="contents-row full-width table-label">
                    <div className="table-cell flex-center num">No.</div>
                    <div className="table-cell flex-center">이름</div>
                    <div className="table-cell flex-center email">이메일</div>
                    <div className="table-cell flex-center wide">연락처</div>
                    <div className="table-cell flex-center wide">사용자구분</div>
                    <div className="table-cell flex-center">형태</div>
                    <div className="table-cell flex-center wide">인증상태</div>
                    <div className="table-cell flex-center">상태</div>
                    <div className="table-cell flex-center">쪽지</div>
                </div>
                {console.log(userList)}
                {userList && userList.map((user, index) => (
                <div className="contents-row full-width table-label" key={index}>
                    {/* <div className="table-cell flex-center num">{index + 1}</div> */}
                    <div className="table-cell flex-center num">{(activePage - 1) * portfoPageSize + (index + 1)}</div>
                    <div className="table-cell flex-center">{user.user_nm || '-'}</div>
                    <div className="table-cell flex-center email">{user.email}</div>
                    <div className="table-cell flex-center wide">{user.phone_no || '-'}</div>
                    {/* {console.log(user.user_ty)} */}
                    {/* {console.log(user.email +'>'+ user.user_ty)} */}
                    {/* {console.log('타입' +'>'+ typeof(user.user_ty))} */}
                    {/* {console.log(user.user_ty)} */}
                    {console.log('Options:', userTyOptions)}
                    {console.log('Dropdown Value:', getDropdownValue(user.user_ty))}
                    <div className="table-cell flex-center wide">
                        {/* <Dropdown
                            inline
                            options={userTyOptions}
                            // value={user.user_ty ||''}
                            // value={user.user_ty === null || user.user_ty === undefined ? '' : user.user_ty} // 1, 2, 3 이외 값 처리
                            // value={[1, 2, 3].includes(user.user_ty) ? user.user_ty : ''}
                            value={getDropdownValue(user.user_ty)}
                            onChange={(event, data)=>{handleUserTyChange(event, data, user.user_id)}}
                        /> */}
                        {/* <Dropdown
                            key={user.user_id}
                            inline
                            options={userTyOptions}
                            value={getDropdownValue(user.user_ty)}
                            onChange={(event, data) => handleUserTyChange(event, data, user.user_id)}
                        /> */}
                        <Dropdown
                            key={user.user_id}
                            inline
                            options={userTyOptions}
                            value={getDropdownValue(user.user_ty)}
                            onChange={(event, data) => handleUserTyChange(event, data, user.user_id)}
                        />
                    </div>
                    {/* <div className="table-cell flex-center">{user.user_ty === 1 ? '파트너' : user.user_ty === 2 ? '클라이언트' : '미정'}</div> */}
                    <div className="table-cell flex-center">{user.co_ty === 1 ? '개인' : user.co_ty === 2 ? '개인사업자' :  user.co_ty === 3 ? '법인사업자' : '미정'}</div>
                    <div className="table-cell flex-center wide">
                        <Dropdown
                            inline
                            options={certifiedStateOptions}
                            // value={user.certified_com_yn === true ? true : user.certified_com_yn === false ? false : ''}
                            // onChange={(event, data)=>{handleIsCertifiedChange(event, data, user.user_id)}}
                            value={user.certified_com_yn}
                            onChange={(event, data) => {handleIsCertifiedChange(event, data, user.user_id) }}
                            text={user.certified_com_yn === true ? user.certified_com_de : '미인증'}
                        />
                    </div>
                    <div className="table-cell flex-center">
                        <Dropdown
                            inline
                            options={statusOptions}
                            value={user.actvtn_cd ||''}
                            onChange={(event, data)=>{handleActvtnCdChange(event, data, user.user_id)}}
                            // disabled={user.aprv_stts_cd === 2 || user.aprv_stts_cd === 3}    
                        />
                    </div>
                    {/* <div className="table-cell flex-center">{user.actvtn_cd ==  1 ? '활성' : user.actvtn_cd ==  2 ? '비활성' : user.actvtn_cd ==  3 ? '탈퇴' : null}</div> */}
                    <div className="table-cell flex-center">
                        <div className="gc-btn gc-btn-line send-msg" onClick={() => {setShowSendMsg(true); setSendMsgUserData(user)}}>보내기</div>
                    </div>
                </div>
                ))}
                {userList &&userList.length !== 0 ?
                <div className="flex-center">
                    <div className="flex-center">
                    <Pagination
                        className="pagination"
                        activePage={activePage}
                        totalPages={totalPages}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                        onPageChange={handlePageChange}
                    >                        
                    </Pagination>
                    </div>
                </div>
                : null}
            </div>
            {showSendMsg && <SendMsg showSendMsg={showSendMsg} setShowSendMsg={setShowSendMsg} sendMsgUserData={sendMsgUserData} />}   
           
            {/* <Popup
                show={showPopup}
                header=""
            >
                <div className='contents-col full-width flex-center popup-info'>
                <p>{popupMsg}</p>
                <div 
                    className="contents-row gc-btn flex-center close_btn" 
                    onClick={() => {
                        setShowPopup(false);
                    }}  
                >닫기</div>
                </div>
            </Popup> */}
        </React.Fragment>
    );
}

export default ManagerAccountMgt;