import './Partner.css';

import React, { useState, useEffect } from 'react';

import { Checkbox, Dropdown, Icon, Pagination } from 'semantic-ui-react';

// import {BrowserRouter as useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';

// AXIOS
import axios from "axios";

function Partner(props) {

  const APIToken = localStorage.getItem("login-token");
  const userType = localStorage.getItem('user-ty');

  const navigate = useNavigate();

  const [partnerList, setPartnerList] = useState(null);
  const [sortMenu, setSortMenu] = useState(false);
  const [sortType, setSortType] = useState(1);
  const [prjType, setPrjType] = useState('');
  const [coType, setCoType] = useState('');
  const [score, setScore] = useState('');
  const [sidoType, setSidoType] = useState('');
  const [sidoArray, setSidoArray] = useState();

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const onChangePrjType = (value) => {
    if(prjType.includes(value)){
      setPrjType(prjType.filter((item) => item !== value));
    }
    else{
      setPrjType((prev) => [...prev, value]);
    }
    setActivePage(1);
  }

  const onChangeCoType = (value) => {
    if (coType === value) {
        setCoType('');      // 같은 값 클릭 시 필터 해제
    } else {
        setCoType(value);   // 다른 값 클릭 시 필터 적용
    }
    setActivePage(1);       // 필터 변경 시 페이지 번호를 1로 초기화
    // getPartnerList(); 
  };

  const onChangeSidoType = (value) => {
    if(sidoType.includes(value)){
      setSidoType(sidoType.filter((item) => item !== value));
    }
    else{
      setSidoType((prev) => [...prev, value]);
    }
  }

  const handlePagination = (event, { activePage }) => {
    setActivePage(activePage);
    getPartnerList();   
  };
  
  // const handlePageChange = (e, { activePage }) => {
  //   setActivePage(activePage);
  // };

  const pageSize = 5;

  const scoreOption = [
    {text: '전체', value: 0},
    {text: '1.0', value: 1},
    {text: '2.0', value: 2},
    {text: '3.0', value: 3},
    {text: '4.0', value: 4},
    {text: '5.0', value: 5},
  ]

  useEffect (() => {
    getPartnerList();
  } , [sortType, prjType, coType, score, sidoType, activePage])

  // 파트너 전체 수
  const [partnerListTotal, setPartnerListTotal] = useState();

  function getPartnerList () {
    // console.log(sidoType);
    axios({
      method:'get',
      url:`/api/account/list`
      +`?userTy=1`
      +`&sort=${sortType}`
      +`&prjTyCdArr=${JSON.stringify(prjType)}`
      +`&coTy=${coType}`
      +`&score=${score}`
      +`&sidoCd=${JSON.stringify(sidoType)}`
      +`&page=${activePage}`
      +`&perPage=${pageSize}`
      // page=${activePage}&perPage=${portfoPageSize}
      ,

      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      { 
        // console.log(result.data.total);
        // console.log(result.data);
        let partner_list = result.data.data;
        setPartnerList(partner_list);
        setPartnerListTotal(result.data.total)
        setTotalPages(Math.ceil(result.data.total / pageSize));
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  useEffect (() => {
    axios({
      method:'get',
      url:`/api/util/sido`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        setSidoArray(result.data.data);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  } , [])

  function checkItrst (id, bool) {
    axios({
      method:'post',
      url:`/api/account/interest`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        itrstUserId: id,
        itrst: bool,
      }
    })
    .then((result)=>{
      if(result.data.success == true)
      {
        getPartnerList();
      }
      else{
        getPartnerList();
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  // const inputs = [professionalism_Input, proactiveness_Input, satisfaction_Input, scheduleCompliance_Input, communication_Input, defectRepair_Input];
  // const total = inputs.reduce((acc, val) => acc + parseFloat(val || 0), 0);
  // const average = total / inputs.length;
  
  // const width = ((average / 5) * 101.25) + 'px';
  const fillStyle = {
      position: 'absolute',
      // width: width,
      overflow: 'hidden',
  };

  const lineStyle = {
      position: 'relative',
      width: '101.25px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
  };
  
  return (
    <div className='Partner-container'>
      <div className='partner-view'>
        <div className='partner-view-filter contents-col'>
          <div className='filtering-option'>
            <div className='filtering-header'>
              <div className='header-text'>필터</div>
            </div>
            <div className='filtering-main'>
              <div className='filtering-type'>
                <div className='header-text'>촬영 분야</div>

                <div className='contents-row'>
                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-1' value={1} checked={prjType.includes(1)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-1' className='textB'>일반 레포츠</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-2' value={2} checked={prjType.includes(2)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-2' className='textB'>미디어 촬영</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-3' value={3} checked={prjType.includes(3)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-3' className='textB'>시설점검</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>

                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-4' value={4} checked={prjType.includes(4)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-4' className='textB'>공간정보</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-5' value={5} checked={prjType.includes(5)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-5' className='textB'>농업/방제</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-type-6' value={6} checked={prjType.includes(6)} onChange={(e, data)=>{onChangePrjType(data.value)}}></Checkbox>
                      <label htmlFor='filter-type-6' className='textB'>기타</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>
                </div>

              </div>

              <div className='part-divider'></div> 

              <div className='filtering-form'>
                <div className='header-text'>형태</div>
                <div className='contents-row'>
                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-co-1' value={1} checked={coType == 1} onChange={(e, data)=>{onChangeCoType(data.value)}}></Checkbox>
                      <label htmlFor='filter-co-1' className='textB'>개인</label>
                    </div>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-co-2' value={2} checked={coType == 2} onChange={(e, data)=>{onChangeCoType(data.value)}}></Checkbox>
                      <label htmlFor='filter-co-2' className='textB'>개인사업자</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>

                  <div className='contents-col'>
                    <div className='filtering-factor contents-row'>
                      <Checkbox className='checkbox' id='filter-co-3' value={3} checked={coType == 3} onChange={(e, data)=>{onChangeCoType(data.value)}}></Checkbox>
                      <label htmlFor='filter-co-3' className='textB'>법인사업자</label>
                    </div>
                  </div>

                  <div className='flex-spacer'></div>
                </div>
              </div>

              <div className='part-divider'></div> 
              
              <div className='filtering-score'>
                <div className='header-text'>평점</div>
                <Dropdown
                  className='option-input dropdown'
                  placeholder='전체'
                  fluid
                  selection
                  icon='angle down'
                  options={scoreOption}
                  value={score}
                  // onChange={(e, data)=>{setScore(data.value)}}
                  onChange={(e, data) => {
                    setScore(data.value);   // score 값을 업데이트
                    setActivePage(1);       // 페이지 번호를 1로 초기화
                  }}
                >
                </Dropdown>
              </div>
              
              <div className='part-divider'></div> 

              <div className='filtering-location'>
                {/* <div className='contents-row full-width flex-center'>
                  <div className='header-text'>지역</div>
                    <Checkbox 
                      className='checkbox' 
                      // id={`filter-sido-${sido.sido_cd}`} 
                      // value={sido.sido_cd} 
                      // checked={sidoType.includes(sido.sido_cd)} 
                      // onChange={(e, data)=>{onChangeSidoType(data.value)}}
                    ></Checkbox>
                    <label htmlFor={``} className=''>전체</label>
                </div> */}
                <div className='header-text'>지역</div>
                <div className='contents-col sido-group'>
                  {sidoArray && sidoArray.map((sido)=>{
                    {console.log(sido.sido_cd)}
                    return(
                      <div className='filtering-factor contents-row' key={sido.sido_cd}>
                        <Checkbox 
                          className='checkbox' 
                          id={`filter-sido-${sido.sido_cd}`} 
                          value={sido.sido_cd} 
                          checked={sidoType.includes(sido.sido_cd)} 
                          onChange={(e, data)=>{onChangeSidoType(data.value)}}
                        ></Checkbox>
                        <label htmlFor={`filter-sido-${sido.sido_cd}`} className='textB'>
                          {
                          sido.sido_nm == '서울특별시' ? '서울' :
                          sido.sido_nm == '부산광역시' ? '부산' :
                          sido.sido_nm == '대구광역시' ? '대구' :
                          sido.sido_nm == '인천광역시' ? '광주' :
                          sido.sido_nm == '광주광역시' ? '광주' :
                          sido.sido_nm == '대전광역시' ? '대전' :
                          sido.sido_nm == '울산광역시' ? '울산' :
                          sido.sido_nm == '세종특별자치시' ? '세종' :
                          sido.sido_nm == '경기도' ? '경기' :
                          sido.sido_nm == '강원도' ? '강원' :
                          sido.sido_nm == '충청북도' ? '충북' :
                          sido.sido_nm == '충청남도' ? '충남' :
                          sido.sido_nm == '전라북도' ? '전북' :
                          sido.sido_nm == '전라남도' ? '전남' :
                          sido.sido_nm == '경상북도' ? '경북' :
                          sido.sido_nm == '경상남도' ? '경남' :
                          sido.sido_nm == '제주특별자치도' ? '제주' : sido.sido_nm
                          }
                        </label>
                      </div>
                    )
                  })}
                </div>
                {/* <div className='contents-row'>
                  <Checkbox 
                    className='checkbox' 
                    id={`all-project`} 
                    // value={sido.sido_cd} 
                    // checked={sidoType.includes(sido.sido_cd)} 
                    // onChange={(e, data)=>{onChangeSidoType(data.value)}}
                  ></Checkbox>
                  <label htmlFor={`all-project`} className=''>
                    지원중 외 모든 프로젝트
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='partner-view-list contents-col'>
          <div className='list-header contents-row'>
            <div className='header-text contents-row'>
              <div className='main-text'>파트너 찾기</div>
              <div className='sub-text'>( {partnerList && partnerListTotal ? partnerListTotal:'0'} 명의 파트너 )</div>
              {/* <div className='sub-text'>( {partnerList? partnerList.length:'0'} 명의 파트너 )</div> */}
            </div>
            <div className='flex-spacer'></div>
            {
            sortMenu?
            <div className={`flex-center contents-row sort-button`}>
              <div className='sort-image'></div>
              <div className='sort-text active' onClick={()=>{setSortType(1);setSortMenu(false);}}>평점 순</div>
              <div className='sort-text active' onClick={()=>{setSortType(2);setSortMenu(false);}}>이름 순</div>
            </div>
            :
            <div className='flex-center contents-row  sort-button' onClick={()=>{setSortMenu(true)}}>
              <div className='sort-image'></div>
              <div className='sort-text'>
                {
                sortType == 1? '평점 순' :
                sortType == 2? '이름 순' : 
                '정렬방식'
                }
              </div>
            </div>
            }
          </div>
          <div className='list-main'>
            {
            partnerList && partnerList.map((partner, i) => {
              return (
                // i >= (activePage - 1) * pageSize &&
                // i < activePage * pageSize && (
                  <div key={i} className='partner-summary-box contents-row'>
                    {
                    partner.photoUrl != null? 
                    <img className='partner-logo' src={partner.photoUrl} alt=""></img> 
                    : 
                    <div className='partner-logo null'></div>
                    }
                    <div className='contents-col part-main'>
                      <div className='contents-row'>
                        <div className='partner-name'>{partner.user_nm}</div>
                        <div className='flex-spacer'></div>
                        {/* {console.log(partner.co_ty)} */}
                        {
                          partner.co_ty == 1?
                          <div className='contents-row'>
                            <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-user"/></div>
                            <div className='partner-type'>개인</div>
                          </div>
                          :
                          partner.co_ty == 2?
                          <div className='contents-row'>
                            <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-building"/></div>
                            <div className='partner-type'>개인사업자</div>
                          </div>
                          :
                          partner.co_ty == 3?
                          <div className='contents-row'>
                            <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-building"/></div>
                            <div className='partner-type'>법인사업자</div>
                          </div>
                          :
                          null
                        }
                        {
                          partner.certified_com_yn?
                          <div className='contents-row'>
                            <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award" /></div>
                            <div className='partner-certification'>인증업체</div>
                          </div>
                          :
                          null
                          // <div className='contents-row'>
                          //   <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award none" /></div>
                          //   <div className='partner-certification'>비인증업체</div>
                          // </div>
                        }
                        {
                          partner.user_itrst_yn == true?
                          <div className='flex-center' onClick={()=>{checkItrst(partner.user_id, 'false')}}><FontAwesomeIcon icon="fa-solid fa-heart"/></div>
                          :
                          <div className='flex-center' onClick={()=>{checkItrst(partner.user_id, 'true')}}><FontAwesomeIcon icon="fa-regular fa-heart"/></div>
                        }
                        <div 
                          className='partner-detail flex-center'
                          onClick={()=>{
                          if(props.isLoggedIn)
                            // {navigate(`/partner/${partner.user_id}`)}
                            navigate(`/partner/${partner.user_id}`, { state: { partner } });
                          else
                            {navigate('/login')}
                          }}
                        >
                        더 보기</div>
                      </div>
                      <div className='partner-introduce'>{partner.intro == null || partner.intro == 'null'? '소개없음' : partner.intro}</div>
                    </div>
                      
                    <div className='contents-col part-sub'>
                      <div className='contents-row'>
                        <div className='contents-row score' style={lineStyle}>
                          <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                          {/* <div className='text'>{partner.review_score_avr.toFixed(1)} / 0명</div> */}
                        </div>
                        {/* {console.log(partner)} */}
                        <div className='contents-row score' style={{ position: 'absolute', overflow: 'hidden', width: ((partner.review_score_avr / 5) * 101.25) + 'px' }}>
                          <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                          <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                          {/* <div className='text'>{partner.review_score_avr.toFixed(1)} / 0명</div> */}
                        </div>
                      </div>
                      {/* {console.log(partner)} */}
                      <div className='text'>{partner.review_score_avr.toFixed(1)} / 0명</div>
                      <div className='contents-row'>
                        <div className='partner-option textA'>계약한 프로젝트</div>
                        <div className='partner-option textB'>{partner.contract_co} 건</div>
                      </div>
                      <div className='contents-row'>
                        <div className='partner-option textA'>포트폴리오</div>
                        <div className='partner-option textB'>{partner.prtfolio_co} 건</div>
                      </div>
                      <div className='contents-row'>
                        <div className='partner-option textA'>DROPIA 활동 기간</div>
                        <div className='partner-option textB'>{moment(moment(), "YYYY-MM-DD").diff(partner.hist_join_dt, 'month')} 개월</div>
                      </div>
                    </div>
                  </div>
                // )
              )
            })
            }
          </div>
          <div className="pagination-container flex-center">
            <Pagination
              className="pagination"
              activePage={activePage}
              totalPages={totalPages}
              ellipsisItem={{ key: 'ellipsisItem-1', content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
              onPageChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
