
import './PartnerProfile.css'

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom'
import { Select, Dropdown, DropdownMenu, DropdownItem, Pagination, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';

// AXIOS
import axios from "axios";

function PartnerProfile() {

  const location = useLocation();
  const { partner } = location.state || {};
  // console.log(partner);
    
  const APIToken = localStorage.getItem("login-token");
  const loginUserId = localStorage.getItem('user-id');
  const loginUserType = localStorage.getItem('user-ty');

  const partnerID = useParams();

  const [projectList, setProjectList] = useState();
  const [profileData, setProfileData] = useState();
  const [portfolioList, setPortfolioList] = useState();
  const [reviewList, setReviewList] = useState();
  const [reviewRankList, setReviewRankList] = useState();

  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const itemsPerPage = 2;
  const totalPages = Math.ceil(profileData && profileData.co_history_arr && profileData.co_history_arr.length / itemsPerPage);
  const startIndex = (activePage - 1) * itemsPerPage;
  const currentPageItems = profileData && profileData.co_history_arr != null && profileData.co_history_arr.slice(startIndex, startIndex + itemsPerPage);
  // 데이터가 모두 비어있는지 확인하는 함수
  const allItemsEmpty = currentPageItems && currentPageItems.every(item => !item.date && !item.content);

  useEffect(() => {

    if (partnerID) {
      axios({
        method:'get',
        url:`/api/account/profile?userId=${partnerID.id}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
      })
      .then((result)=>{
        if (result.data.success) {
          let profile_data = result.data.data;
          setProfileData(profile_data);
        } 
        else {

        }
      })
      .catch((error)=>{
        console.log(error)  
      })

      axios({
        method:'get',
        url:`/api/prtfolio/list?userId=${partnerID.id}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
      })
      .then((result)=>{
        if (result.data.success) {
          setPortfolioList(result.data.data);
        } 
        else {

        }
      })
      .catch((error)=>{
        console.log(error)  
      })

      axios({
        method:'get',
        url:`/api/project/review/list?toUserId=${partnerID.id}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
      })
      .then((result)=>{
        if (result.data.success) {
          setReviewList(result.data.data);
          setReviewRankList(result.data.rank)
        } 
        else {

        }
      })
      .catch((error)=>{
        console.log(error)  
      })
    }
  }, [])

  useEffect (() => {
    let urlstring = `/api/project/list?`;

    if(loginUserType == 1){ // 파트너
        urlstring += `partnerId=${loginUserId}`;
    }
    else if(loginUserType == 2){ // 클라이언트
        urlstring += `userId=${loginUserId}`;
    }

    axios({
      method:'get',
      url: urlstring,
      headers: {
          'Authorization': `Bearer {${APIToken}}`
      },
    })
    .then((result)=>{
      console.log(result)
      if(result.data.success === true) {
          let project_list = result.data.data;
          setProjectList(project_list);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }, [])

  const columnCalculateBackgroundColor = (value) => {
    if (isNaN(value) || value === 0) {
        return '#D9D9D9';
    } else {
        const ratio = value / reviewList.total;
        return `linear-gradient(to top, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
    }
  };

  const rowCalculateBackgroundColor = (rowValue) => {
    const value = parseFloat(rowValue);
    if (isNaN(value) || value === 0) {
        return '#D9D9D9';
    } else {
        const ratio = value / 5;
        return `linear-gradient(to right, #FBB01F ${ratio * 100}%, #D9D9D9 ${ratio * 100}%)`;
    }
  };

  // Dropia 프로젝트 n년 계산
  const [ageInYears, setAgeInYears] = useState('');
  useEffect(() => {
    const joinDateObj = new Date(profileData && profileData.hist_join_dt);                   // 프로필 가입일을 Date 객체로 변환
    const currentDate = new Date();                                           // 현재 날짜를 가져오기
    setAgeInYears(currentDate.getFullYear() - joinDateObj.getFullYear());     // 년수 계산
  }, [])

  const fieldLabels = {
    1: '일반 레포츠',
    2: '미디어 촬영',
    3: '시설점검',
    4: '공간정보',
    5: '농업,방제',
    6: '기타',
  };

  // 내가 받은 리뷰 요약 별점
  const lineStyle = {
    position: 'relative',
    width: '101.25px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  return (
  <div className='partnerprofile-container'>
    <div className='contents-col partnerprofile-main'>

      <div className='header-text'>파트너 정보</div>
      <div className='part-divider'/>

      <div className='contents-col full-width'>
        <div className='contents-row full-width my-profile-box'>
          <div className='contents-row user-info spacer'>
            {
            profileData && profileData.photoUrl ? 
            <img
              className="user-logo-img"
              src={profileData && profileData.photoUrl}
              alt="Uploaded Image"
            />
            :
            <div className='user-logo-img null'></div>
            }
            <div className='contents-col user-info-box'>
              <p className='user-nm'>{profileData && profileData.user_nm}</p>
              <div className='contents-row my-profile-tag-box'>
                {profileData && profileData.prj_ty_cd_arr && profileData.prj_ty_cd_arr.length !== 0 ? 
                  // profileData.prj_ty_cd_arr.map((code, index) => (
                  //     // console.log(fieldLabels[code])
                  //     <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>{fieldLabels[code]}</div>
                  // ))
                  // profileData.prj_ty_cd_arr.map((code, index) => (
                  //     fieldLabels[code] !== "기타" && (
                  //         <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                  //             {fieldLabels[code]}
                  //         </div>
                  //     )
                  // ))

                  profileData.prj_ty_cd_arr.map((code, index) => (
                      fieldLabels[code] === "기타" ? (
                          <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                              {profileData.prj_ty_custom}
                          </div>
                      ) : (
                          <div className='gc-btn gc-btn-line info-btn flex-center my-profile-tag' key={index}>
                              {fieldLabels[code]}
                          </div>
                      )
                  ))
                  :
                  <p className='result-info'>선택한 분야 정보가 없습니다.</p>}
              </div>
            </div> 
              {/* <div className='contents-row my-profile-tag-box'>
                {
                profileData && profileData.prj_ty_cd_arr && profileData.prj_ty_cd_arr.length !== 0 ? 
                profileData && profileData.prj_ty_cd_arr.map((code, index) => (
                    <div className='gc-btn gc-btn-line tag-flex flex-center my-profile-tag' key={index}>{fieldLabels[code]}</div>
                ))
                :
                null
                }
              </div>
            </div>  */}
          </div>
          <div className='contents-row justify-center spacer'>
            <div className='contents-col flex-center privacy-box'>
              <div className='contents-row privacy-row'>
                <div className='gc-btn flex-center info-btn privacy years'>
                  경력 {(!profileData || profileData.co_years === null || profileData.co_years === undefined) ? '0' : profileData.co_years}년
                </div>
                {/* <div className='gc-btn tag-flex flex-center info-btn privacy'>
                  {console.log(profileData)}
                  {console.log(profileData.co_ty)}
                  {/* {profileData && profileData.co_ty === 1 ? '개인' : profileData && profileData.co_ty === 2 ? '개인사업자' : '법인사업자'} 
                </div> */}
                {profileData && profileData.co_ty &&
                  <div className='gc-btn tag-flex flex-center info-btn privacy'>
                    {profileData && profileData.co_ty === 1 ? '개인' : profileData && profileData.co_ty === 2 ? '개인사업자' : profileData && profileData.co_ty === 3 ? '법인사업자' : null}
                  </div>
                }
              </div>
              <label>{profileData && profileData.sigungu_nm_arr[0] === '전체' && profileData && profileData.sigungu_nm_arr[1] === '전체' ? '전체' : profileData && profileData.sigungu_nm_arr.join(' ')}</label>
            </div>
            <div className='contents-col flex-center progress-box'>
              <p>Dropia 프로젝트</p>
              <div className='contents-row flex-center full-width'>
                <p className='flex-center'>{ageInYears? ageInYears : 0}년</p>
                <p className='flex-center prj-result'>{partner && partner.contract_co}건</p>
                {/* <p className='flex-center prj-result'>{projectList && projectList.length}건</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className='profile-intro'>{profileData && profileData.intro != 'null' ? profileData.intro : '소개없음'}</div>
      </div>
      
      <div className='part-divider'/>
      <div className='header-text'>리뷰 요약</div>
      {
      reviewList && reviewRankList?
      <div className='contents-row full-width'>
        <div className='contents-col rating-container'>

          {/* <div className='contents-row flex-center portfolio-menubar rating-box'>
            <div className='contents-row flex-center'>
              <div className='contents-row '>
                <div className='contents-row star-rating-box' style={lineStyle}>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                </div>
                <div className='contents-row star-rating-box' style={{ position: 'absolute', overflow: 'hidden', width: ((reviewList.length === 0 ? 0 : reviewList.avgAvr / 5) * 101.25) + 'px' }}>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                </div>
              </div>
              <p>{reviewList.length === 0 ? '0' : reviewList.avgAvr} &nbsp;<span className='rating'>/5</span></p>
              <p className='peoplee-num'>{reviewList.length === 0 ? '0' : reviewList.total}명 평가</p>
            </div>
          </div> */}

          <div className='contents-row full-width rating-factors-box'>
            <div className='contents-row full-width flex-center rating-factors'>
              <label>전문성</label>
              <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore1), position: 'relative' }}></div>
              <label>{reviewList.avgScore1}</label>
            </div>
            <div className='contents-row full-width flex-center rating-factors'>
              <label>적극성</label>
              <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore2), position: 'relative' }}></div>
              <label>{reviewList.avgScore2}</label>
            </div>
          </div>
          <div className='contents-row full-width rating-factors-box'>
            <div className='contents-row full-width flex-center rating-factors'>
              <label>만족도</label>
              <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore3), position: 'relative' }}></div>
              <label>{reviewList.avgScore3}</label>
            </div>
            <div className='contents-row full-width flex-center rating-factors'>
              <label>일정준수</label>
              <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore4), position: 'relative' }}></div>
              <label>{reviewList.avgScore4}</label>
            </div>
          </div>
          <div className='contents-row full-width rating-factors-box'>
            <div className='contents-row full-width flex-center rating-factors'>
              <label>의사소통</label>
              <div className='full-width bar-chart' style={{ background: rowCalculateBackgroundColor(reviewList.avgScore5), position: 'relative' }}></div>
              <label>{reviewList.avgScore5}</label>
            </div>
            <div className='contents-row full-width lex-center rating-factors'>
            </div>
          </div>
          
        </div>
        <div className='contents-row full-width flex-center rating-container overall-rating-box'>
          <div className='contents-col flex-center overall-rating-factors'>
            {/* <span>{reviewRankList.rank_a}</span> */}
            <div className='full-width bar-chart rotate' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_a), position: 'relative' }}></div>
            <span>최고</span>
          </div>
          <div className='contents-col flex-center overall-rating-factors'>
            {/* <span>{reviewRankList.rank_b}</span> */}
            <div className='full-width bar-chart rotate' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_b), position: 'relative' }}></div>
            <span>좋음</span>
          </div>
          <div className='contents-col flex-center overall-rating-factors'>
            {/* <span>{reviewRankList.rank_c}</span> */}
            <div className='full-width bar-chart rotate' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_c), position: 'relative' }}></div>
            <span>보통</span>
          </div>
          <div className='contents-col flex-center overall-rating-factors'>
            {/* <span>{reviewRankList.rank_d}</span> */}
            <div className='full-width bar-chart rotate' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_d), position: 'relative' }}></div>
            <span>별로</span>
          </div>
          <div className='contents-col flex-center overall-rating-factors'>
            {/* <span>{reviewRankList.rank_e}</span> */}
            <div className='full-width bar-chart rotate' style={{ background: columnCalculateBackgroundColor(reviewRankList.rank_e), position: 'relative' }}></div>
            <span>최악</span>
          </div>
        </div>
      </div>
      :
      <div className='empty-content'>등록된 리뷰가 없습니다.</div>
      }

      <div className='part-divider'/>
      <div className='header-text'>포트폴리오</div>

      <div> 
        <div className='contents-row full-width portfolio-list'>
        {
        portfolioList && portfolioList.length !== 0 ? 
        portfolioList.map((portfolio, index) => (
          <React.Fragment key={index}>
            <div 
              className='contents-col flex-center rrn-img-box portfolio-box'
              key={index}
              onClick={()=>{
                // setProfileState('portfolio-view');
              }}
            >
              {
              portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx] ?       
                <img 
                src={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                alt={portfolio.prj_output_urls && portfolio.prj_output_urls[portfolio.prj_output_main_idx]}
                  className='rrn-img portfolio-img-img'
                  style={{
                    maxWidth: '100%',
                    // maxHeight: '130px',
                    objectFit: 'contain'
                  }}
                />
                : 
                <div 
                  className='contents-row flex-center portfolio-img-img'
                  style={{maxWidth: '100%', height: '200px', objectFit: 'contain'}}
                >
                  썸네일 이미지 없음
                </div>
              }
              <div className='text prtfolio_name'>{portfolio.prtfolio_nm}</div>
              <div className='text prtfolio_type'>{portfolio.prj_ty_nm}</div>
              <div className='text prtfolio_date'>{moment(portfolio.prj_start_de).format("YY.MM.DD")}  ~ {moment(portfolio.prj_end_de).format("YY.MM.DD")}</div>
            </div>
          </React.Fragment>
        ))
        : 
        <div className='empty-content'>등록된 포트폴리오가 없습니다.</div>
        } 
        </div>
      </div>

    </div>
  </div>
  );
}

export default PartnerProfile;