import './ProductDetail.css';

import React, { useState, useEffect, useRef, Suspense } from 'react';

// import * as THREE from "three";
import { Canvas, useThree, useLoader } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, OrthographicCamera, Environment, PivotControls, GizmoHelper, GizmoViewport } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
// import { DDSLoader } from "three-stdlib";

import JSZip from "jszip"

import { Checkbox, Dropdown } from 'semantic-ui-react';

import Modal from '../../shared/components/uiElements/Modal';
import Popup from '../../shared/components/uiElements/Popup';
// AXIOS
import axios from "axios";

function ProductDetail(props) {

  const APIToken = localStorage.getItem("login-token");

  const userID = localStorage.getItem('user-id');
  const userType = localStorage.getItem('user-ty');

  const [productOutputId, setProductOutputId] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productTyCd, setProductTyCd] = useState('');
  const [productAddr, setProductAddr] = useState('');
  const [productGeojson, setProductGeojson] = useState('');
  const [productExtUrl, setProductExtUrl] = useState('');
  const [productCreateDt, setProductCreateDt] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productOutputFile, setProductOutputFile] = useState('');
  const [productPrevFile, setProductPrevFile] = useState('');
  const [productShareLv, setProductShareLv] = useState('');

  // 산출물 수정
  const [modifyMode, setModifyMode] = useState(false);

  // 로딩화면
  const [loading, setLoading] = useState(false);

  const [popupState, setPopupState] = useState();                     // 팝업 상태
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);  // 질문팝업 boolen
  const [showAlarmPopup, setShowAlarmPopup] = useState(false);        // 알림팝업 boolen
  const [popupMsg, setPopupMsg] = useState('');                       // 팝업메시지 

  const canvasRef = React.useRef(null)
  const productRef = useRef(null);

  const handleProductClick = () => {productRef.current.click();};
  
  const typeOptions = [
    {text: '이미지', value: 1},
    {text: '비디오', value: 2},
    {text: '기타', value: 3},
    {text: '정사영상', value: 101},
    {text: 'DEM', value: 102},
    {text: '3D', value: 103},
  ]

  useEffect(()=>{
    if(props.selectedProduct){
      setProductOutputId(props.selectedProduct.output_id);
      setProductTitle(props.selectedProduct.output_title);
      setProductTyCd(props.selectedProduct.output_ty_cd);
      setProductAddr(props.selectedProduct.output_addr);
      setProductGeojson(props.selectedProduct.output_geojson);
      setProductExtUrl(props.selectedProduct.output_ext_url);
      setProductCreateDt(props.selectedProduct.register_dt);
      setProductDescription(props.selectedProduct.output_description);
      setProductOutputFile(props.selectedProduct.output_file_path);
      setProductPrevFile(props.selectedProduct.output_preview);
      setProductShareLv(props.selectedProduct.share_lv);
    }
  },[props.selectedProduct])

  function modifyProduct () {

    const formData = new FormData();
    formData.append("ouputId", productOutputId);
    formData.append("title", productTitle);
    formData.append("tyCd", productTyCd);
    formData.append("addr", productAddr);
    if(productGeojson){formData.append("geojson", productGeojson);}
    if(productExtUrl){formData.append("extUrl", productExtUrl);}
    if(productCreateDt){formData.append("createDt", productCreateDt);}
    if(productDescription){formData.append("description", productDescription);}
    if(productOutputFile){formData.append("outputFile", productOutputFile);}
    if(productPrevFile){formData.append("prevFile", productPrevFile);}
    if(productShareLv){formData.append("shareLv", productShareLv);}

    axios({
      method:'post',
      url:`/api/output/update`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: formData,
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('산출물 정보를 수정하였습니다.');
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg('산출물 수정 실패');
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
      setPopupMsg('산출물 수정 요청 오류');
      setShowAlarmPopup(true);
    })
  }

  function deleteProduct () {
    axios({
      method:'post',
      url:`/api/output/delete`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: { ouputId: props.selectedProduct.output_id},
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg('산출물을 삭제하였습니다.');
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg(result.data.msg);
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      setPopupMsg('산출물 삭제 요청 오류');
      setShowAlarmPopup(true);
    })
  }

  function downloadProduct () {
    if(props.selectedProduct.output_file_path){
      window.location.href = props.selectedProduct.output_file_path;
    }
  }

  // 3D 모델 Loader
  const ModelLoader = ({ obj, mtl, textures }) => {

    const [object, setObject] = useState(null);
  
    useEffect(() => {
      const mtlLoader = new MTLLoader();
      mtlLoader.load(mtl, (materials) => {

        materials.preload();
  
        const objLoader = new OBJLoader();
        objLoader.load(obj, (loadedObject) => {
          setObject(loadedObject);
        });

        // Case No MTL : Define Texture Loader
        // if (textures) {
        //   for (const materialName in materials.materials) {
        //     const material = materials.materials[materialName];
        //     if (material && material.map && textures[material.map.name]) {
        //       material.map = new THREE.TextureLoader().load(textures[material.map.name]);
        //       material.map.needsUpdate = true;

        //       for (let texture of textures) {
        //         material.map = new THREE.TextureLoader().load(textures[0]);
        //         material.map.needsUpdate = true;
        //       }
        //     }
        //   }
        // }
  
      });
    }, [obj, mtl, textures]);
  
    return object ? <primitive object={object}/> : null;
  };

  // 3D 모델 State
  const [modelFiles, setModelFiles] = useState({ obj: null, mtl: null, textures: [] });

  // 3D 모델 저장 함수 접근
  useEffect(()=>{
    if(props.selectedProduct.output_ty_cd == 103)
      modelDonwloadRendering(props.selectedProduct.output_file_path, '3D_Model.zip');
  },[props.selectedProduct])

  async function modelDonwloadRendering(url, filename) {

    // 로딩 시작
    setLoading(true);

    // Zip 파일 메모리 저장
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      // const urlBlob = window.URL.createObjectURL(blob);

      // const downloadLink = document.createElement('a');
      // downloadLink.style.display = 'none';
      // downloadLink.href = urlBlob;
      // downloadLink.download = filename;

      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // window.URL.revokeObjectURL(urlBlob);
      // document.body.removeChild(downloadLink);

      // JSZip으로 Zip에 포함된 OBJ, MTL 파일 Setting
      JSZip.loadAsync(blob).then((zip) => {
        let objUrl = null;
        let mtlUrl = null;

        zip.forEach((relativePath, zipEntry) => {

          if (relativePath.endsWith('.obj')) {
            zipEntry.async('blob').then((objBlob) => {
              objUrl = URL.createObjectURL(objBlob);
              setModelFiles((prevFiles) => ({ ...prevFiles, obj: objUrl }));
            });
          } 
          else if (relativePath.endsWith('.mtl')) {
            zipEntry.async('blob').then((mtlBlob) => {
              mtlUrl = URL.createObjectURL(mtlBlob);
              setModelFiles((prevFiles) => ({ ...prevFiles, mtl: mtlUrl }));
            });
          } 
          // Case No MTL : Create Texture blob
          // else if (relativePath.endsWith('.jpg') || relativePath.endsWith('.jpeg') || relativePath.endsWith('.png')) {
          //   zipEntry.async('blob').then((textureBlob) => {
          //     textureUrl.push(URL.createObjectURL(textureBlob));
          //     setModelFiles((prevFiles) => ({ ...prevFiles, textures: textureUrl }));
          //   });
          // }
        });
      })
      
    } catch (error) {
      console.error('파일 다운로드 중 오류 발생:', error);
    }

    setLoading(false);
  }

  return (
  <React.Fragment>
    <Modal
      show={props.productDetailModal}
      className='contents-row product-detail-modal-container'
      icon={<i className="code x icon"  onClick={() => props.setProductDetailModal(false)} /> }
    >

      <div className='header-text modal-header'>산출물 상세보기</div>

      <div className='part-divider'></div>

      <div className='contents-row'>
        <div className='contents-col'>
          <div className='header-text product-reg'>제목</div>
          <div className='header-text product-reg'>구분</div>
          <div className='header-text product-reg'>위치</div>
          <div className='header-text product-reg'>파일</div>
          <div className='header-text product-reg'>미리보기</div>
          <div className='header-text product-reg'>URL</div>
          <div className='header-text product-reg'>공개범위</div>
          <div className='header-text product-reg'>설명</div>
        </div>
      
        <div className='contents-col'>
          <input 
            className='product-reg-input'
            readOnly={modifyMode? false:true}
            value={productTitle}
            onChange={(e)=>{setProductTitle(e.target.value)}}
          ></input>
          <Dropdown 
            className='product-reg-input dropdown'
            fluid
            selection
            disabled={modifyMode? false:true}
            options={typeOptions}
            value={productTyCd}
            onChange={(e, data)=>{setProductTyCd(data.value)}}
          >
          </Dropdown>
          <input 
            className='product-reg-input'
            readOnly={modifyMode? false:true}
            value={productAddr}
            onChange={(e)=>{setProductAddr(e.target.value)}}
          ></input>
          <input 
            className='product-reg-input'
            readOnly={modifyMode? false:true}
            value={productOutputFile? productOutputFile.substr(productOutputFile.lastIndexOf('/') + 1) : ''}
            onChange={(e)=>{}}
          ></input>
          <input 
            className='product-reg-input'
            readOnly={modifyMode? false:true}
            value={productPrevFile? productPrevFile.substr(productPrevFile.lastIndexOf('/') + 1) : ''}
            onChange={(e)=>{}}
          ></input>
          <input 
            className='product-reg-input'
            readOnly={modifyMode? false:true}
            value={productExtUrl? productExtUrl : ''}
            onChange={(e)=>{setProductExtUrl(e.target.value)}}
          ></input>
          
          <div className='product-reg-checkbox-group contents-row flex-center'>
            <Checkbox 
              className='product-reg-checkbox'
              label='전체공개'
              value={10}
              disabled={modifyMode? false:true}
              checked={productShareLv == 10}
              onChange={(e, data)=>{setProductShareLv(data.value)}}
            >
            </Checkbox>
            <div className='flex-spacer'></div>
            <Checkbox 
              className='product-reg-checkbox'
              label='비공개'
              value={1}
              disabled={modifyMode? false:true}
              checked={productShareLv == 1}
              onChange={(e, data)=>{setProductShareLv(data.value)}}
            >
            </Checkbox>
          </div>
          <textarea 
            className='product-reg-input long'
            readOnly={modifyMode? false:true}
            value={productDescription? productDescription : ''}
            onChange={(e)=>{setProductDescription(e.target.value)}}
          ></textarea>
        </div>
        {
          props.selectedProduct.output_ty_cd == 1?    // 이미지
          <div className='product-viewer flex-center' style={props.previewImage}></div>
          :
          props.selectedProduct.output_ty_cd == 2?    // 비디오
          <div className='product-viewer flex-center contents-col'>
            <div className='msg'>※ 아래 URL을 클릭하시면 유튜브 페이지로 연결됩니다.</div>
            <a className='url' href={props.selectedProduct.output_ext_url} target='_blank'>{props.selectedProduct.output_ext_url}</a>
          </div>
          :
          props.selectedProduct.output_ty_cd == 3?    // 기타
          <div className='product-viewer flex-center' style={props.previewImage}></div>
          :
          props.selectedProduct.output_ty_cd == 101?  // 정사영상
          <div className='product-viewer flex-center' style={props.previewImage}></div>
          :
          props.selectedProduct.output_ty_cd == 102?  // DEM
          <div className='product-viewer flex-center' style={props.previewImage}></div>
          :
          props.selectedProduct.output_ty_cd == 103?  // 3D 모델
          <div className='product-viewer flex-center'>

            <Canvas
              className='canvas-container'
              ref={canvasRef}
              // camera={{ fov: 100, near: 0.01, far: 1000, position: [0, 20, 0], rotation:[10,10,10] }}
            >
              <Suspense fallback={null}>
              <PerspectiveCamera
                makeDefault
                // rotation={[0, 10, 0]}
                fov={100}
                position={[0, 10, 0]}
                near={1}
                far={1000}
              ></PerspectiveCamera>
              <ambientLight intensity={0.5} />
              <directionalLight position={[10, 10, 5]} />
              <spotLight position={[300, 300, 300]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
              <pointLight position={[-300, -300, -300]} decay={0} intensity={Math.PI} />
              {modelFiles.obj && modelFiles.mtl && modelFiles.textures && <ModelLoader obj={modelFiles.obj} mtl={modelFiles.mtl} textures={modelFiles.textures} />}
              <OrbitControls 
                zoomSpeed={1.5} 
                // minPolarAngle={Math.PI}
                // maxPolarAngle={Math.PI}     // 수직 회전 각도제한
              />
              <GizmoHelper alignment="top-right" margin={[60, 60]}>
                <GizmoViewport labelColor="white" axisHeadScale={1} />
              </GizmoHelper>
              </Suspense>
            </Canvas>

          </div>
          :
          <div className='product-viewer flex-center'></div>
        }
      </div>
      {
      props.selectedProduct.user_id == userID || userType == 3?
      <div>
      {
        modifyMode == false?
        <div className='contents-row flex-center'>
          <div className='flex-center button modify' onClick={()=>{setModifyMode(true)}}>수정</div>
          <div className='flex-center button delete' onClick={()=>{setPopupState('deleteProduct');setShowQuestionPopup(true);setPopupMsg('산출물을 삭제하시겠습니까?');}}>삭제</div>
          <div className='flex-center button download' onClick={()=>{setPopupState('downloadProduct');setShowQuestionPopup(true);setPopupMsg('산출물을 다운로드하시겠습니까?');}}>다운로드</div>
          <div className='flex-center button cancel' onClick={()=>{props.setProductDetailModal(false)}}>닫기</div>
        </div>
        :
        <div className='contents-row flex-center'>
          <div className='flex-center button modify' onClick={()=>{setPopupState('modifyProduct');setShowQuestionPopup(true);setPopupMsg('산출물 정보를 수정하시겠습니까?');}}>수정</div>
          <div className='flex-center button cancel' onClick={()=>{setModifyMode(false)}}>취소</div>
        </div>
      }
      </div>
      :
      <div className='contents-row flex-center'>
        {
        props.selectedProduct.share_lv == 10?
        <div className='flex-center button download' onClick={()=>{setPopupState('downloadProduct');setShowQuestionPopup(true);setPopupMsg('산출물을 다운로드하시겠습니까?');}}>다운로드</div> : null
        }
        <div className='flex-center button cancel' onClick={()=>{props.setProductDetailModal(false)}}>닫기</div>
      </div>
      }
    </Modal>
    {
      showQuestionPopup?
      <Popup
        show={showQuestionPopup}
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p>{popupMsg}</p>
          <div className='contents-row'>
            <div 
              className="contents-row gc-btn flex-center ok_btn" 
              onClick={() => {
                if(popupState == 'modifyProduct'){
                  setShowQuestionPopup(false);
                  modifyProduct();
                }
                else if(popupState == 'deleteProduct'){
                  setShowQuestionPopup(false);
                  deleteProduct();
                }
                else if(popupState == 'downloadProduct'){
                  setShowQuestionPopup(false);
                  downloadProduct();
                }
              } 
              } 
            >{popupState == 'modifyProduct'? '수정' : popupState == 'deleteProduct'? '삭제' : '다운로드'}</div>
            <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
                setShowQuestionPopup(false);
              }}  
            >닫기</div>
          </div>
        </div>
      </Popup>
      :
      null
    }
    {
      showAlarmPopup?
      <Popup
        show={showAlarmPopup}
      >
        <div className='contents-col full-width flex-center popup-info'>
          <p>{popupMsg}</p>
          <div className='contents-row'>
            <div 
              className="contents-row gc-btn flex-center close_btn" 
              onClick={() => {
                if(popupState == 'deleteProduct'){
                  setShowAlarmPopup(false);
                  props.setSelectedProduct(false);
                  props.setSubstract(false);
                  props.setProductDetailModal(false);
                  props.getProductList();
                }
                else if(popupState == 'modifyProduct'){
                  setShowAlarmPopup(false);
                  props.setSelectedProduct(false);
                  props.setSubstract(false);
                  props.setProductDetailModal(false);
                  props.getProductList();
                  setModifyMode(false);
                }
                else{
                  setShowAlarmPopup(false);
                }
              }}  
            >닫기</div>
          </div>
        </div>
      </Popup>
      :
      null
    }

    {
      loading ? 
      <div className="loading-container">
        <div id="loading"></div>
      </div>
      : 
      null
    }

  </React.Fragment>
  )
}

export default ProductDetail;