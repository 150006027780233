import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import Backdrop from './Backdrop';
import './Popup.css';

const PopupOverlay = props => {
  const content = (
    <div className={`popup ${props.className}`} style={props.style}>
      <header className={`popup__header ${props.headerClass}`}>
      <span className='popup_header'>{props.header}</span>
        <span className='popup_icon'style={props.style} >{props.icon}</span>
      </header>
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : event => event.preventDefault()
        }
      >
        <div className={`popup__content flex-center ${props.contentClass}`}>
          {props.children}
        </div>
        <footer className={`popup__footer ${props.footerClass}`}>
          {props.footer}
        </footer>
      </form>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const Popup = props => {    // isOn
  return (
//     isOn ? (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="popup"
      >
        <PopupOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
    // ) : (<></>), Popup
  );
};

export default Popup;
