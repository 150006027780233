import './ProposalDetail.css';

import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate , useParams } from 'react-router-dom';

import { Dropdown } from 'semantic-ui-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popup from '../../shared/components/uiElements/Popup';

import moment from 'moment';

// AXIOS
import axios from "axios";

function ProposalDetail(props) {
  console.log(props)

  const APIToken = localStorage.getItem("login-token");
  const userType = localStorage.getItem('user-ty');

  const navigate = useNavigate();

  const [proposalInfo, setProposalInfo] = useState();
  const [partner, setPartner] = useState();
  const [rerender, setRerender] = useState(false);

  const [meetingDate, setMeetingDate] = useState();                   // 미팅 일자
  const [meetingDetail, setMeetingDetail] = useState('');             // 미팅 메모
  const [meetingComplete, setMeetingComplete] = useState(1);          // 미팅 완료 여부
  const meetingOption = [
    {text: '완료', value: true},
    {text: '미완료', value: false},
  ]

  const [process, setProcess] = useState(1);                          // 지원서 상태      
  const processOption = [
    {text: '작성중', value: 1},
    {text: '지원완료', value: 2},
    {text: '미팅', value: 4},
    {text: '계약 검토', value: 5},
    {text: '미팅 미선정', value: 100},
    {text: '계약 미선정', value: 101},
    {text: '계약 실패', value: 102},
    {text: '계약 완료', value: 200},
  ]
  
  const [popupState, setPopupState] = useState();                     // 팝업 상태
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);  // 질문팝업 boolen
  const [showAlarmPopup, setShowAlarmPopup] = useState(false);        // 알림팝업 boolen
  const [popupMsg, setPopupMsg] = useState('');                       // 팝업메시지 

  useEffect (() => {
    if(props.selectProposal){
      axios({
        method:'get',
        url:`/api/project/apply/info?applyId=${props.selectProposal.prj_apply_id}`,
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          let proposal_info = result.data.data;
          setProposalInfo(proposal_info);
          setMeetingDate(moment(proposal_info.meeting_dt).format("YYYY-MM-DD"));
          setMeetingDetail(proposal_info.meeting_memo);
          setMeetingComplete(proposal_info.meeting_done);
          setProcess(proposal_info.prj_apply_stts_cd);
          setRerender(false);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  } , [rerender])

  useEffect (() => {
    if(proposalInfo){
      axios({
        method:'get',
        url:`/api/account/profile?userId=${proposalInfo.user_id}`,  
        headers: {
          'Authorization': `Bearer {${APIToken}}`
        },
      })
      .then((result)=>{
        if(result.data.success == true){
          let partner_info = result.data.data;
          setPartner(partner_info);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  } , [proposalInfo])

  function exeptApply () {
    axios({
      method:'post',
      url:`/api/project/apply/status`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        applyId: proposalInfo.prj_apply_id,
        sttsCd: 100,
      }
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg("지원서를 제외하였습니다.");
        setShowAlarmPopup(true);
      }
      else{
        setPopupMsg(result.data.msg);
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  function interestedApply () {

    let postInter = null;

    if(proposalInfo.interested_yn == true)
      {postInter = 'false'}
    else if(proposalInfo.interested_yn == false)
      {postInter = 'true'}

    axios({
      method:'post',
      url:`/api/project/apply/interested`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        applyId: proposalInfo.prj_apply_id,
        interested: postInter,
      }
    })
    .then((result)=>{
      if(result.data.success == true){
        setRerender(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  function meetingApply () {
    axios({
      method:'post',
      url:`/api/project/apply/status`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {
        applyId: proposalInfo.prj_apply_id,
        sttsCd: 4,
      }
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg("파트너와 미팅을 신청하였습니다.\n상세 일정과 내용을 작성해주세요.");
        setShowAlarmPopup(true);
        setRerender(true);
      }
      else{
        setPopupMsg(result.data.msg);
        setShowAlarmPopup(true);
        setRerender(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  function updateMeeting () {
    axios({
      method:'post',
      url:`/api/project/apply/meet`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {  
        applyId: proposalInfo.prj_apply_id,
        meetDt: meetingDate,
        meetDone: meetingComplete,
        meetMemo: meetingDetail,
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg("미팅 정보를 업데이트하였습니다.");
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        console.log(result.data.msg);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  function updateProcess () {
    axios({
      method:'post',
      url:`/api/project/apply/status`,
      headers: {
        'Authorization': `Bearer {${APIToken}}`
      },
      data: {  
        applyId: proposalInfo.prj_apply_id,
        sttsCd: process,
      },
    })
    .then((result)=>{
      if(result.data.success == true){
        setPopupMsg("지원서의 진행 상태를\n업데이트하였습니다.");
        setShowAlarmPopup(true);
      }
      else if(result.data.success == false){
        setPopupMsg(result.data.msg);
        setShowAlarmPopup(true);
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  // 평점
  const lineStyle = {
    position: 'relative',
    width: '101.25px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  return (
    <React.Fragment>
      <div className='proposal-view-detail'>
        <div className='proposal-view-detail-main contents-col align-center'>
          <div className='partner-summary-box contents-row'>
            {
            partner && partner.photoUrl != null? 
            <img className='partner-logo' src={partner && partner.photoUrl} alt=""></img> 
            : 
            <div className='partner-logo null'></div>
            }
            <div className='contents-col part-main'>
              <div className='contents-row'>
                <div className='partner-name'>{partner && partner.user_nm}</div>
                <div className='flex-spacer'></div>
                {
                  partner && partner.co_ty == 1?
                  <div className='contents-row'>
                    <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-user"/></div>
                    <div className='partner-type'>개인</div>
                  </div>
                  :
                  partner && partner.co_ty == 2?
                  <div className='contents-row'>
                    <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-building"/></div>
                    <div className='partner-type'>개인사업자</div>
                  </div>
                  :
                  partner && partner.co_ty == 3?
                  <div className='contents-row'>
                    <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-building"/></div>
                    <div className='partner-type'>법인사업자</div>
                  </div>
                  :
                  null
                }
                {
                  partner && partner.certified_com_yn?
                  <div className='contents-row'>
                    <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award" /></div>
                    <div className='partner-certification'>인증업체</div>
                  </div>
                  :
                  null
                  // <div className='contents-row'>
                  //   <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-award none" /></div>
                  //   <div className='partner-certification'>비인증업체</div>
                  // </div>
                }
                {
                  partner && partner.user_itrst_yn?
                  <div className='flex-center'><FontAwesomeIcon icon="fa-solid fa-heart"/></div>
                  :
                  <div className='flex-center'><FontAwesomeIcon icon="fa-regular fa-heart"/></div>
                }
                <div className='partner-detail flex-center' onClick={()=>{navigate(`/partner/${partner.user_id}`)}}>더 보기</div>
              </div>
              <div className='partner-introduce'>{partner && partner.intro == null || partner && partner.intro == 'null'? '소개없음' : partner && partner.intro}</div>
            </div>
              
            <div className='contents-col part-sub'>
              <div className='contents-row score'>
                <FontAwesomeIcon icon="fa-solid fa-star"/>
                <FontAwesomeIcon icon="fa-solid fa-star"/>
                <FontAwesomeIcon icon="fa-solid fa-star"/>
                <FontAwesomeIcon icon="fa-regular fa-star"/>
                <FontAwesomeIcon icon="fa-regular fa-star"/>
                <div className='text'>{partner && partner.review_score_avr.toFixed(1)} / 0명</div>
              </div>
              {/* <div className='contents-row'>
                <div className='contents-row score' style={lineStyle}>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-regular fa-star" style={{ fontSize: "18px" }}/>
                </div>
                <div className='contents-row score' style={{ position: 'absolute', overflow: 'hidden', width: ((partner.review_score_avr / 5) * 101.25) + 'px' }}>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                  <FontAwesomeIcon icon="fa-solid fa-star" style={{ fontSize: "18px" }}/>
                </div>
              </div> */}
              <div className='contents-row'>
                <div className='partner-option textA'>계약한 프로젝트</div>
                <div className='partner-option textB'>{partner && partner.contract_co} 건</div>
              </div>
              <div className='contents-row'>
                <div className='partner-option textA'>포트폴리오</div>
                <div className='partner-option textB'>{partner && partner.prtfolio_co} 건</div>
              </div>
              <div className='contents-row'>
                <div className='partner-option textA'>DROPIA 활동 기간</div>
                <div className='partner-option textB'>{partner && moment(moment(), "YYYY-MM-DD").diff(partner.user_join_dt, 'month')} 개월</div>
              </div>
            </div>
          </div>
          <div className='proposal-abstract'>
            <div className='project-apply'>
              <div className='header-text'>프로젝트 지원하기</div>
              <div className='contents-row'>
                <div className='option contents-row flex-center'>
                  <div className='option-name flex-center'>제안금액</div>
                  <div className='proposal-input text flex-center' >{proposalInfo && proposalInfo.prj_apply_cost_tt}</div>
                  <div className='sub-text flex-center'> 만원</div>
                </div>
                <div className='option contents-row flex-center'>
                  <div className='option-name flex-center'>제안일정</div>
                  <div className='proposal-input text flex-center'>{proposalInfo && proposalInfo.prj_apply_duration_day}</div>
                  <div className='sub-text flex-center'> 일</div>
                </div>
              </div>
              <div className='header-text'>제안 내용</div>
              <div className='contents-input'>{proposalInfo && proposalInfo.prj_apply_content}</div>
              <div className='part-divider'></div>
              <div className='header-text'>관련 포트폴리오</div>
              <div className='portfolio-group contents-row'>
                <div className='portfolio flex-center'>포트폴리오</div>
              </div>
            </div>
          </div>
        </div>

        <div className='proposal-view-detail-sub contents-col'>

          {
          userType == 2 && proposalInfo?
          <div className='button-group contents-col flex-center'>
            <div 
              className='back button flex-center'
              onClick={()=>{props.setSelectProposal();}}
            >뒤로가기</div>
            
            
            {
            proposalInfo && proposalInfo.interested_yn == true && proposalInfo.prj_apply_stts_cd == 2?
            <div 
              className={`inter button flex-center`}
              onClick={()=>{interestedApply()}}
            ><FontAwesomeIcon icon="fa-solid fa-heart"/>지원서 찜하기</div>
            :
            proposalInfo && proposalInfo.interested_yn == false && proposalInfo.prj_apply_stts_cd == 2?
            <div 
              className={`inter button flex-center`}
              onClick={()=>{interestedApply()}}
            ><FontAwesomeIcon icon="fa-regular fa-heart"/>지원서 찜하기</div>
            :
            null
            }
            {
            proposalInfo.prj_apply_stts_cd == 2?
            <div 
              className='except button flex-center'
              onClick={()=>{setPopupState('except');setShowQuestionPopup(true);setPopupMsg('지원서 선정에서 제외하시겠습니까?');}}
            >선정 제외하기</div>
            :
            null
            }
            {
            proposalInfo.prj_apply_stts_cd == 2?
            <div 
              className='meeting button flex-center'
              onClick={()=>{setPopupState('meeting');setShowQuestionPopup(true);setPopupMsg('지원 파트너와 미팅을 신청하시겠습니까?');}}
            >미팅 신청하기</div>
            :
            <div className='meeting done button flex-center'>미팅 신청 완료</div>
            }
          </div>
          :
          null
          }
          
          {
          userType == 3?
          <div className='meeting-management contents-col'>
            <div className='header-text'>미팅 관리</div>
            <div className='part-divider'></div>
            <div className='contents-row'>
              <div className='content-text flex-center'>미팅일자</div>
              <div className='flex-spacer'></div>
              <input 
                className='meeting-date flex-center' 
                type='date'
                value={meetingDate}
                onChange={(e)=>{setMeetingDate(e.target.value)}}
              >
              </input>
            </div>
            <div className='contents-row'>
              <div className='content-text flex-center'>완료 여부</div>
              <div className='flex-spacer'></div>
              <Dropdown
                className='dropdown'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={meetingOption}
                value={meetingComplete}
                onChange={(e, data)=>{setMeetingComplete(data.value)}}
              ></Dropdown>
            </div>
            <div className='content-text'>미팅 메모</div>
            <textarea 
              className='memo-textarea content-text'
              placeholder='미팅 정보를 입력해 주세요.'
              value={meetingDetail}
              onChange={(e)=>{setMeetingDetail(e.target.value)}}
            ></textarea>
            <div className='button cancel flex-center' onClick={()=>{props.setSelectProposal();}}>뒤로가기</div>
            <div className='button save flex-center'
              onClick={()=>{updateMeeting()}}
            >저장하기</div>
          </div>
          :
          null
          }
          
          {
          userType == 3?
          <div className='contract-management'>
            <div className='header-text'>계약 관리</div>
            <div className='part-divider'></div>
            <div className='contents-row'>
              <div className='content-text flex-center'>진행상태</div>
              <div className='flex-spacer'></div>
              <Dropdown
                className='dropdown'
                placeholder='선택'
                fluid
                selection
                icon='angle down'
                options={processOption}
                value={process}
                onChange={(e, data)=>{setProcess(data.value)}}
              ></Dropdown>
            </div>
            <div 
              className='button save flex-center header-text'
              onClick={()=>{updateProcess()}}
            >저장하기</div>
          </div>
          :
          null
          }

        </div>
      </div>

      {
        showQuestionPopup?
        <Popup
          show={showQuestionPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center ok_btn" 
                onClick={() => {
                  if (popupState == 'except'){
                    setShowQuestionPopup(false);
                    exeptApply();
                  }
                  else if (popupState == 'meeting'){
                    setShowQuestionPopup(false);
                    meetingApply();
                  }
                }}  
              >{popupState == 'except'? '제외' : popupState == 'meeting'? '신청' : '확인'}</div>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowQuestionPopup(false);
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

      {
        showAlarmPopup?
        <Popup
          show={showAlarmPopup}
        >
          <div className='contents-col full-width flex-center popup-info'>
            <p>{popupMsg}</p>
            <div className='contents-row'>
              <div 
                className="contents-row gc-btn flex-center close_btn" 
                onClick={() => {
                  setShowAlarmPopup(false);
                  if(popupState == 'except'){
                    props.setSelectProposal(false);
                    props.getProposalList();
                  }
                }}  
              >닫기</div>
            </div>
          </div>
        </Popup>
        :
        null
      }

    </React.Fragment>
  )

}

export default ProposalDetail;