import './ManagerProfile.css';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Pagination, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// AXIOS
import axios from "axios";

function MyProject() {

    // /localStorage
    const APIToken = localStorage.getItem("login-token");
    const loginUserId = localStorage.getItem('user-id');
  
    // Pagination
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

  const navigate = useNavigate();

    // 서브메뉴
    const [profileState, setProfileState] = useState('entire-project');
    const handleStateChange = (newState) => {
        setProfileState(newState);
        // window.history.pushState({ profileState: newState }, '', '/profile');
    };

    // main-title
    let titleText;
    switch (profileState) {
        case 'entire-project': titleText = '전체 프로젝트'; break;
        case 'my-project': titleText = '내 프로젝트'; break;
        case 'account-mgt': titleText = '계정 관리'; break;
        case 'my-profile': titleText = '프로필'; break;
    }


    // 진행중인 프로젝트 (첫 렌더링 데이터 : 1)
    const [managerPrjSttsCd, setManagerPrjSttsCd] = useState(1);
        // const [prjSttsCd, setPrjSttsCd] = useState('');
        const progressSteps = [
        { id: 1, label: '생성' },
        { id: 2, label: '지원' },
        { id: 3, label: '미팅' },
        { id: 4, label: '계약' },
        { id: 5, label: '진행' },
        { id: 7, label: '완료' }
    ];

    // 전체 프로젝트
    const [managerProjectList1, setManagerProjectList1] = useState([]); // 생성
    const [managerProjectList2, setManagerProjectList2] = useState([]); // 지원
    const [managerProjectList3, setManagerProjectList3] = useState([]); // 미팅
    const [managerProjectList4, setManagerProjectList4] = useState([]); // 계약
    const [managerProjectList5, setManagerProjectList5] = useState([]); // 진행
    const [managerProjectList7, setManagerProjectList7] = useState([]); // 완료
    const [managerProjectList100, setManagerProjectList100] = useState([]); // 실패
    
    const [entireProjectList, setEntireProjectList] = useState([]);
    const projectPageSize = 6;
    console.log(loginUserId)
    const fetchManagerData = (prjSttsCd, setManagerProjectList) => {
        if (prjSttsCd) {
        axios({
            method:'get',
            url:`/api/project/list?managerId=${loginUserId}&prjSttsCd=${prjSttsCd}&page=${activePage}&perPage=${projectPageSize}`,
            headers: {
                'Authorization': `Bearer {${APIToken}}`
            },
        })
        .then((result)=>{
            // console.log(result.data.data);
            if(result.data.success === true) {
                let projectList = result.data;
                setManagerProjectList(projectList.data);
                setTotalPages(Math.ceil(projectList.total / projectPageSize));
            }
        })
        .catch((error)=>{
            console.log(error)
        })
        }
    };

    // 전체 프로젝트
    // useEffect (() => {
    //   fetchManagerData(1, setManagerProjectList1);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(2, setManagerProjectList2);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(3, setManagerProjectList3);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(4, setManagerProjectList4);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(5, setManagerProjectList5);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(7, setManagerProjectList7);
    // }, []);
    // useEffect (() => {
    //   fetchManagerData(100, setManagerProjectList100);
    // }, []);
    useEffect(() => {
        fetchManagerData(managerPrjSttsCd, getSetterByStatus(managerPrjSttsCd));
    }, [managerPrjSttsCd, activePage]);

    const getSetterByStatus = (status) => {
        switch (status) {
        case 1: return setManagerProjectList1;
        case 2: return setManagerProjectList2;
        case 3: return setManagerProjectList3;
        case 4: return setManagerProjectList4;
        case 5: return setManagerProjectList5;
        case 7: return setManagerProjectList7;
        case 100: return setManagerProjectList100;
        default: return setManagerProjectList1;
        }
    };
    
    // 첫 렌더링 데이터
    useEffect(() => {
        if (managerPrjSttsCd === 1) {
        setManagerProjectList(managerProjectList1);
        }
    }, [managerProjectList1]);

    const [managerProjectList, setManagerProjectList] = useState([]); 
    // const handleManagerProgressClick = (statusNumber) => {
    //   let selectedProjectList = [];
    //   switch (statusNumber) {
    //     case 1:
    //       selectedProjectList = managerProjectList1;
    //       break;
    //     case 2:
    //       selectedProjectList = managerProjectList2;
    //       break;
    //     case 3:
    //       selectedProjectList = managerProjectList3;
    //       break;
    //     case 4:
    //       selectedProjectList = managerProjectList4;
    //       break;
    //     case 5:
    //       selectedProjectList = managerProjectList5;
    //       break;
    //     case 7:
    //       selectedProjectList = managerProjectList7;
    //       break;
    //     case 100:
    //       selectedProjectList = managerProjectList100;
    //       break;
    //     default:
    //       selectedProjectList = [];
    //   }
    //   setManagerProjectList(selectedProjectList);
    //   setManagerPrjSttsCd(statusNumber);
    // };
    const handleManagerProgressClick = (statusNumber) => {
        setManagerPrjSttsCd(statusNumber);
        setActivePage(1);  // 페이지 변경 시 1페이지로 초기화
        setManagerProjectList(getListByStatus(statusNumber));
    };

    const getListByStatus = (status) => {
        switch (status) {
        case 1: return managerProjectList1;
        case 2: return managerProjectList2;
        case 3: return managerProjectList3;
        case 4: return managerProjectList4;
        case 5: return managerProjectList5;
        case 7: return managerProjectList7;
        case 100: return managerProjectList100;
        default: return [];
        }
    };


    const getManagerProjectListLength = (stepId) => {
        switch (stepId) {
            case 1:
            return managerProjectList1.length;
            case 2:
            return managerProjectList2.length;
            case 3:
            return managerProjectList3.length;
                break;
            case 4:
            return managerProjectList4.length;
            case 5:
            return managerProjectList5.length;
            case 7:
            return managerProjectList7.length;
            case 100:
            return managerProjectList100.length;
            default:
            return 0
        }
    };

    // 프로젝트 진행상태
    const statusTextMap = {
        1: '생성',
        2: '지원',
        3: '미팅',
        4: '계약',
        5: '진행',
        6: '검수',
        7: '완료',
        // 7: '지급',
        // 8: '완료',
        100: '실패'
    };
        

    return (
        <React.Fragment>
            <div className='full-width'>
            <div className='main-text'>진행중인 프로젝트</div>
            <div className='contents-row flex-center project-progress'>
            {progressSteps && progressSteps.map((step, index) => {
                const projectListLength = getManagerProjectListLength(step.id);
                return (
                    <React.Fragment key={step.id}>
                        <div className={`flex-center contents-col progress-circle ${managerPrjSttsCd === step.id ? 'click' : ''}`} onClick={() => handleManagerProgressClick(step.id)}>
                        {/* <div className='flex-center contents-col progress-circle' onClick={() => handleProgressClick(step.id)}> */}
                            <span>{step.label}</span>
                            <span>{projectListLength}</span>
                        </div>
                        {index !== progressSteps.length - 1 && <FontAwesomeIcon icon="fa-solid fa-chevron-right"/>}
                    </React.Fragment>
                );
            })}
            </div>
            </div>
            {managerPrjSttsCd &&  (
            <>
            <div className='hr-solid'/>
            <div className='full-width'>
                {/* <div className='main-text'>완료 프로젝트</div> */}
                <div className='main-text'>
                    {progressSteps.map(step => {
                        if (step.id === parseInt(managerPrjSttsCd)) {
                            return `${step.label} 프로젝트`;
                        }
                    })}
                </div>
                {managerProjectList && managerProjectList.length !== 0 ? 
                <React.Fragment>
                {managerProjectList.map((managerPrjItem, index) => {
                    // console.log(myPartnerProjectList);
                    return (
                        <div className='contents-row full-width flex-center project-progress req' key={index}>
                        {/* {console.log(clientPrjItem)} */}
                        <div className='contents-col flex-center spacer progress-box req'>
                            <p>{managerPrjItem.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p>예상금액 {managerPrjItem.exptd_cost_tt} 만원 | 예상일정 {managerPrjItem.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[managerPrjItem.prj_stts_cd]}</span></p>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>{managerPrjItem.prj_ty_nm}</div>
                            <div className='contents-col req-state'>
                                {/* <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{clientPrjItem.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p> */}
                                <div className='flex-center'>
                                    <FontAwesomeIcon icon='fa-solid fa-clipboard-check' className={`fs-icon-clipboard ${managerPrjItem.prj_qc_yn? '':'none'}`}/>
                                    <div className={`prj-qc-yn ${managerPrjItem.prj_qc_yn? '':'none'}`}>{managerPrjItem.prj_qc_yn? '검수 포함' : '검수 미포함'}</div>
                                </div>
                                
                                <p className='contents-row flex-center project-user-info'>
                                    {/* <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/> */}
                                    {console.log(managerPrjItem.prj_owner_user_photo)}
                                    {managerPrjItem.prj_owner_user_photo === null ? (
                                            <FontAwesomeIcon icon="fa-solid fa-user-large" className="user-logo" />
                                    ) : (
                                        <img
                                            className="user-logo-img small"
                                            src={managerPrjItem.prj_owner_user_photo}
                                            alt="Uploaded Image"
                                            style={{ maxWidth: '100%', maxHeight: '22px' }}
                                        />
                                    )}
                                    {managerPrjItem.prj_owner_user_nm}
                                </p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${managerPrjItem.prj_id}`)}}>상세보기</div>
                        </div>
                        </div>
                    );
                })}
                <div className="flex-center">
                    <div className="flex-center">
                    <Pagination
                        className="pagination"
                        activePage={activePage}
                        totalPages={totalPages}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}   
                        onPageChange={handlePageChange}
                    >                        
                    </Pagination>
                    </div>
                </div>
                </React.Fragment>
                : 
                    <div className='contents-row full-width flex-center'>
                        <p className='result-info'>
                            {progressSteps.map(step => {
                                if (step.id === parseInt(managerPrjSttsCd)) {
                                    return `${step.label} 프로젝트가 없습니다.`;
                                }
                            })}
                        </p>
                        {/* <p className='result-info'>회사 연혁 없습니다.</p> */}
                    </div>
                }
                {/* {projectList.map((project, i) => (
                    <div className='contents-row flex-center project-progress req' key={i}>
                        <div className='contents-col flex-center spacer progress-box req apply'>
                            <p>{project.prj_nm}<FontAwesomeIcon icon="fa-solid fa-envelope-open-text"/></p>
                            <p className='apply'>예상금액 {project.exptd_cost_tt} 만원 | 예상일정 {project.exptd_duration_day} 일<span className='gc-btn gc-btn-line prj-stts-cd'>{statusTextMap[project.prj_stts_cd]}</span></p>
                        </div>
                        <div className='contents-row spacer progress-box req'>
                            <div className='gc-btn info-btn video'>{project.prj_ty_nm}</div>
                            <div className='contents-col req-state'>
                                <p><FontAwesomeIcon icon="fa-solid fa-clipboard-check"/>{project.prj_qc_yn ? '검수 포함' : '검수 미포함'}</p>
                                <p className='contents-row flex-center'>
                                    <FontAwesomeIcon icon="fa-solid fa-user-large" className='user-logo'/>
                                    e****e
                                </p>
                            </div>
                            <div className='gc-btn info-btn flex-center view-detail' onClick={()=>{navigate(`/project/${project.prj_id}`)}}>상세보기</div>
                        </div>
                    </div>
                ))} */}
            </div>
            </>
            )}
        </React.Fragment>
    );
}

export default MyProject;